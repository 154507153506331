import {Module, VuexModule, Mutation, getModule, Action} from 'vuex-module-decorators'
import {Auth} from "~/shared/Backend.dtos";

@Module({
  name: 'session',
  stateFactory: true,
  namespaced: true
})
export default class SessionModule extends VuexModule {
  auth: Auth = Auth.createInstance();

  @Mutation
  public save(auth: Auth) {
    this.auth = auth;
  }

  @Mutation
  public destroy() {
    this.auth = Auth.createInstance();
  }

  get roles() {
    return this.auth.roles
  }

  get isActive() {
    return this.auth.exp > (new Date().getTime() / 1000);
  }

  get isAdmin() {
    return this.auth.roles?.includes('Admin')
  }

  get isUser() {
    return this.auth.roles?.includes('User')
  }
}
