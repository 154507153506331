import {JsonServiceClient} from "@servicestack/client";
import {NuxtAxiosInstance} from "@nuxtjs/axios";
import {
  Auth,
  Contact, EmailOffer,
  EmailTemplate,
  File as FileEntity,
  Iso3166_1, Like,
  Offer,
  Request,
  RequestOffer
} from "~/shared/Backend.dtos";
import {OfferType} from "~/entities/OfferType";
import {
  Geocoding, GeocodingResponse,
  GetBlogPost, GetBlogPostResponse,
  GetBlogPosts,
  GetBlogPostsResponse, GetFrontendPage, GetFrontendPageResponse, GetFrontendText, GetFrontendTextResponse,
  GetWpPage, GetWpPageResponse, PostFrontendPage, PostFrontendText
} from "~/shared/MyService.dtos";
import ApiResponse from "~/entities/ApiResponse";
import {Upload} from 'tus-js-client'

export class Api {
  private _serviceStackClient: JsonServiceClient = null;
  private _backendAxiosClient: NuxtAxiosInstance = null;
  private _storage: Storage = {
    getItem: () => '',
    setItem: () => null,
    removeItem: () => null,
    clear: () => null,
    key: () => '',
    length: 0
  }

  public constructor(axiosInstance: NuxtAxiosInstance) {
    if (typeof window !== 'undefined') {
      this._storage = window.localStorage;
    }

    this._serviceStackClient = new JsonServiceClient(axiosInstance.defaults.baseURL);
    this._backendAxiosClient = axiosInstance;

    if (this.token) {
      this._serviceStackClient.bearerToken = this.token;
      this._backendAxiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
    }
  }

  public get token(): string {
    return this._storage.getItem('bearerToken');
  }

  public set token(value: string) {
    this._storage.setItem('bearerToken', value);
    this._serviceStackClient.bearerToken = value;
    this._backendAxiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + value;
  }

  public destroy(): void {
    this._storage.removeItem('bearerToken');
    delete this._serviceStackClient.bearerToken;
    delete this._backendAxiosClient.defaults.headers.common['Authorization'];
  }

  public async checkAuth(): Promise<Auth> {
    return await this._backendAxiosClient.$get('/api/v1/auth/').then(({data}) => data);
  }

  public async createAuth(username: string, password: string): Promise<Auth> {
    return await this._backendAxiosClient.$post('/api/v1/auth/', {username, password}).then(({data}) => data);
  }

  public async getRequest(uuid: string): Promise<Request> {
    return await this._backendAxiosClient.$get('/api/v1/requests/' + uuid).then(({data}) => data);
  }

  public async getOffer(uuid: string): Promise<Offer> {
    return await this._backendAxiosClient.$get('/api/v1/offers/' + uuid).then(({data}) => data);
  }

  public async getOfferBySlug(slug: string): Promise<Offer> {
    return await this._backendAxiosClient.$get('/api/v1/offers/slug/' + slug).then(({data}) => data);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async searchOffers(type: OfferType, params, includeAll = false): Promise<Offer> {
    return await this._backendAxiosClient.$get('/api/v1/offers', {
      params: {
        ...params,
        'offer_type': type,
        include: !includeAll ? [
          '_infotext',
          '_praxis_art',
          '_praxis_art_kleintier',
          '_praxis_art_pferde',
          '_praxis_art_gemischt',
          '_praxis_art_rinder',
          '_praxis_art_gefluegel',
          '_praxis_art_schwein',
          '_praxis_art_reptilien',
          '_praxis_art_industrie',
          '_praxis_art_verwaltung',
          '_praxis_art_universitaet',
          '_praxis_art_heimtiere',
          '_praxis_art_oeffentlicher_dienst',
          '_praxis_hat_preisvorstellung',
          '_praxis_fachliche_spezialisierung',
          '_praxis_form',
          '_praxis_flaeche',
          '_praxis_fortbildung',
          '_praxis_fortbildung_angebot',
          '_praxis_gruendungsjahr',
          '_praxis_hat_notdienst',
          '_praxis_notdienst',
          '_praxis_notdienst_auszahlung',
          '_praxis_notdienst_freizeitausgleich',
          '_praxis_sprechstunde',
          '_praxis_hat_terminsprechstunde',
          '_praxis_hat_vertretungsregelung',
          '_praxis_jahresnettoumsatz_2015',
          '_praxis_jahresnettoumsatz_2016',
          '_praxis_jahresnettoumsatz_2017',
          '_praxis_jahresnettoumsatz_2018',
          '_praxis_kurzbeschreibung',
          '_praxis_materieller_wert',
          '_praxis_patientenbesitzer',
          '_praxis_preisvorstellung',
          '_praxis_sonstige_beschreibung',
          '_praxis_telefon_homepage_uebernahme',
          '_praxis_uebergabedatum',
          '_praxis_uebernahme_in',
          '_praxis_uebernahme',
          '_praxis_teilhaber',
          '_praxis_ueberschrift',
          '_praxis_zulassungsstatus',
          '_alle_regionen',
          '_region_bw',
          '_region_by',
          '_region_be',
          '_region_bb',
          '_region_hb',
          '_region_hh',
          '_region_he',
          '_region_mv',
          '_region_ni',
          '_region_nw',
          '_region_rp',
          '_region_sl',
          '_region_sn',
          '_region_sa',
          '_region_sh',
          '_region_th',
          '_region_0x',
          '_region_1x',
          '_region_2x',
          '_region_3x',
          '_region_4x',
          '_region_5x',
          '_region_6x',
          '_region_7x',
          '_region_8x',
          '_region_9x',
          '_slug',
          '_slug_v2',
          '_stammdaten_ort',
          '_stammdaten_vorname',
          '_stammdaten_nachname',
          '_stammdaten_plz',
          '_stammdaten_adresse',
          '_standort_geolocated',
          '_standort_lat',
          '_standort_lng',
          '_standort',
          '_standort_land',
          '_stellenart_assistenzstelle',
          '_stellenart_praktikum',
          '_stellenart_praxisvertreter',
          '_stellenart_tfa',
          '_stellenart_praktikum_ausland',
          '_stellenart_selbststaendig',
          '_stellenart_teilhaber',
          '_stellenart_tierarzt',
          '_stellenart_tfa_ausbildung',
          '_stellenart_dissertation',
          '_stellenart_is_empfehlung',
          '_title',
          '_subtitle',
          '_chiffre',
          '_besonderheit_berufseinsteiger_willkommen',
          '_admin_is_sold'
        ] : null
      },
    }).then(({data}) => {
      if (data.totalItems === 0) {
        throw new Error();
      }
      return data;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async countOffers(type: OfferType, params): Promise<Offer> {
    return await this._backendAxiosClient.$get('/api/v1/offers?stats=1', {
      params: {...params, 'offer_type': type},
      progress: false
    }).then(({data}) => data);
  }

  public async countAllOffers(): Promise<Offer> {
    return await this._backendAxiosClient.$get('/api/v1/offers?stats=1', {
      progress: false
    }).then(({data}) => data);
  }

  public async createOffer(type: OfferType, data: Offer): Promise<Offer> {
    return await this._backendAxiosClient.$post('/api/v1/offers', {...data, offer_type: type}).then(({data}) => data);
  }

  public async updateOffer(uuid: string, data: Offer): Promise<Offer> {
    return await this._backendAxiosClient.$put('/api/v1/offers/' + uuid, data).then(({data}) => data);
  }

  public async createContact(data: Contact): Promise<Contact> {
    return await this._backendAxiosClient.$post('/api/v1/contacts/', data).then(({data}) => data);
  }

  public async updateContact(uuid: string, data: Contact): Promise<Contact> {
    return await this._backendAxiosClient.$put('/api/v1/contacts/' + uuid, data).then(({data}) => data);
  }

  public async searchMatchingOffers(uuid: string): Promise<Offer> {
    return await this._backendAxiosClient.$get('/api/v1/matching/', {
      params: {
        uuid
      }
    }).then(({data}) => {
      if (data.totalItems === 0) {
        throw new Error();
      }
      return data;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async searchRequest(params?): Promise<Request> {
    return await this._backendAxiosClient.$get('/api/v1/requests/', { params }).then(({data}) => {
      if (data.totalItems === 0) {
        throw new Error();
      }
      return data;
    })
  }

  public async searchRequestOffers(uuid: string): Promise<RequestOffer> {
    return await this._backendAxiosClient.$get('/api/v1/requestoffers/', {
      params: {
        uuid
      }
    }).then(({data}) => {
      if (data.totalItems === 0) {
        return {
          totalItems: 0,
          items: []
        };
      }
      return data;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async searchContacts(params?): Promise<Contact> {
    return await this._backendAxiosClient.$get('/api/v1/contacts', { params }).then(({data}) => data);
  }

  public async getEmailOffers(message_type: string): Promise<EmailOffer> {
    return await this._backendAxiosClient.$get('/api/v1/email_offers', {
      params: {
        message_type
      }
    }).then(({data}) => data);
  }

  public async createEmailOffer(data: EmailOffer): Promise<EmailOffer> {
    return await this._backendAxiosClient.$post('/api/v1/email_offers', data).then(({data}) => data);
  }

  public async createRequest(data: Request): Promise<Request> {
    return await this._backendAxiosClient.$post('/api/v1/requests', data).then(({data}) => data);
  }

  public async createRequestOffer(data: RequestOffer): Promise<RequestOffer> {
    return await this._backendAxiosClient.$post('/api/v1/requestoffers', data).then(({data}) => data);
  }

  public async updateRequest(uuid: string, data: Request): Promise<Request> {
    return await this._backendAxiosClient.$put('/api/v1/requests/' + uuid, data).then(({data}) => data);
  }

  public async updateRequestOffer(id: number, data: RequestOffer): Promise<RequestOffer> {
    return await this._backendAxiosClient.$put('/api/v1/requestoffers/' + id, data).then(({data}) => data);
  }

  public async deleteRequest(uuid: string): Promise<Request> {
    return await this._backendAxiosClient.$delete('/api/v1/requests/' + uuid).then(({data}) => data);
  }

  public async getEmailTemplate(name: string): Promise<EmailTemplate> {
    return await this._backendAxiosClient.$get('/api/v1/email_templates/' + name).then(({data}) => data);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async searchEmailTemplates(params?): Promise<EmailTemplate> {
    return await this._backendAxiosClient.$get('/api/v1/email_templates', { params }).then(({data}) => {
      if (!data.items || data.totalItems === 0) {
        throw new Error();
      }
      return data;
    });
  }

  public async createEmailTemplate(data: EmailTemplate): Promise<EmailTemplate> {
    return await this._backendAxiosClient.$post('/api/v1/email_templates', data).then(({data}) => data);
  }

  public async updateEmailTemplate(uuid: string, data: EmailTemplate): Promise<EmailTemplate> {
    return await this._backendAxiosClient.$put('/api/v1/email_templates/' + uuid, data).then(({data}) => data);
  }

  public async deleteEmailTemplate(uuid: string): Promise<EmailTemplate> {
    return await this._backendAxiosClient.$delete('/api/v1/email_templates/' + uuid).then(({data}) => data);
  }

  public async getBlogPosts(limit = 4): Promise<GetBlogPostsResponse> {
    return await this._serviceStackClient.get(new GetBlogPosts({
      categories: [30],
      limit: limit
    })).then(data => {
      data.results.forEach(item => delete(item.content));
      return data;
    });
  }

  public async getBlogPost(request: GetBlogPost): Promise<GetBlogPostResponse> {
    return this._serviceStackClient.get(request)
  }

  public async geocode(address: string, country = 'DE'): Promise<GeocodingResponse> {
    return this._serviceStackClient.get(new Geocoding({
      address,
      country
    }));
  }

  public getFrontendText(uniqueIdentifier: string): Promise<GetFrontendTextResponse> {
    return this._serviceStackClient.get(new GetFrontendText({
      uniqueIdentifier
    }));
  }

  public async saveFrontendText(frontendText: PostFrontendText): Promise<boolean> {
    try {
      await this._serviceStackClient.post(frontendText);
      return true;
    } catch (e) {
      return false;
    }
  }

  public getFrontendPage(uniqueIdentifier: string): Promise<GetFrontendPageResponse> {
    return this._serviceStackClient.get(new GetFrontendPage({
      uniqueIdentifier
    }))
  }

  public async saveFrontendPage(frontendPage: PostFrontendPage): Promise<boolean> {
    try {
      await this._serviceStackClient.post(frontendPage);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async getPage(page: GetWpPage): Promise<GetWpPageResponse> {
    return await this._serviceStackClient.get(page);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async searchFiles(params?): Promise<FileEntity> {
    return await this._backendAxiosClient.$get('/api/v1/files', {params}).then(({data}) => {
      if (!data.items || data.totalItems === 0) {
        throw new Error();
      }
      return data;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async updateFilesDisplayOrder(items: FileEntity[], params?): Promise<ApiResponse<FileEntity>> {
    const {entity_uuid, ...extractedParams} = params;
    return await this._backendAxiosClient.$put(`/api/v1/files_display_order/${entity_uuid}`, {items},{params: extractedParams});
  }

  public async getContact(uuid: string): Promise<Contact> {
    return await this._backendAxiosClient.$get('/api/v1/contacts/' + uuid).then(({data}) => data);
  }

  public async deleteContact(uuid: string): Promise<Contact> {
    return await this._backendAxiosClient.$delete('/api/v1/contacts/' + uuid);
  }

  public async deleteOffer(uuid: string): Promise<Offer> {
    return await this._backendAxiosClient.$delete('/api/v1/offers/' + uuid);
  }

  public async deleteFile(uuid: string): Promise<File> {
    return await this._backendAxiosClient.$delete('/api/v1/files/' + uuid);
  }

  public async createFile(data: FileEntity): Promise<FileEntity> {
    return await this._backendAxiosClient.$post('/api/v1/files/', data).then(({data}) => data);
  }

  public async createContactFile(data: FileEntity): Promise<FileEntity> {
    return await this._backendAxiosClient.$post('/api/v1/contact_files/', data).then(({data}) => data);
  }

  public async getLikes(entity_uuid: string): Promise<ApiResponse<Like>> {
    return await this._backendAxiosClient.$get('/api/v1/likes?entity_uuid=' + entity_uuid);
  }

  public async createLike(data: Like): Promise<Like> {
    return await this._backendAxiosClient.$post('/api/v1/likes', data).then(({data}) => data);
  }

  public async upload(
    file: File,
    uuid: string,
    path: string,
    onProgress: ({bytesUploaded, bytesTotal, percentage}: {bytesUploaded: number, bytesTotal: number, percentage: number}) => void): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) => {
      const client = new Upload(file, {
        endpoint: this.baseURL('/upload'),
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 1024 * 100,
        headers: {
          Authorization: 'Bearer ' + this.token
        },
        metadata: {
          file: file.name,
          path: path,
          uuid: uuid
        },
        onError(err) {
          console.log("Upload Error", err)
          reject(err);
        },
        onSuccess() {
          resolve(true);
        },
        onProgress(bytesUploaded, bytesTotal) {
          const percentage = bytesUploaded / bytesTotal;
          onProgress({bytesUploaded, bytesTotal, percentage})
        }
      });
      client.start();
    })
  }

  public baseURL(relativeURL: string): string {
    const baseURL = this._backendAxiosClient.defaults.baseURL;
    return relativeURL
      ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')
      : baseURL;
  }

  public async newOffer(): Promise<Offer> {
    return await this._backendAxiosClient.$get('/api/v1/offers/new').then(({data}) => data);
  }

  public async getCountries(): Promise<Iso3166_1> {
    return await this._backendAxiosClient.$get('/api/v1/countries').then(({data}) => data);
  }
}

let api;

export function initializeApi(axiosInstance: NuxtAxiosInstance): Api {
  api = new Api(axiosInstance);
  return api;
}

export { api }
