import {Vue} from 'nuxt-property-decorator'
import * as VueGoogleMaps from 'gmap-vue'

export const gmapApi = VueGoogleMaps.gmapApi;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA2aOfdslbt_0UIB_-PAws2znXJUXAQyP8'
  }
})
