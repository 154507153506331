import {Context, Plugin} from '@nuxt/types'
import { initializeApi, Api } from '~/shared/api'

let $api: Api;

declare module '@nuxt/types' {
  interface Context {
    $api: Api
  }
}

const accessor: Plugin = (context: Context, inject: any) => {
  $api = initializeApi(context.$axios);
  context.$api = $api;
  inject('api', $api);
}

export default accessor

export { $api }
