

























import {Component, Prop, Vue} from 'nuxt-property-decorator';
import WizardStepConfig from "~/entities/WizardStepConfig";

@Component
export default class WizardIndicator extends Vue {
  @Prop({ type: Array })
  steps: WizardStepConfig[];

  @Prop({ type: Number })
  currentIndex: number;

  @Prop({ type: String, default: 'primary' })
  variant;

  @Prop({ type: Boolean })
  navigation;
}
