










































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Request} from "~/shared/Backend.dtos";
import {OfferType} from "~/entities/OfferType";

@Component
export default class AgentModal extends Vue {
  @Prop({type: String, required: true})
  id;

  @Prop({type: String, required: true})
  label;

  @Prop({type: Number, required: true})
  request: OfferType;

  @Prop({type: Object})
  value;

  form = {}
  promise: Promise<Request> = null;

  public namespaced(append: string): string {
    switch (this.request) {
      case OfferType.Stelle:
        return 'jobagent-' + append
      case OfferType.Praxis:
        return 'praxisagent' + append
    }
  }

  public createAgent(): void {
    this.promise = this.$api.createRequest(new Request({...this.value, ...this.form, request_type: this.request}));
  }

  public removeFilter(key): void {
    const value = {...this.value};
    delete value[key];
    this.$emit('input', value);
  }

  @Watch('value', {deep: true})
  handleChange(): void {
    this.promise = null;
  }
}
