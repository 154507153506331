import dayjs from 'dayjs'

import 'dayjs/locale/de'

dayjs.locale('de')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
