import WizardStepConfig from "~/entities/WizardStepConfig";
import {Offer} from "~/shared/Backend.dtos";
import WizardOptionCheckbox from "~/entities/WizardOptionCheckbox";
import WizardOptionLabel from "~/entities/WizardOptionLabel";
import WizardOptionText from "~/entities/WizardOptionText";
import {WizardOptionType} from "~/entities/WizardOptionType";
import WizardOptionRow from "~/entities/WizardOptionRow";
import WizardOptionRadio from "~/entities/WizardOptionRadio";
import {Off} from "nuxt-property-decorator";

export default class WizardConfig {
  constructor(
    public name: string = '',
    public steps?: WizardStepConfig[]
  ) {
  }

  /**
   *
   * @param data
   * @param filterPredicate
   * @param limit
   */
  getQueryParams(data, filterPredicate: (step: WizardStepConfig) => boolean, limit = null) {
    const queryParams = {}
    // Filter steps by predicate and slice by limit
    this.steps.filter(filterPredicate).slice(0, limit || this.steps.length).forEach(step =>{
      // Only include options with values
      step.options.filter(x => x.hasValue(data)).forEach(option => {
        if (option.name) {
          queryParams[option.name] = option.getValue(data);
        }
        // Only include child options with values
        option.options.filter(x => x.hasValue(data)).forEach(x => {
          queryParams[x.name] = x.getValue(data);
        })
      })
    })
    return queryParams
  }
}

function optionsFromLabels(label: string) {
  return Object.keys(Offer.labels.de)
    .filter(key => key.startsWith('_ausstattung_'))
    .map(key => new WizardOptionCheckbox(key, Offer.labels.de[key]));
}

export const presets = {
  arbeitsantritt: [
    new WizardOptionRadio(Offer._arbeitsantritt_monate, 'Ab sofort', '1'),
    new WizardOptionRadio(Offer._arbeitsantritt_monate, 'Innerhalb von ca. 3 Monaten', '3'),
    new WizardOptionRadio(Offer._arbeitsantritt_monate, 'Innerhalb von ca. 6 Monaten', '6'),
    new WizardOptionRadio(Offer._arbeitsantritt_monate, 'Innerhalb von ca. 12 Monaten', '12'),
    new WizardOptionRadio(Offer._arbeitsantritt_monate, '> 12 Monate', '13')
  ],

  arbeitsplatz: [
    new WizardOptionCheckbox(Offer._einrichtung_tierklinik, Offer.labels.de[Offer._einrichtung_tierklinik]),
    new WizardOptionCheckbox(Offer._einrichtung_tierarztpraxis, Offer.labels.de[Offer._einrichtung_tierarztpraxis]),
    new WizardOptionCheckbox(Offer._einrichtung_fachtierarztpraxis, Offer.labels.de[Offer._einrichtung_fachtierarztpraxis]),
    new WizardOptionCheckbox(Offer._einrichtung_fahrpraxis, Offer.labels.de[Offer._einrichtung_fahrpraxis]),
    new WizardOptionCheckbox(Offer._einrichtung_sonstiges, Offer.labels.de[Offer._einrichtung_sonstiges]).setOptions([
      new WizardOptionText(Offer._einrichtung, Offer.labels.de[Offer._einrichtung])
    ])
  ],

  arbeitsplatzStelle: [
    new WizardOptionCheckbox(Offer._einrichtung_tierklinik, Offer.labels.de[Offer._einrichtung_tierklinik]),
    new WizardOptionCheckbox(Offer._einrichtung_tierarztpraxis, Offer.labels.de[Offer._einrichtung_tierarztpraxis]),
    new WizardOptionCheckbox(Offer._einrichtung_fachtierarztpraxis, Offer.labels.de[Offer._einrichtung_fachtierarztpraxis]),
    new WizardOptionCheckbox(Offer._einrichtung_fahrpraxis, Offer.labels.de[Offer._einrichtung_fahrpraxis]),
    new WizardOptionCheckbox(Offer._einrichtung_industrie, Offer.labels.de[Offer._einrichtung_industrie]),
    new WizardOptionCheckbox(Offer._einrichtung_oeffentlicher_dienst, Offer.labels.de[Offer._einrichtung_oeffentlicher_dienst]),
    new WizardOptionCheckbox(Offer._einrichtung_hochschule, Offer.labels.de[Offer._einrichtung_hochschule])
  ],

  ausstattungen: optionsFromLabels('_ausstattung_'),

  ausstattungenStelle: [
    new WizardOptionCheckbox(Offer._ausstattung_roentgen_digital, Offer.labels.de[Offer._ausstattung_roentgen_digital]),
    new WizardOptionCheckbox(Offer._ausstattung_ultraschall, Offer.labels.de[Offer._ausstattung_ultraschall]),
    new WizardOptionCheckbox(Offer._ausstattung_herzultraschall, Offer.labels.de[Offer._ausstattung_herzultraschall]),
    new WizardOptionCheckbox(Offer._ausstattung_ct, Offer.labels.de[Offer._ausstattung_ct]),
    new WizardOptionCheckbox(Offer._ausstattung_mrt, Offer.labels.de[Offer._ausstattung_mrt]),
    new WizardOptionCheckbox(Offer._ausstattung_szintigraph, Offer.labels.de[Offer._ausstattung_szintigraph]),
    new WizardOptionCheckbox(Offer._ausstattung_inhouse_labor, Offer.labels.de[Offer._ausstattung_inhouse_labor]),
    new WizardOptionCheckbox(Offer._ausstattung_endoskop, Offer.labels.de[Offer._ausstattung_endoskop]),
    new WizardOptionCheckbox(Offer._ausstattung_osteosynthese_set, Offer.labels.de[Offer._ausstattung_osteosynthese_set]),
    new WizardOptionCheckbox(Offer._ausstattung_narkosegeraet, Offer.labels.de[Offer._ausstattung_narkosegeraet]),
    new WizardOptionCheckbox(Offer._ausstattung_mikroskop, Offer.labels.de[Offer._ausstattung_mikroskop]),
    new WizardOptionCheckbox(Offer._ausstattung_station, Offer.labels.de[Offer._ausstattung_station]),
    new WizardOptionCheckbox(Offer._ausstattung_getrennter_wartebereich, Offer.labels.de[Offer._ausstattung_getrennter_wartebereich]),
    new WizardOptionCheckbox(Offer._ausstattung_software_zur_praxisverwaltung, Offer.labels.de[Offer._ausstattung_software_zur_praxisverwaltung]),
    new WizardOptionCheckbox(Offer._ausstattung_sonstiges, Offer.labels.de[Offer._ausstattung_sonstiges]).setOptions([
      new WizardOptionText(Offer._ausstattung, Offer.labels.de[Offer._ausstattung])
    ])
  ],

  behandlungen: [
    new WizardOptionCheckbox(Offer._behandlung_akupunktur, Offer.labels.de[Offer._behandlung_akupunktur]),
    new WizardOptionCheckbox(Offer._behandlung_chiropraktik, Offer.labels.de[Offer._behandlung_chiropraktik]),
    new WizardOptionCheckbox(Offer._behandlung_ct, Offer.labels.de[Offer._behandlung_ct]),
    new WizardOptionCheckbox(Offer._behandlung_dentalroentgen, Offer.labels.de[Offer._behandlung_dentalroentgen]),
    new WizardOptionCheckbox(Offer._behandlung_digitales_roentgen, Offer.labels.de[Offer._behandlung_digitales_roentgen]),
    new WizardOptionCheckbox(Offer._behandlung_endoskopie, Offer.labels.de[Offer._behandlung_endoskopie]),
    new WizardOptionCheckbox(Offer._behandlung_herzultraschall, Offer.labels.de[Offer._behandlung_herzultraschall]),
    new WizardOptionCheckbox(Offer._behandlung_inhouse_labor, Offer.labels.de[Offer._behandlung_inhouse_labor]),
    new WizardOptionCheckbox(Offer._behandlung_mobiles_roentgen, Offer.labels.de[Offer._behandlung_mobiles_roentgen]),
    new WizardOptionCheckbox(Offer._behandlung_mrt, Offer.labels.de[Offer._behandlung_mrt]),
    new WizardOptionCheckbox(Offer._behandlung_op_mit_narkose, Offer.labels.de[Offer._behandlung_op_mit_narkose]),
    new WizardOptionCheckbox(Offer._behandlung_osteopathie, Offer.labels.de[Offer._behandlung_osteopathie]),
    new WizardOptionCheckbox(Offer._behandlung_osteosynthese, Offer.labels.de[Offer._behandlung_osteosynthese]),
    new WizardOptionCheckbox(Offer._behandlung_physiotherapie, Offer.labels.de[Offer._behandlung_physiotherapie]),
    new WizardOptionCheckbox(Offer._behandlung_stationaer, Offer.labels.de[Offer._behandlung_stationaer]),
    new WizardOptionCheckbox(Offer._behandlung_szintigraphie, Offer.labels.de[Offer._behandlung_szintigraphie]),
    new WizardOptionCheckbox(Offer._behandlung_ultraschall, Offer.labels.de[Offer._behandlung_ultraschall]),
    new WizardOptionCheckbox(Offer._behandlung_zahnbehandlungen_mobil, Offer.labels.de[Offer._behandlung_zahnbehandlungen_mobil]),
  ],

  besonderheiten: [
    new WizardOptionLabel('Ihre Praxis').setOptions([
      new WizardOptionCheckbox(Offer._besonderheit_gvp_zertifiziert, Offer.labels.de[Offer._besonderheit_gvp_zertifiziert]),
      new WizardOptionCheckbox(Offer._besonderheit_cat_friendly, Offer.labels.de[Offer._besonderheit_cat_friendly]),
      new WizardOptionCheckbox(Offer._besonderheit_mitglied_vuk, Offer.labels.de[Offer._besonderheit_mitglied_vuk]),
    ]),
    new WizardOptionLabel('Zusätzliche Benefits zum Gehalt').setOptions([
      new WizardOptionCheckbox(Offer._besonderheit_bav, Offer.labels.de[Offer._besonderheit_bav]),
      new WizardOptionCheckbox(Offer._besonderheit_bkv, Offer.labels.de[Offer._besonderheit_bkv]),
      new WizardOptionCheckbox(Offer._besonderheit_bezahlte_fortbildung, Offer.labels.de[Offer._besonderheit_bezahlte_fortbildung]),
      new WizardOptionCheckbox(Offer._besonderheit_dienstwagen, Offer.labels.de[Offer._besonderheit_dienstwagen]),
      new WizardOptionCheckbox(Offer._besonderheit_dienstwagen_privat, Offer.labels.de[Offer._besonderheit_dienstwagen_privat]),
      new WizardOptionCheckbox(Offer._besonderheit_mittagessen_kostenlos, Offer.labels.de[Offer._besonderheit_mittagessen_kostenlos]),
      new WizardOptionCheckbox(Offer._besonderheit_sportangebot, Offer.labels.de[Offer._besonderheit_sportangebot]),
      new WizardOptionCheckbox(Offer._besonderheit_gehaltsmodell, Offer.labels.de[Offer._besonderheit_gehaltsmodell]),
      new WizardOptionCheckbox(Offer._besonderheit_urlaubsgeld, Offer.labels.de[Offer._besonderheit_urlaubsgeld]),
      new WizardOptionCheckbox(Offer._besonderheit_weihnachtsgeld, Offer.labels.de[Offer._besonderheit_weihnachtsgeld]),
    ]),
    new WizardOptionLabel('Arbeitszeiten').setOptions([
      new WizardOptionCheckbox(Offer._besonderheit_arbeitszeiterfassung, Offer.labels.de[Offer._besonderheit_arbeitszeiterfassung]),
      new WizardOptionCheckbox(Offer._besonderheit_kein_notdienst, Offer.labels.de[Offer._besonderheit_kein_notdienst]),
      new WizardOptionCheckbox(Offer._besonderheit_notdienstring, Offer.labels.de[Offer._besonderheit_notdienstring]),
    ]),
    new WizardOptionLabel('Ihr Team').setOptions([
      new WizardOptionCheckbox(Offer._besonderheit_familiaere, Offer.labels.de[Offer._besonderheit_familiaere]),
      new WizardOptionCheckbox(Offer._besonderheit_feedbackgespraeche, Offer.labels.de[Offer._besonderheit_feedbackgespraeche]),
      new WizardOptionCheckbox(Offer._besonderheit_teamevents, Offer.labels.de[Offer._besonderheit_teamevents]),
    ]),
    new WizardOptionLabel('Arbeiten').setOptions([
      new WizardOptionCheckbox(Offer._besonderheit_eigenverantwortung, Offer.labels.de[Offer._besonderheit_eigenverantwortung]),
      new WizardOptionCheckbox(Offer._besonderheit_gute_einarbeitung, Offer.labels.de[Offer._besonderheit_gute_einarbeitung]),
      new WizardOptionCheckbox(Offer._besonderheit_rotation_in_bereiche, Offer.labels.de[Offer._besonderheit_rotation_in_bereiche]),
      new WizardOptionCheckbox(Offer._besonderheit_berufseinsteiger_willkommen, Offer.labels.de[Offer._besonderheit_berufseinsteiger_willkommen]),
    ]),
    new WizardOptionLabel('Weitere Besonderheiten').setOptions([
      new WizardOptionCheckbox(Offer._besonderheit_moegl_doktorarbeit, Offer.labels.de[Offer._besonderheit_moegl_doktorarbeit]),
      new WizardOptionCheckbox(Offer._besonderheit_mitnahme_hund, Offer.labels.de[Offer._besonderheit_mitnahme_hund]),
      new WizardOptionCheckbox(Offer._besonderheit_moegl_teilhaberschaft, Offer.labels.de[Offer._besonderheit_moegl_teilhaberschaft]),
    ])
  ],

  besonderheitenPraktikum: [
    new WizardOptionLabel('Praktikum').setOptions([
      new WizardOptionCheckbox(Offer._besonderheit_praktikumsverguetung, Offer.labels.de[Offer._besonderheit_praktikumsverguetung]),
      new WizardOptionCheckbox(Offer._besonderheit_wohnen_vor_ort, Offer.labels.de[Offer._besonderheit_wohnen_vor_ort]),
      new WizardOptionCheckbox(Offer._besonderheit_mittagessen_kostenlos, Offer.labels.de[Offer._besonderheit_mittagessen_kostenlos]),
      new WizardOptionCheckbox(Offer._besonderheit_praktikumsleitfaden, Offer.labels.de[Offer._besonderheit_praktikumsleitfaden]),
      new WizardOptionCheckbox(Offer._besonderheit_mitnahme_hund, Offer.labels.de[Offer._besonderheit_mitnahme_hund]),
    ]),
    new WizardOptionLabel('Praktikumsalltag').setOptions([
      new WizardOptionCheckbox(Offer._besonderheit_rotation_in_bereiche, Offer.labels.de[Offer._besonderheit_rotation_in_bereiche]),
      new WizardOptionCheckbox(Offer._besonderheit_familiaere, Offer.labels.de[Offer._besonderheit_familiaere]),
      new WizardOptionCheckbox(Offer._besonderheit_feedbackgespraeche, Offer.labels.de[Offer._besonderheit_feedbackgespraeche]),
      new WizardOptionCheckbox(Offer._besonderheit_gute_einarbeitung, Offer.labels.de[Offer._besonderheit_gute_einarbeitung]),
    ]),
    new WizardOptionLabel('Notdienste').setOptions([
      new WizardOptionCheckbox(Offer._besonderheit_keine_nacht_und_wochenenddienste, 'Es fallen keinerlei Nacht- und Wochenenddienste an.'),
      new WizardOptionCheckbox(Offer._besonderheit_nacht_und_wochenenddienste_auf_wunsch, 'Der Praktikant wird auf Wunsch in Nacht- und Wochenenddienste (Notdienste) eingebunden.'),
      new WizardOptionCheckbox(Offer._besonderheit_nacht_und_wochenenddienste_geregelt, 'Der Praktikant ist in geregelte Nacht- und Wochenenddienste (Notdienste) eingebunden.'),
    ])
  ],

  besonderheitenPraktikumList: [
    new WizardOptionCheckbox(Offer._besonderheit_praktikumsverguetung, Offer.labels.de[Offer._besonderheit_praktikumsverguetung]),
    new WizardOptionCheckbox(Offer._besonderheit_wohnen_vor_ort, Offer.labels.de[Offer._besonderheit_wohnen_vor_ort]),
    new WizardOptionCheckbox(Offer._besonderheit_mittagessen_kostenlos, Offer.labels.de[Offer._besonderheit_mittagessen_kostenlos]),
    new WizardOptionCheckbox(Offer._besonderheit_praktikumsleitfaden, Offer.labels.de[Offer._besonderheit_praktikumsleitfaden]),
    new WizardOptionCheckbox(Offer._besonderheit_mitnahme_hund, Offer.labels.de[Offer._besonderheit_mitnahme_hund]),
    new WizardOptionCheckbox(Offer._besonderheit_rotation_in_bereiche, Offer.labels.de[Offer._besonderheit_rotation_in_bereiche]),
    new WizardOptionCheckbox(Offer._besonderheit_familiaere, Offer.labels.de[Offer._besonderheit_familiaere]),
    new WizardOptionCheckbox(Offer._besonderheit_feedbackgespraeche, Offer.labels.de[Offer._besonderheit_feedbackgespraeche]),
    new WizardOptionCheckbox(Offer._besonderheit_gute_einarbeitung, Offer.labels.de[Offer._besonderheit_gute_einarbeitung]),
    new WizardOptionCheckbox(Offer._besonderheit_keine_nacht_und_wochenenddienste, 'Es fallen keinerlei Nacht- und Wochenenddienste an.'),
    new WizardOptionCheckbox(Offer._besonderheit_nacht_und_wochenenddienste_auf_wunsch, 'Der Praktikant wird auf Wunsch in Nacht- und Wochenenddienste (Notdienste) eingebunden.'),
    new WizardOptionCheckbox(Offer._besonderheit_nacht_und_wochenenddienste_geregelt, 'Der Praktikant ist in geregelte Nacht- und Wochenenddienste (Notdienste) eingebunden.')
  ],

  besonderheitenStelle: [
    new WizardOptionCheckbox(Offer._besonderheit_bav, Offer.labels.de[Offer._besonderheit_bav]),
    new WizardOptionCheckbox(Offer._besonderheit_bkv, Offer.labels.de[Offer._besonderheit_bkv]),
    new WizardOptionCheckbox(Offer._besonderheit_vwl, Offer.labels.de[Offer._besonderheit_vwl]),
    new WizardOptionCheckbox(Offer._besonderheit_fahrtkostenzuschuss, Offer.labels.de[Offer._besonderheit_fahrtkostenzuschuss]),
    new WizardOptionCheckbox(Offer._besonderheit_dienstwagen, Offer.labels.de[Offer._besonderheit_dienstwagen]),
    new WizardOptionCheckbox(Offer._besonderheit_mittagessen_kostenlos, 'Kostenlose Getränke/Mittagessen'),
    new WizardOptionCheckbox(Offer._besonderheit_sportangebot, 'Sportkurse'),
    new WizardOptionCheckbox(Offer._besonderheit_feedbackgespraeche, Offer.labels.de[Offer._besonderheit_feedbackgespraeche]),
    new WizardOptionCheckbox(Offer._besonderheit_teamevents, Offer.labels.de[Offer._besonderheit_teamevents]),
    new WizardOptionCheckbox(Offer._besonderheit_keine_nacht_und_wochenenddienste, 'Keine Nacht- und Notdienste'),
    new WizardOptionCheckbox(Offer._besonderheit_nacht_und_wochenenddienste_geregelt, 'Geregelte Nacht- und Notdienste'),
    new WizardOptionCheckbox(Offer._besonderheit_moegl_doktorarbeit, 'Doktorarbeit parallel möglich'),
    new WizardOptionCheckbox(Offer._besonderheit_mitnahme_hund, Offer.labels.de[Offer._besonderheit_mitnahme_hund]),
    new WizardOptionCheckbox(Offer._besonderheit_moegl_teilhaberschaft, 'Option zur Teilhaberschaft'),
    new WizardOptionCheckbox(Offer._besonderheit_wohnen_vor_ort, Offer.labels.de[Offer._besonderheit_wohnen_vor_ort]),
    new WizardOptionCheckbox(Offer._besonderheit_berufseinsteiger_willkommen, Offer.labels.de[Offer._besonderheit_berufseinsteiger_willkommen]),
  ],

  fortbildung: [
    new WizardOptionCheckbox(Offer._praxis_fortbildung, 'Weiter&shy;bildungs&shy;ermächtigung vorhanden?').setOptions([
      new WizardOptionText(Offer._fortbildungsbudget, Offer.labels.de[Offer._fortbildungsbudget] + ' in EUR', WizardOptionType.Numeric)
    ])
  ],

  fortbildungStelle: [
    new WizardOptionCheckbox(Offer._praxis_fortbildung, 'Weiterbildungsermächtigung vorhanden'),
    new WizardOptionCheckbox(Offer._besonderheit_interne_fortbildungen, 'regelmäßige, interne Fortbildungen'),
    new WizardOptionCheckbox(Offer._fortbildung_freie_tage, 'freie Tage für Fortbildungen'),
    new WizardOptionCheckbox(Offer._fortbildungsbudget_vorhanden, 'Fortbildungsbudget')
  ],

  infrastruktur: [
    new WizardOptionCheckbox(Offer._standort_hat_parkplatz, Offer.labels.de[Offer._standort_hat_parkplatz]).setOptions([
      new WizardOptionText(Offer._standort_anzahl_parkplatz).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._standort_hat_bushaltestelle, Offer.labels.de[Offer._standort_hat_bushaltestelle]).setOptions([
      new WizardOptionText(Offer._standort_entfernung_bushaltestelle).setPlaceholder('km').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._standort_hat_bahnhof, Offer.labels.de[Offer._standort_hat_bahnhof]).setOptions([
      new WizardOptionText(Offer._standort_entfernung_bahnhof).setPlaceholder('km').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._standort_hat_kindergarten, Offer.labels.de[Offer._standort_hat_kindergarten]).setOptions([
      new WizardOptionText(Offer._standort_entfernung_kindergarten).setPlaceholder('km').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._standort_hat_grundschule, Offer.labels.de[Offer._standort_hat_grundschule]).setOptions([
      new WizardOptionText(Offer._standort_entfernung_grundschule).setPlaceholder('km').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._standort_hat_schule, Offer.labels.de[Offer._standort_hat_schule]).setOptions([
      new WizardOptionText(Offer._standort_entfernung_schule).setPlaceholder('km').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._standort_hat_einkauf, Offer.labels.de[Offer._standort_hat_einkauf]).setOptions([
      new WizardOptionText(Offer._standort_entfernung_einkauf).setPlaceholder('km').setType(WizardOptionType.Numeric)
    ])
  ],

  infrastrukturEinfach: [
    new WizardOptionCheckbox(Offer._standort_hat_mitarbeiterparkplatz, Offer.labels.de[Offer._standort_hat_parkplatz]),
    new WizardOptionCheckbox(Offer._standort_hat_bahnhof, Offer.labels.de[Offer._standort_hat_bahnhof]),
    new WizardOptionCheckbox(Offer._standort_entfernung_bushaltestelle, Offer.labels.de[Offer._standort_entfernung_bushaltestelle]),
  ],

  infrastrukturLangform: [
    new WizardOptionCheckbox(Offer._standort_hat_mitarbeiterparkplatz, Offer.labels.de[Offer._standort_hat_parkplatz]),
    new WizardOptionCheckbox(Offer._standort_hat_bahnhof, Offer.labels.de[Offer._standort_hat_bahnhof]),
    new WizardOptionCheckbox(Offer._standort_entfernung_bushaltestelle, Offer.labels.de[Offer._standort_entfernung_bushaltestelle]),
  ],

  infrastrukturStelle: [
    new WizardOptionCheckbox(Offer._standort_hat_mitarbeiterparkplatz, 'Es stehen Mitarbeiterparkplätze zur Verfügung'),
    new WizardOptionCheckbox(Offer._standort_hat_parkmoeglichkeiten, 'Es gibt ausreichende Parkmöglichkeiten in unmittelbarer Umgebung'),
    new WizardOptionCheckbox(Offer._standort_hat_oeffentliche_anbindung, 'Mit öffentlichem Nahverkehr problemlos erreichbar'),
  ],

  mitarbeiter: [
    new WizardOptionText(Offer._mitarbeiter_ta, Offer.labels.de[Offer._mitarbeiter_ta]).setType(WizardOptionType.Numeric),
    new WizardOptionText(Offer._mitarbeiter_tfa, Offer.labels.de[Offer._mitarbeiter_tfa]).setType(WizardOptionType.Numeric),
    new WizardOptionText(Offer._mitarbeiter_sonstige, Offer.labels.de[Offer._mitarbeiter_sonstige]).setType(WizardOptionType.Numeric),
  ],

  notdienst: [
    new WizardOptionRadio(Offer._praxis_notdienst, 'Ja', 'ja'),
    new WizardOptionRadio(Offer._praxis_notdienst, 'Nur in Ausnahmefällen', 'ausnahme'),
    new WizardOptionRadio(Offer._praxis_notdienst, 'Nein', 'nein'),
  ],

  oeffnungszeiten: [
    new WizardOptionCheckbox(Offer._wochentag_montag, Offer.labels.de[Offer._wochentag_montag]).setOptions([
      new WizardOptionText(Offer._arbeitszeit_montag).setPlaceholder('Öffnungszeiten')
    ]),
    new WizardOptionCheckbox(Offer._wochentag_dienstag, Offer.labels.de[Offer._wochentag_dienstag]).setOptions([
      new WizardOptionText(Offer._arbeitszeit_dienstag).setPlaceholder('Öffnungszeiten')
    ]),
    new WizardOptionCheckbox(Offer._wochentag_mittwoch, Offer.labels.de[Offer._wochentag_mittwoch]).setOptions([
      new WizardOptionText(Offer._arbeitszeit_mittwoch).setPlaceholder('Öffnungszeiten')
    ]),
    new WizardOptionCheckbox(Offer._wochentag_donnerstag, Offer.labels.de[Offer._wochentag_donnerstag]).setOptions([
      new WizardOptionText(Offer._arbeitszeit_donnerstag).setPlaceholder('Öffnungszeiten')
    ]),
    new WizardOptionCheckbox(Offer._wochentag_freitag, Offer.labels.de[Offer._wochentag_freitag]).setOptions([
      new WizardOptionText(Offer._arbeitszeit_freitag).setPlaceholder('Öffnungszeiten')
    ]),
    new WizardOptionCheckbox(Offer._wochentag_samstag, Offer.labels.de[Offer._wochentag_samstag]).setOptions([
      new WizardOptionText(Offer._arbeitszeit_samstag).setPlaceholder('Öffnungszeiten')
    ]),
    new WizardOptionCheckbox(Offer._wochentag_sonntag, Offer.labels.de[Offer._wochentag_sonntag]).setOptions([
      new WizardOptionText(Offer._arbeitszeit_sonntag).setPlaceholder('Öffnungszeiten')
    ])
  ],

  praxisabgabe: [
    new WizardOptionRadio(Offer._praxis_abgabe_in, 'Zum nächstmöglichen Zeitpunkt', '1'),
    new WizardOptionRadio(Offer._praxis_abgabe_in, 'Innerhalb von ca. 1 Jahr', '12'),
    new WizardOptionRadio(Offer._praxis_abgabe_in, 'Innerhalb von ca. 1-2 Jahren', '24'),
    new WizardOptionRadio(Offer._praxis_abgabe_in, '> 2 Jahre', '25'),
  ],

  praxisarten: [
    new WizardOptionCheckbox(Offer._praxis_art_kleintier, Offer.labels.de[Offer._praxis_art_kleintier]),
    new WizardOptionCheckbox(Offer._praxis_art_pferde, Offer.labels.de[Offer._praxis_art_pferde]),
    new WizardOptionCheckbox(Offer._praxis_art_rinder, Offer.labels.de[Offer._praxis_art_rinder]),
    new WizardOptionCheckbox(Offer._praxis_art_schwein, Offer.labels.de[Offer._praxis_art_schwein]),
    new WizardOptionCheckbox(Offer._praxis_art_gefluegel, Offer.labels.de[Offer._praxis_art_gefluegel]),
    new WizardOptionCheckbox(Offer._praxis_art_ziervoegel, Offer.labels.de[Offer._praxis_art_ziervoegel]),
    new WizardOptionCheckbox(Offer._praxis_art_heimtiere, Offer.labels.de[Offer._praxis_art_heimtiere]),
    new WizardOptionCheckbox(Offer._praxis_art_gemischt, Offer.labels.de[Offer._praxis_art_gemischt]),
    new WizardOptionCheckbox(Offer._praxis_art_reptilien, Offer.labels.de[Offer._praxis_art_reptilien]),
    new WizardOptionCheckbox(Offer._praxis_art_oeffentlicher_dienst, Offer.labels.de[Offer._praxis_art_oeffentlicher_dienst]),
    new WizardOptionCheckbox(Offer._praxis_art_hochschule, Offer.labels.de[Offer._praxis_art_hochschule]),
    new WizardOptionCheckbox(Offer._praxis_art_industrie,  Offer.labels.de[Offer._praxis_art_industrie]),
  ],

  praxisartenMitAnteil: [
    new WizardOptionCheckbox(Offer._praxis_art_kleintier, Offer.labels.de[Offer._praxis_art_kleintier]).setOptions([
      new WizardOptionText(Offer._ratio_kleintiere).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._praxis_art_pferde, Offer.labels.de[Offer._praxis_art_pferde]).setOptions([
      new WizardOptionText(Offer._ratio_pferde).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._praxis_art_rinder, Offer.labels.de[Offer._praxis_art_rinder]).setOptions([
      new WizardOptionText(Offer._ratio_rinder).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._praxis_art_schwein, Offer.labels.de[Offer._praxis_art_schwein]).setOptions([
      new WizardOptionText(Offer._ratio_schweine).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._praxis_art_gefluegel, Offer.labels.de[Offer._praxis_art_gefluegel]).setOptions([
      new WizardOptionText(Offer._ratio_gefluegel).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._praxis_art_ziervoegel, Offer.labels.de[Offer._praxis_art_ziervoegel]).setOptions([
      new WizardOptionText(Offer._praxis_art_ziervoegel).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._praxis_art_heimtiere, Offer.labels.de[Offer._praxis_art_heimtiere]).setOptions([
      new WizardOptionText(Offer._ratio_heimtiere).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._praxis_art_reptilien, Offer.labels.de[Offer._praxis_art_reptilien]).setOptions([
      new WizardOptionText(Offer._ratio_reptilien).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._praxis_art_oeffentlicher_dienst, Offer.labels.de[Offer._praxis_art_oeffentlicher_dienst]).setOptions([
      new WizardOptionText(Offer._ratio_oeffentlicher_dienst).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._praxis_art_industrie, Offer.labels.de[Offer._praxis_art_industrie]).setOptions([
      new WizardOptionText(Offer._ratio_industrie).setPlaceholder('Anteil in %').setType(WizardOptionType.Numeric)
    ])
  ],

  praxisformen: [
    new WizardOptionCheckbox(Offer._einrichtung_tierklinik, Offer.labels.de[Offer._einrichtung_tierklinik]),
    new WizardOptionCheckbox(Offer._einrichtung_tierarztpraxis, Offer.labels.de[Offer._einrichtung_tierarztpraxis]),
    new WizardOptionCheckbox(Offer._einrichtung_sonstiges, Offer.labels.de[Offer._einrichtung_sonstiges]).setOptions([
      new WizardOptionText(Offer._einrichtung_sonstiges, Offer.labels.de[Offer._einrichtung_sonstiges])
    ])
  ],

  preisvorstellungen: [
    new WizardOptionRadio(Offer._praxis_hat_preisvorstellung, 'Ja', 'ja').setOptions([
      new WizardOptionText(Offer._praxis_preisvorstellung).setPlaceholder('€')
    ]),
    new WizardOptionRadio(Offer._praxis_hat_preisvorstellung, 'Noch unsicher', 'unsicher'),
    new WizardOptionRadio(Offer._praxis_hat_preisvorstellung, 'Ich wünsche eine Beratung', 'beratung'),
  ],

  raeume: [
    new WizardOptionCheckbox(Offer._raeume_vorhanden_empfang, 'Empfangsbereich').setOptions([
      new WizardOptionText(Offer._raeume_empfang).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._raeume_vorhanden_warten, 'Wartezimmer').setOptions([
      new WizardOptionText(Offer._raeume_warten).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._raeume_vorhanden_behandlung1, 'Behandlungszimmer').setOptions([
      new WizardOptionText(Offer._raeume_behandlung1).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._raeume_vorhanden_op, 'OP-Raum').setOptions([
      new WizardOptionText(Offer._raeume_op).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._raeume_vorhanden_op_vorb, 'OP-Vorbereitungsraum').setOptions([
      new WizardOptionText(Offer._raeume_op_vorb).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._raeume_vorhanden_station, 'Station').setOptions([
      new WizardOptionText(Offer._raeume_station).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._raeume_vorhanden_wc, 'Toiletten').setOptions([
      new WizardOptionText(Offer._raeume_wc).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._raeume_vorhanden_labor, 'Labor').setOptions([
      new WizardOptionText(Offer._raeume_labor).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._raeume_vorhanden_apotheke, 'Apotheke').setOptions([
      new WizardOptionText(Offer._raeume_apotheke).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._raeume_vorhanden_mitarbeiter, 'Mitarbeiterraum').setOptions([
      new WizardOptionText(Offer._raeume_mitarbeiter).setPlaceholder('Anzahl').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionText(Offer._praxis_flaeche, 'Gesamtfläche in m²').setType(WizardOptionType.Numeric),
  ],

  regionen: [
    new WizardOptionCheckbox(Offer._region_0x, Offer.labels.de[Offer._region_0x]),
    new WizardOptionCheckbox(Offer._region_1x, Offer.labels.de[Offer._region_1x]),
    new WizardOptionCheckbox(Offer._region_2x, Offer.labels.de[Offer._region_2x]),
    new WizardOptionCheckbox(Offer._region_3x, Offer.labels.de[Offer._region_3x]),
    new WizardOptionCheckbox(Offer._region_4x, Offer.labels.de[Offer._region_4x]),
    new WizardOptionCheckbox(Offer._region_5x, Offer.labels.de[Offer._region_5x]),
    new WizardOptionCheckbox(Offer._region_6x, Offer.labels.de[Offer._region_6x]),
    new WizardOptionCheckbox(Offer._region_7x, Offer.labels.de[Offer._region_7x]),
    new WizardOptionCheckbox(Offer._region_8x, Offer.labels.de[Offer._region_8x]),
    new WizardOptionCheckbox(Offer._region_9x, Offer.labels.de[Offer._region_9x])
  ],

  schwerpunkte: [
    new WizardOptionCheckbox(Offer._schwerpunkt_allgemeine_tiermedizin, Offer.labels.de[Offer._schwerpunkt_allgemeine_tiermedizin]),
    new WizardOptionCheckbox(Offer._schwerpunkt_innere_medizin, Offer.labels.de[Offer._schwerpunkt_innere_medizin]),
    new WizardOptionCheckbox(Offer._schwerpunkt_weichteilchirurgie, Offer.labels.de[Offer._schwerpunkt_weichteilchirurgie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_orthopaedie, Offer.labels.de[Offer._schwerpunkt_orthopaedie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_osteosynthese, Offer.labels.de[Offer._schwerpunkt_osteosynthese]),
    new WizardOptionCheckbox(Offer._schwerpunkt_arthroskopie, Offer.labels.de[Offer._schwerpunkt_arthroskopie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_zahnheilkunde, Offer.labels.de[Offer._schwerpunkt_zahnheilkunde]),
    new WizardOptionCheckbox(Offer._schwerpunkt_augenheilkunde, Offer.labels.de[Offer._schwerpunkt_augenheilkunde]),
    new WizardOptionCheckbox(Offer._schwerpunkt_onkologie, Offer.labels.de[Offer._schwerpunkt_onkologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_dermatologie, Offer.labels.de[Offer._schwerpunkt_dermatologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_neurologie, Offer.labels.de[Offer._schwerpunkt_neurologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_kardiologie, Offer.labels.de[Offer._schwerpunkt_kardiologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_endokrinologie, Offer.labels.de[Offer._schwerpunkt_endokrinologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_reproduktionsmedizin, Offer.labels.de[Offer._schwerpunkt_reproduktionsmedizin]),
    new WizardOptionCheckbox(Offer._schwerpunkt_roentgen, Offer.labels.de[Offer._schwerpunkt_roentgen]), // Bildgebung
    new WizardOptionCheckbox(Offer._schwerpunkt_ernaehrung, Offer.labels.de[Offer._schwerpunkt_ernaehrung]),
    new WizardOptionCheckbox(Offer._schwerpunkt_alternative_heilmethoden, Offer.labels.de[Offer._schwerpunkt_alternative_heilmethoden]),
    new WizardOptionCheckbox(Offer._schwerpunkt_bestandsbetreuung, Offer.labels.de[Offer._schwerpunkt_bestandsbetreuung]),
    new WizardOptionCheckbox(Offer._schwerpunkt_fahrpraxis, Offer.labels.de[Offer._schwerpunkt_fahrpraxis]),
    new WizardOptionCheckbox(Offer._schwerpunkt_notfallmedizin, Offer.labels.de[Offer._schwerpunkt_notfallmedizin])
  ],

  schwerpunktePraktikum: [
    new WizardOptionCheckbox(Offer._schwerpunkt_allgemeine_tiermedizin, Offer.labels.de[Offer._schwerpunkt_allgemeine_tiermedizin]),
    new WizardOptionCheckbox(Offer._schwerpunkt_alternative_heilmethoden, Offer.labels.de[Offer._schwerpunkt_alternative_heilmethoden]),
    new WizardOptionCheckbox(Offer._schwerpunkt_arthroskopie, Offer.labels.de[Offer._schwerpunkt_arthroskopie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_augenheilkunde, Offer.labels.de[Offer._schwerpunkt_augenheilkunde]),
    new WizardOptionCheckbox(Offer._schwerpunkt_bestandsbetreuung, Offer.labels.de[Offer._schwerpunkt_bestandsbetreuung]),
    new WizardOptionCheckbox(Offer._schwerpunkt_roentgen, Offer.labels.de[Offer._schwerpunkt_roentgen]), // Bildgebung
    new WizardOptionCheckbox(Offer._schwerpunkt_dermatologie, Offer.labels.de[Offer._schwerpunkt_dermatologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_endokrinologie, Offer.labels.de[Offer._schwerpunkt_endokrinologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_ernaehrung, Offer.labels.de[Offer._schwerpunkt_ernaehrung]),
    new WizardOptionCheckbox(Offer._schwerpunkt_fahrpraxis, Offer.labels.de[Offer._schwerpunkt_fahrpraxis]),
    new WizardOptionCheckbox(Offer._schwerpunkt_innere_medizin, Offer.labels.de[Offer._schwerpunkt_innere_medizin]),
    new WizardOptionCheckbox(Offer._schwerpunkt_kardiologie, Offer.labels.de[Offer._schwerpunkt_kardiologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_neurologie, Offer.labels.de[Offer._schwerpunkt_neurologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_notfallmedizin, Offer.labels.de[Offer._schwerpunkt_notfallmedizin]),
    new WizardOptionCheckbox(Offer._schwerpunkt_onkologie, Offer.labels.de[Offer._schwerpunkt_onkologie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_orthopaedie, Offer.labels.de[Offer._schwerpunkt_orthopaedie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_osteosynthese, Offer.labels.de[Offer._schwerpunkt_osteosynthese]),
    new WizardOptionCheckbox(Offer._schwerpunkt_reproduktionsmedizin, Offer.labels.de[Offer._schwerpunkt_reproduktionsmedizin]),
    new WizardOptionCheckbox(Offer._schwerpunkt_weichteilchirurgie, Offer.labels.de[Offer._schwerpunkt_weichteilchirurgie]),
    new WizardOptionCheckbox(Offer._schwerpunkt_zahnheilkunde, Offer.labels.de[Offer._schwerpunkt_zahnheilkunde]),
  ],

  sprechstunde: [
    new WizardOptionRadio(Offer._praxis_sprechstunde, 'Offene Sprechstunde', 'offen'),
    new WizardOptionRadio(Offer._praxis_sprechstunde, 'Termin&shy;sprechstunde', 'termin'),
    new WizardOptionRadio(Offer._praxis_sprechstunde, 'Gemischt', 'gemischt'),
    new WizardOptionRadio(Offer._praxis_sprechstunde, 'Nicht zutreffend', '')
  ],

  stammdaten: [
    new WizardOptionRow([6, 6]).setOptions([
      new WizardOptionText(Offer._stammdaten_nachname, 'Nachname').setRules('required|min:3'),
      new WizardOptionText(Offer._stammdaten_vorname, 'Vorname').setRules('required|min:3')
    ]),
    new WizardOptionText(Offer._stammdaten_adresse, 'Adresse').setRules('required|min:5'),
    new WizardOptionRow([4, 8]).setOptions([
      new WizardOptionText(Offer._stammdaten_plz, 'PLZ').setRules('required|min:4|max:5'),
      new WizardOptionText(Offer._stammdaten_ort, 'Ort').setRules('required|min:3'),
    ]),
    new WizardOptionText(Offer._stammdaten_email, 'E-Mail-Adresse').setRules('required|email'),
    new WizardOptionText(Offer._stammdaten_telefon, 'Telefonnummer').setRules('required|phone|min:6')
  ],

  dissertation: [
    new WizardOptionText(Offer._geplante_dauer, 'Geplante Dauer der Dissertation').setRules('required'),
    new WizardOptionText(Offer._wochenstunden, 'Wochenstunden/Wochenarbeitszeit').setRules('required'),
    new WizardOptionRadio(Offer._taetigkeit_ort, Offer.labels.de[Offer._taetigkeit_nur_vor_ort], 'Nur vor Ort'),
    new WizardOptionRadio(Offer._taetigkeit_ort, Offer.labels.de[Offer._taetigkeit_nur_dezentral], 'Nur dezentral'),
    new WizardOptionRadio(Offer._taetigkeit_ort, Offer.labels.de[Offer._taetigkeit_ort_kombiniert], 'Kombiniert'),
    new WizardOptionCheckbox(Offer._bezahlte_stelle, 'Bezahlte Stelle/an Stelle gekoppelt?'),
    new WizardOptionCheckbox(Offer._parallele_anstellung, 'Parallele Anstellung in Praxis/anderem Unternehmen möglich?'),
    new WizardOptionText(Offer._infotext, Offer.labels.de[Offer._beschreibung_text], WizardOptionType.Textarea)
  ],

  stellenarten: [
    new WizardOptionCheckbox(Offer._stellenart_tierarzt, Offer.labels.de[Offer._stellenart_tierarzt]),
    new WizardOptionCheckbox(Offer._stellenart_tfa, Offer.labels.de[Offer._stellenart_tfa]),
    new WizardOptionCheckbox(Offer._stellenart_dissertation, Offer.labels.de[Offer._stellenart_dissertation]),
    new WizardOptionCheckbox(Offer._stellenart_praxisvertreter, Offer.labels.de[Offer._stellenart_praxisvertreter]),
    new WizardOptionCheckbox(Offer._stellenart_praktikum, Offer.labels.de[Offer._stellenart_praktikum]),
    new WizardOptionCheckbox(Offer._stellenart_praktikum_ausland, Offer.labels.de[Offer._stellenart_praktikum_ausland]),
    new WizardOptionCheckbox(Offer._stellenart_tfa_ausbildung, Offer.labels.de[Offer._stellenart_tfa_ausbildung])
  ],

  taetigkeiten: [
    new WizardOptionCheckbox(Offer._schwerpunkt_sprechstunde, Offer.labels.de[Offer._schwerpunkt_sprechstunde]),
    new WizardOptionCheckbox(Offer._schwerpunkt_stationaere_betreuung, Offer.labels.de[Offer._schwerpunkt_stationaere_betreuung]),
    new WizardOptionCheckbox(Offer._schwerpunkt_bestandsbetreuung, Offer.labels.de[Offer._schwerpunkt_bestandsbetreuung]),
    new WizardOptionCheckbox(Offer._schwerpunkt_zahn_behandlungen, Offer.labels.de[Offer._schwerpunkt_zahn_behandlungen]),
    new WizardOptionCheckbox(Offer._schwerpunkt_nachtdienste, Offer.labels.de[Offer._schwerpunkt_nachtdienste]),
    new WizardOptionCheckbox(Offer._schwerpunkt_wochenenddienste, Offer.labels.de[Offer._schwerpunkt_wochenenddienste]),
    new WizardOptionCheckbox(Offer._schwerpunkt_veranstaltungsbetreuung, Offer.labels.de[Offer._schwerpunkt_veranstaltungsbetreuung]),
    new WizardOptionCheckbox(Offer._schwerpunkt_ueberwachung_von_narkose, Offer.labels.de[Offer._schwerpunkt_ueberwachung_von_narkose]),
    new WizardOptionCheckbox(Offer._schwerpunkt_op_assistenz, Offer.labels.de[Offer._schwerpunkt_op_assistenz]),
    new WizardOptionCheckbox(Offer._schwerpunkt_operationen, Offer.labels.de[Offer._schwerpunkt_operationen]),
    new WizardOptionCheckbox(Offer._schwerpunkt_untersuchung_laborproben, Offer.labels.de[Offer._schwerpunkt_untersuchung_laborproben]),
    new WizardOptionCheckbox(Offer._schwerpunkt_apothekenmanagement, Offer.labels.de[Offer._schwerpunkt_apothekenmanagement]),
  ],

  urlaubstage: [
    new WizardOptionText(Offer._urlaubstage, Offer.labels.de[Offer._urlaubstage]),
    new WizardOptionText(Offer._fortbildungstage, Offer.labels.de[Offer._fortbildungstage]).setOptions([
      new WizardOptionCheckbox(Offer._fortbildungstage_nach_vereinbarung, Offer.labels.de[Offer._fortbildungstage_nach_vereinbarung])
    ])
  ],

  voraussetzungen: [
    new WizardOptionCheckbox(Offer._voraussetzungen_deutsche_approbation_tierarzt, Offer.labels.de[Offer._voraussetzungen_deutsche_approbation_tierarzt]),
    new WizardOptionCheckbox(Offer._voraussetzungen_min_berufserfahrungen, Offer.labels.de[Offer._voraussetzungen_min_berufserfahrungen]).setOptions([
      new WizardOptionText(Offer._voraussetzungen_berufserfahrungen_in_jahren, Offer.labels.de[Offer._voraussetzungen_berufserfahrungen_in_jahren]).setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._voraussetzungen_bereits_absolviertes_internship, Offer.labels.de[Offer._voraussetzungen_bereits_absolviertes_internship]),
    new WizardOptionCheckbox(Offer._voraussetzungen_hat_titel, Offer.labels.de[Offer._voraussetzungen_hat_titel]).setOptions([
      new WizardOptionText(Offer._voraussetzungen_title_zusatzbezeichnung, Offer.labels.de[Offer._voraussetzungen_title_zusatzbezeichnung]).setRules({
        min: 3
      })
    ]),
    new WizardOptionCheckbox(Offer._voraussetzungen_notdienst_bereitschaft, Offer.labels.de[Offer._voraussetzungen_notdienst_bereitschaft]),
    new WizardOptionCheckbox(Offer._voraussetzungen_belastbarkeit, Offer.labels.de[Offer._voraussetzungen_belastbarkeit]),
    new WizardOptionCheckbox(Offer._voraussetzungen_empathischer_umgang, Offer.labels.de[Offer._voraussetzungen_empathischer_umgang]),
    new WizardOptionCheckbox(Offer._voraussetzungen_selbststaendiges_arbeiten, Offer.labels.de[Offer._voraussetzungen_selbststaendiges_arbeiten]),
    new WizardOptionCheckbox(Offer._voraussetzungen_teamfaehigkeit, Offer.labels.de[Offer._voraussetzungen_teamfaehigkeit]),
    new WizardOptionCheckbox(Offer._voraussetzungen_fuehrerschein, Offer.labels.de[Offer._voraussetzungen_fuehrerschein]),
    new WizardOptionCheckbox(Offer._voraussetzungen_eigenes_auto, Offer.labels.de[Offer._voraussetzungen_eigenes_auto]),
    new WizardOptionCheckbox(Offer._voraussetzungen_puenktlichkeit, Offer.labels.de[Offer._voraussetzungen_puenktlichkeit]),
    new WizardOptionCheckbox(Offer._voraussetzungen_zuverlaessigkeit, Offer.labels.de[Offer._voraussetzungen_zuverlaessigkeit]),
    new WizardOptionCheckbox(Offer._voraussetzungen_interesse_an_weiterbildung, Offer.labels.de[Offer._voraussetzungen_interesse_an_weiterbildung]),
    new WizardOptionCheckbox(Offer._voraussetzungen_kollegialer_umgang, Offer.labels.de[Offer._voraussetzungen_kollegialer_umgang]),
  ],

  zeitumfang: [
    new WizardOptionCheckbox(Offer._umfang_vollzeit, Offer.labels.de[Offer._umfang_vollzeit]),
    new WizardOptionCheckbox(Offer._umfang_teilzeit, Offer.labels.de[Offer._umfang_teilzeit]),
    new WizardOptionCheckbox(Offer._umfang_nebenbeschaeftigung, Offer.labels.de[Offer._umfang_nebenbeschaeftigung])
  ],

  zeitumfangMitStundenAngabe: [
    new WizardOptionRadio(Offer._arbeitsumfang,'Vollzeit','vollzeit').setOptions([
      new WizardOptionText(Offer._arbeitsumfang_vollzeit).setPlaceholder('Stunden pro Woche').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionRadio(Offer._arbeitsumfang, Offer.labels.de[Offer._arbeitsumfang], 'teilzeit').setOptions([
      new WizardOptionText(Offer._arbeitsumfang_teilzeit).setPlaceholder('Stunden pro Woche').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionRadio(Offer._arbeitsumfang, Offer.labels.de[Offer._arbeitsumfang], 'nebenbeschaeftigung').setOptions([
      new WizardOptionText(Offer._arbeitsumfang_nebenbeschaeftigung).setPlaceholder('Stunden pro Woche').setType(WizardOptionType.Numeric)
    ])
  ],

  zeitumfangMitStundenAngabeCheckbox: [
    new WizardOptionCheckbox(Offer._umfang_vollzeit,Offer.labels.de[Offer._umfang_vollzeit]).setOptions([
      new WizardOptionText(Offer._arbeitsumfang_vollzeit).setPlaceholder('Stunden pro Woche').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._umfang_teilzeit, Offer.labels.de[Offer._umfang_teilzeit]).setOptions([
      new WizardOptionText(Offer._arbeitsumfang_teilzeit).setPlaceholder('Stunden pro Woche').setType(WizardOptionType.Numeric)
    ]),
    new WizardOptionCheckbox(Offer._umfang_nebenbeschaeftigung, Offer.labels.de[Offer._umfang_nebenbeschaeftigung]).setOptions([
      new WizardOptionText(Offer._arbeitsumfang_nebenbeschaeftigung).setPlaceholder('Stunden pro Woche').setType(WizardOptionType.Numeric)
    ])
  ]
}
