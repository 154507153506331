




































































































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {WizardOptionType} from "~/entities/WizardOptionType";

@Component
export default class WizardOption extends Vue {
  types = WizardOptionType;

  @Prop({type: Object })
  option;

  @Prop()
  value;

  @Prop({type: Boolean, default: false})
  tile;

  @Prop({type: Boolean})
  sidebar;

  @Prop({type: String, default: 'primary'})
  variant;

  @Prop({type: Object, default: () => ({})})
  appendLabels

  @Prop({type: Boolean})
  allAvailable;

  @Prop({type: Boolean})
  readonly;

  @Prop({type: Array, default: () => []})
  hooks;

  get label() {
    return this.option.label
  }

  runHooksForOption(option, name) {
    const callbacks = this.getHooksForOption(option, name)
    if (callbacks.length === 0) return () => null
    return (...args) => {
      // run all hooks for this option, but stop if one hook returns false
      for (const callback of callbacks) {
        if (callback.callback(...args) === false) break
      }
    }
  }

  getHooksForOption(option, name) {
    if (!Array.isArray(this.hooks) || this.hooks.length === 0) return []
    let hooks = (this.hooks || []).filter(x => x.name === name).filter(x => x.optionName === option.name || x.optionName === '*')
    // sort hooks by priority - lower number means higher priority
    return hooks.sort((a, b) => a.priority - b.priority)
  }
}
