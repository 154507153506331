





















import {Component, Prop, Vue} from 'vue-property-decorator';
import WizardOptionConfig from "~/entities/WizardOptionConfig";

@Component
export default class WizardOptionGroup extends Vue {
  @Prop({type: Array})
  options;

  @Prop()
  value;

  @Prop({type: Boolean})
  sidebar;

  @Prop({type: String, default: 'primary'})
  variant;

  @Prop({type: Object, default: () => ({})})
  appendLabels

  @Prop({type: Boolean})
  allAvailable;

  @Prop({type: Boolean})
  readonly;

  @Prop({type: Array, default: () => []})
  hooks;

  get availableOptions(): WizardOptionConfig[] {
    return this.sidebar ? this.options.filter(x => !x.hiddenInSidebar) : this.options.filter(x => x.isAvailable)
  }
}
