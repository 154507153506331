












































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PersistentModule} from "~/shared/MyService.dtos";
import {SessionStore} from "~/utils/store-accessor";
import {File as FileEntity} from "~/shared/Backend.dtos";
import {v4 as uuidv4} from "uuid";

class Upload extends FileEntity {
  public progress = 0;
  public selected = false;

  constructor(
    public file: File,
    public offerUuid: string,
    public rawName: string = uuidv4()
  ) {
    super({
      offer_uuid: offerUuid,
      orig_name: file.name,
      file_ext: file.name.substring(file.name.lastIndexOf('.')),
      image_type: file.name.substring(file.name.lastIndexOf('.')+1),
      raw_name: rawName,
      file_name: rawName + file.name.substring(file.name.lastIndexOf('.'))
    });
  }

  get url() {
    return window.URL.createObjectURL(this.file);
  }
}

@Component
export default class EditableModule extends Vue {
  @Prop({type: Number})
  index;

  @Prop({type: Boolean})
  isColumn;

  @Prop()
  module: PersistentModule;

  @Prop({type: String})
  variant;

  @Prop({type: Boolean})
  vertical;

  $refs: {
    filePicker: HTMLInputElement
  }

  editableContent = '';
  editMode = false;
  Editor = null;
  fileInput: Upload[] = null;
  optionsInput = {};
  pending = false;
  progress = 0;
  showEditor = false;
  showOptions = false;
  tempUrl = '';
  timestamp: number = new Date().getTime();

  init: {[key: string]: any} = {
    skin: false,
    language: 'de',
    language_url: '/langs/de.js',
    content_css: '/skins/tinymce.css',
    menubar: false,
    statusbar: false,
    plugins: 'link lists autolink autoresize paste visualchars',
    autoresize_bottom_margin: 30,
    paste_as_text: true,
    entity_encoding : 'numeric',
    entities: '173,shy',
    toolbar: 'formatselect | fontsizeselect | forecolor | bold italic underline | alignleft aligncenter alignright alignjustify alignnone | link bullist numlist | visualchars | addSoftHyphen',
    color_map: [
      '738392', 'Standard',
      'EC008A', 'Primär',
      '04A0D7', 'Sekundär',
      '546476', 'Dunkel',
      '000000', 'Schwarz'
    ],
    fontsize_formats: '0.75rem 1rem 1.25rem 1.5rem 1.75rem 2rem 2.5rem 3rem 3.5rem',
    setup(ed) {
      ed.ui.registry.addButton('addSoftHyphen', {
        text: 'Optionaler-Umbruch',
        onAction(): void {
          ed.insertContent('&#173;');
        }
      });
    }
  }

  get editable () {
    return SessionStore.isAdmin
  }

  get options() {
    return this.module.options || {};
  }

  get optionsUrl() {
    return (this.options['url-640'] ? '/storage/files/' + this.options['url-640'] : null) || this.tempUrl || '';
  }

  get optionsUuid() {
    return this.options['uuid'];
  }

  get optionsImageAltText() {
    return this.options['imageAltText'];
  }

  get type() {
    return this.module.type;
  }

  set type(value: string) {
    this.$emit('update:module', {
      ...this.module,
      type: value
    });
  }

  get typeOptions() {
    return [
      {text: 'Bild', value: 'image'},
      {text: 'Text', value: 'text'}
    ]
  }

  get sizeOptions() {
    return [
      {text: 'Klein', value: 'sm'},
      {text: 'Mittel', value: 'md'},
      {text: 'Groß', value: 'lg'}
    ]
  }

  get isButton() {
    return this.options['isButton']?.toString() === 'true';
  }

  get hasButton() {
    return this.options['hasButton']?.toString() === 'true';
  }

  get buttonLabel() {
    return this.options['buttonLabel'] || 'Hier klicken';
  }

  get buttonSize() {
    return this.options['buttonSize'] || 'md';
  }

  get buttonTarget() {
    return this.options['buttonTarget'] || '';
  }

  get openInNewWindow() {
    return this.options['openInNewWindow']?.toString() === 'true';
  }

  updateContent() {
    this.editMode = false;
    this.showEditor = false;
    this.$emit('update:module', {
      ...this.module,
      content: this.editableContent
    });
  }

  updateModule(uniqueIdentifier: string, module: PersistentModule) {
    let modules = [...this.module.modules];
    const index = modules.findIndex(x => x.uniqueIdentifier === uniqueIdentifier);
    modules.splice(index, 1, module);
    this.$emit('update:module', {
      ...this.module,
      modules
    });
  }

  updateOption(name: string, value: string) {
    let options = this.options;
    options[name] = value;
    this.$emit('update:module', {
      ...this.module,
      options
    });
  }

  onEditorInit() {
    this.showEditor = true;
    setTimeout(() => {
      this.pending = false;
    }, 1000)
  }

  cancelContentModification() {
    this.editMode = false;
    this.showEditor = false;
    this.editableContent = this.module.content;
  }

  editContent() {
    this.editMode = true;
    this.editableContent = this.module.content;
    this.Editor = Vue.component('TinyMce', () => import(/* webpackChunkName: "chunk-tiny-mce" */ '~/plugins/tinymce.js'));
  }

  uploadFile(event: any) {
    this.timestamp = new Date().getTime();
    const fileInput: FileList = event.target.files
    this.fileInput = Object.values(fileInput).map(file => {
      const upload = new Upload(file, this.module.uniqueIdentifier);
      upload.display_order = 1;
      return upload;
    });
    if (this.fileInput.length === 0) {
      return;
    }

    this.fileInput.forEach(handle => {
      const path = this.module.uniqueIdentifier
      this.tempUrl = handle.url;
      this.$api.upload(handle.file, handle.raw_name, path, this.onProgress(handle)).then(res => {
        let options = this.options;
        options['url-1920'] = path + '/' + handle.rawName + '-1920' + handle.file_ext;
        options['url-1280'] = path + '/' + handle.rawName + '-1280' + handle.file_ext;
        options['url-640'] = path + '/' + handle.rawName + '-640' + handle.file_ext;
        options['url-180'] = path + '/' + handle.rawName + '-180' + handle.file_ext;
        this.$emit('update:module', {
          ...this.module,
          options
        });
      });
    });
  }

  onProgress(handle: Upload) {
    return ({bytesUploaded, bytesTotal, percentage}) => {
      this.progress = percentage;
    }
  }

  startModifyOptions() {
    this.optionsInput = {
      type: this.type,
      isButton: this.isButton,
      hasButton: this.hasButton,
      buttonLabel: this.buttonLabel,
      buttonSize: this.buttonSize,
      buttonTarget: this.buttonTarget,
      openInNewWindow: this.openInNewWindow
    };
    this.showOptions = true;
  }

  updateModifiedOptions() {
    this.$emit('update:module', {
      ...this.module,
      type: this.optionsInput['type'],
      options: {
        ...this.options,
        ...this.optionsInput
      }
    });
  }

  onContentClick() {
    if (this.editMode || this.editable) return;
    if (!this.isButton) return;
    if (this.openInNewWindow) {
      window.open(this.buttonTarget);
    } else {
      window.top.location.href = this.buttonTarget;
    }
  }
}
