import {Vue} from 'nuxt-property-decorator';
import {Offer} from "~/shared/Backend.dtos";

Vue.filter('formatDate', (value?: Date|string) => {
  if (value !== null && value instanceof Date) {
    return value.toLocaleDateString('de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  } else if (value === '0000-00-00 00:00:00') {
    return '';
  } else if (value !== null) {
    try {
      return new Date(value).toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (e) {
      return value;
    }
  }
  return value;
});

Vue.filter('formatDateTime', (value?: Date) => {
  if (value !== null && value instanceof Date) {
    return value.toLocaleDateString('de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  } else if (value !== null) {
    try {
      return new Date(value).toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (e) {
      return value;
    }
  }
  return value;
});

Vue.filter('stammdatenVorNachname', (value: Offer|Request) => {
  const items = [];
  if (value[Offer._stammdaten_vorname]) {
    items.push(value[Offer._stammdaten_vorname]);
  }
  if (value[Offer._stammdaten_nachname]) {
    items.push(value[Offer._stammdaten_nachname]);
  }
  return items.join(' ');
});

Vue.filter('stammdatenNachVorname', (value: Offer|Request) => {
  const items = [];
  if (value[Offer._stammdaten_nachname]) {
    items.push(value[Offer._stammdaten_nachname]);
  }
  if (value[Offer._stammdaten_vorname]) {
    items.push(value[Offer._stammdaten_vorname]);
  }
  return items.join(', ');
});

Vue.filter('stammdatenAdresse', (value: Offer|Request) => {
  const items = [];
  if (value[Offer._stammdaten_adresse]) {
    items.push(value[Offer._stammdaten_adresse] + ', ');
  }
  if (value[Offer._stammdaten_plz]) {
    items.push(value[Offer._stammdaten_plz]);
  }
  if (value[Offer._stammdaten_ort]) {
    items.push(value[Offer._stammdaten_ort]);
  }
  return items.join(' ');
});

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});
