



















import {Component, Vue} from 'nuxt-property-decorator';
import {SessionStore} from "~/utils/store-accessor";
import {Auth} from "~/shared/Backend.dtos";

@Component
export default class LoginForm extends Vue {
  form = {
    username: '',
    password: ''
  }
  status: boolean = null;

  async login() {
    try {
      this.status = null;
      const auth: Auth = await this.$api.createAuth(this.form.username, this.form.password);
      this.$api.token = auth.token;
      SessionStore.save(auth);
      this.$emit('login', auth);
    } catch (e) {
      this.status = false;
    }
  }
}
