import sanitizeHtml from 'sanitize-html';

export function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

export function flattener(obj, values = {}, name = '') {
  if (!name?.startsWith('_')) {
    name = '';
  }
  if (obj !== null && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      const item = obj[key];
      if (['_wunschort1_location', '_wunschort2_location', '_wunschort3_location'].includes(key) && item.address) {
        values[`${name}${key}_adresse`] = `${item.address}`
        values[`${name}${key}`] = `${item.lat},${item.lng},${item.radius}`
      } else if (Array.isArray(item)) {
        item.forEach(value => {
          values[value] = '1';
        })
      } else if (typeof item === 'object') {
        flattener(item, values, key);
      } else {
        if (item) {
          values[`${name}${key}`] = item;
        }
      }
    })
  }
  return values;
}

export function offset(el: HTMLElement) {
  let rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export function waitFor(ms = 500) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

let debounceTimer = null;

export function debounce(callback, ms) {
  if (debounceTimer) {
    clearTimeout(debounceTimer);
    debounceTimer = null;
  }
  debounceTimer = setTimeout(() => {
    debounceTimer = null;
    callback();
  }, ms);
}

export async function sanitizeHTML(text: string) {
  if (typeof document !== "undefined") {
    const element = document.createElement('div');
    element.innerText = text;
    return element.innerHTML;
  } else {
    return await import('sanitize-html').then(lib => {
      return lib.default(text, {
        allowedTags: []
      });
    });
  }
}

export function shortenText(text: string, maxlen: number): string {
  if (text && text.length > maxlen) {
    return text.substr(0, maxlen) + '...';
  }
  return text;
}
