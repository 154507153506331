const redirects = require('./redirects.json');

const middleware = async function (req, res, next) {
  console.log(req.url)
  const redirect = redirects.find(r => r.from === req.url)
  if (redirect) {
    console.log(`redirect: ${redirect.from} => /stellen/details${redirect.to}`)
    res.writeHead(301, { Location: '/stellen/details' + redirect.to })
    res.end()
  } else {
    next()
  }
}

module.exports = middleware;
