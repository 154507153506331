export default class Location {
  public static parse(location: string): Location {
    const [lat, lng, radius] = location.split(',');
    if (lat && lng && radius) {
      return new Location(parseFloat(lat), parseFloat(lng), parseInt(radius));
    }
    return new Location();
  }

  constructor(
    public lat: number = 0,
    public lng: number = 0,
    public radius: number = 50
  ) {
  }

  toString(): string {
    return `${this.lat},${this.lng},${this.radius}`
  }
}
