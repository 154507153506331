import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import KeyValModule from "~/store/keyval";
import OfferModule from '~/store/offer';
import SessionModule from '~/store/session';

let KeyValStore: KeyValModule;
let OfferStore: OfferModule
let SessionStore: SessionModule

function initialiseStores(store: Store<any>): void {
  KeyValStore = getModule(KeyValModule, store);
  OfferStore = getModule(OfferModule, store);
  SessionStore = getModule(SessionModule, store);
}

export { initialiseStores, KeyValStore, OfferStore, SessionStore }
