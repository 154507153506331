



































import {Component, Prop, Vue} from 'nuxt-property-decorator';
import {EmailOffer} from "~/shared/Backend.dtos";

@Component
export default class EmailOfferList extends Vue {
  @Prop({type: String})
  messageType;

  @Prop({type: String})
  offerUuid;

  @Prop({type: String})
  recipient;

  promise: Promise<EmailOffer> = null;

  created(): void {
    this.promise = this.$api.getEmailOffers(this.messageType).then(data => {
      return data;
    });
  }

  async sendEmail(first = true): Promise<void> {
    if (!this.recipient) {
      await this.$bvModal.msgBoxOk('Bitte geben Sie eine E-Mail-Adresse an!', {
        centered: true
      });
      return;
    }

    if (!first) {
      const res = await this.$bvModal.msgBoxConfirm('Soll eine weitere E-Mail versendet werden?', {
        centered: true,
        cancelTitle: 'Nein',
        okTitle: 'Ja'
      });
      if (!res) return;
    } else {
      const res = await this.$bvModal.msgBoxConfirm('Soll eine E-Mail versendet werden?', {
        centered: true,
        cancelTitle: 'Nein',
        okTitle: 'Ja'
      });
      if (!res) return;
    }

    await this.$api.createEmailOffer(new EmailOffer({
      offer_uuid: this.offerUuid,
      message_type: this.messageType,
      recipient: this.recipient
    }));
    this.promise = this.$api.getEmailOffers(this.messageType).then(data => {
      return data;
    });
  }
}
