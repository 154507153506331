import {WizardOptionType} from "./WizardOptionType";
import {IWizardOption} from "~/entities/IWizardOption";

export default class WizardOptionConfig implements IWizardOption {
  constructor(
    public name: string,
    public label: string = '',
    public defaultValue: any = '',
    public type: WizardOptionType = null
  ) {
  }

  public initialValue: any;

  get html(): string {
    return this.label;
  }

  get text(): string {
    return this.label;
  }

  private _labelKey;

  get labelKey(): string {
    return this._labelKey || this.name
  }

  private _availableWhen: Function = () => true;

  public get isAvailable(): boolean {
    return this._availableWhen();
  }

  public setAvailableWhen(availableWhen: Function): WizardOptionConfig {
    this._availableWhen = availableWhen;
    return this;
  }

  public options: IWizardOption[] = [];

  public setOptions(options: IWizardOption[]): WizardOptionConfig {
    this.options = options;
    return this;
  }

  public get hasOptions(): boolean {
    return this.options != null && this.options.length > 0
  }

  hasOptionsState(data: {[p: string]: any}): boolean {
    return this.options.filter(x => data[x.name] === x.defaultValue).length > 0
  }

  hasValue(data: {[p: string]: any}): boolean {
    return data[this.name] && data[this.name] !== null;
  }

  getValue(data: {[p: string]: any}): string {
    return data[this.name];
  }

  public hiddenInSidebar;

  public hideInSidebar(): WizardOptionConfig {
    this.hiddenInSidebar = true;
    return this;
  }

  setType(value: WizardOptionType): WizardOptionConfig {
    this.type = value;
    return this;
  }

  setLabelKey(labelKey: string): WizardOptionConfig {
    this._labelKey = labelKey;
    return this;
  }

  public hasIcon: boolean;

  enableIcon(): WizardOptionConfig {
    this.hasIcon = true;
    return this;
  }

  public changeHandler: (value: any) => void;

  setChangeHandler(func: (value: any) => void) {
    this.changeHandler = func;
    return this;
  }

  onChange(value) {
    if (this.changeHandler) {
      this.changeHandler(value);
    }
  }
}
