


































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Accordion extends Vue {
  @Prop({type: String})
  group;

  @Prop({ type: String })
  title;

  @Prop()
  index: any;

  @Prop({type: String})
  namespace;

  @Prop({type: Boolean})
  visible;

  get id() {
    return `${this.namespace}${(this.index+1)}`;
  }
}
