




























import {Component, Prop, Vue, Watch} from "nuxt-property-decorator";

@Component
export default class DynamicForm extends Vue {
  @Prop({ type: Array })
  fields;

  @Prop({ type: Array })
  groups;

  @Prop({ type: Object, required: true })
  value;

  get rows() {
    return this.fields ?? this.groups
  }

  getValidationState({ dirty, validated, valid = null }) {
    return dirty || validated ? valid : null;
  }

  @Watch('value', {deep: true})
  handle(value) {
    this.$emit('input', value)
  }
}
