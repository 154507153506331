




















































































import {Component, Vue} from 'nuxt-property-decorator';
import {CookieValObject, AnalyticsCookieVersion} from "~/middleware/analytics";

@Component
export default class CookieModal extends Vue {
  showSettings = false;
  requiredCookies = true;
  statisticCookies = false;
  showModal = true;

  created(): void {
    const cookieVal: CookieValObject = this.$cookies.get('cookie-settings');
    if (cookieVal && cookieVal.version === AnalyticsCookieVersion) {
      this.showModal = false;
      this.$bvModal.hide('cookies');
      if (cookieVal.ga) {
        this.initGa();
      }
      setTimeout(() => {
        this.$emit('hidden');
      }, 1000);
    }
  }

  setCookie(value: CookieValObject): void {
    this.$cookies.set('cookie-settings', value, {
      path: '/',
      maxAge: 60 * 60 * 24 * 7
    });
  }

  saveSettings(): void {
    const cookieVal = new CookieValObject();
    if (this.statisticCookies) {
      this.initGa();
      cookieVal.ga = true;
    }
    this.$bvModal.hide('cookies');
    this.setCookie(cookieVal);
  }

  acceptAll(): void {
    this.initGa();
    this.$bvModal.hide('cookies');
    this.setCookie(new CookieValObject(true));
  }

  initGa(): void {
    this.$gtm.init('GTM-M7PSFGG');
    this.$gtm.push({
      event: 'pageView',
      page: {
        path: this.$route.fullPath,
        title: this.$route.meta.title
      }
    });
  }
}
