










































































// noinspection TypeScriptCheckImport
import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
import {GeocodingResponse} from "~/shared/MyService.dtos";
import Location from "~/entities/Location";

@Component
export default class LocationInput extends Vue {
  @Prop({type: String})
  value;

  @Prop({type: String})
  label;

  @Prop({type: String, default: ''})
  address;

  @Prop({ type: Boolean })
  stacked;

  formattedAddress = '';
  country = 'DE';
  pending = false;

  get addressInput(): string {
    return this.address;
  }

  set addressInput(value: string) {
    this.searchLocation(value);
    this.$emit('update:address', value);
  }

  get location(): Location {
    return this.value ? Location.parse(this.value) : new Location();
  }

  set location(value: Location) {
    this.$emit('input', value?.toString())
  }

  get lat(): number {
    return this.location.lat;
  }

  get lng(): number {
    return this.location.lng;
  }

  get radius(): number {
    return this.location.radius;
  }

  set radius(value: number) {
    this.location = new Location(this.lat, this.lng, value);
  }

  error: string = null;
  status: boolean = null;
  promise: Promise<GeocodingResponse> = null;
  radiusOptions: Array<{value: number, text: string}> = [
    { value: 50, text: '50 km' },
    { value: 100, text: '100 km' },
    { value: 150, text: '150 km' }
  ];

  searchLocation(value: string): void {
    this.status = null;
    this.promise = null;
    if (value && value.length >= 3) {
      this.pending = true;
      this.promise = this.$api.geocode(value, this.country).then((e) => {
        this.status = true;
        this.location = new Location(e.lat, e.lng, this.radius);
        this.$emit('update:lat', e.lat);
        this.$emit('update:lng', e.lng);
        this.$emit('update:result', true);
        this.formattedAddress = e.formattedAddress[0];
        return e;
      }).catch((e) => {
        this.error = e.responseStatus.message;
        this.status = false;
        this.location = new Location( 0, 0, this.radius);
        this.$emit('update:lat', 0);
        this.$emit('update:lng', 0);
        this.$emit('update:result', false);
        this.formattedAddress = '';
        return e;
      }).finally(() => {
        this.pending = false;
      });
    } else {
      this.location = null;
    }
  }

  @Watch('addressInput')
  handleAddressChange(value: string): void {
    this.searchLocation(value);
  }

  @Watch('pending')
  handlePendingChange(value: boolean): void {
    this.$emit('update:pending', value);
  }
}
