





























































import {Component, Prop, Vue} from "nuxt-property-decorator";
import {v4 as uuid} from 'uuid'
import {Like} from "~/shared/Backend.dtos";

@Component
export default class LikeButtons extends Vue {
  @Prop({type: String})
  entityUuid;

  totalCount = 0;
  likeList = {};
  showModal = false;
  dislikeInfo = '';
  dislikeInfoSelection = '';

  get hasLike() {
    return this.likeList[this.entityUuid] === 1;
  }

  get hasDislike() {
    return this.likeList[this.entityUuid] === 0;
  }

  mounted() {
    this.loadLikes();
    this.loadLikeList();
  }

  loadLikes() {
    this.$api.getLikes(this.entityUuid).then(res => {
      this.totalCount = res.data.totalItems
    });
  }

  loadLikeList() {
    let likeList = {};
    let likeListKey = localStorage.getItem('like-list');
    if (likeListKey) {
      likeList = JSON.parse(likeListKey);
    }
    this.likeList = likeList;
  }

  sendLike(dislike = false, bvModalEvent) {
    if (dislike && !this.dislikeInfoSelection) {
      bvModalEvent.preventDefault()
      return;
    }

    let dislikeInfo = this.dislikeInfo;
    if (this.dislikeInfoSelection !== 'Sonstiges') {
      dislikeInfo = this.dislikeInfoSelection;
    }

    let likeId = localStorage.getItem('like-id');
    if (!likeId) {
      likeId = uuid();
      localStorage.setItem('like-id', likeId);
    }

    const likeList = this.likeList;

    this.$api.createLike({
      like_type: 1,
      like_value: dislike ? 0 : 1,
      entity_uuid: this.entityUuid,
      dislike_info: dislikeInfo,
      source_identifier: likeId
    } as Like).then(res => {
      likeList[this.entityUuid] = res.like_value;
      localStorage.setItem('like-list', JSON.stringify(likeList));
      this.likeList = {...likeList};
      this.loadLikes();
      this.dislikeInfo = '';
      this.dislikeInfoSelection = '';
    });
  }
}
