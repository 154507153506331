import WizardOptionConfig from "~/entities/WizardOptionConfig";
import {WizardOptionType} from "~/entities/WizardOptionType";

export default class WizardOptionCheckbox extends WizardOptionConfig {
  constructor(
    public name: string,
    public label: string
  ) {
    super(name, label, true, WizardOptionType.Checkbox);
  }

  hasValue(data) {
    return data[this.name] && data[this.name] !== '0';
  }
}
