


























































































































































































































































































































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Contact, File as FileEntity, Offer} from "~/shared/Backend.dtos";
import {OfferType} from "~/entities/OfferType";
import FileUpload from "~/components/FileUpload.vue";
import {v4 as uuidv4} from "uuid";
import TextCircle from "~/components/TextCircle.vue";
import LikeButtons from "~/components/LikeButtons.vue";
import Dissertationsstelle from "~/components/wizards/Dissertationsstelle.vue";

class UploadItem extends FileEntity {
  public progress = 0;
  public error = '';

  constructor(
    public file: File,
    public rawName: string = uuidv4()
  ) {
    super({
      orig_name: file.name,
      file_ext: file.name.substring(file.name.lastIndexOf('.')),
      raw_name: rawName,
      file_name: rawName + file.name.substring(file.name.lastIndexOf('.'))
    });
  }

  get url() {
    return this._media ? this._media['180'] : window.URL.createObjectURL(this.file);
  }
}

// Letzte Änderung: 2020-07-09 11:52
// Letzter Test: 2020-07-09 11:52
@Component({
  components: {LikeButtons, TextCircle, FileUpload},
  head() {
    return {
      title: this.title,
      meta: [
        { hid: 'og:title', property: 'og:title', content: this.title },
        { hid: 'og:image', property: 'og:image', content: this.imgUrl },
        { hid: 'og:url', property: 'og:url', content: this.url }
      ]
    }
  }
})
export default class OfferView extends Vue {
  labels = Offer.labels.de;

  @Prop({type: Object, default: () => ({}), required: true})
  offer: Offer;

  @Prop({type: Boolean})
  hideSidebar;

  @Prop({type: Number})
  previewType: OfferType;

  types = OfferType;
  contactPromise: Promise<Contact> = null;
  feedbackPromise: Promise<Contact> = null;
  uploadResolver: () => void = () => null;
  uploadRejecter: () => void = () => null;
  uploadKey = new Date().getTime();
  feedbackModal = false;
  contactModal = false;
  askModal = false;
  contact: Contact = new Contact();
  uploads: UploadItem[] = [];
  offerType = OfferType;

  cardGroups = {
    '_behandlung': 'Behandlungen',
    '_besonderheit': 'Besonderheit',
    '_schwerpunkt': 'Schwerpunkte'
  }

  mounted(): void {
    this.contact = new Contact({
      pending: true,
      offer_uuid: this.offer.uuid
    })
  }

  get type(): OfferType {
    return this.previewType || (this.offer.offer_type ?? 1) as OfferType;
  }

  get title(): string {
    switch (this.type) {
      case OfferType.Praxis:
        return this.offer._praxis_ueberschrift;
      case OfferType.Stelle:
        return this.offer._title;
    }
    return null;
  }

  get text(): string {
    switch (this.type) {
      case OfferType.Praxis:
        return this.offer._praxis_kurzbeschreibung;
      case OfferType.Stelle:
        return this.offer._infotext;
    }
    return null;
  }

  get url(): string {
    if (typeof window !== "undefined") {
      return window.top.location.toString();
    }
    if (process.server) {
      return `https://${this.$ssrContext.req.headers.host}${this.$ssrContext.req.url}`;
    }
    return '';
  }

  get feedback(): {value: string}[] {
    return [
      {value: 'Ein Praktikum ist aktuell möglich.'},
      {value: 'Die Praktikumsstelle ist nicht erreichbar.'},
      {value: 'Es ist aktuell kein Praktikum möglich.'},
      {value: 'Die Praxis bzw. Klinik existiert nicht mehr.'},
      {value: 'Sonstiges'},
    ];
  }

  get fileLength(): number {
    return this.offer._files?.length || 0;
  }

  get imgUrl(): string {
    if (this.offer.offer_type === OfferType.Praxis) {
      if (this.offer._files && this.offer._files?.length > 0) {
        if (this.offer.import_id !== 0) {
          const file = this.offer._files[0];
          const fileName = file.file_name;
          return `https://www.tvd-praxisvermittlung.de/storage/alt/${this.offer.import_id}/${fileName}`;
        } else {
          const file = this.offer._files[0];
          const fileName = file.file_name.replace(/(.*)\.(.*)/, '$1-640.$2');
          return `https://www.tvd-praxisvermittlung.de/storage/${this.offer.uuid}/${fileName}`;
        }
      }
    } else {
      if (this.offer._files && this.offer._files?.length > 0) {
        return this.offer._files[0]._media['640'];
      }
    }
    return null;
  }

  get isStelle(): boolean {
    return this.offer.offer_type === OfferType.Stelle;
  }

  get isPraktikum(): boolean {
    return this.offer._stellenart_praktikum || this.offer._stellenart_praktikum_ausland
  }

  get isDissertationsstelle(): boolean {
    return this.offer._stellenart_dissertation;
  }

  get filteredCardGroups(): {[key: string]: string} {
    return Object.keys(this.cardGroups).reduce((map, key) => {
      if (this.anyKeyStartsWith(key)) {
        map[key] = this.cardGroups[key];
      }
      return map;
    }, {})
  }

  get offerKeys(): string[] {
    return Object.keys(this.offer).filter(k => !!this.offer[k]);
  }

  get webShareApiSupported(): boolean {
    if (process.server) return false;
    return !!navigator.share
  }

  shareViaWebShare(): void {
    navigator.share({
      title: this.title,
      url: this.url
    })
  }

  anyKeyStartsWith(value: string): boolean {
    return Object.keys(this.offer).filter(x => x.startsWith(value)).length > 0;
  }

  public onSubmitFeedback(): void {
    this.feedbackPromise = this.$api.createContact({
      ...this.contact,
      pending: false
    });
  }

  public onSubmit(): void {
    const uploadPromise = new Promise<void>((resolve, reject) => {
      this.uploadResolver = resolve
      this.uploadRejecter = reject
    });
    if (this.contact.uuid) {
      this.uploadKey = new Date().getTime();
      uploadPromise.then(() => {
        this.contactPromise = this.$api.updateContact(this.contact.uuid, this.contact);
      });
    } else {
      this.contactPromise = this.$api.createContact(this.contact).then(async (res) => {
        this.uploadKey = new Date().getTime();
        this.contact.uuid = res.uuid;
        this.contact.pending = false;
        try {
          await uploadPromise;
          this.contactPromise = this.$api.updateContact(this.contact.uuid, this.contact);
        } catch (e) {
          this.contactPromise = null;
          return null;
        }
        return res;
      });
    }
  }
}
