import {Vue} from 'nuxt-property-decorator';

import LocationInput from '~/components/LocationInput.vue';
import Promised from '~/components/Promised.vue';
import WizardFooterBtn from '~/components/WizardFooterBtn.vue';
import WizardStep from '~/components/WizardStep.vue';
import WizardTile from '~/components/WizardTile.vue';
import WizardIndicator from "~/components/WizardIndicator.vue";
import WizardTileGroup from "~/components/WizardTileGroup.vue";
import Wizard from "~/components/Wizard.vue";
import Accordion from "~/components/Accordion.vue";
import MyMap from "~/components/MyMap.vue";
import DynamicForm from "~/components/DynamicForm.vue";
import ValidateInput from "~/components/ValidateInput.vue";
import JulianKnierim from "~/components/cards/JulianKnierim.vue";
import MobileSidebar from "~/components/MobileSidebar.vue";
import EditableContent from "~/components/EditableContent.vue";
import LoginForm from "~/components/LoginForm.vue";
import WizardOption from "~/components/WizardOption.vue";
import WizardOptionGroup from "~/components/WizardOptionGroup.vue";
import NotFound from "~/components/NotFound.vue";
import OfferView from "~/components/OfferView.vue";
import OfferListItem from "~/components/OfferListItem.vue";
import OfferListItemBtn from "~/components/OfferListItemBtn.vue";
import Pagination from "~/components/Pagination.vue";
import WizardFooterResultBtn from "~/components/WizardFooterResultBtn.vue";
import ContactForm from "~/components/ContactForm.vue";
import OfferEditor from "~/components/OfferEditor.vue";
import LayoutFooter from "~/components/LayoutFooter.vue";
import FilterBadges from '~/components/FilterBadges.vue';
import AgentModal from "~/components/AgentModal.vue";
import ImageGallery from "~/components/ImageGallery.vue";
import GalleryManager from "~/components/GalleryManager.vue";
import DevBar from "~/components/DevBar.vue";
import EditableModule from "~/components/EditableModule.vue";
import AnnikaWemmers from "~/components/cards/AnnikaWemmers.vue";

Vue.component('location-input', LocationInput)
Vue.component('accordion', Accordion)
Vue.component('wizard', Wizard)
Vue.component('wizard-tile', WizardTile)
Vue.component('promised', Promised)
Vue.component('wizard-footer-btn', WizardFooterBtn)
Vue.component('wizard-step', WizardStep)
Vue.component('offer-list-item', OfferListItem)
Vue.component('offer-list-item-btn', OfferListItemBtn)
Vue.component('wizard-indicator', WizardIndicator)
Vue.component('wizard-tile-group', WizardTileGroup)
Vue.component('my-map', MyMap)
Vue.component('dynamic-form', DynamicForm)
Vue.component('validate-input', ValidateInput)
Vue.component('card-annika-wemmers', AnnikaWemmers)
Vue.component('card-julian-knierim', JulianKnierim)
Vue.component('mobile-sidebar', MobileSidebar)
Vue.component('editable-content', EditableContent)
Vue.component('login-form', LoginForm)
Vue.component('wizard-option', WizardOption)
Vue.component('wizard-option-group', WizardOptionGroup)
Vue.component('not-found', NotFound)
Vue.component('offer-view', OfferView)
Vue.component('pagination', Pagination)
Vue.component('wizard-footer-result-btn', WizardFooterResultBtn)
Vue.component('contact-form', ContactForm)
Vue.component('offer-editor', OfferEditor)
Vue.component('layout-footer', LayoutFooter)
Vue.component('filter-badges', FilterBadges)
Vue.component('agent-modal', AgentModal)
Vue.component('image-gallery', ImageGallery)
Vue.component('gallery-manager', GalleryManager)
Vue.component('dev-bar', DevBar)
Vue.component('editable-module', EditableModule)
