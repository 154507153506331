


















import {Component, Prop, Vue} from 'nuxt-property-decorator';
import {IWizardStep} from "~/interfaces/IWizardStep";

@Component
export default class WizardStep extends Vue implements IWizardStep {
  @Prop({ type: Number })
  index;

  @Prop({ type: Boolean, default: false })
  selected;

  @Prop({ type: Number, default: 2 })
  columns;
}
