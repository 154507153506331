























import {Component, Prop, Vue} from 'nuxt-property-decorator';

@Component
export default class WizardFooterBtn extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled;

  @Prop({ type: Boolean, default: false })
  iconRight;

  @Prop({ type: String, default: 'primary' })
  variant;

  @Prop({ type: String })
  to;

  @Prop({ type: String })
  icon;

  @Prop({type: String})
  cols;

  @Prop({type: String})
  lg;

  @Prop({type: String})
  md;

  @Prop({type: String})
  sm;
}
