export const AnalyticsCookieVersion = 2;

export class CookieValObject {
  public version = AnalyticsCookieVersion;

  public constructor(
    public ga: boolean = false
  ) {
  }
}

export default function ({ route, $gtm, $cookies }) {
  if (process.server) return; // Disable on SSR
  if (route.fullPath.startsWith('/admin')) return;

  const cookieVal: CookieValObject = $cookies.get('cookie-settings');
  if (cookieVal && cookieVal.version === AnalyticsCookieVersion) {
    if (cookieVal.ga) {
      $gtm.push({
        event: 'pageView',
        page: {
          path: route.fullPath,
          title: route.meta.title
        }
      });
    }
  }
}
