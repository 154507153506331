import {Module, VuexModule, Mutation, Action} from 'vuex-module-decorators';
import {Vue} from 'nuxt-property-decorator'

@Module({
  name: 'keyval',
  stateFactory: true,
  namespaced: true
})
export default class KeyValModule extends VuexModule {
  data: { [key: string]: any } = {};
  keys: { [key: string]: any } = {};

  @Mutation
  public setData(data) {
    this.data = data;
  }

  @Mutation
  public setValue({key, value, source = null}: {key: string, value: any, source: string}) {
    Vue.set(this.data, key, value);
    Vue.set(this.keys, key, source);
  }

  @Mutation
  public setValues(values: {[key: string]: any}) {
    this.data = {...this.data, ...values};
  }

  @Mutation
  public setKeys(keys: {[key: string]: string}) {
    this.keys = {...this.keys, ...keys};
  }

  @Mutation
  public deleteKey(key: string) {
    Vue.delete(this.data, key);
    Vue.delete(this.keys, key);
  }

  get dataByNamespace() {
    return (namespace: string) => {
      const namespacedKeys: string[] = Object.keys(this.data).filter(x => x.startsWith(`${namespace}:`));
      return namespacedKeys.reduce((map, namespacedKey) => {
        map[this.keys[namespacedKey]] = this.data[namespacedKey];
        return map;
      }, {});
    }
  }

  get hasKey() {
    return (key: string) => {
      return Object.keys(this.data).includes(key)
    }
  }

  get hasAnyKeyIn() {
    return (keys: string[]) => {
      return Object.keys(this.data).filter(x => keys.includes(x)).length > 0;
    }
  }
}
