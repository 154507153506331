import WizardOptionConfig from "~/entities/WizardOptionConfig";
import {WizardOptionType} from "~/entities/WizardOptionType";

export default class WizardOptionGroup extends WizardOptionConfig {
  constructor() {
    super('', '', '', WizardOptionType.Group);
  }

  public hasValue(data): boolean {
    return true;
  }
}
