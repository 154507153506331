import {Module, VuexModule, Mutation, Action} from 'vuex-module-decorators';
import {Offer} from "~/shared/Backend.dtos";
import {OfferType} from "~/entities/OfferType";
import {api} from '~/shared/api'

@Module({
  name: 'offer',
  stateFactory: true,
  namespaced: true
})
export default class OfferModule extends VuexModule {
  data: Offer = new Offer();

  @Mutation
  public setData(data: Offer) {
    this.data = data;
  }

  @Action
  public async search({type, params = null}: {type: OfferType, params?: {[key: string]: string}}): Promise<Offer> {
    return await api.searchOffers(type, params).then(data => {
      this.setData(data);
      if (data.totalItems === 0) {
        throw new Error();
      }
      return data;
    });
  }

  @Action
  public async count({type, params = null}: {type: OfferType, params?: {[key: string]: string}}): Promise<Offer> {
    return await api.countOffers(type, params).then(data => {
      this.setData(data);
      return data;
    });
  }
}
