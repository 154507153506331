/* Options:
Date: 2022-12-02 19:36:54
Version: 5.133
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:5001

//GlobalNamespace:
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion:
//AddDescriptionAsComments: True
//IncludeTypes:
//ExcludeTypes:
//DefaultImports:
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export class PersistentModule
{
    public id: number;
    public uniqueIdentifier: string;
    public content: string;
    public type: string;
    public options: { [index: string]: string; };
    public modules: PersistentModule[];

    public constructor(init?: Partial<PersistentModule>) { (Object as any).assign(this, init); }
}

export enum Status
{
    Publish = 'publish',
    Private = 'private',
    Future = 'future',
    Draft = 'draft',
    Pending = 'pending',
    Trash = 'trash',
}

export class RenderedRawBase
{
    public rendered: string;
    public raw: string;

    public constructor(init?: Partial<RenderedRawBase>) { (Object as any).assign(this, init); }
}

export class Title extends RenderedRawBase
{

    public constructor(init?: Partial<Title>) { super(init); (Object as any).assign(this, init); }
}

export class Content extends RenderedRawBase
{
    public isProtected: boolean;

    public constructor(init?: Partial<Content>) { super(init); (Object as any).assign(this, init); }
}

export class Base
{
    public id: number;

    public constructor(init?: Partial<Base>) { (Object as any).assign(this, init); }
}

export enum MediaQueryStatus
{
    Inherit = 'inherit',
    Private = 'private',
    Trash = 'trash',
}

export enum OpenStatus
{
    Open = 'open',
    Closed = 'closed',
}

export class Caption extends RenderedRawBase
{

    public constructor(init?: Partial<Caption>) { super(init); (Object as any).assign(this, init); }
}

export class Description extends RenderedRawBase
{
    public isProtected: boolean;

    public constructor(init?: Partial<Description>) { super(init); (Object as any).assign(this, init); }
}

export enum MediaType
{
    Image = 'image',
    File = 'file',
}

export class MediaSize
{
    public file: string;
    public width?: number;
    public height?: number;
    public mimeType: string;
    public sourceUrl: string;

    public constructor(init?: Partial<MediaSize>) { (Object as any).assign(this, init); }
}

export class ImageMeta
{
    public aperture: string;
    public credit: string;
    public camera: string;
    public caption: string;
    public createdTimestamp: string;
    public copyright: string;
    public focalLength: string;
    public iso: string;
    public shutterSpeed: string;
    public title: string;
    public orientation: string;

    public constructor(init?: Partial<ImageMeta>) { (Object as any).assign(this, init); }
}

export class MediaDetails
{
    public width: number;
    public height: number;
    public file: string;
    public sizes: { [index: string]: MediaSize; };
    public imageMeta: ImageMeta;

    public constructor(init?: Partial<MediaDetails>) { (Object as any).assign(this, init); }
}

export class HrefBase
{
    public href: string;

    public constructor(init?: Partial<HrefBase>) { (Object as any).assign(this, init); }
}

export class Self extends HrefBase
{

    public constructor(init?: Partial<Self>) { super(init); (Object as any).assign(this, init); }
}

export class Collection extends HrefBase
{

    public constructor(init?: Partial<Collection>) { super(init); (Object as any).assign(this, init); }
}

export class About extends HrefBase
{

    public constructor(init?: Partial<About>) { super(init); (Object as any).assign(this, init); }
}

export class WpPostType extends HrefBase
{

    public constructor(init?: Partial<WpPostType>) { super(init); (Object as any).assign(this, init); }
}

export class Cury extends HrefBase
{
    public name: string;
    public templated: boolean;

    public constructor(init?: Partial<Cury>) { super(init); (Object as any).assign(this, init); }
}

export class Author extends HrefBase
{
    public embeddable: boolean;

    public constructor(init?: Partial<Author>) { super(init); (Object as any).assign(this, init); }
}

export class Reply extends HrefBase
{
    public embeddable: boolean;

    public constructor(init?: Partial<Reply>) { super(init); (Object as any).assign(this, init); }
}

export class VersionHistory extends HrefBase
{

    public constructor(init?: Partial<VersionHistory>) { super(init); (Object as any).assign(this, init); }
}

export class HttpsApiWOrgAttachment extends HrefBase
{

    public constructor(init?: Partial<HttpsApiWOrgAttachment>) { super(init); (Object as any).assign(this, init); }
}

export class HttpsApiWOrgFeaturedmedia extends HrefBase
{
    public embeddable: boolean;

    public constructor(init?: Partial<HttpsApiWOrgFeaturedmedia>) { super(init); (Object as any).assign(this, init); }
}

export class HttpsApiWOrgTerm extends HrefBase
{
    public taxonomy: string;
    public embeddable: boolean;

    public constructor(init?: Partial<HttpsApiWOrgTerm>) { super(init); (Object as any).assign(this, init); }
}

export class Links
{
    public self: Self[];
    public collection: Collection[];
    public about: About[];
    public wpPostType: WpPostType[];
    public curies: Cury[];
    public author: Author[];
    public replies: Reply[];
    public versions: VersionHistory[];
    public attachment: HttpsApiWOrgAttachment[];
    public featuredMedia: HttpsApiWOrgFeaturedmedia[];
    public term: HttpsApiWOrgTerm[];

    public constructor(init?: Partial<Links>) { (Object as any).assign(this, init); }
}

export class MediaItem extends Base
{
    public date: string;
    public dateGmt: string;
    public guid: string;
    public modified: string;
    public modifiedGmt: string;
    public slug: string;
    public status: MediaQueryStatus;
    public type: string;
    public link: string;
    public title: Title;
    public author: number;
    public commentStatus?: OpenStatus;
    public pingStatus?: OpenStatus;
    public altText: string;
    public caption: Caption;
    public description: Description;
    public mediaType: MediaType;
    public mimeType: string;
    public mediaDetails: MediaDetails;
    public post: number;
    public sourceUrl: string;
    public meta: Object;
    public links: Links;

    public constructor(init?: Partial<MediaItem>) { super(init); (Object as any).assign(this, init); }
}

export class BlogPost
{
    public date: string;
    public dateGmt: string;
    public modified: string;
    public modifiedGmt: string;
    public slug: string;
    public status: Status;
    public type: string;
    public title: Title;
    public link: string;
    public content: Content;
    public featuredMedia?: number;
    public categories: number[];
    public fImg_Url: string;
    public mediaItem: MediaItem;

    public constructor(init?: Partial<BlogPost>) { (Object as any).assign(this, init); }
}

export class Acf
{
    public section: Section[];

    public constructor(init?: Partial<Acf>) { (Object as any).assign(this, init); }
}

export class WpPage
{
    public acf: Acf;

    public constructor(init?: Partial<WpPage>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class Section
{
    public module: Module[];

    public constructor(init?: Partial<Section>) { (Object as any).assign(this, init); }
}

export class TileGrid
{
    public large: number;
    public medium: number;
    public small: number;

    public constructor(init?: Partial<TileGrid>) { (Object as any).assign(this, init); }
}

export class ModuleTileDesign
{
    public tileGrid: TileGrid;
    public tileAspect: number;
    public tileValign: string;
    public tileHalign: string;
    public tileColor: string;
    public tileBgColor: string;
    public tileBgImg: string;
    public tileBgSize: string;
    public tileBgPosition: number;
    public tileBorderless: boolean;
    public tileRounded: boolean;
    public tileContentBg: string;

    public constructor(init?: Partial<ModuleTileDesign>) { (Object as any).assign(this, init); }
}

export class Module
{
    public acfFcLayout: string;
    public simpleContent: string;
    public accordionItem: AccordionItem[];
    public tileType?: number;
    public tileText: string;
    public tileLink: string;
    public innerTile: string;
    public tileDesign: ModuleTileDesign;
    public mobileOrderFirst?: boolean;

    public constructor(init?: Partial<Module>) { (Object as any).assign(this, init); }
}

export class AccordionItem
{
    public accordionTitle: string;
    public accordionText: string;

    public constructor(init?: Partial<AccordionItem>) { (Object as any).assign(this, init); }
}

export class GetBlogPostsResponse
{
    public results: BlogPost[];

    public constructor(init?: Partial<GetBlogPostsResponse>) { (Object as any).assign(this, init); }
}

export class GetBlogPostResponse
{
    public result: BlogPost;

    public constructor(init?: Partial<GetBlogPostResponse>) { (Object as any).assign(this, init); }
}

export class GetFrontendTextResponse
{
    public content: string;

    public constructor(init?: Partial<GetFrontendTextResponse>) { (Object as any).assign(this, init); }
}

export class GetFrontendPageResponse
{
    public modules: PersistentModule[];

    public constructor(init?: Partial<GetFrontendPageResponse>) { (Object as any).assign(this, init); }
}

export class GetWpPageResponse
{
    public dummy: WpPage;
    public result: Object;

    public constructor(init?: Partial<GetWpPageResponse>) { (Object as any).assign(this, init); }
}

export class GeocodingResponse
{
    public lat: number;
    public lng: number;
    public locality: string[];
    public postalcode: string[];
    public address: string[];
    public formattedAddress: string[];
    public status: string;

    public constructor(init?: Partial<GeocodingResponse>) { (Object as any).assign(this, init); }
}

// @Route("/create_key_pair")
export class CreateKeyPair implements IReturn<CreateKeyPair>
{

    public constructor(init?: Partial<CreateKeyPair>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateKeyPair(); }
    public getTypeName() { return 'CreateKeyPair'; }
    public getMethod() { return 'GET'; }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConvertSessionToTokenResponse
{
    // @DataMember(Order=1)
    public meta: { [index: string]: string; };

    // @DataMember(Order=2)
    public accessToken: string;

    // @DataMember(Order=3)
    public refreshToken: string;

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConvertSessionToTokenResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAccessTokenResponse
{
    // @DataMember(Order=1)
    public accessToken: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAccessTokenResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegisterResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public referrerUrl: string;

    // @DataMember(Order=5)
    public bearerToken: string;

    // @DataMember(Order=6)
    public refreshToken: string;

    // @DataMember(Order=7)
    public roles: string[];

    // @DataMember(Order=8)
    public permissions: string[];

    // @DataMember(Order=9)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=10)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegisterResponse>) { (Object as any).assign(this, init); }
}

// @Route("/blog/posts")
export class GetBlogPosts implements IReturn<GetBlogPostsResponse>
{
    public limit: number;
    public categories: number[];

    public constructor(init?: Partial<GetBlogPosts>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetBlogPostsResponse(); }
    public getTypeName() { return 'GetBlogPosts'; }
    public getMethod() { return 'GET'; }
}

// @Route("/blog/post")
export class GetBlogPost implements IReturn<GetBlogPostResponse>
{
    public slug: string;

    public constructor(init?: Partial<GetBlogPost>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetBlogPostResponse(); }
    public getTypeName() { return 'GetBlogPost'; }
    public getMethod() { return 'GET'; }
}

// @Route("/frontend/text")
export class GetFrontendText implements IReturn<GetFrontendTextResponse>
{
    public uniqueIdentifier: string;

    public constructor(init?: Partial<GetFrontendText>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFrontendTextResponse(); }
    public getTypeName() { return 'GetFrontendText'; }
    public getMethod() { return 'GET'; }
}

// @Route("/frontend/text", "POST")
export class PostFrontendText implements IReturnVoid
{
    public uniqueIdentifier: string;
    public content: string;

    public constructor(init?: Partial<PostFrontendText>) { (Object as any).assign(this, init); }
    public createResponse() {}
    public getTypeName() { return 'PostFrontendText'; }
    public getMethod() { return 'POST'; }
}

// @Route("/frontend/page")
export class GetFrontendPage implements IReturn<GetFrontendPageResponse>
{
    public uniqueIdentifier: string;

    public constructor(init?: Partial<GetFrontendPage>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFrontendPageResponse(); }
    public getTypeName() { return 'GetFrontendPage'; }
    public getMethod() { return 'GET'; }
}

// @Route("/frontend/page", "POST")
export class PostFrontendPage implements IReturnVoid
{
    public uniqueIdentifier: string;
    public modules: PersistentModule[];

    public constructor(init?: Partial<PostFrontendPage>) { (Object as any).assign(this, init); }
    public createResponse() {}
    public getTypeName() { return 'PostFrontendPage'; }
    public getMethod() { return 'POST'; }
}

// @Route("/wp/page")
export class GetWpPage implements IReturn<GetWpPageResponse>
{
    public slug: string;

    public constructor(init?: Partial<GetWpPage>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetWpPageResponse(); }
    public getTypeName() { return 'GetWpPage'; }
    public getMethod() { return 'GET'; }
}

// @Route("/geocoding")
export class Geocoding implements IReturn<GeocodingResponse>
{
    public address: string;
    public country: string;

    public constructor(init?: Partial<Geocoding>) { (Object as any).assign(this, init); }
    public createResponse() { return new GeocodingResponse(); }
    public getTypeName() { return 'Geocoding'; }
    public getMethod() { return 'GET'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
}

// @Route("/session-to-token")
// @DataContract
export class ConvertSessionToToken implements IReturn<ConvertSessionToTokenResponse>, IPost
{
    // @DataMember(Order=1)
    public preserveSession: boolean;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ConvertSessionToToken>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConvertSessionToTokenResponse(); }
    public getTypeName() { return 'ConvertSessionToToken'; }
    public getMethod() { return 'ANY'; }
}

// @Route("/access-token")
// @DataContract
export class GetAccessToken implements IReturn<GetAccessTokenResponse>, IPost
{
    // @DataMember(Order=1)
    public refreshToken: string;

    // @DataMember(Order=2)
    public useTokenCookie?: boolean;

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetAccessToken>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAccessTokenResponse(); }
    public getTypeName() { return 'GetAccessToken'; }
    public getMethod() { return 'ANY'; }
}

// @Route("/register")
// @DataContract
export class Register implements IReturn<RegisterResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public firstName: string;

    // @DataMember(Order=3)
    public lastName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public email: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public confirmPassword: string;

    // @DataMember(Order=8)
    public autoLogin?: boolean;

    // @DataMember(Order=10)
    public errorView: string;

    // @DataMember(Order=11)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Register>) { (Object as any).assign(this, init); }
    public createResponse() { return new RegisterResponse(); }
    public getTypeName() { return 'Register'; }
}

