























































import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator';
import TextCircle from "~/components/TextCircle.vue";
@Component({
  components: {TextCircle}
})
export default class PopUp extends Vue {
  @Prop()
  readonly cookieModalVisible;

  @Prop()
  readonly hidden;

  show = false;

  created(): void {
    this.showIfNotDisabled(this.cookieModalVisible);
  }

  openLink(): void {
    this.disablePopup();
    window.location.href = "/blog/jetzt-traumstelle-finden-und-gratis-fortbildungsabo-sichern";
  }

  disablePopup(): void {
    this.show = false;
    this.$cookies.set('popup-state2', {'seen': true}, {
      path: '/',
      maxAge: 60 * 60 * 24 * 7
    });
  }

  showIfNotDisabled(cookieModal: boolean): void {
    if (cookieModal === false) {
      const store = this.$cookies.get('popup-state2') || {'seen': false};
      this.show = !store['seen'];
    }
  }

  @Watch('cookieModalVisible')
  handleCookieModalChange(value: boolean): void {
    this.showIfNotDisabled(value);
  }
}
