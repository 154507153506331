export default class ApiResponse<T> {
  public data: T|ApiResponseData<T>;
  public error: ApiResponseError
}

export class ApiResponseData<T> {
  public metaCount: {[key: string]: number} = {};
  public totalItems: number = 0;
  public items: T[] = [];

  constructor(data = null) {
    if (data) {
      this.metaCount = data.metaCount || {};
      this.totalItems = data.totalItems || 0;
      this.items = data.items || [];
    }
  }
}

export class ApiResponseError {
  public code: number;
  public message: string;
  public errors: any[];
}
