


















































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Offer} from "~/shared/Backend.dtos";
import {OfferType} from "~/entities/OfferType";
import TextCircle from "~/components/TextCircle.vue";
@Component({
  components: {TextCircle}
})
export default class OfferListItem extends Vue {
  favorite = false;
  labels = Offer.labels.de;

  @Prop({type: String, required: true})
  id;

  @Prop({type: Object, required: true, default: () => ({})})
  offer: Offer;

  @Prop({type: String})
  img;

  @Prop({type: String})
  title;

  @Prop({type: String})
  subtitle;

  @Prop({type: String})
  standort;

  @Prop({type: String})
  chiffre;

  @Prop({type: String})
  text;

  @Prop({type: String})
  to;

  @Prop({type: String, default: '#'})
  href;

  @Prop({type: String, default: 'mehr Infos'})
  btnLabel;

  @Prop({type: String, default: 'primary'})
  btnVariant;

  @Prop({type: Boolean, default: false})
  isNew;

  @Prop({type: Boolean, default: false})
  isGeolocated;

  @Prop({type: Boolean})
  small;

  get imgUrl(): string {
    if (this.offer.offer_type === OfferType.Praxis) {
      if (this.offer._files && this.offer._files.length > 0) {
        if (this.offer.import_id !== 0) {
          const file = this.offer._files[0];
          const fileName = file.file_name;
          return `https://www.tvd-praxisvermittlung.de/storage/alt/${this.offer.import_id}/${fileName}`;
        } else {
          const file = this.offer._files[0];
          const fileName = file.file_name.replace(/(.*)\.(.*)/, '$1-640.$2');
          return `https://www.tvd-praxisvermittlung.de/storage/${this.offer.uuid}/${fileName}`;
        }
      }
    } else {
      if (this.offer._files && this.offer._files.length > 0) {
        return this.offer._files[0]._media['640'];
      }
    }
    return '';
  }

  get offerKeys(): string[] {
    return Object.keys(this.offer).filter(k => !!this.offer[k]);
  }

  badgeFilter(metaKey: string): boolean {
    return (metaKey.startsWith('_stellenart_') && metaKey !== '_stellenart_is_empfehlung')
    || metaKey.startsWith('_praxis_art_')
    || !!metaKey.match(/_region_\d/)
    || metaKey === '_besonderheit_berufseinsteiger_willkommen'
    || metaKey === '_standort_distance'
  }
}
