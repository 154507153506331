import {Vue} from 'nuxt-property-decorator';
import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate';
import {extend} from 'vee-validate';
import {alpha_dash, required, email, min, max, numeric, regex, min_value, max_value, is, oneOf} from 'vee-validate/dist/rules';
import {localize} from "vee-validate";
import de from 'vee-validate/dist/locale/de.json';

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

setInteractionMode('eager');

localize('de', de);

extend('alpha_dash', alpha_dash);
extend('email', email);
extend('min', min);
extend('min_value', min_value);
extend('required', required);
extend('max', max);
extend('max_value', max_value);
extend('numeric', numeric);
extend('regex', regex);
extend('is', is);
extend('oneOf', oneOf);
extend('phone', {
  message: (field) => field + ' muss eine gültige Telefonummer sein',
  validate: (val) => {
    const re = new RegExp(/^[0-9+\- ]+$/);
    return re.test(val);
  }
})

