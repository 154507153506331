



















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
export default class Promised<T> extends Vue {
  @Prop()
  promise;

  @Prop({ type: Boolean, default: false })
  overlay;

  @Prop({type: String})
  overlayClass;

  @Prop({type: Number, default: 0})
  debounce;

  @Prop({type: String, default: 'fade'})
  animation;

  data: T = null;
  error = null;
  loading = false;
  debounceTimer = null;

  async fetch() {
    await this.load();
  }

  async mounted() {
    if (this.data) return;
    await this.load();
  }

  async load() {
    this.error = null;

    if (!this.promise) {
      this.data = null;
      return;
    }

    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.loading = true
    }, this.debounce);

    try {
      this.data = await this.promise;
      this.$emit('resolved', this.data);
    } catch (e) {
      this.error = e;
      this.$emit('rejected', e);
    }

    clearTimeout(this.debounceTimer);
    this.loading = false;
  }

  @Watch('promise')
  handler (promise: Promise<T>) {
    this.load();
  }
}
