import Vue from 'vue';

import {
  FormInputPlugin,
  SidebarPlugin,
  CollapsePlugin,
  BVToastPlugin,
  ModalPlugin,
  VBTogglePlugin,
  VBModalPlugin,
  BContainer,
  BRow,
  BCol,
  BButton,
  BButtonGroup,
  BButtonClose,
  BFormInput,
  BFormDatepicker,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormRow,
  BFormSelect,
  BInputGroup,
  BInputGroupText,
  BCard,
  BCardImg,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCollapse,
  BLink,
  BIcon,
  BImgLazy,
  BImg,
  BSidebar,
  BOverlay,
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BNavbarNav,
  BNavItem,
  BNavItemDropdown,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  BBadge,
  BForm,
  BListGroup,
  BListGroupItem,
  BPagination,
  BModal,
  BToast,
  BToaster,
  BJumbotron,
  BFormSelectOption,
  BTabs,
  BTab,
  BTable,
  BProgress,
  BProgressBar,
  BIconBellFill,
  BIconCaretLeftFill,
  BIconCaretRightFill,
  BIconCaretDownFill,
  BIconPen,
  BIconX,
  BIconGear,
  BIconXCircle,
  BIconCheckCircle,
  BIconArrowRepeat,
  BIconFunnel,
  BIconHeart,
  BIconHeartFill,
  BIconCheck,
  BIconInfo,
  BIconInfoCircle,
  BIconInfoCircleFill,
  BIconSquare,
  BIconCheckSquare,
  BIconCloudUpload,
  BIconList,
  BIconGeoAlt,
  BIconArrowRight,
  BIconShare,
  BIconShareFill,
  BIconHandThumbsUpFill,
  BIconHandThumbsDownFill
} from 'bootstrap-vue';

Vue.use(FormInputPlugin);
Vue.use(SidebarPlugin);
Vue.use(CollapsePlugin);
Vue.use(BVToastPlugin);
Vue.use(ModalPlugin);

Vue.use(VBTogglePlugin);
Vue.use(VBModalPlugin);

Vue.component('BContainer', BContainer);
Vue.component('BRow', BRow);
Vue.component('BCol', BCol);
Vue.component('BButton', BButton);
Vue.component('BButtonGroup', BButtonGroup);
Vue.component('BButtonClose', BButtonClose);
Vue.component('BFormInput', BFormInput);
Vue.component('BFormDatepicker', BFormDatepicker);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BFormCheckboxGroup', BFormCheckboxGroup);
Vue.component('BFormRadio', BFormRadio);
Vue.component('BFormRadioGroup', BFormRadioGroup);
Vue.component('BFormInvalidFeedback', BFormInvalidFeedback);
Vue.component('BFormRow', BFormRow);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BInputGroup', BInputGroup);
Vue.component('BInputGroupText', BInputGroupText);
Vue.component('BCard', BCard);
Vue.component('BCardImg', BCardImg);
Vue.component('BCardBody', BCardBody);
Vue.component('BCardFooter', BCardFooter);
Vue.component('BCardHeader', BCardHeader);
Vue.component('BCollapse', BCollapse);
Vue.component('BLink', BLink);
Vue.component('BIcon', BIcon);
Vue.component('BImgLazy', BImgLazy);
Vue.component('BImg', BImg);
Vue.component('BSidebar', BSidebar);
Vue.component('BOverlay', BOverlay);
Vue.component('BNavbar', BNavbar);
Vue.component('BNavbarBrand', BNavbarBrand);
Vue.component('BNavbarToggle', BNavbarToggle);
Vue.component('BNavbarNav', BNavbarNav);
Vue.component('BNavItem', BNavItem);
Vue.component('BNavItemDropdown', BNavItemDropdown);
Vue.component('BDropdown', BDropdown);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('BFormTextarea', BFormTextarea);
Vue.component('BBadge', BBadge);
Vue.component('BForm', BForm);
Vue.component('BListGroup', BListGroup);
Vue.component('BListGroupItem', BListGroupItem);
Vue.component('BPagination', BPagination);
Vue.component('BModal', BModal);
Vue.component('BToast', BToast);
Vue.component('BToaster', BToaster);
Vue.component('BJumbotron', BJumbotron);
Vue.component('BFormSelectOption', BFormSelectOption);
Vue.component('BTabs', BTabs);
Vue.component('BTab', BTab);
Vue.component('BTable', BTable);
Vue.component('BProgress', BProgress);
Vue.component('BProgressBar', BProgressBar);
Vue.component('BIconBellFill', BIconBellFill);
Vue.component('BIconCaretLeftFill', BIconCaretLeftFill);
Vue.component('BIconCaretRightFill', BIconCaretRightFill);
Vue.component('BIconCaretDownFill', BIconCaretDownFill);
Vue.component('BIconPen', BIconPen);
Vue.component('BIconX', BIconX);
Vue.component('BIconGear', BIconGear);
Vue.component('BIconXCircle', BIconXCircle);
Vue.component('BIconCheckCircle', BIconCheckCircle);
Vue.component('BIconArrowRepeat', BIconArrowRepeat);
Vue.component('BIconFunnel', BIconFunnel);
Vue.component('BIconHeart', BIconHeart);
Vue.component('BIconHeartFill', BIconHeartFill);
Vue.component('BIconCheck', BIconCheck);
Vue.component('BIconInfo', BIconInfo);
Vue.component('BIconInfoCircle', BIconInfoCircle);
Vue.component('BIconInfoCircleFill', BIconInfoCircleFill);
Vue.component('BIconSquare', BIconSquare);
Vue.component('BIconCheckSquare', BIconCheckSquare);
Vue.component('BIconCloudUpload', BIconCloudUpload);
Vue.component('BIconList', BIconList);
Vue.component('BIconGeoAlt', BIconGeoAlt);
Vue.component('BIconArrowRight', BIconArrowRight);
Vue.component('BIconShare', BIconShare);
Vue.component('BIconShareFill', BIconShareFill);
Vue.component('BIconHandThumbsUpFill', BIconHandThumbsUpFill);
Vue.component('BIconHandThumbsDownFill', BIconHandThumbsDownFill);
