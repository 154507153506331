import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faThumbsUp as freeFasFaThumbsUp,
  faThumbsDown as freeFasFaThumbsDown
} from '@fortawesome/free-solid-svg-icons'

import {
  faTwitter as freeFabFaTwitter,
  faFacebook as freeFabFaFacebook,
  faWhatsapp as freeFabFaWhatsapp
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFasFaThumbsUp,
  freeFasFaThumbsDown,
  freeFabFaTwitter,
  freeFabFaFacebook,
  freeFabFaWhatsapp
)

config.autoAddCss = false

Vue.component('faIcon', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
