import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fce29282 = () => interopDefault(import('../ClientApp/pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _6a0c4af0 = () => interopDefault(import('../ClientApp/pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _30153560 = () => interopDefault(import('../ClientApp/pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _d993ed8e = () => interopDefault(import('../ClientApp/pages/dissertation/index.vue' /* webpackChunkName: "pages/dissertation/index" */))
const _08cc3306 = () => interopDefault(import('../ClientApp/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _6eaeb656 = () => interopDefault(import('../ClientApp/pages/gehaltsreport.vue' /* webpackChunkName: "pages/gehaltsreport" */))
const _758113e4 = () => interopDefault(import('../ClientApp/pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _5f2e574d = () => interopDefault(import('../ClientApp/pages/kontakt.vue' /* webpackChunkName: "pages/kontakt" */))
const _4e825c56 = () => interopDefault(import('../ClientApp/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3df78914 = () => interopDefault(import('../ClientApp/pages/mitarbeiter/index.vue' /* webpackChunkName: "pages/mitarbeiter/index" */))
const _63cdf0ef = () => interopDefault(import('../ClientApp/pages/nachfolger/index.vue' /* webpackChunkName: "pages/nachfolger/index" */))
const _66aa48b2 = () => interopDefault(import('../ClientApp/pages/praktikum/index.vue' /* webpackChunkName: "pages/praktikum/index" */))
const _1396c848 = () => interopDefault(import('../ClientApp/pages/praxen/index.vue' /* webpackChunkName: "pages/praxen/index" */))
const _497be2fd = () => interopDefault(import('../ClientApp/pages/stellen/index.vue' /* webpackChunkName: "pages/stellen/index" */))
const _2ce83771 = () => interopDefault(import('../ClientApp/pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _82d77144 = () => interopDefault(import('../ClientApp/pages/admin/agenten/index.vue' /* webpackChunkName: "pages/admin/agenten/index" */))
const _6e1982e6 = () => interopDefault(import('../ClientApp/pages/admin/bewerbungen/index.vue' /* webpackChunkName: "pages/admin/bewerbungen/index" */))
const _3089fbb9 = () => interopDefault(import('../ClientApp/pages/admin/dissertation/index.vue' /* webpackChunkName: "pages/admin/dissertation/index" */))
const _7c7d070b = () => interopDefault(import('../ClientApp/pages/admin/gesuch/index.vue' /* webpackChunkName: "pages/admin/gesuch/index" */))
const _0a596960 = () => interopDefault(import('../ClientApp/pages/admin/gesuche/index.vue' /* webpackChunkName: "pages/admin/gesuche/index" */))
const _220a98df = () => interopDefault(import('../ClientApp/pages/admin/mailvorlagen/index.vue' /* webpackChunkName: "pages/admin/mailvorlagen/index" */))
const _52c1339c = () => interopDefault(import('../ClientApp/pages/admin/praktikum/index.vue' /* webpackChunkName: "pages/admin/praktikum/index" */))
const _71729ac8 = () => interopDefault(import('../ClientApp/pages/admin/praxen/index.vue' /* webpackChunkName: "pages/admin/praxen/index" */))
const _b1cb3f06 = () => interopDefault(import('../ClientApp/pages/admin/stellen/index.vue' /* webpackChunkName: "pages/admin/stellen/index" */))
const _cccf8782 = () => interopDefault(import('../ClientApp/pages/faq/abgeber-einer-tierarztpraxis.vue' /* webpackChunkName: "pages/faq/abgeber-einer-tierarztpraxis" */))
const _7a883b7e = () => interopDefault(import('../ClientApp/pages/faq/angestellte-tieraerzte.vue' /* webpackChunkName: "pages/faq/angestellte-tieraerzte" */))
const _1a115bc7 = () => interopDefault(import('../ClientApp/pages/faq/arbeitgeber-bzw-inhaber-einer-tierarztpraxis.vue' /* webpackChunkName: "pages/faq/arbeitgeber-bzw-inhaber-einer-tierarztpraxis" */))
const _6ecb7e0f = () => interopDefault(import('../ClientApp/pages/faq/studenten-der-tiermedizin.vue' /* webpackChunkName: "pages/faq/studenten-der-tiermedizin" */))
const _6fb60d8a = () => interopDefault(import('../ClientApp/pages/faq/tierarztpraxisgruender.vue' /* webpackChunkName: "pages/faq/tierarztpraxisgruender" */))
const _475cf22f = () => interopDefault(import('../ClientApp/pages/faq/tiermedizinische-fachangestellte.vue' /* webpackChunkName: "pages/faq/tiermedizinische-fachangestellte" */))
const _2e85306d = () => interopDefault(import('../ClientApp/pages/mitarbeiter/profil/index.vue' /* webpackChunkName: "pages/mitarbeiter/profil/index" */))
const _629e4c32 = () => interopDefault(import('../ClientApp/pages/nachfolger/profil/index.vue' /* webpackChunkName: "pages/nachfolger/profil/index" */))
const _f483528a = () => interopDefault(import('../ClientApp/pages/praxen/details/index.vue' /* webpackChunkName: "pages/praxen/details/index" */))
const _f6d02f18 = () => interopDefault(import('../ClientApp/pages/praxen/suche.vue' /* webpackChunkName: "pages/praxen/suche" */))
const _63682720 = () => interopDefault(import('../ClientApp/pages/stellen/details/index.vue' /* webpackChunkName: "pages/stellen/details/index" */))
const _8b05f9ae = () => interopDefault(import('../ClientApp/pages/stellen/suche.vue' /* webpackChunkName: "pages/stellen/suche" */))
const _5002f610 = () => interopDefault(import('../ClientApp/pages/user/agenten/index.vue' /* webpackChunkName: "pages/user/agenten/index" */))
const _22a136f8 = () => interopDefault(import('../ClientApp/pages/admin/bewerbungen/view/index.vue' /* webpackChunkName: "pages/admin/bewerbungen/view/index" */))
const _c65eaeb8 = () => interopDefault(import('../ClientApp/pages/admin/gesuch/edit/index.vue' /* webpackChunkName: "pages/admin/gesuch/edit/index" */))
const _90149648 = () => interopDefault(import('../ClientApp/pages/admin/gesuch/new/index.vue' /* webpackChunkName: "pages/admin/gesuch/new/index" */))
const _11d42cd8 = () => interopDefault(import('../ClientApp/pages/admin/gesuche/praxen.vue' /* webpackChunkName: "pages/admin/gesuche/praxen" */))
const _6bc111cb = () => interopDefault(import('../ClientApp/pages/admin/gesuche/stellen.vue' /* webpackChunkName: "pages/admin/gesuche/stellen" */))
const _3fd91b50 = () => interopDefault(import('../ClientApp/pages/admin/mailvorlagen/edit/index.vue' /* webpackChunkName: "pages/admin/mailvorlagen/edit/index" */))
const _d83a39f2 = () => interopDefault(import('../ClientApp/pages/admin/praxen/edit/index.vue' /* webpackChunkName: "pages/admin/praxen/edit/index" */))
const _2e2b6672 = () => interopDefault(import('../ClientApp/pages/admin/stellen/edit/index.vue' /* webpackChunkName: "pages/admin/stellen/edit/index" */))
const _9dd69028 = () => interopDefault(import('../ClientApp/pages/admin/bewerbungen/view/_id.vue' /* webpackChunkName: "pages/admin/bewerbungen/view/_id" */))
const _6e0ef7e8 = () => interopDefault(import('../ClientApp/pages/admin/gesuch/edit/_id.vue' /* webpackChunkName: "pages/admin/gesuch/edit/_id" */))
const _5e95b1b8 = () => interopDefault(import('../ClientApp/pages/admin/mailvorlagen/edit/_id.vue' /* webpackChunkName: "pages/admin/mailvorlagen/edit/_id" */))
const _11e809a2 = () => interopDefault(import('../ClientApp/pages/admin/praxen/edit/_id.vue' /* webpackChunkName: "pages/admin/praxen/edit/_id" */))
const _3437454c = () => interopDefault(import('../ClientApp/pages/admin/stellen/edit/_id.vue' /* webpackChunkName: "pages/admin/stellen/edit/_id" */))
const _2ee32bd6 = () => interopDefault(import('../ClientApp/pages/mitarbeiter/profil/_id.vue' /* webpackChunkName: "pages/mitarbeiter/profil/_id" */))
const _3155b31a = () => interopDefault(import('../ClientApp/pages/nachfolger/profil/_id.vue' /* webpackChunkName: "pages/nachfolger/profil/_id" */))
const _f7f0951a = () => interopDefault(import('../ClientApp/pages/praxen/details/_slug.vue' /* webpackChunkName: "pages/praxen/details/_slug" */))
const _66d569b0 = () => interopDefault(import('../ClientApp/pages/stellen/details/_slug.vue' /* webpackChunkName: "pages/stellen/details/_slug" */))
const _fe118710 = () => interopDefault(import('../ClientApp/pages/user/agenten/_id.vue' /* webpackChunkName: "pages/user/agenten/_id" */))
const _6d798d80 = () => interopDefault(import('../ClientApp/pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _f64a8d82 = () => interopDefault(import('../ClientApp/pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _fce29282,
    name: "admin"
  }, {
    path: "/blog",
    component: _6a0c4af0,
    name: "blog"
  }, {
    path: "/datenschutz",
    component: _30153560,
    name: "datenschutz"
  }, {
    path: "/dissertation",
    component: _d993ed8e,
    name: "dissertation"
  }, {
    path: "/faq",
    component: _08cc3306,
    name: "faq"
  }, {
    path: "/gehaltsreport",
    component: _6eaeb656,
    name: "gehaltsreport"
  }, {
    path: "/impressum",
    component: _758113e4,
    name: "impressum"
  }, {
    path: "/kontakt",
    component: _5f2e574d,
    name: "kontakt"
  }, {
    path: "/login",
    component: _4e825c56,
    name: "login"
  }, {
    path: "/mitarbeiter",
    component: _3df78914,
    name: "mitarbeiter"
  }, {
    path: "/nachfolger",
    component: _63cdf0ef,
    name: "nachfolger"
  }, {
    path: "/praktikum",
    component: _66aa48b2,
    name: "praktikum"
  }, {
    path: "/praxen",
    component: _1396c848,
    name: "praxen"
  }, {
    path: "/stellen",
    component: _497be2fd,
    name: "stellen"
  }, {
    path: "/user",
    component: _2ce83771,
    name: "user"
  }, {
    path: "/admin/agenten",
    component: _82d77144,
    name: "admin-agenten"
  }, {
    path: "/admin/bewerbungen",
    component: _6e1982e6,
    name: "admin-bewerbungen"
  }, {
    path: "/admin/dissertation",
    component: _3089fbb9,
    name: "admin-dissertation"
  }, {
    path: "/admin/gesuch",
    component: _7c7d070b,
    name: "admin-gesuch"
  }, {
    path: "/admin/gesuche",
    component: _0a596960,
    name: "admin-gesuche"
  }, {
    path: "/admin/mailvorlagen",
    component: _220a98df,
    name: "admin-mailvorlagen"
  }, {
    path: "/admin/praktikum",
    component: _52c1339c,
    name: "admin-praktikum"
  }, {
    path: "/admin/praxen",
    component: _71729ac8,
    name: "admin-praxen"
  }, {
    path: "/admin/stellen",
    component: _b1cb3f06,
    name: "admin-stellen"
  }, {
    path: "/faq/abgeber-einer-tierarztpraxis",
    component: _cccf8782,
    name: "faq-abgeber-einer-tierarztpraxis"
  }, {
    path: "/faq/angestellte-tieraerzte",
    component: _7a883b7e,
    name: "faq-angestellte-tieraerzte"
  }, {
    path: "/faq/arbeitgeber-bzw-inhaber-einer-tierarztpraxis",
    component: _1a115bc7,
    name: "faq-arbeitgeber-bzw-inhaber-einer-tierarztpraxis"
  }, {
    path: "/faq/studenten-der-tiermedizin",
    component: _6ecb7e0f,
    name: "faq-studenten-der-tiermedizin"
  }, {
    path: "/faq/tierarztpraxisgruender",
    component: _6fb60d8a,
    name: "faq-tierarztpraxisgruender"
  }, {
    path: "/faq/tiermedizinische-fachangestellte",
    component: _475cf22f,
    name: "faq-tiermedizinische-fachangestellte"
  }, {
    path: "/mitarbeiter/profil",
    component: _2e85306d,
    name: "mitarbeiter-profil"
  }, {
    path: "/nachfolger/profil",
    component: _629e4c32,
    name: "nachfolger-profil"
  }, {
    path: "/praxen/details",
    component: _f483528a,
    name: "praxen-details"
  }, {
    path: "/praxen/suche",
    component: _f6d02f18,
    name: "praxen-suche"
  }, {
    path: "/stellen/details",
    component: _63682720,
    name: "stellen-details"
  }, {
    path: "/stellen/suche",
    component: _8b05f9ae,
    name: "stellen-suche"
  }, {
    path: "/user/agenten",
    component: _5002f610,
    name: "user-agenten"
  }, {
    path: "/admin/bewerbungen/view",
    component: _22a136f8,
    name: "admin-bewerbungen-view"
  }, {
    path: "/admin/gesuch/edit",
    component: _c65eaeb8,
    name: "admin-gesuch-edit"
  }, {
    path: "/admin/gesuch/new",
    component: _90149648,
    name: "admin-gesuch-new"
  }, {
    path: "/admin/gesuche/praxen",
    component: _11d42cd8,
    name: "admin-gesuche-praxen"
  }, {
    path: "/admin/gesuche/stellen",
    component: _6bc111cb,
    name: "admin-gesuche-stellen"
  }, {
    path: "/admin/mailvorlagen/edit",
    component: _3fd91b50,
    name: "admin-mailvorlagen-edit"
  }, {
    path: "/admin/praxen/edit",
    component: _d83a39f2,
    name: "admin-praxen-edit"
  }, {
    path: "/admin/stellen/edit",
    component: _2e2b6672,
    name: "admin-stellen-edit"
  }, {
    path: "/admin/bewerbungen/view/:id",
    component: _9dd69028,
    name: "admin-bewerbungen-view-id"
  }, {
    path: "/admin/gesuch/edit/:id",
    component: _6e0ef7e8,
    name: "admin-gesuch-edit-id"
  }, {
    path: "/admin/mailvorlagen/edit/:id",
    component: _5e95b1b8,
    name: "admin-mailvorlagen-edit-id"
  }, {
    path: "/admin/praxen/edit/:id",
    component: _11e809a2,
    name: "admin-praxen-edit-id"
  }, {
    path: "/admin/stellen/edit/:id",
    component: _3437454c,
    name: "admin-stellen-edit-id"
  }, {
    path: "/mitarbeiter/profil/:id",
    component: _2ee32bd6,
    name: "mitarbeiter-profil-id"
  }, {
    path: "/nachfolger/profil/:id",
    component: _3155b31a,
    name: "nachfolger-profil-id"
  }, {
    path: "/praxen/details/:slug",
    component: _f7f0951a,
    name: "praxen-details-slug"
  }, {
    path: "/stellen/details/:slug",
    component: _66d569b0,
    name: "stellen-details-slug"
  }, {
    path: "/user/agenten/:id",
    component: _fe118710,
    name: "user-agenten-id"
  }, {
    path: "/blog/:slug",
    component: _6d798d80,
    name: "blog-slug"
  }, {
    path: "/",
    component: _f64a8d82,
    name: "index"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
