import WizardOptionConfig from "~/entities/WizardOptionConfig";
import {WizardOptionType} from "~/entities/WizardOptionType";

export default class WizardOptionRow extends WizardOptionConfig {
  constructor(
    public cols: number[] = null
  ) {
    super('', '', null, WizardOptionType.Row);
  }

  getCols(index) {
    return this.cols && this.cols.length > index ? this.cols[index] : null
  }

  getValue(data): string {
    return this.options.map(x => x.getValue(data)).join(' ');
  }
}
