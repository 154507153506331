



































import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator';
import {File as FileEntity} from "~/shared/Backend.dtos";
import {v4 as uuidv4, validate} from "uuid";

class UploadItem extends FileEntity {
  public progress: number = 0;
  public error: string = '';

  constructor(
    public file: File,
    public rawName: string = uuidv4()
  ) {
    super({
      orig_name: file.name,
      file_ext: file.name.substring(file.name.lastIndexOf('.')),
      raw_name: rawName,
      file_name: rawName + file.name.substring(file.name.lastIndexOf('.'))
    });
  }

  get url() {
    return this._media ? this._media['180'] : window.URL.createObjectURL(this.file);
  }
}

@Component
export default class FileUpload extends Vue {
  @Prop()
  value: FileEntity[];

  @Prop()
  uuid;

  @Prop({type: String, required: true})
  entity;

  $refs: {
    filePicker: HTMLInputElement
  }

  get items(): FileEntity[] {
    return this.value;
  }

  set items(value) {
    this.$emit('input', value);
  }

  fileInput: UploadItem[] = null;
  timestamp: number = new Date().getTime();

  onFileInput(event: any) {
    this.timestamp = new Date().getTime();
    const fileInput: FileList = event.target.files
    this.fileInput = Object.values(fileInput).map(file => {
      return new UploadItem(file);
    });
    if (this.fileInput.length === 0) {
      return;
    }

    this.fileInput.forEach(handle => {
      this.items = [...this.items, handle];
    });
  }

  onProgress(handle: UploadItem) {
    return ({bytesUploaded, bytesTotal, percentage}) => {
      Vue.set(handle, 'progress', percentage);
    }
  }

  deleteItem(index) {
    Vue.delete(this.items, index);
  }

  @Watch('uuid', {immediate: true})
  handle(value) {
    if (!validate(value)) return;
    const path = value;
    const uploads = [];
    this.items.forEach((handle: UploadItem) => {
      if (handle.progress >= 1) {
        return;
      }
      handle[this.entity + '_uuid'] = value;
      const upload = this.$api.upload(handle.file, handle.raw_name, path, this.onProgress(handle)).then(res => {
        this.$api.createContactFile(handle).then(data => {
          Vue.set(handle, 'uuid', data.uuid);
          Vue.set(handle, '_media', data._media);
        });
      }).catch(err => {
        Vue.set(handle, 'error', 'Die Datei ist zu groß!');
        this.$emit('error', handle);
      });
      uploads.push(upload);
    });
    Promise.all(uploads).then(() => {
      this.$emit('success')
    });
  }
}
