<template>
  <b-card
    class="h-100"
    header="Dr. Annika Fels"
    header-bg-variant="white"
    header-class="h5"
    :border-variant="noBorder ? '0' : null"
    no-body
  >
    <b-row no-gutters>
      <b-col>
        <div class="image">
          <b-img
            src="~assets/img/mitarbeiter/Annika_Fels.jpg"
            alt="Annika Fels"
          />
        </div>
      </b-col>
    </b-row>
    <b-card-body>
      <b-row>
        <b-col>
          <div class="table">
            <div>
              <span>Tel.</span><span>0176-46 71 77 19</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mb-3">
        <a
          class="text-primary"
          href="mailto:annika.fels@tvd-finanz.de"
        >
          annika.fels@tvd-finanz.de
        </a>
      </div>
      <div class="mb-3">Personalvermittlung</div>
      <div>Einen Termin buchen?</div>
      <div>
        <a href="https://calendly.com/annika-wemmers/" target="_blank">
          https://calendly.com/annika-wemmers/
        </a>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
  import {Component, Prop, Vue} from 'nuxt-property-decorator';

  @Component
  export default class AnnikaWemmers extends Vue {
    @Prop({type: Boolean, default: false})
    noBorder;
  }
</script>

<style scoped>
  .image img{
    max-width: 100%;
  }

  .table {
    display: table;
  }

  .table > div {
    display: table-row;
  }

  .table > div > span {
    display: table-cell;
  }

  .table > div > span:first-child {
    padding-right: 1rem;
  }
</style>
