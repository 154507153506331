import { Plugin } from '@nuxt/types'
import { get } from 'lodash';

declare module 'vue/types/vue' {
  interface Vue {
    $_get<T>(obj: any, path: string, defaultValue?: T): T
  }
}

declare module '@nuxt/types' {
  interface Context {
    $_get<T>(obj: any, path: string, defaultValue?: T): T
  }
}

const lodashGet: Plugin = (context, inject) => {
  inject('_get', (obj: any, path: string, defaultValue?: any) => get(obj, path, defaultValue));
}

export default lodashGet

