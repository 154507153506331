




































import {Component, Prop, Vue} from 'nuxt-property-decorator';
import {File as FileEntity} from "~/shared/Backend.dtos";
import {v4 as uuidv4} from 'uuid';

class Upload extends FileEntity {
  public progress: number = 0;
  public selected: boolean = false;

  constructor(
    public file: File,
    public offerUuid: string,
    public rawName: string = uuidv4()
  ) {
    super({
      offer_uuid: offerUuid,
      orig_name: file.name,
      file_ext: file.name.substring(file.name.lastIndexOf('.')),
      image_type: file.name.substring(file.name.lastIndexOf('.')+1),
      raw_name: rawName,
      file_name: rawName + file.name.substring(file.name.lastIndexOf('.'))
    });
  }

  get url() {
    return this._media ? this._media['180'] : window.URL.createObjectURL(this.file);
  }
}

class FileItem extends FileEntity {
  public selected: boolean = false;

  constructor(data) {
    super(data);
    this._media = data._media;
  }

  get url() {
    return this._media['180'];
  }
}

@Component
export default class GalleryManager extends Vue {
  @Prop({type: String, required: true})
  uuid;

  items: FileEntity[] = [];
  draggingItem: {display_order: number} = null;
  fileInput: Upload[] = null;
  timestamp: number = new Date().getTime();
  selectable: boolean = false;

  $refs: {
    gallery: HTMLElement,
    filePicker: HTMLInputElement
  }

  get sortedItems() {
    const items = [...this.items];
    items.sort(function(a, b) {
      const aDisplayOrder = a.display_order;
      const bDisplayOrder = b.display_order;
      if (aDisplayOrder < bDisplayOrder) {
        return -1;
      }
      if (aDisplayOrder > bDisplayOrder) {
        return 1;
      }
      return 0;
    })
    return items;
  }

  async fetch() {
    this.$api.searchFiles({offer_uuid: this.uuid}).then(data => {
      this.items = data.items.map(item => new FileItem(item));
    }).catch(err => {
      // Ignore
    });
  }

  dragstart (item, e) {
    this.draggingItem = item;
    e.target.style.opacity = 0.3;
  }

  dragend (e) {
    e.target.style.opacity = 1;
  }

  dragenter (file: FileEntity, e) {
    let tempIndex = file.display_order;
    file.display_order = this.draggingItem.display_order;
    this.draggingItem.display_order = tempIndex;
  }

  saveOrder() {
    this.$api.updateFilesDisplayOrder(this.items, {
      entity_uuid: this.uuid
    }).then(res => {
      this.$bvToast.show('success');
      return res.data as FileEntity;
    }).catch(err => {
      this.$bvToast.show('error');
      return err;
    });
  }

  onFileInput(event: any) {
    let nextDisplayOrder = 0;
    if (this.sortedItems.length > 0) {
      const lastItem = this.sortedItems[this.sortedItems.length-1];
      nextDisplayOrder = lastItem.display_order+1;
    }

    this.timestamp = new Date().getTime();
    const fileInput: FileList = event.target.files
    this.fileInput = Object.values(fileInput).map(file => {
      const upload = new Upload(file, this.uuid);
      upload.display_order = nextDisplayOrder++;
      return upload;
    });
    if (this.fileInput.length === 0) {
      return;
    }

    this.fileInput.forEach(handle => {
      const path = this.uuid
      this.items = [...this.items, handle];
      this.$api.upload(handle.file, handle.raw_name, path, this.onProgress(handle)).then(res => {
        this.$api.createFile(handle).then(data => {
          Vue.set(handle, 'uuid', data.uuid);
          Vue.set(handle, '_media', data._media);
        });
      });
    });
  }

  onProgress(handle: Upload) {
    return ({bytesUploaded, bytesTotal, percentage}) => {
      Vue.set(handle, 'progress', percentage);
    }
  }

  confirmDelete(item) {
    this.$bvModal.msgBoxConfirm('Sollen die ausgewählten Datei wirklich gelöscht werden?', {
      title: 'Bitte bestätigen',
      okVariant: 'danger',
      okTitle: 'JA',
      cancelTitle: 'NEIN',
      centered: true,
      size: 'sm'
    }).then(async val => {
      this.selectable = false;
      if (val) {
        const items = [...this.items]
        for (const item of items) {
          if (item.selected) {
            await this.$api.deleteFile(item.uuid);
            Vue.delete(this.items, this.items.findIndex(x => x.uuid === item.uuid));
          }
        }
      }
    }).catch(err => {
      console.error(err);
    })
  }
}
