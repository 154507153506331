















import {Component, Prop, Vue} from 'nuxt-property-decorator';

@Component
export default class WizardTileGroup extends Vue {
  @Prop({type: Array})
  options;

  @Prop()
  value;

  @Prop()
  cols;

  @Prop()
  colsMd;

  @Prop()
  colsLg;

  @Prop({type: Object, default: () => ({})})
  appendLabels

  @Prop({type: String, default: 'primary'})
  variant;

  get availableOptions() {
    return this.options.filter(x => x.isAvailable)
  }

  get autoCols() {
    return this.cols ? this.cols : 2;
  }

  get autoColsMd() {
    return this.colsMd ? this.colsMd : 3;
  }

  get autoColsLg() {
    if (this.colsLg) {
      return this.colsLg
    } else if (this.options.length >= 4) {
      return 4
    } else {
      return null
    }
  }
}
