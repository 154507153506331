




















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {gmapApi} from "~/plugins/gmap";
import styles from '~/shared/gmap-style.json'

@Component
export default class MyMap extends Vue {
  @Prop({default: () => ({lat:51, lng:9})})
  center;

  @Prop({type: String, default: '70vh'})
  height;

  @Prop({type: Array, default: () => ([])})
  markers;

  $refs: {
    gmap: any
  };

  private debounce = null;
  private options = {
    zoomControl: true,
    gestureHandling: 'greedy',
    styles: styles,
    minZoom: 5,
    maxZoom: 18
  }

  @Watch('markers', {immediate: true})
  handle(markers) {
    if (markers.length === 0) return;

    if (this.debounce) {
      clearTimeout(this.debounce);
      this.debounce = null;
    }

    this.debounce = setTimeout(async () => {
      try {
        const google = gmapApi();
        const map = await this.$refs.gmap.$mapPromise;
        const bounds = new google.maps.LatLngBounds();
        markers.filter(x => x.lat && x.lng).forEach((marker) => {
          bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
        });
        map.fitBounds(bounds);
      } catch (e) {
        console.error(e)
      }
      this.debounce = null;
    }, 500)
  }
}
