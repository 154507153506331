

















































































































































































































































































































































































































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Iso3166_1, Offer} from "~/shared/Backend.dtos";
import {OfferType} from "~/entities/OfferType";
import {WizardOptionType} from "~/entities/WizardOptionType";
import {presets} from "~/entities/WizardConfig";
import WizardOptionText from "~/entities/WizardOptionText";
import WizardOptionGroup from "~/entities/WizardOptionGroup";
import WizardOptionCheckbox from "~/entities/WizardOptionCheckbox";
import WizardOptionRadio from "~/entities/WizardOptionRadio";
import EmailOfferList from "~/components/EmailOfferList.vue";

// Letzter Test: 2020-07-09 12:53
@Component({
  components: {EmailOfferList}
})
export default class OfferEditor extends Vue {
  optionType = WizardOptionType;
  offerType = OfferType;
  promise: Promise<Offer> = null;
  types = WizardOptionType;
  presets = presets;

  Editor = null;

  @Prop({type: Object})
  offer: Offer;

  form: Offer = new Offer();
  deleted = false;

  editOfferType = false;
  offerTypeOptions = [
    {text: 'Praxis Exposé', value: 1},
    {text: 'Stellenanzeige', value: 2},
    {text: 'Mitarbeiter Gesuch', value: 3},
    {text: 'Nachfolger Gesuch', value: 4}
  ]

  editIsPublic = false;
  isPublicOptions = [
    {text: 'Veröffentlicht', value: true},
    {text: 'Entwurf', value: false},
  ];

  editIsIntern = false;
  isInternOptions = [
    {text: 'Öffentlich', value: false},
    {text: 'Privat', value: true}
  ]

  adminStatusOptions = [
    {text: '', value: ''},
    {text: 'Offen', value: 'Offen'},
    {text: 'In Bearbeitung', value: 'In Bearbeitung'},
    {text: 'Erledigt', value: 'Erledigt'}
  ]

  editExpireDate = false;
  withoutExpireDate = false;
  previousExpireDate = null;

  kontaktinfos = [
    ...presets.stammdaten,
    new WizardOptionText(Offer._stammdaten_praxisname, 'Praxisname')
  ];

  sonstigeInfos = [
    new WizardOptionGroup().setOptions([
      new WizardOptionText(Offer._praxis_apothekenbestand, Offer.labels.de[Offer._praxis_apothekenbestand]),
      new WizardOptionText(Offer._praxis_gruendungsjahr, Offer.labels.de[Offer._praxis_gruendungsjahr]),
      new WizardOptionText(Offer._praxis_patientenbesitzer, Offer.labels.de[Offer._praxis_patientenbesitzer]),
      new WizardOptionText(Offer._praxis_zulassungsstatus, Offer.labels.de[Offer._praxis_zulassungsstatus]),
      new WizardOptionText(Offer._praxis_jahresnettoumsatz_2015, Offer.labels.de[Offer._praxis_jahresnettoumsatz_2015]),
      new WizardOptionText(Offer._praxis_jahresnettoumsatz_2016, Offer.labels.de[Offer._praxis_jahresnettoumsatz_2016]),
      new WizardOptionText(Offer._praxis_jahresnettoumsatz_2017, Offer.labels.de[Offer._praxis_jahresnettoumsatz_2017]),
      new WizardOptionText(Offer._praxis_jahresnettoumsatz_2018, Offer.labels.de[Offer._praxis_jahresnettoumsatz_2018]),
      new WizardOptionText(Offer._praxis_jahresnettoumsatz_2019, Offer.labels.de[Offer._praxis_jahresnettoumsatz_2019]),
      new WizardOptionText(Offer._praxis_jahresnettoumsatz_2020, Offer.labels.de[Offer._praxis_jahresnettoumsatz_2020]),
      new WizardOptionText(Offer._praxis_jahresnettoumsatz_2021, Offer.labels.de[Offer._praxis_jahresnettoumsatz_2021]),
      new WizardOptionText(Offer._praxis_jahresnettoumsatz_2022, Offer.labels.de[Offer._praxis_jahresnettoumsatz_2022])
    ])
  ];

  stelleGroups: {name: string; options: WizardOptionRadio[]; label: string}[] = [];
  praxisGroups: {name: string; options: WizardOptionRadio[]; label: string}[] = [];

  get isStelle(): boolean {
    return this.form.offer_type === OfferType.Stelle
      || this.form.offer_type === OfferType.MitarbeiterGesuch;
  }

  get isPraxis(): boolean {
    return this.form.offer_type === OfferType.Praxis
      || this.form.offer_type === OfferType.NachfolgerGesuch;
  }

  get isAnfrage(): boolean {
    return this.offer.offer_type === OfferType.NachfolgerGesuch
      || this.offer.offer_type === OfferType.MitarbeiterGesuch;
  }

  get isDissertation(): boolean {
    return this.offer.offer_type === OfferType.Stelle && this.offer._stellenart_dissertation;
  }

  get mainGroups(): {name: string, options: WizardOptionRadio[], label: string}[] {
    if (this.isPraxis) {
      return this.praxisGroups
    } else if (this.isStelle) {
      if (this.offer._stellenart_dissertation) {
        return this.stelleGroups.filter(x => ['rahmenbedingungen'].includes(x.name))
      } else {
        return this.stelleGroups.filter(x => !['taetigkeiten', 'oeffnungszeiten', 'wunschbeginn', 'urlaubstage', 'rahmenbedingungen'].includes(x.name))
      }
    }
    return null;
  }

  sideGroups = [
    {
      name: 'regionen',
      label: 'Regionen',
      options: [
        new WizardOptionGroup().setOptions([
          new WizardOptionCheckbox(Offer._alle_regionen, Offer.labels.de[Offer._alle_regionen]),
          ...presets.regionen
        ])
      ]
    }
  ]

  countries: Promise<Iso3166_1> = null;

  mounted(): void {
    this.countries = this.$api.getCountries();

    this.Editor = Vue.component('TextEditor', () => import(/* webpackChunkName: "chunk-text-editor" */ '~/components/TextEditor.vue'));

    this.stelleGroups = [
      {
        name: 'arbeitsplatz',
        label: 'Arbeitsplatz',
        options: this.isStelle ? [
          ...presets.arbeitsplatzStelle
        ] : [
          ...presets.arbeitsplatz
        ]
      },
      {
        name: 'besonderheiten',
        label: 'Besonderheiten',
        options: [
          ...(this.offer.offer_type === OfferType.Stelle ? presets.besonderheitenStelle : presets.besonderheiten),
          ...(this.offer._stellenart_praktikum || this.offer._stellenart_praktikum_ausland ? presets.besonderheitenPraktikumList : []),
          new WizardOptionCheckbox(Offer._besonderheit_sonstiges, Offer.labels.de[Offer._besonderheit_sonstiges]),
          new WizardOptionText(Offer._besonderheit, 'Sonstiges')
        ]
      },
      {
        name: 'fortbildung',
        label: 'Fortbildung',
        options: this.isStelle ? [
          ...presets.fortbildungStelle
        ] : [
          ...presets.fortbildung
        ]
      },
      {
        name: 'infrastruktur',
        label: 'Infrastruktur',
        options: [
          new WizardOptionGroup().setOptions(this.offer.offer_type === OfferType.Stelle ? presets.infrastrukturLangform : presets.infrastruktur)
        ]
      },
      {
        name: 'oeffnungszeiten',
        label: 'Öffnungszeiten',
        options: [
          new WizardOptionGroup().setOptions(presets.oeffnungszeiten)
        ]
      },
      {
        name: 'schwerpunkte',
        label: 'Tätigkeitsschwerpunkte',
        options: [
          new WizardOptionGroup().setOptions(presets.schwerpunkte)
        ]
      },
      {
        name: 'taetigkeiten',
        label: 'Tätigkeiten',
        options: [
          new WizardOptionGroup().setOptions(presets.taetigkeiten)
        ]
      },
      {
        name: 'urlaubstage',
        label: 'Urlaubstage',
        options: [
          ...presets.urlaubstage
        ]
      },
      {
        name: 'wunschbeginn',
        label: 'Arbeitsantritt / Wunschbeginn',
        options: [
          ...this.presets.arbeitsantritt
        ]
      },
      {
        name: 'zeitumfang',
        label: 'Zeitumfang',
        options: [
          ...this.presets.zeitumfang
        ]
      },
      {
        name: 'ausstattung',
        label: 'Ausstattung',
        options: [
          this.isStelle
            ? new WizardOptionGroup().setOptions(presets.ausstattungenStelle)
            : new WizardOptionGroup().setOptions(presets.ausstattungen)
        ]
      },
      {
        name: 'rahmenbedingungen',
        label: 'Rahmenbedingungen',
        options: [
          new WizardOptionText(Offer._stammdaten_praxisname, 'Name des Instituts/Projektgebers').setRules({
            required: true,
            min: 3
          }),
          new WizardOptionText(Offer._arbeitsantritt, Offer.labels.de[Offer._arbeitsantritt]).setRules('required'),
          ...this.presets.dissertation.filter(x => x.name !== Offer._infotext)
        ]
      },
    ];

    this.praxisGroups = [
      {
        name: 'abgabe',
        label: 'Abgabezeitpunkt',
        options: [
          ...presets.praxisabgabe
        ]
      },
      {
        name: 'ausstattung',
        label: 'Ausstattung',
        options: [
          new WizardOptionGroup().setOptions(this.offer.offer_type === OfferType.Stelle ? presets.ausstattungenStelle : presets.ausstattungen)
        ]
      },
      {
        name: 'behandlungen',
        label: 'Behandlungen',
        options: [
          new WizardOptionGroup().setOptions(presets.behandlungen)
        ]
      },
      {
        name: 'praxisart',
        label: 'Bereiche mit Anteil',
        options: [
          new WizardOptionGroup().setOptions(presets.praxisartenMitAnteil)
        ]
      },
      {
        name: 'besonderheiten',
        label: 'Besonderheiten',
        options: [
          ...presets.besonderheiten,
          new WizardOptionCheckbox(Offer._besonderheit_sonstiges, Offer.labels.de[Offer._besonderheit_sonstiges]),
          new WizardOptionText(Offer._besonderheit, 'Sonstiges')
        ]
      },
      {
        name: 'infrastruktur',
        label: 'Infrastruktur',
        options: [
          new WizardOptionGroup().setOptions(presets.infrastruktur)
        ]
      },
      {
        name: 'mitarbeiter',
        label: 'Mitarbeiter',
        options: [
          new WizardOptionGroup().setOptions(presets.mitarbeiter)
        ]
      },
      {
        name: 'notdienst',
        label: 'Notdienst',
        options: [
          ...presets.notdienst,
          new WizardOptionText(Offer._ausgleich_notdienst, 'Wie werden Notdienste ausgeglichen?')
        ]
      },
      {
        name: 'oeffnungszeiten',
        label: 'Öffnungszeiten',
        options: [
          new WizardOptionGroup().setOptions(presets.oeffnungszeiten)
        ]
      },
      {
        name: 'preisvorstellung',
        label: 'Preisvorstellung',
        options: [
          ...presets.preisvorstellungen
        ]
      },
      {
        name: 'raeume',
        label: 'Räume',
        options: [
          new WizardOptionGroup().setOptions(presets.raeume)
        ]
      },
      {
        name: 'spezialisierungen',
        label: 'Spezialisierungen',
        options: [
          new WizardOptionGroup().setOptions(presets.schwerpunkte)
        ]
      },
      {
        name: 'sprechstunde',
        label: 'Sprechstunde',
        options: [
          ...presets.sprechstunde
        ]
      }
    ];
  }

  get title(): string {
    if (this.form.offer_type === OfferType.Praxis) {
      return this.form._praxis_ueberschrift;
    } else {
      return this.form._title;
    }
  }

  set title(value: string) {
    if (this.form.offer_type === OfferType.Praxis) {
      this.form._praxis_ueberschrift = value;
    } else {
      this.form._title = value;
    }
  }

  get infotext(): string {
    if (this.form.offer_type === OfferType.Praxis) {
      return this.form._praxis_kurzbeschreibung;
    } else {
      return this.form._infotext;
    }
  }

  set infotext(value: string) {
    if (this.form.offer_type === OfferType.Praxis) {
      this.form._praxis_kurzbeschreibung = value;
    } else {
      this.form._infotext = value;
    }
  }

  @Watch('offer', {immediate: true})
  handleOfferChange(value: Offer): void {
    this.form = value;
  }

  @Watch('form', {deep: true})
  handleFormChange(): void {
    this.promise = null;
  }

  @Watch('withoutExpireDate')
  handleChangeWithoutExpireDate(value: string): void {
    if (value) {
      this.previousExpireDate = this.form.expire_date;
      Vue.set(this.form, 'expire_date', null);
    } else {
      if (this.previousExpireDate) {
        this.form.expire_date = this.previousExpireDate;
      }
    }
  }

  save(): void {
    if (this.offer._stellenart_dissertation) {
      if (!this.form.expire_date) {
        this.form.expire_date = this.$dayjs().add(90, 'days').toISOString();
        this.withoutExpireDate = false;
      }
    }

    this.editIsIntern = false;
    this.editIsPublic = false;
    this.editOfferType = false;
    this.editExpireDate = false;
    this.promise = this.$api.updateOffer(this.offer.uuid, this.form);
  }

  confirmDelete(): void {
    this.$bvModal.msgBoxConfirm('Soll der Eintrag wirklich gelöscht werden?', {
      title: 'Bitte bestätigen',
      okVariant: 'danger',
      okTitle: 'JA',
      cancelTitle: 'NEIN',
      centered: true,
      size: 'sm'
    }).then(async val => {
      if (val) {
        await this.$api.deleteOffer(this.offer.uuid);
        this.deleted = true;
      }
    }).catch(() => null)
  }
}
