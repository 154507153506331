












import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator';

@Component
export default class Pagination extends Vue {
  @Prop({type: Number})
  value;

  @Prop({type: Number})
  totalRows;

  @Prop({type: Number})
  perPage;

  @Prop({type: String, default: 'primary'})
  variant;

  get input() {
    return this.value
  }

  set input(value) {
    this.$emit('input', value)
  }
}
