export enum WizardOptionType {
  Checkbox,
  Radio,
  Text,
  Textarea,
  Location,
  Repeater,
  Hidden,
  Numeric,
  Row,
  Label,
  Group,
  Address
}
