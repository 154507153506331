









































import {Component, Prop, Vue} from 'nuxt-property-decorator'
import {Offer, File} from "~/shared/Backend.dtos";

@Component
export default class ImageGallery extends Vue {
  @Prop({type: Object, required: true})
  offer: Offer

  index = 1;

  get currentImage(): File {
    return this.offer._files[this.index];
  }

  get fileLength(): number {
    return this.offer._files.length;
  }
}
