import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=9b35989a&scoped=true&"
import script from "./admin.vue?vue&type=script&lang=ts&"
export * from "./admin.vue?vue&type=script&lang=ts&"
import style0 from "./admin.vue?vue&type=style&index=0&id=9b35989a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b35989a",
  null
  
)

export default component.exports