
























































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class MobileSidebar extends Vue {
  @Prop({type: String})
  id;

  @Prop({type: String})
  title;

  @Prop({type: Number})
  cols;

  @Prop({type: Boolean})
  hideTitle;
}
