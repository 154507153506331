























































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Offer} from "~/shared/Backend.dtos";

@Component
export default class FilterBadges extends Vue {
  @Prop({required: true, default: () => ({})})
  value;

  @Prop({type: String})
  label;

  @Prop({type: String, default: 'mb-2'})
  labelClass;

  @Prop({type: String})
  rules;

  @Prop({type: Boolean})
  noDelete;

  ignore = [
    '_wunschorte',
    '_wunschort1_location',
    '_wunschort2_location',
    '_wunschort3_location',
    '_praxis_uebernahme',
    '_praxis_teilhaber',
    '_praxis_art_sonstiges',
    '_stellenart_sonstiges',
    '_stellenart_is_empfehlung',
    '_lebensphase',
  ];

  noAppendLabel = [

  ];

  showValueAsLabel = [
    '_wunschort1_location_address',
    '_wunschort2_location_address',
    '_wunschort3_location_address'
  ]

  get countItems(): number {
    return Object.values(this.items).length;
  }

  get hasItems(): boolean {
    return Object.values(this.items).length > 0
  }

  get items() {
    return Object.keys(this.value).reduce((map, key) => {
      if (!key.startsWith('_')) return map;

      if (!!this.value[key] && !this.ignore.includes(key)) {
        map[key] = this.showValueAsLabel.includes(key) ? this.value[key] : Offer.labels.de[key];
      }

      if (key === 'with_non_public' && !!this.value['with_non_public']) {
        map[key] = 'Nicht veröffentlichte Anzeigen'
      }

      if (key === 'with_expired' && !!this.value['with_expired']) {
        map[key] = 'Abgelaufene Anzeigen'
      }
      return map;
    }, {})
  }

  getValidationState({ dirty, validated, valid }) {
    return (validated || dirty) ? (validated ? valid : null) : null;
  }
}
