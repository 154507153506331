





























import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator';

@Component
export default class WizardTile extends Vue {
  @Prop({ type: String })
  html;

  @Prop({ type: String })
  name;

  @Prop()
  checkValue;

  @Prop()
  value;

  @Prop({ type: Boolean, default: false })
  radio;

  @Prop({ type: Boolean, default: false })
  indicator;

  @Prop({type: String, default: 'dark-gray'})
  variant;

  @Prop({type: String, default: 'primary'})
  activeVariant;

  @Prop()
  appendLabel;

  get checked() {
    return this.checkValue === this.input;
  }

  get type() {
    return this.radio ? 'radio' : 'checkbox';
  }

  get input() {
    return this.value;
  }

  set input(value) {
    this.$emit('input', value)
    if (this.checkValue === value) {
      this.$emit('click')
    }
  }
}
