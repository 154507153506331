














import {Component, Vue} from 'nuxt-property-decorator';

@Component
export default class DevBar extends Vue {
  get isLocalhost() {
    return false;
    if (typeof window === 'undefined') return false;
    return !this.$nuxt.$isServer && window?.origin?.includes('localhost')
  }
}
