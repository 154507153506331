<template>
  <div
    class="circle"
    :class="size"
  >
    <div class="spacer" />
    <div class="px-3 pt-4 pb-2 text-center text-uppercase text-white">
      <span class="h1" v-html="title" />
      <span
        class="h4"
        v-html="subtitle"
      />
    </div>
  </div>
</template>

<script>
  import {Component, Prop, Vue} from 'nuxt-property-decorator';

  @Component
  export default class TextCircle extends Vue {
    @Prop({type: String})
    title;

    @Prop({type: String})
    subtitle;

    @Prop({type: String, default: 'lg'})
    size;
  }
</script>

<style lang="scss" scoped>
  .circle {
    background: $primary;
    border-radius: 50%;
    width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.xs {
      width: 4.3rem;

      &:after {
        content: '';
        border: 0.2rem solid $primary;
        position: absolute;
        top: -0.37rem;
        right: -0.37rem;
        left: -0.37rem;
        bottom: -0.37rem;
        border-radius: 50%;
      }

      .h1 {
        font-size: 1.4rem;
        margin: 0;
        position: relative;
        top: -0.5rem;
        text-transform: none !important;
      }
    }

    &.sm {
      width: 6.3rem;

      &:after {
        content: '';
        border: 0.2rem solid $primary;
        position: absolute;
        top: -0.37rem;
        right: -0.37rem;
        left: -0.37rem;
        bottom: -0.37rem;
        border-radius: 50%;
      }

      .h1 {
        font-size: 1.4rem;
        margin: 0;
        position: relative;
        top: -0.5rem;
        text-transform: none !important;
      }
    }

    &:after {
      content: '';
      border: 0.5rem solid $primary;
      position: absolute;
      top: -1rem;
      right: -1rem;
      left: -1rem;
      bottom: -1rem;
      border-radius: 50%;
    }
  }

  .spacer {
    padding-bottom: 100%;
  }

  h1 {
    line-height: 2.5rem;
  }
</style>
