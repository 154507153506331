
























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {GetFrontendTextResponse, PostFrontendText} from "~/shared/MyService.dtos";
import {SessionStore} from "~/utils/store-accessor";

@Component
export default class EditableContent extends Vue {
  @Prop()
  contentClass;

  @Prop({type: String, required: true})
  uniqueIdentifier;

  @Prop({type: String})
  variant;

  Editor = null;

  init: {[key: string]: any} = {
    skin: false,
    language: 'de',
    language_url: '/langs/de.js',
    content_css: '/skins/tinymce.css',
    menubar: false,
    statusbar: false,
    plugins: 'link lists autolink autoresize paste visualchars',
    autoresize_bottom_margin: 30,
    paste_as_text: true,
    entity_encoding : 'numeric',
    entities: '173,shy',
    toolbar: 'formatselect | fontsizeselect | forecolor | bold italic underline | alignleft aligncenter alignright alignjustify alignnone | link bullist numlist | visualchars | addSoftHyphen',
    color_map: [
      '738392', 'Standard',
      'EC008A', 'Primär',
      '04A0D7', 'Sekundär',
      '546476', 'Dunkel',
      '000000', 'Schwarz'
    ],
    fontsize_formats: '0.75rem 1rem 1.25rem 1.5rem 1.75rem 2rem 2.5rem 3rem 3.5rem',
    setup(ed) {
      ed.ui.registry.addButton('addSoftHyphen', {
        text: 'Optionaler-Umbruch',
        onAction(): void {
          ed.insertContent('&#173;');
        }
      });
    }
  }

  content = '<p>Editierbarer Text</p>';
  origContent = '<p>Editierbarer Text</p>';
  hasContent = false;
  editMode = false;
  showEditor = false;
  pending = true;
  loaded = false;
  promise: Promise<GetFrontendTextResponse> = null;

  created() {
    this.promise = this.$api.getFrontendText(this.uniqueIdentifier).then(data => {
      if (data.content) {
        this.content = data.content;
        this.origContent = data.content;
        this.hasContent = true;
      }
      this.loaded = true;
      return data;
    });
  }

  get editable () {
    return SessionStore.isAdmin
  }

  async postText() {
    this.pending = true;
    const request = await this.$api.saveFrontendText(new PostFrontendText({
      uniqueIdentifier: this.uniqueIdentifier,
      content: this.content
    }))
    if (request) {
      this.editMode = false;
      this.showEditor = false;
      this.origContent = this.content;
    }
    this.pending = false;
    this.hasContent = !!this.content;
  }

  onInit() {
    this.showEditor = true;
    setTimeout(() => {
      this.pending = false;
    }, 1000)
  }

  onCancel() {
    this.editMode = false;
    this.showEditor = false;
    this.content = this.origContent;
  }

  onClickEdit() {
    this.editMode = true;
    this.pending = true;
    this.Editor = Vue.component('TinyMce', () => import(/* webpackChunkName: "chunk-tiny-mce" */ '~/plugins/tinymce.js'));
  }
}
