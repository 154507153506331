import WizardOptionConfig from "~/entities/WizardOptionConfig";
import {WizardOptionType} from "~/entities/WizardOptionType";

export default class WizardOptionText extends WizardOptionConfig {
  constructor(
    public name: string,
    public label: string = null,
    public type: WizardOptionType = WizardOptionType.Text,
    public height: number = 25
  ) {
    super(name, label, '', type);
  }

  public hasValue(data) {
    return data[this.name] !== null && data[this.name] !== '';
  }

  public placeholder;

  public setPlaceholder(value): WizardOptionText {
    this.placeholder = value;
    return this;
  }

  public rules;

  public setRules(value): WizardOptionText {
    this.rules = value;
    return this;
  }
}
