import WizardOptionConfig from "~/entities/WizardOptionConfig";
import {WizardOptionType} from "~/entities/WizardOptionType";

export default class WizardOptionRadio extends WizardOptionConfig {
  constructor(
    public name: string,
    public label: string,
    public defaultValue: string
  ) {
    super(name, label, defaultValue, WizardOptionType.Radio);
  }

  hasValue(data) {
    return data[this.name] && data[this.name] !== '0';
  }
}
