




























































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class OfferListItemBtn extends Vue {
  favorite = false;

  @Prop({type: Boolean})
  isActive;

  @Prop({type: String, required: true})
  id;

  @Prop({type: String})
  img;

  @Prop({type: String, required: true})
  title;

  @Prop({type: String, required: true})
  subtitle;

  @Prop({type: String})
  text;

  @Prop({type: Array})
  badges;

  @Prop({type: String})
  to;

  @Prop({type: Array, default: () => []})
  flags;
}
