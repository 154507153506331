





















import {Component, Prop, Vue} from 'nuxt-property-decorator';

@Component
export default class WizardFooterResultBtn extends Vue {
  @Prop({type: Promise})
  promise;

  @Prop({type: String})
  to;

  @Prop({type: String, default: 'primary'})
  variant;

  @Prop({type: String})
  cols;

  @Prop({type: String})
  lg;

  @Prop({type: String})
  md;

  @Prop({type: String})
  sm;
}
