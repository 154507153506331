


















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {IWizard} from "~/interfaces/IWizard";
import WizardStepConfig from "~/entities/WizardStepConfig";
import {offset} from "~/utils/tools";
import {ValidationObserver} from "vee-validate";

@Component
export default class Wizard extends Vue implements IWizard{
  @Prop({type: String, required: true})
  name;

  @Prop({ type: Array, default: [] })
  steps;

  @Prop({type: String, default: 'primary'})
  variant;

  @Prop({type: Number})
  limit;

  @Prop({type: Boolean, default: true})
  navigation;

  @Prop({type: Object})
  addLastStep: WizardStepConfig;

  max = 0;

  $refs: {
    validator: InstanceType<typeof ValidationObserver>,
    wizard: HTMLElement
  }

  mounted(): void {
    if (!this.$route.query['step']) {
      this.$router.replace({ query: { step: (this.index+1).toString() } })
    } else {
      const stepNum = parseInt(this.$route.query['step']?.toString());
      const skipNum = parseInt(this.$route.query['skip']?.toString());

      if (stepNum !== 1 && stepNum > skipNum+1) {
        const query = { step: (skipNum+1).toString() };
        if (skipNum) {
          query['skip'] = skipNum.toString();
        }
        this.$router.replace({ query });
      }
    }
  }

  get index(): number {
    return parseInt(this.$route.query.step as string ?? '1') - 1;
  }

  set index(value: number) {
    this.max = value < this.max ? this.max : value;
    this.$router.push({ query: { step: (value+1).toString() }})
  }

  get stepCount(): number {
    return (this.limit || this.steps.length) + (this.addLastStep ? 1 : 0);
  }

  get availableSteps(): WizardStepConfig[] {
    return [...this.steps.slice(0, (this.limit || this.steps.length)), this.addLastStep].filter(x => !!x);
  }

  get step(): WizardStepConfig {
    return this.availableSteps[this.index]
  }

  get current(): WizardStepConfig {
    return this.steps[this.index];
  }

  get isLast(): boolean {
    return this.index === this.stepCount - 1;
  }

  get title(): string {
    return (this.index + 1) + '. ' + this.steps[this.index].title;
  }

  /**
   * Set the step by index
   * @param index
   */
  public goToStep(index: number): void {
    if (this.navigation) {
      if (index > this.max) return;
      this.$emit('step-change', { currentIndex: this.index, nextIndex: index });
      this.index = index;
    }
  }

  public goToLast(): void {
    window.scrollTo(0, offset(this.$refs.wizard).top)
    this.$emit('step-change', { currentIndex: this.index, nextIndex: this.stepCount - 1 });
    this.index = this.stepCount - 1;
  }

  /**
   * Go to next step by incrementing the index
   */
  public async nextStep(): Promise<void> {
    if ((this.index + 1) < this.stepCount) {
      window.scrollTo(0, offset(this.$refs.wizard).top)
      this.$emit('step-change', { currentIndex: this.index, nextIndex: this.index+1 });
      this.index++;
    }
  }

  /**
   * Go to previous step by decrementing the index.
   */
  public prevStep(): void {
    if (this.index > 0) {
      window.scrollTo(0, offset(this.$refs.wizard).top)
      this.$emit('step-change', { currentIndex: this.index, nextIndex: this.index-1 });
      this.index--;
    }
  }

  public firstStep(): void {
    this.$emit('step-change', { currentIndex: this.index, nextIndex: 0 });
    this.index = 0;
  }

  /**
   * Set index to 0 and emit reset event.
   */
  public reset(): void {
    this.index = 0;
    this.$emit('reset')
  }

  submit(): void {
    this.$emit('submit');
  }
}
