






































































































































































import {Component, Vue} from "nuxt-property-decorator";
import {SessionStore} from "~/utils/store-accessor";
import {Auth} from "~/shared/Backend.dtos";

@Component
export default class Default extends Vue {
  promise: Promise<Auth> = null;

  mounted(): void {
    this.loadSession()
  }

  get isAdmin(): boolean {
    return SessionStore?.isAdmin;
  }

  get sessionActive(): boolean {
    return SessionStore?.isActive;
  }

  loadSession(auth?: Auth): void {
    if (auth != null) {
      this.promise = new Promise<Auth>((resolve => resolve(new Auth())))
    } else {
      this.promise = this.$api.checkAuth().then(auth => {
        if (auth) {
          SessionStore?.save(auth);
        }
        return auth;
      });
    }
  }

  logout(): void {
    this.$api.destroy();
    SessionStore?.destroy();
    this.$router.replace('/');
  }
}
