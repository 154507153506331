import WizardOptionConfig from "~/entities/WizardOptionConfig";
import {WizardOptionType} from "~/entities/WizardOptionType";

export default class WizardOptionLabel extends WizardOptionConfig {
  constructor(
    public label: string
  ) {
    super('', label, '', WizardOptionType.Label);
  }

  public hasValue(data): boolean {
    return true;
  }
}
