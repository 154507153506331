

















































































































































import {Component, Vue} from "nuxt-property-decorator";
import {SessionStore} from "~/utils/store-accessor";
import CookieModal from "~/components/CookieModal.vue";
import PopUp from "~/components/PopUp.vue";
@Component({
  components: {PopUp, CookieModal}
})
export default class Default extends Vue {
  cookieModalVisible = true;

  mounted(): void {
    this.loadSession()
  }

  get isUser(): boolean {
    return SessionStore.isUser;
  }

  get isAdmin(): boolean {
    return SessionStore.isAdmin;
  }

  get sessionActive(): boolean {
    return SessionStore.isActive;
  }

  async loadSession(): Promise<void> {
    if (!this.$api.token) return;
    this.$api.checkAuth().then(auth => {
      if (auth) {
        SessionStore.save(auth);
      }
      return auth;
    });
  }

  logout(): void {
    this.$api.destroy();
    SessionStore?.destroy();
    this.$router.replace('/');
  }

  clearHash(): void {
    window.location.hash = '';
  }
}
