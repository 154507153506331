



















































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Offer} from '~/shared/Backend.dtos';

@Component
export default class ContactForm extends Vue {
  @Prop()
  value;

  feedbackOptions = [
    '_feedback_gefunden_ueber_tvd',
    '_feedback_gefunden_ueber_empfehlung',
    '_feedback_gefunden_ueber_google',
    '_feedback_gefunden_ueber_newsletter',
    '_feedback_gefunden_ueber_facebook',
    '_feedback_gefunden_ueber_instagram',
    '_feedback_gefunden_ueber_sonstiges',
  ];
  feedbackOptionsSelected = 0;

  lang = Offer.labels.de;

  /**
   *
   */
  get requireAtleastOne(): number {
    console.log(this.feedbackOptions.map(x => this.value[x]).filter(x => x === true || x === 1).length);
    return this.feedbackOptions.map(x => this.value[x]).filter(x => x === true || x === 1).length;
  }

  set requireAtleastOne(value: number) {

  }

  getValidationState({ dirty, validated, valid }) {
    return (validated || dirty) ? (validated ? valid : null) : null;
  }

  @Watch('requireAtleastOne')
  handleFeedbackOptionsChange(value: number) {
    this.feedbackOptionsSelected = value;
  }
}
