














import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator';

@Component
export default class ValidateInput extends Vue {
  @Prop({required: true})
  value;

  @Prop({type: String})
  label;

  @Prop()
  rules;

  @Prop({type: Boolean})
  hideLabel;

  getValidationState({ dirty, validated, valid }) {
    return (validated || dirty) ? (validated ? valid : null) : null;
  }

  @Watch('value', {deep: true})
  handle(value) {
    this.$emit('input', value)
  }
}
