/* Options:
Date: 2022-12-02 19:36:58
Version: 5.91
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:8080

//GlobalNamespace:
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion:
//AddDescriptionAsComments: True
//IncludeTypes:
//ExcludeTypes:
//DefaultImports:
*/

import {ApiResponseData} from "~/entities/ApiResponse";

export class Advert extends ApiResponseData<Advert>
{
    public static uuid = 'uuid';
    public static entity_uuid = 'entity_uuid';
    public static advert_lead = 'advert_lead';
    public static advert_date = 'advert_date';
    public static advert_text = 'advert_text';
    public static in_foreign_system = 'in_foreign_system';
    public static picture_uuid = 'picture_uuid';



    public uuid: string;
    public entity_uuid: string;
    public advert_lead: string;
    public advert_date: string;
    public advert_text: string;
    public in_foreign_system: boolean;
    public picture_uuid: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.uuid = data.uuid;
            this.entity_uuid = data.entity_uuid;
            this.advert_lead = data.advert_lead;
            this.advert_date = data.advert_date;
            this.advert_text = data.advert_text;
            this.in_foreign_system = data.in_foreign_system;
            this.picture_uuid = data.picture_uuid;
        }
    }

    public static createInstance() {
        return new Advert({
            'uuid': '',
            'entity_uuid': '',
            'advert_lead': '',
            'advert_date': '',
            'advert_text': '',
            'in_foreign_system': false,
            'picture_uuid': '',
        });
    }
}

export class Auth extends ApiResponseData<Auth>
{
    public static sub = 'sub';
    public static iat = 'iat';
    public static exp = 'exp';
    public static email = 'email';
    public static roles = 'roles';
    public static uuid = 'uuid';
    public static token = 'token';



    public sub: number;
    public iat: number;
    public exp: number;
    public email: string;
    public name: string;
    public roles: string[];
    public uuid: string;
    public token: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.sub = data.sub;
            this.iat = data.iat;
            this.exp = data.exp;
            this.email = data.email;
            this.name = data.name;
            this.roles = data.roles;
            this.uuid = data.uuid;
            this.token = data.token;
        }
    }

    public static createInstance() {
        return new Auth({
            'sub': 0,
            'iat': 0,
            'exp': 0,
            'email': '',
            'name': '',
            'roles': null,
            'uuid': '',
            'token': '',
        });
    }
}

export class Contact extends ApiResponseData<Contact>
{
    public static uuid = 'uuid';
    public static offer_uuid = 'offer_uuid';
    public static create_date = 'create_date';
    public static modify_date = 'modify_date';
    public static pending = 'pending';
    public static _vorname = '_vorname';
    public static _nachname = '_nachname';
    public static _email = '_email';
    public static _telefon = '_telefon';
    public static _nachricht = '_nachricht';
    public static user_uuid = 'user_uuid';
    public static _datenschutz_akzeptiert = '_datenschutz_akzeptiert';
    public static _contact_status = '_contact_status';
    public static _feedback = '_feedback';



    public uuid: string;
    public offer_uuid: string;
    public create_date: string;
    public modify_date: string;
    public pending: boolean;
    public _vorname: string;
    public _nachname: string;
    public _email: string;
    public _telefon: string;
    public _nachricht: string;
    public user_uuid: string;
    public _datenschutz_akzeptiert: boolean;
    public _contact_status: string;
    public _feedback: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.uuid = data.uuid;
            this.offer_uuid = data.offer_uuid;
            this.create_date = data.create_date;
            this.modify_date = data.modify_date;
            this.pending = data.pending;
            this._vorname = data._vorname;
            this._nachname = data._nachname;
            this._email = data._email;
            this._telefon = data._telefon;
            this._nachricht = data._nachricht;
            this.user_uuid = data.user_uuid;
            this._datenschutz_akzeptiert = data._datenschutz_akzeptiert;
            this._contact_status = data._contact_status;
            this._feedback = data._feedback;
        }
    }

    public static createInstance() {
        return new Contact({
            'uuid': '',
            'offer_uuid': '',
            'create_date': '',
            'modify_date': '',
            'pending': false,
            '_vorname': '',
            '_nachname': '',
            '_email': '',
            '_telefon': '',
            '_nachricht': '',
            'user_uuid': '',
            '_datenschutz_akzeptiert': false,
            '_contact_status': '',
            '_feedback': '',
        });
    }
}

export class EmailOffer extends ApiResponseData<EmailOffer>
{
    public static offer_uuid = 'offer_uuid';
    public static message_type = 'message_type';
    public static recipient = 'recipient';



    public offer_uuid: string;
    public message_type: string;
    public recipient: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.offer_uuid = data.offer_uuid;
            this.message_type = data.message_type;
            this.recipient = data.recipient;
        }
    }

    public static createInstance() {
        return new EmailOffer({
            'offer_uuid': '',
            'message_type': '',
            'recipient': '',
        });
    }
}

export class EmailTemplate extends ApiResponseData<EmailTemplate>
{
    public static uuid = 'uuid';
    public static create_date = 'create_date';
    public static modify_date = 'modify_date';
    public static delete_date = 'delete_date';
    public static display_name = 'display_name';
    public static subject = 'subject';
    public static mjml_content = 'mjml_content';
    public static html_content = 'html_content';
    public static is_system = 'is_system';
    public static is_layout = 'is_layout';
    public static has_raw_content = 'has_raw_content';



    public uuid: string;
    public create_date: string;
    public modify_date: string;
    public delete_date: string;
    public name: string;
    public display_name: string;
    public subject: string;
    public mjml_content: string;
    public html_content: string;
    public is_system: boolean;
    public is_layout: boolean;
    public has_raw_content: boolean;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.uuid = data.uuid;
            this.create_date = data.create_date;
            this.modify_date = data.modify_date;
            this.delete_date = data.delete_date;
            this.name = data.name;
            this.display_name = data.display_name;
            this.subject = data.subject;
            this.mjml_content = data.mjml_content;
            this.html_content = data.html_content;
            this.is_system = data.is_system;
            this.is_layout = data.is_layout;
            this.has_raw_content = data.has_raw_content;
        }
    }

    public static createInstance() {
        return new EmailTemplate({
            'uuid': '',
            'create_date': '',
            'modify_date': '',
            'delete_date': '',
            'name': '',
            'display_name': '',
            'subject': '',
            'mjml_content': '',
            'html_content': '',
            'is_system': false,
            'is_layout': false,
            'has_raw_content': false,
        });
    }
}

export class File extends ApiResponseData<File>
{
    public static uuid = 'uuid';
    public static create_date = 'create_date';
    public static modify_date = 'modify_date';
    public static contact_uuid = 'contact_uuid';
    public static offer_uuid = 'offer_uuid';
    public static file_name = 'file_name';
    public static file_type = 'file_type';
    public static file_size = 'file_size';
    public static file_ext = 'file_ext';
    public static raw_name = 'raw_name';
    public static orig_name = 'orig_name';
    public static display_type = 'display_type';
    public static display_name = 'display_name';
    public static display_width = 'display_width';
    public static display_height = 'display_height';
    public static image_width = 'image_width';
    public static image_height = 'image_height';
    public static image_type = 'image_type';
    public static disabled = 'disabled';
    public static display_order = 'display_order';
    public static is_public = 'is_public';
    public static thumbnail = 'thumbnail';
    public static version = 'version';



    public uuid: string;
    public create_date: string;
    public modify_date: string;
    public contact_uuid: string;
    public offer_uuid: string;
    public file_name: string;
    public file_type: string;
    public file_size: number;
    public file_ext: string;
    public raw_name: string;
    public orig_name: string;
    public display_type: string;
    public display_name: string;
    public display_width: number;
    public display_height: number;
    public image_width: number;
    public image_height: number;
    public image_type: string;
    public disabled: boolean;
    public display_order: number;
    public is_public: boolean;
    public thumbnail: string;
    public version: number;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.uuid = data.uuid;
            this.create_date = data.create_date;
            this.modify_date = data.modify_date;
            this.contact_uuid = data.contact_uuid;
            this.offer_uuid = data.offer_uuid;
            this.file_name = data.file_name;
            this.file_type = data.file_type;
            this.file_size = data.file_size;
            this.file_ext = data.file_ext;
            this.raw_name = data.raw_name;
            this.orig_name = data.orig_name;
            this.display_type = data.display_type;
            this.display_name = data.display_name;
            this.display_width = data.display_width;
            this.display_height = data.display_height;
            this.image_width = data.image_width;
            this.image_height = data.image_height;
            this.image_type = data.image_type;
            this.disabled = data.disabled;
            this.display_order = data.display_order;
            this.is_public = data.is_public;
            this.thumbnail = data.thumbnail;
            this.version = data.version;
        }
    }

    public static createInstance() {
        return new File({
            'uuid': '',
            'create_date': '',
            'modify_date': '',
            'contact_uuid': '',
            'offer_uuid': '',
            'file_name': '',
            'file_type': '',
            'file_size': 0,
            'file_ext': '',
            'raw_name': '',
            'orig_name': '',
            'display_type': '',
            'display_name': '',
            'display_width': 0,
            'display_height': 0,
            'image_width': 0,
            'image_height': 0,
            'image_type': '',
            'disabled': false,
            'display_order': 0,
            'is_public': false,
            'thumbnail': '',
            'version': 0,
        });
    }
}

export class Iso3166_1 extends ApiResponseData<Iso3166_1>
{
    public static code = 'code';
    public static en = 'en';
    public static de = 'de';
    public static es = 'es';
    public static fr = 'fr';
    public static it = 'it';
    public static ru = 'ru';



    public code: string;
    public en: string;
    public de: string;
    public es: string;
    public fr: string;
    public it: string;
    public ru: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.code = data.code;
            this.en = data.en;
            this.de = data.de;
            this.es = data.es;
            this.fr = data.fr;
            this.it = data.it;
            this.ru = data.ru;
        }
    }

    public static createInstance() {
        return new Iso3166_1({
            'code': '',
            'en': '',
            'de': '',
            'es': '',
            'fr': '',
            'it': '',
            'ru': '',
        });
    }
}

export class Location extends ApiResponseData<Location>
{
    public static lat = 'lat';
    public static lng = 'lng';
    public static radius = 'radius';



    public lat: number;
    public lng: number;
    public radius: number;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.lat = data.lat;
            this.lng = data.lng;
            this.radius = data.radius;
        }
    }

    public static createInstance() {
        return new Location({
            'lat': 0,
            'lng': 0,
            'radius': 0,
        });
    }
}

export class Offer extends ApiResponseData<Offer>
{
    public static uuid = 'uuid';
    public static user_uuid = 'user_uuid';
    public static offer_type = 'offer_type';
    public static create_date = 'create_date';
    public static modify_date = 'modify_date';
    public static delete_date = 'delete_date';
    public static expire_date = 'expire_date';
    public static last_mail_date = 'last_mail_date';
    public static opt_in_date = 'opt_in_date';
    public static opt_out_date = 'opt_out_date';
    public static import_id = 'import_id';
    public static is_public = 'is_public';
    public static is_intern = 'is_intern';
    public static is_opted_in = 'is_opted_in';
    public static need_payment = 'need_payment';
    public static _chiffre = '_chiffre';
    public static _admin_status = '_admin_status';
    public static _admin_is_public = '_admin_is_public';
    public static _admin_is_sold = '_admin_is_sold';
    public static _admin_is_deleted = '_admin_is_deleted';
    public static _admin_is_reserved = '_admin_is_reserved';
    public static _admin_is_upper_class = '_admin_is_upper_class';
    public static _admin_tvd_ansprechpartner = '_admin_tvd_ansprechpartner';
    public static _admin_zusatzinfos = '_admin_zusatzinfos';
    public static _arbeitsantritt = '_arbeitsantritt';
    public static _arbeitsantritt_monate = '_arbeitsantritt_monate';
    public static _arbeitsumfang = '_arbeitsumfang';
    public static _arbeitsumfang_teilzeit = '_arbeitsumfang_teilzeit';
    public static _arbeitsumfang_vollzeit = '_arbeitsumfang_vollzeit';
    public static _arbeitsumfang_dauer = '_arbeitsumfang_dauer';
    public static _arbeitsumfang_dauer_min_wochen = '_arbeitsumfang_dauer_min_wochen';
    public static _arbeitsumfang_dauer_max_wochen = '_arbeitsumfang_dauer_max_wochen';
    public static _arbeitsumfang_dauer_sonstiges = '_arbeitsumfang_dauer_sonstiges';
    public static _arbeitsumfang_nebenbeschaeftigung = '_arbeitsumfang_nebenbeschaeftigung';
    public static _arbeitszeit_montag = '_arbeitszeit_montag';
    public static _arbeitszeit_dienstag = '_arbeitszeit_dienstag';
    public static _arbeitszeit_mittwoch = '_arbeitszeit_mittwoch';
    public static _arbeitszeit_donnerstag = '_arbeitszeit_donnerstag';
    public static _arbeitszeit_freitag = '_arbeitszeit_freitag';
    public static _arbeitszeit_samstag = '_arbeitszeit_samstag';
    public static _arbeitszeit_sonntag = '_arbeitszeit_sonntag';
    public static _ausstattung_autoclav = '_ausstattung_autoclav';
    public static _ausstattung_behandlungstisch = '_ausstattung_behandlungstisch';
    public static _ausstattung_blutanalyse = '_ausstattung_blutanalyse';
    public static _ausstattung_boxen = '_ausstattung_boxen';
    public static _ausstattung_computer_drucker = '_ausstattung_computer_drucker';
    public static _ausstattung_endoskop = '_ausstattung_endoskop';
    public static _ausstattung_gefriertruhe = '_ausstattung_gefriertruhe';
    public static _ausstattung_inhouse_labor = '_ausstattung_inhouse_labor';
    public static _ausstattung_klimaanlage = '_ausstattung_klimaanlage';
    public static _ausstattung_kuehlschrank = '_ausstattung_kuehlschrank';
    public static _ausstattung_mikroskop = '_ausstattung_mikroskop';
    public static _ausstattung_narkosegeraet = '_ausstattung_narkosegeraet';
    public static _ausstattung_op_besteck = '_ausstattung_op_besteck';
    public static _ausstattung_op_lampen = '_ausstattung_op_lampen';
    public static _ausstattung_osteosynthese_set = '_ausstattung_osteosynthese_set';
    public static _ausstattung_otoskop = '_ausstattung_otoskop';
    public static _ausstattung_roentgen_analog = '_ausstattung_roentgen_analog';
    public static _ausstattung_roentgen_digital = '_ausstattung_roentgen_digital';
    public static _ausstattung_sonstiges = '_ausstattung_sonstiges';
    public static _ausstattung_telefonanlagen = '_ausstattung_telefonanlagen';
    public static _ausstattung_tierwaage = '_ausstattung_tierwaage';
    public static _ausstattung_trockner = '_ausstattung_trockner';
    public static _ausstattung_ultraschall = '_ausstattung_ultraschall';
    public static _ausstattung_herzultraschall = '_ausstattung_herzultraschall';
    public static _ausstattung_waschmaschine = '_ausstattung_waschmaschine';
    public static _ausstattung_zahnsteineinheit = '_ausstattung_zahnsteineinheit';
    public static _ausstattung_szintigraph = '_ausstattung_szintigraph';
    public static _ausstattung_station = '_ausstattung_station';
    public static _ausstattung_getrennter_wartebereich = '_ausstattung_getrennter_wartebereich';
    public static _ausstattung_software_zur_praxisverwaltung = '_ausstattung_software_zur_praxisverwaltung';
    public static _ausstattung_crm = '_ausstattung_crm';
    public static _ausstattung_ct = '_ausstattung_ct';
    public static _ausstattung_mrt = '_ausstattung_mrt';
    public static _ausstattung = '_ausstattung';
    public static _ausgleich_notdienst = '_ausgleich_notdienst';
    public static _behandlung_op_mit_narkose = '_behandlung_op_mit_narkose';
    public static _behandlung_ernaehrungsberatung = '_behandlung_ernaehrungsberatung';
    public static _behandlung_osteosynthese = '_behandlung_osteosynthese';
    public static _behandlung_stationaer = '_behandlung_stationaer';
    public static _behandlung_osteopathie = '_behandlung_osteopathie';
    public static _behandlung_physiotherapie = '_behandlung_physiotherapie';
    public static _behandlung_chiropraktik = '_behandlung_chiropraktik';
    public static _behandlung_akupunktur = '_behandlung_akupunktur';
    public static _behandlung_herzultraschall = '_behandlung_herzultraschall';
    public static _behandlung_ultraschall = '_behandlung_ultraschall';
    public static _behandlung_digitales_roentgen = '_behandlung_digitales_roentgen';
    public static _behandlung_mobiles_roentgen = '_behandlung_mobiles_roentgen';
    public static _behandlung_zahnbehandlungen_mobil = '_behandlung_zahnbehandlungen_mobil';
    public static _behandlung_dentalroentgen = '_behandlung_dentalroentgen';
    public static _behandlung_inhouse_labor = '_behandlung_inhouse_labor';
    public static _behandlung_sonstiges = '_behandlung_sonstiges';
    public static _behandlung = '_behandlung';
    public static _behandlung_ct = '_behandlung_ct';
    public static _behandlung_mrt = '_behandlung_mrt';
    public static _behandlung_szintigraphie = '_behandlung_szintigraphie';
    public static _behandlung_endoskopie = '_behandlung_endoskopie';
    public static _benoetigte_unterlagen = '_benoetigte_unterlagen';
    public static _benoetigte_bewerber_voraussetzungen = '_benoetigte_bewerber_voraussetzungen';
    public static _beschreibung_text = '_beschreibung_text';
    public static _beschreibung_titel = '_beschreibung_titel';
    public static _besonderheit = '_besonderheit';
    public static _besonderheit_sonstiges = '_besonderheit_sonstiges';
    public static _besonderheit_cat_friendly = '_besonderheit_cat_friendly';
    public static _besonderheit_mitnahme_hund = '_besonderheit_mitnahme_hund';
    public static _besonderheit_moegl_doktorarbeit = '_besonderheit_moegl_doktorarbeit';
    public static _besonderheit_moegl_teilhaberschaft = '_besonderheit_moegl_teilhaberschaft';
    public static _besonderheit_praktikumsverguetung = '_besonderheit_praktikumsverguetung';
    public static _besonderheit_bez_fortbildung = '_besonderheit_bez_fortbildung';
    public static _besonderheit_wohnen_vor_ort = '_besonderheit_wohnen_vor_ort';
    public static _besonderheit_kein_nachtdienst = '_besonderheit_kein_nachtdienst';
    public static _besonderheit_bkv = '_besonderheit_bkv';
    public static _besonderheit_bav = '_besonderheit_bav';
    public static _besonderheit_eigenverantwortung = '_besonderheit_eigenverantwortung';
    public static _besonderheit_gehaltsmodell = '_besonderheit_gehaltsmodell';
    public static _besonderheit_gute_einarbeitung = '_besonderheit_gute_einarbeitung';
    public static _besonderheit_gutes_team = '_besonderheit_gutes_team';
    public static _besonderheit_familiaere = '_besonderheit_familiaere';
    public static _besonderheit_hohes_niveau = '_besonderheit_hohes_niveau';
    public static _besonderheit_moderne_ausstattung = '_besonderheit_moderne_ausstattung';
    public static _besonderheit_feedbackgespraeche = '_besonderheit_feedbackgespraeche';
    public static _besonderheit_arbeitszeiterfassung = '_besonderheit_arbeitszeiterfassung';
    public static _besonderheit_bezahlte_fortbildung = '_besonderheit_bezahlte_fortbildung';
    public static _besonderheit_interne_fortbildungen = '_besonderheit_interne_fortbildungen';
    public static _besonderheit_weihnachtsgeld = '_besonderheit_weihnachtsgeld';
    public static _besonderheit_urlaubsgeld = '_besonderheit_urlaubsgeld';
    public static _besonderheit_dienstwagen = '_besonderheit_dienstwagen';
    public static _besonderheit_dienstwagen_privat = '_besonderheit_dienstwagen_privat';
    public static _besonderheit_mittagessen_kostenlos = '_besonderheit_mittagessen_kostenlos';
    public static _besonderheit_rotation_in_bereiche = '_besonderheit_rotation_in_bereiche';
    public static _besonderheit_sportangebot = '_besonderheit_sportangebot';
    public static _besonderheit_teamevents = '_besonderheit_teamevents';
    public static _besonderheit_mitglied_vuk = '_besonderheit_mitglied_vuk';
    public static _besonderheit_praktikumsleitfaden = '_besonderheit_praktikumsleitfaden';
    public static _besonderheit_notdienstring = '_besonderheit_notdienstring';
    public static _besonderheit_kein_notdienst = '_besonderheit_kein_notdienst';
    public static _besonderheit_gvp_zertifiziert = '_besonderheit_gvp_zertifiziert';
    public static _besonderheit_praktikum_basics = '_besonderheit_praktikum_basics';
    public static _besonderheit_vwl = '_besonderheit_vwl';
    public static _besonderheit_fahrtkostenzuschuss = '_besonderheit_fahrtkostenzuschuss';
    public static _besonderheit_keine_nacht_und_wochenenddienste = '_besonderheit_keine_nacht_und_wochenenddienste';
    public static _besonderheit_nacht_und_wochenenddienste_auf_wunsch = '_besonderheit_nacht_und_wochenenddienste_auf_wunsch';
    public static _besonderheit_nacht_und_wochenenddienste_geregelt = '_besonderheit_nacht_und_wochenenddienste_geregelt';
    public static _besonderheit_berufseinsteiger_willkommen = '_besonderheit_berufseinsteiger_willkommen';
    public static _bezahlte_stelle = '_bezahlte_stelle';
    public static _an_stelle_gekoppelt = '_an_stelle_gekoppelt';
    public static _datenschutz_akzeptiert = '_datenschutz_akzeptiert';
    public static _einrichtung_sonstiges = '_einrichtung_sonstiges';
    public static _einrichtung_tierklinik = '_einrichtung_tierklinik';
    public static _einrichtung_tierarztpraxis = '_einrichtung_tierarztpraxis';
    public static _einrichtung_fahrpraxis = '_einrichtung_fahrpraxis';
    public static _einrichtung_fachtierarztpraxis = '_einrichtung_fachtierarztpraxis';
    public static _einrichtung_industrie = '_einrichtung_industrie';
    public static _einrichtung_oeffentlicher_dienst = '_einrichtung_oeffentlicher_dienst';
    public static _einrichtung_hochschule = '_einrichtung_hochschule';
    public static _einrichtung = '_einrichtung';
    public static _externes_medium1 = '_externes_medium1';
    public static _externes_medium2 = '_externes_medium2';
    public static _externes_medium3 = '_externes_medium3';
    public static _facharzt_abschluesse = '_facharzt_abschluesse';
    public static _facharzt_ausbildung = '_facharzt_ausbildung';
    public static _facharzt_abschluss1 = '_facharzt_abschluss1';
    public static _facharzt_abschluss2 = '_facharzt_abschluss2';
    public static _facharzt_abschluss3 = '_facharzt_abschluss3';
    public static _feedback_gefunden_ueber = '_feedback_gefunden_ueber';
    public static _feedback_gefunden_ueber_tvd = '_feedback_gefunden_ueber_tvd';
    public static _feedback_gefunden_ueber_empfehlung = '_feedback_gefunden_ueber_empfehlung';
    public static _feedback_gefunden_ueber_google = '_feedback_gefunden_ueber_google';
    public static _feedback_gefunden_ueber_newsletter = '_feedback_gefunden_ueber_newsletter';
    public static _feedback_gefunden_ueber_facebook = '_feedback_gefunden_ueber_facebook';
    public static _feedback_gefunden_ueber_instagram = '_feedback_gefunden_ueber_instagram';
    public static _feedback_gefunden_ueber_sonstiges = '_feedback_gefunden_ueber_sonstiges';
    public static _fortbildungsbudget = '_fortbildungsbudget';
    public static _fortbildungsbudget_vorhanden = '_fortbildungsbudget_vorhanden';
    public static _fortbildungsbudget_nach_vereinbarung = '_fortbildungsbudget_nach_vereinbarung';
    public static _urlaubstage = '_urlaubstage';
    public static _fortbildungstage = '_fortbildungstage';
    public static _fortbildungstage_nach_vereinbarung = '_fortbildungstage_nach_vereinbarung';
    public static _fortbildung_regelm_intern = '_fortbildung_regelm_intern';
    public static _fortbildung_freie_tage = '_fortbildung_freie_tage';
    public static _geplante_dauer = '_geplante_dauer';
    public static _homepage = '_homepage';
    public static _icon_name = '_icon_name';
    public static _infotext = '_infotext';
    public static _lebensphase = '_lebensphase';
    public static _mitarbeiter_assistenten = '_mitarbeiter_assistenten';
    public static _mitarbeiter_auszubildende = '_mitarbeiter_auszubildende';
    public static _mitarbeiter_in_mutterschutz = '_mitarbeiter_in_mutterschutz';
    public static _mitarbeiter_minijobs = '_mitarbeiter_minijobs';
    public static _mitarbeiter_tfa = '_mitarbeiter_tfa';
    public static _mitarbeiter_ta = '_mitarbeiter_ta';
    public static _mitarbeiter_sonstige = '_mitarbeiter_sonstige';
    public static _nachmittag_dienstag = '_nachmittag_dienstag';
    public static _nachmittag_donnerstag = '_nachmittag_donnerstag';
    public static _nachmittag_freitag = '_nachmittag_freitag';
    public static _nachmittag_mittwoch = '_nachmittag_mittwoch';
    public static _nachmittag_montag = '_nachmittag_montag';
    public static _nachmittag_samstag = '_nachmittag_samstag';
    public static _nachricht = '_nachricht';
    public static _parallele_anstellung = '_parallele_anstellung';
    public static _praxis_abgabe_in = '_praxis_abgabe_in';
    public static _praxis_apothekenbestand = '_praxis_apothekenbestand';
    public static _praxis_art = '_praxis_art';
    public static _praxis_art_sonstiges = '_praxis_art_sonstiges';
    public static _praxis_art_kleintier = '_praxis_art_kleintier';
    public static _praxis_art_pferde = '_praxis_art_pferde';
    public static _praxis_art_gemischt = '_praxis_art_gemischt';
    public static _praxis_art_rinder = '_praxis_art_rinder';
    public static _praxis_art_gefluegel = '_praxis_art_gefluegel';
    public static _praxis_art_schwein = '_praxis_art_schwein';
    public static _praxis_art_reptilien = '_praxis_art_reptilien';
    public static _praxis_art_industrie = '_praxis_art_industrie';
    public static _praxis_art_verwaltung = '_praxis_art_verwaltung';
    public static _praxis_art_universitaet = '_praxis_art_universitaet';
    public static _praxis_art_heimtiere = '_praxis_art_heimtiere';
    public static _praxis_art_hochschule = '_praxis_art_hochschule';
    public static _praxis_art_oeffentlicher_dienst = '_praxis_art_oeffentlicher_dienst';
    public static _praxis_art_wildtiere = '_praxis_art_wildtiere';
    public static _praxis_art_ziervoegel = '_praxis_art_ziervoegel';
    public static _praxis_hat_preisvorstellung = '_praxis_hat_preisvorstellung';
    public static _praxis_fachliche_spezialisierung = '_praxis_fachliche_spezialisierung';
    public static _praxis_form = '_praxis_form';
    public static _praxis_flaeche = '_praxis_flaeche';
    public static _praxis_fortbildung = '_praxis_fortbildung';
    public static _praxis_fortbildung_angebot = '_praxis_fortbildung_angebot';
    public static _praxis_gruendungsjahr = '_praxis_gruendungsjahr';
    public static _praxis_hat_notdienst = '_praxis_hat_notdienst';
    public static _praxis_notdienst = '_praxis_notdienst';
    public static _praxis_notdienst_auszahlung = '_praxis_notdienst_auszahlung';
    public static _praxis_notdienst_freizeitausgleich = '_praxis_notdienst_freizeitausgleich';
    public static _praxis_sprechstunde = '_praxis_sprechstunde';
    public static _praxis_hat_terminsprechstunde = '_praxis_hat_terminsprechstunde';
    public static _praxis_hat_vertretungsregelung = '_praxis_hat_vertretungsregelung';
    public static _praxis_jahresnettoumsatz_2015 = '_praxis_jahresnettoumsatz_2015';
    public static _praxis_jahresnettoumsatz_2016 = '_praxis_jahresnettoumsatz_2016';
    public static _praxis_jahresnettoumsatz_2017 = '_praxis_jahresnettoumsatz_2017';
    public static _praxis_jahresnettoumsatz_2018 = '_praxis_jahresnettoumsatz_2018';
    public static _praxis_jahresnettoumsatz_2019 = '_praxis_jahresnettoumsatz_2019';
    public static _praxis_jahresnettoumsatz_2020 = '_praxis_jahresnettoumsatz_2020';
    public static _praxis_jahresnettoumsatz_2021 = '_praxis_jahresnettoumsatz_2021';
    public static _praxis_jahresnettoumsatz_2022 = '_praxis_jahresnettoumsatz_2022';
    public static _praxis_kurzbeschreibung = '_praxis_kurzbeschreibung';
    public static _praxis_materieller_wert = '_praxis_materieller_wert';
    public static _praxis_patientenbesitzer = '_praxis_patientenbesitzer';
    public static _praxis_preisvorstellung = '_praxis_preisvorstellung';
    public static _praxis_sonstige_beschreibung = '_praxis_sonstige_beschreibung';
    public static _praxis_telefon_homepage_uebernahme = '_praxis_telefon_homepage_uebernahme';
    public static _praxis_uebergabedatum = '_praxis_uebergabedatum';
    public static _praxis_uebernahme_in = '_praxis_uebernahme_in';
    public static _praxis_uebernahme = '_praxis_uebernahme';
    public static _praxis_teilhaber = '_praxis_teilhaber';
    public static _praxis_ueberschrift = '_praxis_ueberschrift';
    public static _praxis_zulassungsstatus = '_praxis_zulassungsstatus';
    public static _praxis_sprache_1 = '_praxis_sprache_1';
    public static _praxis_sprache_2 = '_praxis_sprache_2';
    public static _raeume_apotheke = '_raeume_apotheke';
    public static _raeume_behandlung1 = '_raeume_behandlung1';
    public static _raeume_behandlung2 = '_raeume_behandlung2';
    public static _raeume_empfang = '_raeume_empfang';
    public static _raeume_labor = '_raeume_labor';
    public static _raeume_op = '_raeume_op';
    public static _raeume_op_vorb = '_raeume_op_vorb';
    public static _raeume_roentgen = '_raeume_roentgen';
    public static _raeume_sonstige = '_raeume_sonstige';
    public static _raeume_sozial = '_raeume_sozial';
    public static _raeume_station = '_raeume_station';
    public static _raeume_warten = '_raeume_warten';
    public static _raeume_wc = '_raeume_wc';
    public static _raeume_mitarbeiter = '_raeume_mitarbeiter';
    public static _raeume_vorhanden_apotheke = '_raeume_vorhanden_apotheke';
    public static _raeume_vorhanden_behandlung1 = '_raeume_vorhanden_behandlung1';
    public static _raeume_vorhanden_behandlung2 = '_raeume_vorhanden_behandlung2';
    public static _raeume_vorhanden_empfang = '_raeume_vorhanden_empfang';
    public static _raeume_vorhanden_labor = '_raeume_vorhanden_labor';
    public static _raeume_vorhanden_op = '_raeume_vorhanden_op';
    public static _raeume_vorhanden_op_vorb = '_raeume_vorhanden_op_vorb';
    public static _raeume_vorhanden_roentgen = '_raeume_vorhanden_roentgen';
    public static _raeume_vorhanden_sozial = '_raeume_vorhanden_sozial';
    public static _raeume_vorhanden_station = '_raeume_vorhanden_station';
    public static _raeume_vorhanden_warten = '_raeume_vorhanden_warten';
    public static _raeume_vorhanden_wc = '_raeume_vorhanden_wc';
    public static _raeume_vorhanden_sonstige = '_raeume_vorhanden_sonstige';
    public static _raeume_vorhanden_mitarbeiter = '_raeume_vorhanden_mitarbeiter';
    public static _ratio_exoten = '_ratio_exoten';
    public static _ratio_kleintiere = '_ratio_kleintiere';
    public static _ratio_nutztiere = '_ratio_nutztiere';
    public static _ratio_pferde = '_ratio_pferde';
    public static _ratio_rinder = '_ratio_rinder';
    public static _ratio_schweine = '_ratio_schweine';
    public static _ratio_gefluegel = '_ratio_gefluegel';
    public static _ratio_heimtiere = '_ratio_heimtiere';
    public static _ratio_reptilien = '_ratio_reptilien';
    public static _ratio_oeffentlicher_dienst = '_ratio_oeffentlicher_dienst';
    public static _ratio_industrie = '_ratio_industrie';
    public static _ratio_sonstiges = '_ratio_sonstiges';
    public static _alle_regionen = '_alle_regionen';
    public static _region_bw = '_region_bw';
    public static _region_by = '_region_by';
    public static _region_be = '_region_be';
    public static _region_bb = '_region_bb';
    public static _region_hb = '_region_hb';
    public static _region_hh = '_region_hh';
    public static _region_he = '_region_he';
    public static _region_mv = '_region_mv';
    public static _region_ni = '_region_ni';
    public static _region_nw = '_region_nw';
    public static _region_rp = '_region_rp';
    public static _region_sl = '_region_sl';
    public static _region_sn = '_region_sn';
    public static _region_sa = '_region_sa';
    public static _region_sh = '_region_sh';
    public static _region_th = '_region_th';
    public static _region_0x = '_region_0x';
    public static _region_1x = '_region_1x';
    public static _region_2x = '_region_2x';
    public static _region_3x = '_region_3x';
    public static _region_4x = '_region_4x';
    public static _region_5x = '_region_5x';
    public static _region_6x = '_region_6x';
    public static _region_7x = '_region_7x';
    public static _region_8x = '_region_8x';
    public static _region_9x = '_region_9x';
    public static _schwerpunkt_apothekenmanagement = '_schwerpunkt_apothekenmanagement';
    public static _schwerpunkt_allgemeine_tiermedizin = '_schwerpunkt_allgemeine_tiermedizin';
    public static _schwerpunkt_alternativmedizin = '_schwerpunkt_alternativmedizin';
    public static _schwerpunkt_fahrpraxis = '_schwerpunkt_fahrpraxis';
    public static _schwerpunkt_bestandsbetreuung = '_schwerpunkt_bestandsbetreuung';
    public static _schwerpunkt_chirurgie = '_schwerpunkt_chirurgie';
    public static _schwerpunkt_gynaekologie = '_schwerpunkt_gynaekologie';
    public static _schwerpunkt_haut_augenerkrankung = '_schwerpunkt_haut_augenerkrankung';
    public static _schwerpunkt_innere_medizin = '_schwerpunkt_innere_medizin';
    public static _schwerpunkt_intensivmedizin = '_schwerpunkt_intensivmedizin';
    public static _schwerpunkt_kardiologie = '_schwerpunkt_kardiologie';
    public static _schwerpunkt_klassische_tiermedizin = '_schwerpunkt_klassische_tiermedizin';
    public static _schwerpunkt_neurologie = '_schwerpunkt_neurologie';
    public static _schwerpunkt_notdienste = '_schwerpunkt_notdienste';
    public static _schwerpunkt_onkologie = '_schwerpunkt_onkologie';
    public static _schwerpunkt_operationen_knochen = '_schwerpunkt_operationen_knochen';
    public static _schwerpunkt_operationen_weichteile = '_schwerpunkt_operationen_weichteile';
    public static _schwerpunkt_operationen = '_schwerpunkt_operationen';
    public static _schwerpunkt_op_assistenz = '_schwerpunkt_op_assistenz';
    public static _schwerpunkt_roentgen = '_schwerpunkt_roentgen';
    public static _schwerpunkt_sonstiges = '_schwerpunkt_sonstiges';
    public static _schwerpunkt_stationaere_aufnahme = '_schwerpunkt_stationaere_aufnahme';
    public static _schwerpunkt_stationaere_betreuung = '_schwerpunkt_stationaere_betreuung';
    public static _schwerpunkt_sprechstunde = '_schwerpunkt_sprechstunde';
    public static _schwerpunkt_ultraschall = '_schwerpunkt_ultraschall';
    public static _schwerpunkt_untersuchung_laborproben = '_schwerpunkt_untersuchung_laborproben';
    public static _schwerpunkt_ueberwachung_von_narkose = '_schwerpunkt_ueberwachung_von_narkose';
    public static _schwerpunkt_verhaltenstherapie = '_schwerpunkt_verhaltenstherapie';
    public static _schwerpunkt_veranstaltungsbetreuung = '_schwerpunkt_veranstaltungsbetreuung';
    public static _schwerpunkt_zahn_kieferchirugie = '_schwerpunkt_zahn_kieferchirugie';
    public static _schwerpunkt_zahn_behandlungen = '_schwerpunkt_zahn_behandlungen';
    public static _schwerpunkt_zahnheilkunde = '_schwerpunkt_zahnheilkunde';
    public static _schwerpunkt_nachtdienste = '_schwerpunkt_nachtdienste';
    public static _schwerpunkt_nachtdienste_anzahl = '_schwerpunkt_nachtdienste_anzahl';
    public static _schwerpunkt_wochenenddienste = '_schwerpunkt_wochenenddienste';
    public static _schwerpunkt_wochenenddienste_anzahl = '_schwerpunkt_wochenenddienste_anzahl';
    public static _schwerpunkt_ophthalmologie = '_schwerpunkt_ophthalmologie';
    public static _schwerpunkt_dermatologie = '_schwerpunkt_dermatologie';
    public static _schwerpunkt_orthopaedie = '_schwerpunkt_orthopaedie';
    public static _schwerpunkt_alternative_heilmethoden = '_schwerpunkt_alternative_heilmethoden';
    public static _schwerpunkt_weichteilchirurgie = '_schwerpunkt_weichteilchirurgie';
    public static _schwerpunkt_osteosynthese = '_schwerpunkt_osteosynthese';
    public static _schwerpunkt_arthroskopie = '_schwerpunkt_arthroskopie';
    public static _schwerpunkt_augenheilkunde = '_schwerpunkt_augenheilkunde';
    public static _schwerpunkt_reproduktionsmedizin = '_schwerpunkt_reproduktionsmedizin';
    public static _schwerpunkt_ernaehrung = '_schwerpunkt_ernaehrung';
    public static _schwerpunkt_notfallmedizin = '_schwerpunkt_notfallmedizin';
    public static _schwerpunkt_endokrinologie = '_schwerpunkt_endokrinologie';
    public static _schwerpunkt = '_schwerpunkt';
    public static _seo_description = '_seo_description';
    public static _seo_hiringorganization_name = '_seo_hiringorganization_name';
    public static _seo_hiringorganization_sameas = '_seo_hiringorganization_sameas';
    public static _seo_joblocation_addresslocality = '_seo_joblocation_addresslocality';
    public static _seo_joblocation_streetaddress = '_seo_joblocation_streetaddress';
    public static _seo_joblocation_addressregion = '_seo_joblocation_addressregion';
    public static _seo_joblocation_postalcode = '_seo_joblocation_postalcode';
    public static _seo_joblocation_addresscountry = '_seo_joblocation_addresscountry';
    public static _slug = '_slug';
    public static _slug_v2 = '_slug_v2';
    public static _stammdaten_adresse = '_stammdaten_adresse';
    public static _stammdaten_anrede = '_stammdaten_anrede';
    public static _stammdaten_email = '_stammdaten_email';
    public static _stammdaten_fax = '_stammdaten_fax';
    public static _stammdaten_grussform = '_stammdaten_grussform';
    public static _stammdaten_mobil = '_stammdaten_mobil';
    public static _stammdaten_nachname = '_stammdaten_nachname';
    public static _stammdaten_nr = '_stammdaten_nr';
    public static _stammdaten_ort = '_stammdaten_ort';
    public static _stammdaten_plz = '_stammdaten_plz';
    public static _stammdaten_strasse = '_stammdaten_strasse';
    public static _stammdaten_telefon = '_stammdaten_telefon';
    public static _stammdaten_titel = '_stammdaten_titel';
    public static _stammdaten_vorname = '_stammdaten_vorname';
    public static _stammdaten_praxisname = '_stammdaten_praxisname';
    public static _stammdaten_ansprechpartner = '_stammdaten_ansprechpartner';
    public static _stammdaten_iso3166_1 = '_stammdaten_iso3166_1';
    public static _stammdaten_iso3166_2 = '_stammdaten_iso3166_2';
    public static _standort = '_standort';
    public static _standort_beschreibung = '_standort_beschreibung';
    public static _standort_distance = '_standort_distance';
    public static _standort_einwohnerzahl = '_standort_einwohnerzahl';
    public static _standort_anzahl_parkplatz = '_standort_anzahl_parkplatz';
    public static _standort_entfernung_bahnhof = '_standort_entfernung_bahnhof';
    public static _standort_entfernung_bushaltestelle = '_standort_entfernung_bushaltestelle';
    public static _standort_entfernung_einkauf = '_standort_entfernung_einkauf';
    public static _standort_entfernung_schule = '_standort_entfernung_schule';
    public static _standort_entfernung_grundschule = '_standort_entfernung_grundschule';
    public static _standort_entfernung_kindergarten = '_standort_entfernung_kindergarten';
    public static _standort_hat_bahnhof = '_standort_hat_bahnhof';
    public static _standort_hat_bushaltestelle = '_standort_hat_bushaltestelle';
    public static _standort_hat_einkauf = '_standort_hat_einkauf';
    public static _standort_hat_parkplatz = '_standort_hat_parkplatz';
    public static _standort_hat_schule = '_standort_hat_schule';
    public static _standort_hat_grundschule = '_standort_hat_grundschule';
    public static _standort_hat_kindergarten = '_standort_hat_kindergarten';
    public static _standort_hat_mitarbeiterparkplatz = '_standort_hat_mitarbeiterparkplatz';
    public static _standort_hat_parkmoeglichkeiten = '_standort_hat_parkmoeglichkeiten';
    public static _standort_hat_oeffentliche_anbindung = '_standort_hat_oeffentliche_anbindung';
    public static _standort_immobilie = '_standort_immobilie';
    public static _standort_immobilie_inklusive = '_standort_immobilie_inklusive';
    public static _standort_geolocated = '_standort_geolocated';
    public static _standort_mietzins = '_standort_mietzins';
    public static _standort_lat = '_standort_lat';
    public static _standort_lng = '_standort_lng';
    public static _standort_land = '_standort_land';
    public static _standort_plz = '_standort_plz';
    public static _standort_region = '_standort_region';
    public static _stellenart_assistenzstelle = '_stellenart_assistenzstelle';
    public static _stellenart_praktikum = '_stellenart_praktikum';
    public static _stellenart_praxisvertreter = '_stellenart_praxisvertreter';
    public static _stellenart_tfa = '_stellenart_tfa';
    public static _stellenart_praktikum_ausland = '_stellenart_praktikum_ausland';
    public static _stellenart_selbststaendig = '_stellenart_selbststaendig';
    public static _stellenart_teilhaber = '_stellenart_teilhaber';
    public static _stellenart_tierarzt = '_stellenart_tierarzt';
    public static _stellenart_tfa_ausbildung = '_stellenart_tfa_ausbildung';
    public static _stellenart_dissertation = '_stellenart_dissertation';
    public static _stellenart_sonstiges = '_stellenart_sonstiges';
    public static _stellenart_is_empfehlung = '_stellenart_is_empfehlung';
    public static _subtitle = '_subtitle';
    public static _taetigkeit_ort = '_taetigkeit_ort';
    public static _taetigkeit_nur_vor_ort = '_taetigkeit_nur_vor_ort';
    public static _taetigkeit_nur_dezentral = '_taetigkeit_nur_dezentral';
    public static _taetigkeit_ort_kombiniert = '_taetigkeit_ort_kombiniert';
    public static _title = '_title';
    public static _umfang_vollzeit = '_umfang_vollzeit';
    public static _umfang_teilzeit = '_umfang_teilzeit';
    public static _umfang_nebenbeschaeftigung = '_umfang_nebenbeschaeftigung';
    public static _vormittag_dienstag = '_vormittag_dienstag';
    public static _vormittag_donnerstag = '_vormittag_donnerstag';
    public static _vormittag_freitag = '_vormittag_freitag';
    public static _vormittag_mittwoch = '_vormittag_mittwoch';
    public static _vormittag_montag = '_vormittag_montag';
    public static _vormittag_samstag = '_vormittag_samstag';
    public static _voraussetzungen_deutsche_approbation_tierarzt = '_voraussetzungen_deutsche_approbation_tierarzt';
    public static _voraussetzungen_min_berufserfahrungen = '_voraussetzungen_min_berufserfahrungen';
    public static _voraussetzungen_berufserfahrungen_in_jahren = '_voraussetzungen_berufserfahrungen_in_jahren';
    public static _voraussetzungen_min_semester = '_voraussetzungen_min_semester';
    public static _voraussetzungen_semester_anzahl = '_voraussetzungen_semester_anzahl';
    public static _voraussetzungen_bereits_absolviertes_internship = '_voraussetzungen_bereits_absolviertes_internship';
    public static _voraussetzungen_hat_titel = '_voraussetzungen_hat_titel';
    public static _voraussetzungen_title_zusatzbezeichnung = '_voraussetzungen_title_zusatzbezeichnung';
    public static _voraussetzungen_notdienst_bereitschaft = '_voraussetzungen_notdienst_bereitschaft';
    public static _voraussetzungen_belastbarkeit = '_voraussetzungen_belastbarkeit';
    public static _voraussetzungen_empathischer_umgang = '_voraussetzungen_empathischer_umgang';
    public static _voraussetzungen_selbststaendiges_arbeiten = '_voraussetzungen_selbststaendiges_arbeiten';
    public static _voraussetzungen_teamfaehigkeit = '_voraussetzungen_teamfaehigkeit';
    public static _voraussetzungen_fuehrerschein = '_voraussetzungen_fuehrerschein';
    public static _voraussetzungen_eigenes_auto = '_voraussetzungen_eigenes_auto';
    public static _voraussetzungen_puenktlichkeit = '_voraussetzungen_puenktlichkeit';
    public static _voraussetzungen_zuverlaessigkeit = '_voraussetzungen_zuverlaessigkeit';
    public static _voraussetzungen_interesse_an_weiterbildung = '_voraussetzungen_interesse_an_weiterbildung';
    public static _voraussetzungen_kollegialer_umgang = '_voraussetzungen_kollegialer_umgang';
    public static _wunschort1_location_address = '_wunschort1_location_address';
    public static _wunschort1_location = '_wunschort1_location';
    public static _wunschort2_location_address = '_wunschort2_location_address';
    public static _wunschort2_location = '_wunschort2_location';
    public static _wunschort3_location_address = '_wunschort3_location_address';
    public static _wunschort3_location = '_wunschort3_location';
    public static _wunschort_flexibel = '_wunschort_flexibel';
    public static _wunschorte = '_wunschorte';
    public static _wochenstunden = '_wochenstunden';
    public static _wochentag_montag = '_wochentag_montag';
    public static _wochentag_dienstag = '_wochentag_dienstag';
    public static _wochentag_mittwoch = '_wochentag_mittwoch';
    public static _wochentag_donnerstag = '_wochentag_donnerstag';
    public static _wochentag_freitag = '_wochentag_freitag';
    public static _wochentag_samstag = '_wochentag_samstag';
    public static _wochentag_sonntag = '_wochentag_sonntag';
    public static _zusatzinfos = '_zusatzinfos';
    public static _erreichbarkeit_uhrzeit = '_erreichbarkeit_uhrzeit';


        public static labels = {
        'de': {
            uuid: 'Offer.uuid',
            user_uuid: 'Offer.user_uuid',
            offer_type: 'Offer.offer_type',
            create_date: 'Offer.create_date',
            modify_date: 'Offer.modify_date',
            delete_date: 'Offer.delete_date',
            expire_date: 'Offer.expire_date',
            last_mail_date: 'Offer.last_mail_date',
            opt_in_date: 'Offer.opt_in_date',
            opt_out_date: 'Offer.opt_out_date',
            import_id: 'Offer.import_id',
            is_public: 'Offer.is_public',
            is_intern: 'Offer.is_intern',
            is_opted_in: 'Offer.is_opted_in',
            need_payment: 'Offer.need_payment',
            _chiffre: 'Chiffre',
            _admin_status: 'Offer._admin_status',
            _admin_is_public: 'Offer._admin_is_public',
            _admin_is_sold: 'Offer._admin_is_sold',
            _admin_is_deleted: 'Offer._admin_is_deleted',
            _admin_is_reserved: 'Offer._admin_is_reserved',
            _admin_is_upper_class: 'Offer._admin_is_upper_class',
            _admin_tvd_ansprechpartner: 'Offer._admin_tvd_ansprechpartner',
            _admin_zusatzinfos: 'Offer._admin_zusatzinfos',
            _arbeitsantritt: 'Arbeitsantritt',
            _arbeitsantritt_monate: 'Arbeitsantritt in Monaten',
            _arbeitsumfang: 'Arbeitsumfang',
            _arbeitsumfang_teilzeit: 'Teilzeit',
            _arbeitsumfang_vollzeit: 'Vollzeit',
            _arbeitsumfang_dauer: 'Dauer',
            _arbeitsumfang_dauer_min_wochen: 'Mindestens',
            _arbeitsumfang_dauer_max_wochen: 'Maximal',
            _arbeitsumfang_dauer_sonstiges: 'Sonstiges',
            _arbeitsumfang_nebenbeschaeftigung: 'Nebenbeschäftigung',
            _arbeitszeit_montag: 'Arbeitszeit Montag',
            _arbeitszeit_dienstag: 'Arbeitszeit Dienstag',
            _arbeitszeit_mittwoch: 'Arbeitszeit Mittwoch',
            _arbeitszeit_donnerstag: 'Arbeitszeit Donnerstag',
            _arbeitszeit_freitag: 'Arbeitszeit Freitag',
            _arbeitszeit_samstag: 'Arbeitszeit Samstag',
            _arbeitszeit_sonntag: 'Arbeitszeit Sonntag',
            _ausstattung_autoclav: 'Autoclav',
            _ausstattung_behandlungstisch: 'Behandlungstisch',
            _ausstattung_blutanalyse: 'Blutanalyse',
            _ausstattung_boxen: 'Boxen',
            _ausstattung_computer_drucker: 'Computer & Drucker',
            _ausstattung_endoskop: 'Endoskop',
            _ausstattung_gefriertruhe: 'Gefriertruhe',
            _ausstattung_inhouse_labor: 'Inhouse Labor',
            _ausstattung_klimaanlage: 'Klimaanlage',
            _ausstattung_kuehlschrank: 'Kühlschrank',
            _ausstattung_mikroskop: 'Mikroskop',
            _ausstattung_narkosegeraet: 'Narkosegeraet',
            _ausstattung_op_besteck: 'OP Besteck',
            _ausstattung_op_lampen: 'OP Lampen',
            _ausstattung_osteosynthese_set: 'Osteosynthese Set',
            _ausstattung_otoskop: 'Otoskop',
            _ausstattung_roentgen_analog: 'Analoges Röntgen',
            _ausstattung_roentgen_digital: 'Digitales Röntgen',
            _ausstattung_sonstiges: 'Sonstiges',
            _ausstattung_telefonanlagen: 'Telefonanlagen',
            _ausstattung_tierwaage: 'Tierwaage',
            _ausstattung_trockner: 'Trockner',
            _ausstattung_ultraschall: 'Ultraschall',
            _ausstattung_herzultraschall: 'Herzultraschall',
            _ausstattung_waschmaschine: 'Waschmaschine',
            _ausstattung_zahnsteineinheit: 'Zahnsteineinheit',
            _ausstattung_szintigraph: 'Szintigraph',
            _ausstattung_station: 'Station / Stationsboxen',
            _ausstattung_getrennter_wartebereich: 'Getrennte Wartebereiche für Katzen und Hunde',
            _ausstattung_software_zur_praxisverwaltung: 'Software zur Praxisverwaltung',
            _ausstattung_crm: 'CRM',
            _ausstattung_ct: 'CT',
            _ausstattung_mrt: 'MRT',
            _ausstattung: 'Ausstattung',
            _ausgleich_notdienst: 'Ausgleich bei Notdienst',
            _behandlung_op_mit_narkose: 'OP inkl. Inhalationsnarkose',
            _behandlung_ernaehrungsberatung: 'Ernährungsberatung',
            _behandlung_osteosynthese: 'Osteosynthese',
            _behandlung_stationaer: 'Stationäre Aufnahme von Patienten',
            _behandlung_osteopathie: 'Osteopathie',
            _behandlung_physiotherapie: 'Physiotherapie',
            _behandlung_chiropraktik: 'Chiropraktik',
            _behandlung_akupunktur: 'Akupunktur',
            _behandlung_herzultraschall: 'Herzultraschall',
            _behandlung_ultraschall: 'Ultraschall',
            _behandlung_digitales_roentgen: 'Digitales Röntgen',
            _behandlung_mobiles_roentgen: 'Mobiles Röntgen',
            _behandlung_zahnbehandlungen_mobil: 'Zahnbehandlungen mobil',
            _behandlung_dentalroentgen: 'Dentalröntgen',
            _behandlung_inhouse_labor: 'Inhouse Labor',
            _behandlung_sonstiges: 'Sonstiges',
            _behandlung: 'Behandlung',
            _behandlung_ct: 'CT',
            _behandlung_mrt: 'MRT',
            _behandlung_szintigraphie: 'Szintigraphie',
            _behandlung_endoskopie: 'Endoskopie',
            _benoetigte_unterlagen: 'Benötigte Unterlagen',
            _benoetigte_bewerber_voraussetzungen: 'Voraussetzungen für Bewerbende',
            _beschreibung_text: 'Beschreibung',
            _beschreibung_titel: 'Überschrift',
            _besonderheit: 'Besonderheit',
            _besonderheit_sonstiges: 'Sonstiges',
            _besonderheit_cat_friendly: 'Cat friendly practice/clinic',
            _besonderheit_mitnahme_hund: 'Möglichkeit der Mitnahme meines Hundes',
            _besonderheit_moegl_doktorarbeit: 'Doktorarbeit berufsbegleitend möglich',
            _besonderheit_moegl_teilhaberschaft: 'Möglichkeit zur Teilhaberschaft',
            _besonderheit_praktikumsverguetung: 'Praktikumsvergütung',
            _besonderheit_bez_fortbildung: 'Bezahlte Fortbildungen',
            _besonderheit_wohnen_vor_ort: 'Wohnmöglichkeit vor Ort',
            _besonderheit_kein_nachtdienst: 'Keine Nachtdienste',
            _besonderheit_bkv: 'Betriebliche Krankenversicherung',
            _besonderheit_bav: 'Betriebliche Altersvorsorge',
            _besonderheit_eigenverantwortung: 'Eigenverantwortliches Arbeiten',
            _besonderheit_gehaltsmodell: 'Umsatzbeteiligung möglich',
            _besonderheit_gute_einarbeitung: 'Gründliche Einarbeitungsphase',
            _besonderheit_gutes_team: 'Starker Teamzusammenhalt',
            _besonderheit_familiaere: 'Familiäre Arbeitsatmosphäre',
            _besonderheit_hohes_niveau: 'Arbeiten auf höchstem Niveau',
            _besonderheit_moderne_ausstattung: 'Moderne technische Ausstattung',
            _besonderheit_feedbackgespraeche: 'Regelmäßige Feedbackgespräche',
            _besonderheit_arbeitszeiterfassung: 'Digitale Arbeitszeiterfassung',
            _besonderheit_bezahlte_fortbildung: 'Bezahlte Fortbildungen',
            _besonderheit_interne_fortbildungen: 'Regelmäßige interne Fortbildungen',
            _besonderheit_weihnachtsgeld: 'Weihnachtsgeld',
            _besonderheit_urlaubsgeld: 'Urlaubsgeld',
            _besonderheit_dienstwagen: 'Dienstwagen',
            _besonderheit_dienstwagen_privat: 'Dienstwagen auch zur privaten Nutzung',
            _besonderheit_mittagessen_kostenlos: 'Kostenloses Mittagessen',
            _besonderheit_rotation_in_bereiche: 'Rotation in verschiedenen Bereichen',
            _besonderheit_sportangebot: 'Sportangebot für Mitarbeiter',
            _besonderheit_teamevents: 'Regelmäßige Teamevents',
            _besonderheit_mitglied_vuk: 'Mitglied im VuK',
            _besonderheit_praktikumsleitfaden: 'Orientierung an einem Praktikumsleitfaden',
            _besonderheit_notdienstring: 'Teilnahme am Notdienstring',
            _besonderheit_kein_notdienst: 'Keine Notdienste',
            _besonderheit_gvp_zertifiziert: 'GVP zertifiziert',
            _besonderheit_praktikum_basics: 'Basics können selbständig durchgeführt werden (Injektionen, Katheter legen, Allgemeinuntersuchung, etc.)',
            _besonderheit_vwl: 'Vermögenswirksame Leistungen',
            _besonderheit_fahrtkostenzuschuss: 'Fahrtkostenzuschuss/Tankgutschein',
            _besonderheit_keine_nacht_und_wochenenddienste: 'Keine Not- und Wochenenddienste',
            _besonderheit_nacht_und_wochenenddienste_auf_wunsch: 'Not- und Wochenenddienste auf Wunsch',
            _besonderheit_nacht_und_wochenenddienste_geregelt: 'Geregelte Not- und Wochenenddienste',
            _besonderheit_berufseinsteiger_willkommen: 'Berufseinsteiger willkommen',
            _bezahlte_stelle: 'Bezahlte Stelle?',
            _an_stelle_gekoppelt: 'An Stelle gekoppelt?',
            _datenschutz_akzeptiert: 'Datenschutz akzeptiert',
            _einrichtung_sonstiges: 'Sonstiges',
            _einrichtung_tierklinik: 'Tierklinik / Tiergesundheitszentrum',
            _einrichtung_tierarztpraxis: 'Tierarztpraxis',
            _einrichtung_fahrpraxis: 'Fahrpraxis',
            _einrichtung_fachtierarztpraxis: 'Fachtierarztpraxis',
            _einrichtung_industrie: 'Industrie',
            _einrichtung_oeffentlicher_dienst: 'Öffentlicher Dienst',
            _einrichtung_hochschule: 'Hochschule',
            _einrichtung: 'Sonstiges',
            _externes_medium1: 'Externes Medium',
            _externes_medium2: 'Externes Medium',
            _externes_medium3: 'Externes Medium',
            _facharzt_abschluesse: 'Abschlüsse',
            _facharzt_ausbildung: 'Facharzt',
            _facharzt_abschluss1: 'Erster Abschluss',
            _facharzt_abschluss2: 'Zweiter Abschluss',
            _facharzt_abschluss3: 'Dritter Abschluss',
            _feedback_gefunden_ueber: 'Woher kennen Sie job.vet?',
            _feedback_gefunden_ueber_tvd: 'Über TVD',
            _feedback_gefunden_ueber_empfehlung: 'Empfehlung',
            _feedback_gefunden_ueber_google: 'Google',
            _feedback_gefunden_ueber_newsletter: 'Newsletter',
            _feedback_gefunden_ueber_facebook: 'Facebook',
            _feedback_gefunden_ueber_instagram: 'Instagram',
            _feedback_gefunden_ueber_sonstiges: 'Sonstiges',
            _fortbildungsbudget: 'Fortbildungsbudget',
            _fortbildungsbudget_vorhanden: 'Fortbildungsbudget',
            _fortbildungsbudget_nach_vereinbarung: 'Nach Vereinbarung',
            _urlaubstage: 'Urlaubstage',
            _fortbildungstage: 'Fortbildungstage',
            _fortbildungstage_nach_vereinbarung: 'Nach Vereinbarung',
            _fortbildung_regelm_intern: 'regelmäßige, interne Fortbildungen',
            _fortbildung_freie_tage: 'freie Tage für Fortbildungen',
            _geplante_dauer: 'Geplante Dauer',
            _homepage: 'Homepage',
            _icon_name: 'Icon',
            _infotext: 'Infotext',
            _lebensphase: 'Lebensphase',
            _mitarbeiter_assistenten: 'Assistenten',
            _mitarbeiter_auszubildende: 'Auszubildende',
            _mitarbeiter_in_mutterschutz: 'In Mutterschutz',
            _mitarbeiter_minijobs: 'Minjobs',
            _mitarbeiter_tfa: 'TFAs',
            _mitarbeiter_ta: 'Tierärzte',
            _mitarbeiter_sonstige: 'Sonstige',
            _nachmittag_dienstag: 'Dienstag nachmittag',
            _nachmittag_donnerstag: 'Donnerstag nachmittag',
            _nachmittag_freitag: 'Freitag nachmittag',
            _nachmittag_mittwoch: 'Mittwoch nachmittag',
            _nachmittag_montag: 'Montag nachmittag',
            _nachmittag_samstag: 'Samstag nachmittag',
            _nachricht: 'Ihre Nachricht',
            _parallele_anstellung: 'Parallele Anstellung in Praxis/anderem Unternehmen möglich?',
            _praxis_abgabe_in: 'Abgabe geplant in',
            _praxis_apothekenbestand: 'Apothekenbestand',
            _praxis_art: 'Praxisart',
            _praxis_art_sonstiges: 'Sonstiges',
            _praxis_art_kleintier: 'Kleintiere',
            _praxis_art_pferde: 'Pferde',
            _praxis_art_gemischt: 'Gemischt',
            _praxis_art_rinder: 'Rinder',
            _praxis_art_gefluegel: 'Geflügel',
            _praxis_art_schwein: 'Schweine',
            _praxis_art_reptilien: 'Reptilien',
            _praxis_art_industrie: 'Industrie',
            _praxis_art_verwaltung: 'Verwaltung',
            _praxis_art_universitaet: 'Universität',
            _praxis_art_heimtiere: 'Heimtiere',
            _praxis_art_hochschule: 'Hochschule',
            _praxis_art_oeffentlicher_dienst: 'Öffentlicher Dienst',
            _praxis_art_wildtiere: 'Wildtiere',
            _praxis_art_ziervoegel: 'Ziervögel',
            _praxis_hat_preisvorstellung: 'Preisvorstellung vorhanden',
            _praxis_fachliche_spezialisierung: 'Fachliche Spezialisierung',
            _praxis_form: 'Praxisform',
            _praxis_flaeche: 'Fläche',
            _praxis_fortbildung: 'Weiterbildungsermächtigung',
            _praxis_fortbildung_angebot: 'Fortbildungsangebot',
            _praxis_gruendungsjahr: 'Gründungsjahr',
            _praxis_hat_notdienst: 'Notdienste',
            _praxis_notdienst: 'Notdienst',
            _praxis_notdienst_auszahlung: 'Notdienstvergütung',
            _praxis_notdienst_freizeitausgleich: 'Notdienst Freizeitausgleich',
            _praxis_sprechstunde: 'Sprechstundenform',
            _praxis_hat_terminsprechstunde: 'Terminvereinbarung',
            _praxis_hat_vertretungsregelung: 'Vertretungsregelung',
            _praxis_jahresnettoumsatz_2015: 'Jahresnettoumsatz 2015',
            _praxis_jahresnettoumsatz_2016: 'Jahresnettoumsatz 2016',
            _praxis_jahresnettoumsatz_2017: 'Jahresnettoumsatz 2017',
            _praxis_jahresnettoumsatz_2018: 'Jahresnettoumsatz 2018',
            _praxis_jahresnettoumsatz_2019: 'Jahresnettoumsatz 2019',
            _praxis_jahresnettoumsatz_2020: 'Jahresnettoumsatz 2020',
            _praxis_jahresnettoumsatz_2021: 'Jahresnettoumsatz 2021',
            _praxis_jahresnettoumsatz_2022: 'Jahresnettoumsatz 2022',
            _praxis_kurzbeschreibung: 'Kurzbeschreibung',
            _praxis_materieller_wert: 'Materieller Wert',
            _praxis_patientenbesitzer: 'Patientenbesitzer',
            _praxis_preisvorstellung: 'Preisvorstellung',
            _praxis_sonstige_beschreibung: 'Sonstige Beschreibung',
            _praxis_telefon_homepage_uebernahme: 'Telefonnummer und Homepage übernahme',
            _praxis_uebergabedatum: 'Übergabedatum',
            _praxis_uebernahme_in: 'Übernahme in',
            _praxis_uebernahme: 'Übernahme',
            _praxis_teilhaber: 'Teilhaber',
            _praxis_ueberschrift: 'Überschrift',
            _praxis_zulassungsstatus: 'Zulassungsstatus',
            _praxis_sprache_1: '1. Sprache',
            _praxis_sprache_2: '2. Sprache',
            _raeume_apotheke: 'Anzahl Apotheke',
            _raeume_behandlung1: 'Anzahl Behandlungszimmer',
            _raeume_behandlung2: 'Anzahl Behandlungszimmer',
            _raeume_empfang: 'Anzahl Empfangsbereich',
            _raeume_labor: 'Anzahl Labor',
            _raeume_op: 'Anzahl OP-Raum',
            _raeume_op_vorb: 'Anzahl OP-Vorbereitungsraum',
            _raeume_roentgen: 'Anzahl Röntgenstation',
            _raeume_sonstige: 'Anzahl Sonstige',
            _raeume_sozial: 'Anzahl Sozialraum',
            _raeume_station: 'Anzahl Station',
            _raeume_warten: 'Anzahl Wartezimmer',
            _raeume_wc: 'Anzahl Toiletten',
            _raeume_mitarbeiter: 'Anzahl Mitarbeiterraum',
            _raeume_vorhanden_apotheke: 'Apotheke',
            _raeume_vorhanden_behandlung1: 'Behandlungszimmer',
            _raeume_vorhanden_behandlung2: 'Behandlungszimmer',
            _raeume_vorhanden_empfang: 'Empfangsbereich',
            _raeume_vorhanden_labor: 'Labor',
            _raeume_vorhanden_op: 'OP-Raum',
            _raeume_vorhanden_op_vorb: 'OP-Vorbereitungsraum',
            _raeume_vorhanden_roentgen: 'Röntgenstation',
            _raeume_vorhanden_sozial: 'Sozialraum',
            _raeume_vorhanden_station: 'Station',
            _raeume_vorhanden_warten: 'Wartezimmer',
            _raeume_vorhanden_wc: 'Toiletten',
            _raeume_vorhanden_sonstige: 'Sonstige',
            _raeume_vorhanden_mitarbeiter: 'Mitarbeiterraum',
            _ratio_exoten: 'Anteil in % Exoten',
            _ratio_kleintiere: 'Anteil in % Kleintiere',
            _ratio_nutztiere: 'Anteil in % Nutztiere',
            _ratio_pferde: 'Anteil in % Pferde',
            _ratio_rinder: 'Anteil in % Rinder',
            _ratio_schweine: 'Anteil in % Schweine',
            _ratio_gefluegel: 'Anteil in % Geflügel',
            _ratio_heimtiere: 'Anteil in % Heimtiere',
            _ratio_reptilien: 'Anteil in % Reptilien',
            _ratio_oeffentlicher_dienst: 'Anteil in % Öffentlicher Dienst',
            _ratio_industrie: 'Anteil in % Industrie',
            _ratio_sonstiges: 'Anteil in % Sonstiges',
            _alle_regionen: 'Alle Regionen',
            _region_bw: 'Baden-Württemberg',
            _region_by: 'Bayern',
            _region_be: 'Berlin',
            _region_bb: 'Brandenburg',
            _region_hb: 'Bremen',
            _region_hh: 'Hamburg',
            _region_he: 'Hessen',
            _region_mv: 'Mecklenburg-Vorpommern',
            _region_ni: 'Niedersachsen',
            _region_nw: 'Nordrhein-Westfalen',
            _region_rp: 'Rheinland-Pfalz',
            _region_sl: 'Saarland',
            _region_sn: 'Sachsen',
            _region_sa: 'Sachsen-Anhalt',
            _region_sh: 'Schleswig-Holstein',
            _region_th: 'Thüringen',
            _region_0x: 'PLZ-Bereich 0xxxx',
            _region_1x: 'PLZ-Bereich 1xxxx',
            _region_2x: 'PLZ-Bereich 2xxxx',
            _region_3x: 'PLZ-Bereich 3xxxx',
            _region_4x: 'PLZ-Bereich 4xxxx',
            _region_5x: 'PLZ-Bereich 5xxxx',
            _region_6x: 'PLZ-Bereich 6xxxx',
            _region_7x: 'PLZ-Bereich 7xxxx',
            _region_8x: 'PLZ-Bereich 8xxxx',
            _region_9x: 'PLZ-Bereich 9xxxx',
            _schwerpunkt_apothekenmanagement: 'Apothekenmanagement',
            _schwerpunkt_allgemeine_tiermedizin: 'Allgemeine Tiermedizin',
            _schwerpunkt_alternativmedizin: 'Alternative Tiermedizin',
            _schwerpunkt_fahrpraxis: 'Fahrpraxis',
            _schwerpunkt_bestandsbetreuung: 'Bestandsbetreuung',
            _schwerpunkt_chirurgie: 'Chirurgie',
            _schwerpunkt_gynaekologie: 'Gynäkologie',
            _schwerpunkt_haut_augenerkrankung: 'Behandlung von Haut- und Augenerkrankungen',
            _schwerpunkt_innere_medizin: 'Innere Medizin',
            _schwerpunkt_intensivmedizin: 'Intensivmedizin',
            _schwerpunkt_kardiologie: 'Kardiologie',
            _schwerpunkt_klassische_tiermedizin: 'Klassische Tiermedizin',
            _schwerpunkt_neurologie: 'Neurologie',
            _schwerpunkt_notdienste: 'Geregelte Notdienste',
            _schwerpunkt_onkologie: 'Onkologie',
            _schwerpunkt_operationen_knochen: 'Operationen an Knochen',
            _schwerpunkt_operationen_weichteile: 'Operationen an Weichteilen',
            _schwerpunkt_operationen: 'Operationen',
            _schwerpunkt_op_assistenz: 'OP-Assistenz',
            _schwerpunkt_roentgen: 'Bildgebung',
            _schwerpunkt_sonstiges: 'Sonstiges',
            _schwerpunkt_stationaere_aufnahme: 'Stationäre Aufnahme von Patienten',
            _schwerpunkt_stationaere_betreuung: 'Betreuung stationärer Patienten',
            _schwerpunkt_sprechstunde: 'Führen der Sprechstunde',
            _schwerpunkt_ultraschall: 'Ultraschall',
            _schwerpunkt_untersuchung_laborproben: 'Untersuchung von Laborproben',
            _schwerpunkt_ueberwachung_von_narkose: 'Überwachung von Narkose',
            _schwerpunkt_verhaltenstherapie: 'Verhaltenstherapie',
            _schwerpunkt_veranstaltungsbetreuung: 'Betreuung von Veranstaltungen (z. B. Turniere, Hundeshows)',
            _schwerpunkt_zahn_kieferchirugie: 'Zahn- und Kieferchirurgie',
            _schwerpunkt_zahn_behandlungen: 'Durchführung von Zahnbehandlungen',
            _schwerpunkt_zahnheilkunde: 'Zahnheilkunde',
            _schwerpunkt_nachtdienste: 'Geregelte Nachtdienste',
            _schwerpunkt_nachtdienste_anzahl: 'Nachtdienste pro Monat',
            _schwerpunkt_wochenenddienste: 'Geregelte Wochenenddienste',
            _schwerpunkt_wochenenddienste_anzahl: 'Wochenenddienste pro Monat',
            _schwerpunkt_ophthalmologie: 'Ophthalmologie',
            _schwerpunkt_dermatologie: 'Dermatologie',
            _schwerpunkt_orthopaedie: 'Orthopädie',
            _schwerpunkt_alternative_heilmethoden: 'Alternative Heilmethoden',
            _schwerpunkt_weichteilchirurgie: 'Weichteilchirurgie',
            _schwerpunkt_osteosynthese: 'Osteosynthese',
            _schwerpunkt_arthroskopie: 'Arthroskopie',
            _schwerpunkt_augenheilkunde: 'Augenheilkunde',
            _schwerpunkt_reproduktionsmedizin: 'Reproduktionsmedizin',
            _schwerpunkt_ernaehrung: 'Ernährung',
            _schwerpunkt_notfallmedizin: 'Notfallmedizin',
            _schwerpunkt_endokrinologie: 'Endokrinologie',
            _schwerpunkt: 'Schwerpunkt',
            _seo_description: 'Kurzbeschreibung',
            _seo_hiringorganization_name: 'Anbieter',
            _seo_hiringorganization_sameas: 'URL',
            _seo_joblocation_addresslocality: 'Ort',
            _seo_joblocation_streetaddress: 'Straße',
            _seo_joblocation_addressregion: 'Region',
            _seo_joblocation_postalcode: 'PLZ',
            _seo_joblocation_addresscountry: 'Land',
            _slug: 'Slug',
            _slug_v2: 'Slug',
            _stammdaten_adresse: 'Adresse',
            _stammdaten_anrede: 'Anrede',
            _stammdaten_email: 'E-Mail-Adresse',
            _stammdaten_fax: 'Faxnummer',
            _stammdaten_grussform: 'Grußform',
            _stammdaten_mobil: 'Mobilnummer',
            _stammdaten_nachname: 'Nachname',
            _stammdaten_nr: 'Hausnummer',
            _stammdaten_ort: 'Ort',
            _stammdaten_plz: 'PLZ',
            _stammdaten_strasse: 'Straße',
            _stammdaten_telefon: 'Telefonnummer',
            _stammdaten_titel: 'Titel',
            _stammdaten_vorname: 'Vorname',
            _stammdaten_praxisname: 'Praxisname oder Name des Arbeitgebers',
            _stammdaten_ansprechpartner: 'Ansprechpartner',
            _stammdaten_iso3166_1: 'Landescode',
            _stammdaten_iso3166_2: 'Landescode erweitert',
            _standort: 'Standort',
            _standort_beschreibung: 'Standortbeschreibung',
            _standort_distance: 'Offer._standort_distance',
            _standort_einwohnerzahl: 'Einwohnerzahl',
            _standort_anzahl_parkplatz: 'Anzahl Parkplätze',
            _standort_entfernung_bahnhof: 'Entfernung in km zum Bahnhof',
            _standort_entfernung_bushaltestelle: 'Entfernung in km zur Bushaltestelle',
            _standort_entfernung_einkauf: 'Entfernung in km zu Einkaufsmöglichkeit',
            _standort_entfernung_schule: 'Entfernung in km zu weiterbildende Schule',
            _standort_entfernung_grundschule: 'Entfernung in km zu Grundschule',
            _standort_entfernung_kindergarten: 'Entfernung in km zu Kindergarten',
            _standort_hat_bahnhof: 'Bahnhof in der Nähe',
            _standort_hat_bushaltestelle: 'Bushaltestelle in der Nähe',
            _standort_hat_einkauf: 'Einkaufsmöglichkeit in der Nähe',
            _standort_hat_parkplatz: 'Parkplatz in der Nähe',
            _standort_hat_schule: 'Weiterbildende Schule in der Nähe',
            _standort_hat_grundschule: 'Grundschule in der Nähe',
            _standort_hat_kindergarten: 'Kindergarten in der Nähe',
            _standort_hat_mitarbeiterparkplatz: 'Es stehen Mitarbeiterparkplätze zur Verfügung',
            _standort_hat_parkmoeglichkeiten: 'Es gibt ausreichende Parkmöglichkeiten in unmittelbarer Umgebung',
            _standort_hat_oeffentliche_anbindung: 'Mit öffentlichem Nahverkehr problemlos erreichbar',
            _standort_immobilie: 'Eigene Immobilie',
            _standort_immobilie_inklusive: 'Immobilie inklusiv',
            _standort_geolocated: 'Geolokalisiert',
            _standort_mietzins: 'Mietzins',
            _standort_lat: 'Breitengrad',
            _standort_lng: 'Längengrad',
            _standort_land: 'Land',
            _standort_plz: 'PLZ',
            _standort_region: 'Region',
            _stellenart_assistenzstelle: 'Assistenzstelle',
            _stellenart_praktikum: 'Praktikum in Deutschland',
            _stellenart_praxisvertreter: 'Praxisvertretung',
            _stellenart_tfa: 'Stelle als TFA (m/w/d)',
            _stellenart_praktikum_ausland: 'Praktikum im Ausland',
            _stellenart_selbststaendig: 'Selbständigkeit',
            _stellenart_teilhaber: 'Teilhaberschaft',
            _stellenart_tierarzt: 'Stelle als angestellter Tierarzt (m/w/d)',
            _stellenart_tfa_ausbildung: 'TFA-Ausbildung',
            _stellenart_dissertation: 'Dissertation',
            _stellenart_sonstiges: 'Sonstiges',
            _stellenart_is_empfehlung: 'Offer._stellenart_is_empfehlung',
            _subtitle: 'Zweite Überschrift',
            _taetigkeit_ort: 'Ausübungsort der Tätigkeit',
            _taetigkeit_nur_vor_ort: 'Tätigkeit ausschließlich vor Ort',
            _taetigkeit_nur_dezentral: 'Tätigkeit ausschließlich dezentral',
            _taetigkeit_ort_kombiniert: 'Tätigkeitsort kombiniert',
            _title: 'Überschrift',
            _umfang_vollzeit: 'Vollzeit',
            _umfang_teilzeit: 'Teilzeit',
            _umfang_nebenbeschaeftigung: 'Nebenbeschäftigung',
            _vormittag_dienstag: 'Dienstag vormittag',
            _vormittag_donnerstag: 'Donnerstag vormittag',
            _vormittag_freitag: 'Freitag vormittag',
            _vormittag_mittwoch: 'Mittwoch vormittag',
            _vormittag_montag: 'Montag vormittag',
            _vormittag_samstag: 'Samstag vormittag',
            _voraussetzungen_deutsche_approbation_tierarzt: 'Deutsche Approbation als Tierarzt (m/w/d)',
            _voraussetzungen_min_berufserfahrungen: 'Berufserfahrung',
            _voraussetzungen_berufserfahrungen_in_jahren: 'Berufserfahrung in Jahren',
            _voraussetzungen_min_semester: 'Mindestens Anzahl Semester',
            _voraussetzungen_semester_anzahl: 'Semesteranzahl',
            _voraussetzungen_bereits_absolviertes_internship: 'Bereits absolviertes Internship',
            _voraussetzungen_hat_titel: 'FTA-Titel, Diplomate bzw. Zusatzbezeichnung',
            _voraussetzungen_title_zusatzbezeichnung: 'Titel und/oder Zusatzbezeichnung',
            _voraussetzungen_notdienst_bereitschaft: 'Bereitschaft an geregelten Notdiensten teilzunehmen',
            _voraussetzungen_belastbarkeit: 'Belastbarkeit',
            _voraussetzungen_empathischer_umgang: 'Empathischer Umgang mit Patienten und Patientenbesitzern',
            _voraussetzungen_selbststaendiges_arbeiten: 'Selbstständiges Arbeiten',
            _voraussetzungen_teamfaehigkeit: 'Teamfähigkeit',
            _voraussetzungen_fuehrerschein: 'Führerschein Klasse B',
            _voraussetzungen_eigenes_auto: 'Eigenes Auto',
            _voraussetzungen_puenktlichkeit: 'Pünktlichkeit',
            _voraussetzungen_zuverlaessigkeit: 'Zuverlässigkeit',
            _voraussetzungen_interesse_an_weiterbildung: 'Ausgeprägtes Interesse an Fort- und Weiterbildung',
            _voraussetzungen_kollegialer_umgang: 'Kollegialer Umgang',
            _wunschort1_location_address: 'Adresse',
            _wunschort1_location: '1. Wunschort',
            _wunschort2_location_address: 'Adresse',
            _wunschort2_location: '2. Wunschort',
            _wunschort3_location_address: 'Adresse',
            _wunschort3_location: '3. Wunschort',
            _wunschort_flexibel: 'Flexibel',
            _wunschorte: 'Wunschorte',
            _wochenstunden: 'Wochenstunden',
            _wochentag_montag: 'Montag',
            _wochentag_dienstag: 'Dienstag',
            _wochentag_mittwoch: 'Mittwoch',
            _wochentag_donnerstag: 'Donnerstag',
            _wochentag_freitag: 'Freitag',
            _wochentag_samstag: 'Samstag',
            _wochentag_sonntag: 'Sonntag',
            _zusatzinfos: 'Zusatzinfos',
            _erreichbarkeit_uhrzeit: 'Erreichbarkeit',

        }
    }

    public uuid: string;
    public user_uuid: string;
    public offer_type: number;
    public create_date: string;
    public modify_date: string;
    public delete_date: string;
    public expire_date: string;
    public last_mail_date: string;
    public opt_in_date: string;
    public opt_out_date: string;
    public import_id: number;
    public is_public: boolean;
    public is_intern: boolean;
    public is_opted_in: boolean;
    public need_payment: boolean;
    public _chiffre: string;
    public _admin_status: string;
    public _admin_is_public: boolean;
    public _admin_is_sold: boolean;
    public _admin_is_deleted: boolean;
    public _admin_is_reserved: boolean;
    public _admin_is_upper_class: boolean;
    public _admin_tvd_ansprechpartner: string;
    public _admin_zusatzinfos: string;
    public _arbeitsantritt: string;
    public _arbeitsantritt_monate: string;
    public _arbeitsumfang: string;
    public _arbeitsumfang_teilzeit: string;
    public _arbeitsumfang_vollzeit: string;
    public _arbeitsumfang_dauer: string;
    public _arbeitsumfang_dauer_min_wochen: number;
    public _arbeitsumfang_dauer_max_wochen: number;
    public _arbeitsumfang_dauer_sonstiges: string;
    public _arbeitsumfang_nebenbeschaeftigung: string;
    public _arbeitszeit_montag: string;
    public _arbeitszeit_dienstag: string;
    public _arbeitszeit_mittwoch: string;
    public _arbeitszeit_donnerstag: string;
    public _arbeitszeit_freitag: string;
    public _arbeitszeit_samstag: string;
    public _arbeitszeit_sonntag: string;
    public _ausstattung_autoclav: boolean;
    public _ausstattung_behandlungstisch: boolean;
    public _ausstattung_blutanalyse: boolean;
    public _ausstattung_boxen: boolean;
    public _ausstattung_computer_drucker: boolean;
    public _ausstattung_endoskop: boolean;
    public _ausstattung_gefriertruhe: boolean;
    public _ausstattung_inhouse_labor: boolean;
    public _ausstattung_klimaanlage: boolean;
    public _ausstattung_kuehlschrank: boolean;
    public _ausstattung_mikroskop: boolean;
    public _ausstattung_narkosegeraet: boolean;
    public _ausstattung_op_besteck: boolean;
    public _ausstattung_op_lampen: boolean;
    public _ausstattung_osteosynthese_set: boolean;
    public _ausstattung_otoskop: boolean;
    public _ausstattung_roentgen_analog: boolean;
    public _ausstattung_roentgen_digital: boolean;
    public _ausstattung_sonstiges: boolean;
    public _ausstattung_telefonanlagen: boolean;
    public _ausstattung_tierwaage: boolean;
    public _ausstattung_trockner: boolean;
    public _ausstattung_ultraschall: boolean;
    public _ausstattung_herzultraschall: boolean;
    public _ausstattung_waschmaschine: boolean;
    public _ausstattung_zahnsteineinheit: boolean;
    public _ausstattung_szintigraph: boolean;
    public _ausstattung_station: boolean;
    public _ausstattung_getrennter_wartebereich: boolean;
    public _ausstattung_software_zur_praxisverwaltung: boolean;
    public _ausstattung_crm: boolean;
    public _ausstattung_ct: boolean;
    public _ausstattung_mrt: boolean;
    public _ausstattung: string;
    public _ausgleich_notdienst: string;
    public _behandlung_op_mit_narkose: boolean;
    public _behandlung_ernaehrungsberatung: boolean;
    public _behandlung_osteosynthese: boolean;
    public _behandlung_stationaer: boolean;
    public _behandlung_osteopathie: boolean;
    public _behandlung_physiotherapie: boolean;
    public _behandlung_chiropraktik: boolean;
    public _behandlung_akupunktur: boolean;
    public _behandlung_herzultraschall: boolean;
    public _behandlung_ultraschall: boolean;
    public _behandlung_digitales_roentgen: boolean;
    public _behandlung_mobiles_roentgen: boolean;
    public _behandlung_zahnbehandlungen_mobil: boolean;
    public _behandlung_dentalroentgen: boolean;
    public _behandlung_inhouse_labor: boolean;
    public _behandlung_sonstiges: boolean;
    public _behandlung: string;
    public _behandlung_ct: boolean;
    public _behandlung_mrt: boolean;
    public _behandlung_szintigraphie: boolean;
    public _behandlung_endoskopie: boolean;
    public _benoetigte_unterlagen: string;
    public _benoetigte_bewerber_voraussetzungen: string;
    public _beschreibung_text: string;
    public _beschreibung_titel: string;
    public _besonderheit: string;
    public _besonderheit_sonstiges: boolean;
    public _besonderheit_cat_friendly: boolean;
    public _besonderheit_mitnahme_hund: boolean;
    public _besonderheit_moegl_doktorarbeit: boolean;
    public _besonderheit_moegl_teilhaberschaft: boolean;
    public _besonderheit_praktikumsverguetung: boolean;
    public _besonderheit_bez_fortbildung: boolean;
    public _besonderheit_wohnen_vor_ort: boolean;
    public _besonderheit_kein_nachtdienst: boolean;
    public _besonderheit_bkv: boolean;
    public _besonderheit_bav: boolean;
    public _besonderheit_eigenverantwortung: boolean;
    public _besonderheit_gehaltsmodell: boolean;
    public _besonderheit_gute_einarbeitung: boolean;
    public _besonderheit_gutes_team: boolean;
    public _besonderheit_familiaere: boolean;
    public _besonderheit_hohes_niveau: boolean;
    public _besonderheit_moderne_ausstattung: boolean;
    public _besonderheit_feedbackgespraeche: boolean;
    public _besonderheit_arbeitszeiterfassung: boolean;
    public _besonderheit_bezahlte_fortbildung: boolean;
    public _besonderheit_interne_fortbildungen: boolean;
    public _besonderheit_weihnachtsgeld: boolean;
    public _besonderheit_urlaubsgeld: boolean;
    public _besonderheit_dienstwagen: boolean;
    public _besonderheit_dienstwagen_privat: boolean;
    public _besonderheit_mittagessen_kostenlos: boolean;
    public _besonderheit_rotation_in_bereiche: boolean;
    public _besonderheit_sportangebot: boolean;
    public _besonderheit_teamevents: boolean;
    public _besonderheit_mitglied_vuk: boolean;
    public _besonderheit_praktikumsleitfaden: boolean;
    public _besonderheit_notdienstring: boolean;
    public _besonderheit_kein_notdienst: boolean;
    public _besonderheit_gvp_zertifiziert: boolean;
    public _besonderheit_praktikum_basics: boolean;
    public _besonderheit_vwl: boolean;
    public _besonderheit_fahrtkostenzuschuss: boolean;
    public _besonderheit_keine_nacht_und_wochenenddienste: boolean;
    public _besonderheit_nacht_und_wochenenddienste_auf_wunsch: boolean;
    public _besonderheit_nacht_und_wochenenddienste_geregelt: boolean;
    public _besonderheit_berufseinsteiger_willkommen: boolean;
    public _bezahlte_stelle: boolean;
    public _an_stelle_gekoppelt: boolean;
    public _datenschutz_akzeptiert: boolean;
    public _einrichtung_sonstiges: boolean;
    public _einrichtung_tierklinik: boolean;
    public _einrichtung_tierarztpraxis: boolean;
    public _einrichtung_fahrpraxis: boolean;
    public _einrichtung_fachtierarztpraxis: boolean;
    public _einrichtung_industrie: boolean;
    public _einrichtung_oeffentlicher_dienst: boolean;
    public _einrichtung_hochschule: boolean;
    public _einrichtung: string;
    public _externes_medium1: string;
    public _externes_medium2: string;
    public _externes_medium3: string;
    public _facharzt_abschluesse: number;
    public _facharzt_ausbildung: boolean;
    public _facharzt_abschluss1: string;
    public _facharzt_abschluss2: string;
    public _facharzt_abschluss3: string;
    public _feedback_gefunden_ueber: string;
    public _feedback_gefunden_ueber_tvd: boolean;
    public _feedback_gefunden_ueber_empfehlung: boolean;
    public _feedback_gefunden_ueber_google: boolean;
    public _feedback_gefunden_ueber_newsletter: boolean;
    public _feedback_gefunden_ueber_facebook: boolean;
    public _feedback_gefunden_ueber_instagram: boolean;
    public _feedback_gefunden_ueber_sonstiges: boolean;
    public _fortbildungsbudget: number;
    public _fortbildungsbudget_vorhanden: boolean;
    public _fortbildungsbudget_nach_vereinbarung: boolean;
    public _urlaubstage: string;
    public _fortbildungstage: string;
    public _fortbildungstage_nach_vereinbarung: boolean;
    public _fortbildung_regelm_intern: boolean;
    public _fortbildung_freie_tage: boolean;
    public _geplante_dauer: string;
    public _homepage: string;
    public _icon_name: string;
    public _infotext: string;
    public _lebensphase: string;
    public _mitarbeiter_assistenten: number;
    public _mitarbeiter_auszubildende: number;
    public _mitarbeiter_in_mutterschutz: number;
    public _mitarbeiter_minijobs: number;
    public _mitarbeiter_tfa: number;
    public _mitarbeiter_ta: number;
    public _mitarbeiter_sonstige: number;
    public _nachmittag_dienstag: boolean;
    public _nachmittag_donnerstag: boolean;
    public _nachmittag_freitag: boolean;
    public _nachmittag_mittwoch: boolean;
    public _nachmittag_montag: boolean;
    public _nachmittag_samstag: boolean;
    public _nachricht: string;
    public _parallele_anstellung: boolean;
    public _praxis_abgabe_in: string;
    public _praxis_apothekenbestand: number;
    public _praxis_art: string;
    public _praxis_art_sonstiges: boolean;
    public _praxis_art_kleintier: boolean;
    public _praxis_art_pferde: boolean;
    public _praxis_art_gemischt: boolean;
    public _praxis_art_rinder: boolean;
    public _praxis_art_gefluegel: boolean;
    public _praxis_art_schwein: boolean;
    public _praxis_art_reptilien: boolean;
    public _praxis_art_industrie: boolean;
    public _praxis_art_verwaltung: boolean;
    public _praxis_art_universitaet: boolean;
    public _praxis_art_heimtiere: boolean;
    public _praxis_art_hochschule: boolean;
    public _praxis_art_oeffentlicher_dienst: boolean;
    public _praxis_art_wildtiere: boolean;
    public _praxis_art_ziervoegel: boolean;
    public _praxis_hat_preisvorstellung: boolean;
    public _praxis_fachliche_spezialisierung: string;
    public _praxis_form: string;
    public _praxis_flaeche: number;
    public _praxis_fortbildung: boolean;
    public _praxis_fortbildung_angebot: string;
    public _praxis_gruendungsjahr: string;
    public _praxis_hat_notdienst: boolean;
    public _praxis_notdienst: string;
    public _praxis_notdienst_auszahlung: boolean;
    public _praxis_notdienst_freizeitausgleich: boolean;
    public _praxis_sprechstunde: string;
    public _praxis_hat_terminsprechstunde: boolean;
    public _praxis_hat_vertretungsregelung: boolean;
    public _praxis_jahresnettoumsatz_2015: number;
    public _praxis_jahresnettoumsatz_2016: number;
    public _praxis_jahresnettoumsatz_2017: number;
    public _praxis_jahresnettoumsatz_2018: number;
    public _praxis_jahresnettoumsatz_2019: number;
    public _praxis_jahresnettoumsatz_2020: number;
    public _praxis_jahresnettoumsatz_2021: number;
    public _praxis_jahresnettoumsatz_2022: number;
    public _praxis_kurzbeschreibung: string;
    public _praxis_materieller_wert: number;
    public _praxis_patientenbesitzer: number;
    public _praxis_preisvorstellung: number;
    public _praxis_sonstige_beschreibung: string;
    public _praxis_telefon_homepage_uebernahme: boolean;
    public _praxis_uebergabedatum: string;
    public _praxis_uebernahme_in: string;
    public _praxis_uebernahme: boolean;
    public _praxis_teilhaber: boolean;
    public _praxis_ueberschrift: string;
    public _praxis_zulassungsstatus: string;
    public _praxis_sprache_1: string;
    public _praxis_sprache_2: string;
    public _raeume_apotheke: number;
    public _raeume_behandlung1: number;
    public _raeume_behandlung2: number;
    public _raeume_empfang: number;
    public _raeume_labor: number;
    public _raeume_op: number;
    public _raeume_op_vorb: number;
    public _raeume_roentgen: number;
    public _raeume_sonstige: string;
    public _raeume_sozial: number;
    public _raeume_station: number;
    public _raeume_warten: number;
    public _raeume_wc: number;
    public _raeume_mitarbeiter: number;
    public _raeume_vorhanden_apotheke: boolean;
    public _raeume_vorhanden_behandlung1: boolean;
    public _raeume_vorhanden_behandlung2: boolean;
    public _raeume_vorhanden_empfang: boolean;
    public _raeume_vorhanden_labor: boolean;
    public _raeume_vorhanden_op: boolean;
    public _raeume_vorhanden_op_vorb: boolean;
    public _raeume_vorhanden_roentgen: boolean;
    public _raeume_vorhanden_sozial: boolean;
    public _raeume_vorhanden_station: boolean;
    public _raeume_vorhanden_warten: boolean;
    public _raeume_vorhanden_wc: boolean;
    public _raeume_vorhanden_sonstige: boolean;
    public _raeume_vorhanden_mitarbeiter: boolean;
    public _ratio_exoten: number;
    public _ratio_kleintiere: number;
    public _ratio_nutztiere: number;
    public _ratio_pferde: number;
    public _ratio_rinder: number;
    public _ratio_schweine: number;
    public _ratio_gefluegel: number;
    public _ratio_heimtiere: number;
    public _ratio_reptilien: number;
    public _ratio_oeffentlicher_dienst: number;
    public _ratio_industrie: number;
    public _ratio_sonstiges: number;
    public _alle_regionen: boolean;
    public _region_bw: boolean;
    public _region_by: boolean;
    public _region_be: boolean;
    public _region_bb: boolean;
    public _region_hb: boolean;
    public _region_hh: boolean;
    public _region_he: boolean;
    public _region_mv: boolean;
    public _region_ni: boolean;
    public _region_nw: boolean;
    public _region_rp: boolean;
    public _region_sl: boolean;
    public _region_sn: boolean;
    public _region_sa: boolean;
    public _region_sh: boolean;
    public _region_th: boolean;
    public _region_0x: boolean;
    public _region_1x: boolean;
    public _region_2x: boolean;
    public _region_3x: boolean;
    public _region_4x: boolean;
    public _region_5x: boolean;
    public _region_6x: boolean;
    public _region_7x: boolean;
    public _region_8x: boolean;
    public _region_9x: boolean;
    public _schwerpunkt_apothekenmanagement: boolean;
    public _schwerpunkt_allgemeine_tiermedizin: boolean;
    public _schwerpunkt_alternativmedizin: boolean;
    public _schwerpunkt_fahrpraxis: boolean;
    public _schwerpunkt_bestandsbetreuung: boolean;
    public _schwerpunkt_chirurgie: boolean;
    public _schwerpunkt_gynaekologie: boolean;
    public _schwerpunkt_haut_augenerkrankung: boolean;
    public _schwerpunkt_innere_medizin: boolean;
    public _schwerpunkt_intensivmedizin: boolean;
    public _schwerpunkt_kardiologie: boolean;
    public _schwerpunkt_klassische_tiermedizin: boolean;
    public _schwerpunkt_neurologie: boolean;
    public _schwerpunkt_notdienste: boolean;
    public _schwerpunkt_onkologie: boolean;
    public _schwerpunkt_operationen_knochen: boolean;
    public _schwerpunkt_operationen_weichteile: boolean;
    public _schwerpunkt_operationen: boolean;
    public _schwerpunkt_op_assistenz: boolean;
    public _schwerpunkt_roentgen: boolean;
    public _schwerpunkt_sonstiges: boolean;
    public _schwerpunkt_stationaere_aufnahme: boolean;
    public _schwerpunkt_stationaere_betreuung: boolean;
    public _schwerpunkt_sprechstunde: boolean;
    public _schwerpunkt_ultraschall: boolean;
    public _schwerpunkt_untersuchung_laborproben: boolean;
    public _schwerpunkt_ueberwachung_von_narkose: boolean;
    public _schwerpunkt_verhaltenstherapie: boolean;
    public _schwerpunkt_veranstaltungsbetreuung: boolean;
    public _schwerpunkt_zahn_kieferchirugie: boolean;
    public _schwerpunkt_zahn_behandlungen: boolean;
    public _schwerpunkt_zahnheilkunde: boolean;
    public _schwerpunkt_nachtdienste: boolean;
    public _schwerpunkt_nachtdienste_anzahl: number;
    public _schwerpunkt_wochenenddienste: boolean;
    public _schwerpunkt_wochenenddienste_anzahl: number;
    public _schwerpunkt_ophthalmologie: boolean;
    public _schwerpunkt_dermatologie: boolean;
    public _schwerpunkt_orthopaedie: boolean;
    public _schwerpunkt_alternative_heilmethoden: boolean;
    public _schwerpunkt_weichteilchirurgie: boolean;
    public _schwerpunkt_osteosynthese: boolean;
    public _schwerpunkt_arthroskopie: boolean;
    public _schwerpunkt_augenheilkunde: boolean;
    public _schwerpunkt_reproduktionsmedizin: boolean;
    public _schwerpunkt_ernaehrung: boolean;
    public _schwerpunkt_notfallmedizin: boolean;
    public _schwerpunkt_endokrinologie: boolean;
    public _schwerpunkt: string;
    public _seo_description: string;
    public _seo_hiringorganization_name: string;
    public _seo_hiringorganization_sameas: string;
    public _seo_joblocation_addresslocality: string;
    public _seo_joblocation_streetaddress: string;
    public _seo_joblocation_addressregion: string;
    public _seo_joblocation_postalcode: string;
    public _seo_joblocation_addresscountry: string;
    public _slug: string;
    public _slug_v2: string;
    public _stammdaten_adresse: string;
    public _stammdaten_anrede: string;
    public _stammdaten_email: string;
    public _stammdaten_fax: string;
    public _stammdaten_grussform: string;
    public _stammdaten_mobil: string;
    public _stammdaten_nachname: string;
    public _stammdaten_nr: string;
    public _stammdaten_ort: string;
    public _stammdaten_plz: string;
    public _stammdaten_strasse: string;
    public _stammdaten_telefon: string;
    public _stammdaten_titel: string;
    public _stammdaten_vorname: string;
    public _stammdaten_praxisname: string;
    public _stammdaten_ansprechpartner: string;
    public _stammdaten_iso3166_1: string;
    public _stammdaten_iso3166_2: string;
    public _standort: string;
    public _standort_beschreibung: string;
    public _standort_distance: number;
    public _standort_einwohnerzahl: number;
    public _standort_anzahl_parkplatz: number;
    public _standort_entfernung_bahnhof: number;
    public _standort_entfernung_bushaltestelle: number;
    public _standort_entfernung_einkauf: number;
    public _standort_entfernung_schule: number;
    public _standort_entfernung_grundschule: number;
    public _standort_entfernung_kindergarten: number;
    public _standort_hat_bahnhof: boolean;
    public _standort_hat_bushaltestelle: boolean;
    public _standort_hat_einkauf: boolean;
    public _standort_hat_parkplatz: boolean;
    public _standort_hat_schule: boolean;
    public _standort_hat_grundschule: boolean;
    public _standort_hat_kindergarten: boolean;
    public _standort_hat_mitarbeiterparkplatz: boolean;
    public _standort_hat_parkmoeglichkeiten: boolean;
    public _standort_hat_oeffentliche_anbindung: boolean;
    public _standort_immobilie: string;
    public _standort_immobilie_inklusive: boolean;
    public _standort_geolocated: boolean;
    public _standort_mietzins: number;
    public _standort_lat: number;
    public _standort_lng: number;
    public _standort_land: string;
    public _standort_plz: string;
    public _standort_region: string;
    public _stellenart_assistenzstelle: boolean;
    public _stellenart_praktikum: boolean;
    public _stellenart_praxisvertreter: boolean;
    public _stellenart_tfa: boolean;
    public _stellenart_praktikum_ausland: boolean;
    public _stellenart_selbststaendig: boolean;
    public _stellenart_teilhaber: boolean;
    public _stellenart_tierarzt: boolean;
    public _stellenart_tfa_ausbildung: boolean;
    public _stellenart_dissertation: boolean;
    public _stellenart_sonstiges: boolean;
    public _stellenart_is_empfehlung: boolean;
    public _subtitle: string;
    public _taetigkeit_ort: string;
    public _taetigkeit_nur_vor_ort: boolean;
    public _taetigkeit_nur_dezentral: boolean;
    public _taetigkeit_ort_kombiniert: boolean;
    public _title: string;
    public _umfang_vollzeit: boolean;
    public _umfang_teilzeit: boolean;
    public _umfang_nebenbeschaeftigung: boolean;
    public _vormittag_dienstag: boolean;
    public _vormittag_donnerstag: boolean;
    public _vormittag_freitag: boolean;
    public _vormittag_mittwoch: boolean;
    public _vormittag_montag: boolean;
    public _vormittag_samstag: boolean;
    public _voraussetzungen_deutsche_approbation_tierarzt: boolean;
    public _voraussetzungen_min_berufserfahrungen: boolean;
    public _voraussetzungen_berufserfahrungen_in_jahren: number;
    public _voraussetzungen_min_semester: boolean;
    public _voraussetzungen_semester_anzahl: number;
    public _voraussetzungen_bereits_absolviertes_internship: boolean;
    public _voraussetzungen_hat_titel: boolean;
    public _voraussetzungen_title_zusatzbezeichnung: boolean;
    public _voraussetzungen_notdienst_bereitschaft: boolean;
    public _voraussetzungen_belastbarkeit: boolean;
    public _voraussetzungen_empathischer_umgang: boolean;
    public _voraussetzungen_selbststaendiges_arbeiten: boolean;
    public _voraussetzungen_teamfaehigkeit: boolean;
    public _voraussetzungen_fuehrerschein: boolean;
    public _voraussetzungen_eigenes_auto: boolean;
    public _voraussetzungen_puenktlichkeit: boolean;
    public _voraussetzungen_zuverlaessigkeit: boolean;
    public _voraussetzungen_interesse_an_weiterbildung: boolean;
    public _voraussetzungen_kollegialer_umgang: boolean;
    public _wunschort1_location_address: string;
    public _wunschort1_location: string;
    public _wunschort2_location_address: string;
    public _wunschort2_location: string;
    public _wunschort3_location_address: string;
    public _wunschort3_location: string;
    public _wunschort_flexibel: boolean;
    public _wunschorte: number;
    public _wochenstunden: string;
    public _wochentag_montag: boolean;
    public _wochentag_dienstag: boolean;
    public _wochentag_mittwoch: boolean;
    public _wochentag_donnerstag: boolean;
    public _wochentag_freitag: boolean;
    public _wochentag_samstag: boolean;
    public _wochentag_sonntag: boolean;
    public _zusatzinfos: string;
    public _erreichbarkeit_uhrzeit: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.uuid = data.uuid;
            this.user_uuid = data.user_uuid;
            this.offer_type = data.offer_type;
            this.create_date = data.create_date;
            this.modify_date = data.modify_date;
            this.delete_date = data.delete_date;
            this.expire_date = data.expire_date;
            this.last_mail_date = data.last_mail_date;
            this.opt_in_date = data.opt_in_date;
            this.opt_out_date = data.opt_out_date;
            this.import_id = data.import_id;
            this.is_public = data.is_public;
            this.is_intern = data.is_intern;
            this.is_opted_in = data.is_opted_in;
            this.need_payment = data.need_payment;
            this._chiffre = data._chiffre;
            this._admin_status = data._admin_status;
            this._admin_is_public = data._admin_is_public;
            this._admin_is_sold = data._admin_is_sold;
            this._admin_is_deleted = data._admin_is_deleted;
            this._admin_is_reserved = data._admin_is_reserved;
            this._admin_is_upper_class = data._admin_is_upper_class;
            this._admin_tvd_ansprechpartner = data._admin_tvd_ansprechpartner;
            this._admin_zusatzinfos = data._admin_zusatzinfos;
            this._arbeitsantritt = data._arbeitsantritt;
            this._arbeitsantritt_monate = data._arbeitsantritt_monate;
            this._arbeitsumfang = data._arbeitsumfang;
            this._arbeitsumfang_teilzeit = data._arbeitsumfang_teilzeit;
            this._arbeitsumfang_vollzeit = data._arbeitsumfang_vollzeit;
            this._arbeitsumfang_dauer = data._arbeitsumfang_dauer;
            this._arbeitsumfang_dauer_min_wochen = data._arbeitsumfang_dauer_min_wochen;
            this._arbeitsumfang_dauer_max_wochen = data._arbeitsumfang_dauer_max_wochen;
            this._arbeitsumfang_dauer_sonstiges = data._arbeitsumfang_dauer_sonstiges;
            this._arbeitsumfang_nebenbeschaeftigung = data._arbeitsumfang_nebenbeschaeftigung;
            this._arbeitszeit_montag = data._arbeitszeit_montag;
            this._arbeitszeit_dienstag = data._arbeitszeit_dienstag;
            this._arbeitszeit_mittwoch = data._arbeitszeit_mittwoch;
            this._arbeitszeit_donnerstag = data._arbeitszeit_donnerstag;
            this._arbeitszeit_freitag = data._arbeitszeit_freitag;
            this._arbeitszeit_samstag = data._arbeitszeit_samstag;
            this._arbeitszeit_sonntag = data._arbeitszeit_sonntag;
            this._ausstattung_autoclav = data._ausstattung_autoclav;
            this._ausstattung_behandlungstisch = data._ausstattung_behandlungstisch;
            this._ausstattung_blutanalyse = data._ausstattung_blutanalyse;
            this._ausstattung_boxen = data._ausstattung_boxen;
            this._ausstattung_computer_drucker = data._ausstattung_computer_drucker;
            this._ausstattung_endoskop = data._ausstattung_endoskop;
            this._ausstattung_gefriertruhe = data._ausstattung_gefriertruhe;
            this._ausstattung_inhouse_labor = data._ausstattung_inhouse_labor;
            this._ausstattung_klimaanlage = data._ausstattung_klimaanlage;
            this._ausstattung_kuehlschrank = data._ausstattung_kuehlschrank;
            this._ausstattung_mikroskop = data._ausstattung_mikroskop;
            this._ausstattung_narkosegeraet = data._ausstattung_narkosegeraet;
            this._ausstattung_op_besteck = data._ausstattung_op_besteck;
            this._ausstattung_op_lampen = data._ausstattung_op_lampen;
            this._ausstattung_osteosynthese_set = data._ausstattung_osteosynthese_set;
            this._ausstattung_otoskop = data._ausstattung_otoskop;
            this._ausstattung_roentgen_analog = data._ausstattung_roentgen_analog;
            this._ausstattung_roentgen_digital = data._ausstattung_roentgen_digital;
            this._ausstattung_sonstiges = data._ausstattung_sonstiges;
            this._ausstattung_telefonanlagen = data._ausstattung_telefonanlagen;
            this._ausstattung_tierwaage = data._ausstattung_tierwaage;
            this._ausstattung_trockner = data._ausstattung_trockner;
            this._ausstattung_ultraschall = data._ausstattung_ultraschall;
            this._ausstattung_herzultraschall = data._ausstattung_herzultraschall;
            this._ausstattung_waschmaschine = data._ausstattung_waschmaschine;
            this._ausstattung_zahnsteineinheit = data._ausstattung_zahnsteineinheit;
            this._ausstattung_szintigraph = data._ausstattung_szintigraph;
            this._ausstattung_station = data._ausstattung_station;
            this._ausstattung_getrennter_wartebereich = data._ausstattung_getrennter_wartebereich;
            this._ausstattung_software_zur_praxisverwaltung = data._ausstattung_software_zur_praxisverwaltung;
            this._ausstattung_crm = data._ausstattung_crm;
            this._ausstattung_ct = data._ausstattung_ct;
            this._ausstattung_mrt = data._ausstattung_mrt;
            this._ausstattung = data._ausstattung;
            this._ausgleich_notdienst = data._ausgleich_notdienst;
            this._behandlung_op_mit_narkose = data._behandlung_op_mit_narkose;
            this._behandlung_ernaehrungsberatung = data._behandlung_ernaehrungsberatung;
            this._behandlung_osteosynthese = data._behandlung_osteosynthese;
            this._behandlung_stationaer = data._behandlung_stationaer;
            this._behandlung_osteopathie = data._behandlung_osteopathie;
            this._behandlung_physiotherapie = data._behandlung_physiotherapie;
            this._behandlung_chiropraktik = data._behandlung_chiropraktik;
            this._behandlung_akupunktur = data._behandlung_akupunktur;
            this._behandlung_herzultraschall = data._behandlung_herzultraschall;
            this._behandlung_ultraschall = data._behandlung_ultraschall;
            this._behandlung_digitales_roentgen = data._behandlung_digitales_roentgen;
            this._behandlung_mobiles_roentgen = data._behandlung_mobiles_roentgen;
            this._behandlung_zahnbehandlungen_mobil = data._behandlung_zahnbehandlungen_mobil;
            this._behandlung_dentalroentgen = data._behandlung_dentalroentgen;
            this._behandlung_inhouse_labor = data._behandlung_inhouse_labor;
            this._behandlung_sonstiges = data._behandlung_sonstiges;
            this._behandlung = data._behandlung;
            this._behandlung_ct = data._behandlung_ct;
            this._behandlung_mrt = data._behandlung_mrt;
            this._behandlung_szintigraphie = data._behandlung_szintigraphie;
            this._behandlung_endoskopie = data._behandlung_endoskopie;
            this._benoetigte_unterlagen = data._benoetigte_unterlagen;
            this._benoetigte_bewerber_voraussetzungen = data._benoetigte_bewerber_voraussetzungen;
            this._beschreibung_text = data._beschreibung_text;
            this._beschreibung_titel = data._beschreibung_titel;
            this._besonderheit = data._besonderheit;
            this._besonderheit_sonstiges = data._besonderheit_sonstiges;
            this._besonderheit_cat_friendly = data._besonderheit_cat_friendly;
            this._besonderheit_mitnahme_hund = data._besonderheit_mitnahme_hund;
            this._besonderheit_moegl_doktorarbeit = data._besonderheit_moegl_doktorarbeit;
            this._besonderheit_moegl_teilhaberschaft = data._besonderheit_moegl_teilhaberschaft;
            this._besonderheit_praktikumsverguetung = data._besonderheit_praktikumsverguetung;
            this._besonderheit_bez_fortbildung = data._besonderheit_bez_fortbildung;
            this._besonderheit_wohnen_vor_ort = data._besonderheit_wohnen_vor_ort;
            this._besonderheit_kein_nachtdienst = data._besonderheit_kein_nachtdienst;
            this._besonderheit_bkv = data._besonderheit_bkv;
            this._besonderheit_bav = data._besonderheit_bav;
            this._besonderheit_eigenverantwortung = data._besonderheit_eigenverantwortung;
            this._besonderheit_gehaltsmodell = data._besonderheit_gehaltsmodell;
            this._besonderheit_gute_einarbeitung = data._besonderheit_gute_einarbeitung;
            this._besonderheit_gutes_team = data._besonderheit_gutes_team;
            this._besonderheit_familiaere = data._besonderheit_familiaere;
            this._besonderheit_hohes_niveau = data._besonderheit_hohes_niveau;
            this._besonderheit_moderne_ausstattung = data._besonderheit_moderne_ausstattung;
            this._besonderheit_feedbackgespraeche = data._besonderheit_feedbackgespraeche;
            this._besonderheit_arbeitszeiterfassung = data._besonderheit_arbeitszeiterfassung;
            this._besonderheit_bezahlte_fortbildung = data._besonderheit_bezahlte_fortbildung;
            this._besonderheit_interne_fortbildungen = data._besonderheit_interne_fortbildungen;
            this._besonderheit_weihnachtsgeld = data._besonderheit_weihnachtsgeld;
            this._besonderheit_urlaubsgeld = data._besonderheit_urlaubsgeld;
            this._besonderheit_dienstwagen = data._besonderheit_dienstwagen;
            this._besonderheit_dienstwagen_privat = data._besonderheit_dienstwagen_privat;
            this._besonderheit_mittagessen_kostenlos = data._besonderheit_mittagessen_kostenlos;
            this._besonderheit_rotation_in_bereiche = data._besonderheit_rotation_in_bereiche;
            this._besonderheit_sportangebot = data._besonderheit_sportangebot;
            this._besonderheit_teamevents = data._besonderheit_teamevents;
            this._besonderheit_mitglied_vuk = data._besonderheit_mitglied_vuk;
            this._besonderheit_praktikumsleitfaden = data._besonderheit_praktikumsleitfaden;
            this._besonderheit_notdienstring = data._besonderheit_notdienstring;
            this._besonderheit_kein_notdienst = data._besonderheit_kein_notdienst;
            this._besonderheit_gvp_zertifiziert = data._besonderheit_gvp_zertifiziert;
            this._besonderheit_praktikum_basics = data._besonderheit_praktikum_basics;
            this._besonderheit_vwl = data._besonderheit_vwl;
            this._besonderheit_fahrtkostenzuschuss = data._besonderheit_fahrtkostenzuschuss;
            this._besonderheit_keine_nacht_und_wochenenddienste = data._besonderheit_keine_nacht_und_wochenenddienste;
            this._besonderheit_nacht_und_wochenenddienste_auf_wunsch = data._besonderheit_nacht_und_wochenenddienste_auf_wunsch;
            this._besonderheit_nacht_und_wochenenddienste_geregelt = data._besonderheit_nacht_und_wochenenddienste_geregelt;
            this._besonderheit_berufseinsteiger_willkommen = data._besonderheit_berufseinsteiger_willkommen;
            this._bezahlte_stelle = data._bezahlte_stelle;
            this._an_stelle_gekoppelt = data._an_stelle_gekoppelt;
            this._datenschutz_akzeptiert = data._datenschutz_akzeptiert;
            this._einrichtung_sonstiges = data._einrichtung_sonstiges;
            this._einrichtung_tierklinik = data._einrichtung_tierklinik;
            this._einrichtung_tierarztpraxis = data._einrichtung_tierarztpraxis;
            this._einrichtung_fahrpraxis = data._einrichtung_fahrpraxis;
            this._einrichtung_fachtierarztpraxis = data._einrichtung_fachtierarztpraxis;
            this._einrichtung_industrie = data._einrichtung_industrie;
            this._einrichtung_oeffentlicher_dienst = data._einrichtung_oeffentlicher_dienst;
            this._einrichtung_hochschule = data._einrichtung_hochschule;
            this._einrichtung = data._einrichtung;
            this._externes_medium1 = data._externes_medium1;
            this._externes_medium2 = data._externes_medium2;
            this._externes_medium3 = data._externes_medium3;
            this._facharzt_abschluesse = data._facharzt_abschluesse;
            this._facharzt_ausbildung = data._facharzt_ausbildung;
            this._facharzt_abschluss1 = data._facharzt_abschluss1;
            this._facharzt_abschluss2 = data._facharzt_abschluss2;
            this._facharzt_abschluss3 = data._facharzt_abschluss3;
            this._feedback_gefunden_ueber = data._feedback_gefunden_ueber;
            this._feedback_gefunden_ueber_tvd = data._feedback_gefunden_ueber_tvd;
            this._feedback_gefunden_ueber_empfehlung = data._feedback_gefunden_ueber_empfehlung;
            this._feedback_gefunden_ueber_google = data._feedback_gefunden_ueber_google;
            this._feedback_gefunden_ueber_newsletter = data._feedback_gefunden_ueber_newsletter;
            this._feedback_gefunden_ueber_facebook = data._feedback_gefunden_ueber_facebook;
            this._feedback_gefunden_ueber_instagram = data._feedback_gefunden_ueber_instagram;
            this._feedback_gefunden_ueber_sonstiges = data._feedback_gefunden_ueber_sonstiges;
            this._fortbildungsbudget = data._fortbildungsbudget;
            this._fortbildungsbudget_vorhanden = data._fortbildungsbudget_vorhanden;
            this._fortbildungsbudget_nach_vereinbarung = data._fortbildungsbudget_nach_vereinbarung;
            this._urlaubstage = data._urlaubstage;
            this._fortbildungstage = data._fortbildungstage;
            this._fortbildungstage_nach_vereinbarung = data._fortbildungstage_nach_vereinbarung;
            this._fortbildung_regelm_intern = data._fortbildung_regelm_intern;
            this._fortbildung_freie_tage = data._fortbildung_freie_tage;
            this._geplante_dauer = data._geplante_dauer;
            this._homepage = data._homepage;
            this._icon_name = data._icon_name;
            this._infotext = data._infotext;
            this._lebensphase = data._lebensphase;
            this._mitarbeiter_assistenten = data._mitarbeiter_assistenten;
            this._mitarbeiter_auszubildende = data._mitarbeiter_auszubildende;
            this._mitarbeiter_in_mutterschutz = data._mitarbeiter_in_mutterschutz;
            this._mitarbeiter_minijobs = data._mitarbeiter_minijobs;
            this._mitarbeiter_tfa = data._mitarbeiter_tfa;
            this._mitarbeiter_ta = data._mitarbeiter_ta;
            this._mitarbeiter_sonstige = data._mitarbeiter_sonstige;
            this._nachmittag_dienstag = data._nachmittag_dienstag;
            this._nachmittag_donnerstag = data._nachmittag_donnerstag;
            this._nachmittag_freitag = data._nachmittag_freitag;
            this._nachmittag_mittwoch = data._nachmittag_mittwoch;
            this._nachmittag_montag = data._nachmittag_montag;
            this._nachmittag_samstag = data._nachmittag_samstag;
            this._nachricht = data._nachricht;
            this._parallele_anstellung = data._parallele_anstellung;
            this._praxis_abgabe_in = data._praxis_abgabe_in;
            this._praxis_apothekenbestand = data._praxis_apothekenbestand;
            this._praxis_art = data._praxis_art;
            this._praxis_art_sonstiges = data._praxis_art_sonstiges;
            this._praxis_art_kleintier = data._praxis_art_kleintier;
            this._praxis_art_pferde = data._praxis_art_pferde;
            this._praxis_art_gemischt = data._praxis_art_gemischt;
            this._praxis_art_rinder = data._praxis_art_rinder;
            this._praxis_art_gefluegel = data._praxis_art_gefluegel;
            this._praxis_art_schwein = data._praxis_art_schwein;
            this._praxis_art_reptilien = data._praxis_art_reptilien;
            this._praxis_art_industrie = data._praxis_art_industrie;
            this._praxis_art_verwaltung = data._praxis_art_verwaltung;
            this._praxis_art_universitaet = data._praxis_art_universitaet;
            this._praxis_art_heimtiere = data._praxis_art_heimtiere;
            this._praxis_art_hochschule = data._praxis_art_hochschule;
            this._praxis_art_oeffentlicher_dienst = data._praxis_art_oeffentlicher_dienst;
            this._praxis_art_wildtiere = data._praxis_art_wildtiere;
            this._praxis_art_ziervoegel = data._praxis_art_ziervoegel;
            this._praxis_hat_preisvorstellung = data._praxis_hat_preisvorstellung;
            this._praxis_fachliche_spezialisierung = data._praxis_fachliche_spezialisierung;
            this._praxis_form = data._praxis_form;
            this._praxis_flaeche = data._praxis_flaeche;
            this._praxis_fortbildung = data._praxis_fortbildung;
            this._praxis_fortbildung_angebot = data._praxis_fortbildung_angebot;
            this._praxis_gruendungsjahr = data._praxis_gruendungsjahr;
            this._praxis_hat_notdienst = data._praxis_hat_notdienst;
            this._praxis_notdienst = data._praxis_notdienst;
            this._praxis_notdienst_auszahlung = data._praxis_notdienst_auszahlung;
            this._praxis_notdienst_freizeitausgleich = data._praxis_notdienst_freizeitausgleich;
            this._praxis_sprechstunde = data._praxis_sprechstunde;
            this._praxis_hat_terminsprechstunde = data._praxis_hat_terminsprechstunde;
            this._praxis_hat_vertretungsregelung = data._praxis_hat_vertretungsregelung;
            this._praxis_jahresnettoumsatz_2015 = data._praxis_jahresnettoumsatz_2015;
            this._praxis_jahresnettoumsatz_2016 = data._praxis_jahresnettoumsatz_2016;
            this._praxis_jahresnettoumsatz_2017 = data._praxis_jahresnettoumsatz_2017;
            this._praxis_jahresnettoumsatz_2018 = data._praxis_jahresnettoumsatz_2018;
            this._praxis_jahresnettoumsatz_2019 = data._praxis_jahresnettoumsatz_2019;
            this._praxis_jahresnettoumsatz_2020 = data._praxis_jahresnettoumsatz_2020;
            this._praxis_jahresnettoumsatz_2021 = data._praxis_jahresnettoumsatz_2021;
            this._praxis_jahresnettoumsatz_2022 = data._praxis_jahresnettoumsatz_2022;
            this._praxis_kurzbeschreibung = data._praxis_kurzbeschreibung;
            this._praxis_materieller_wert = data._praxis_materieller_wert;
            this._praxis_patientenbesitzer = data._praxis_patientenbesitzer;
            this._praxis_preisvorstellung = data._praxis_preisvorstellung;
            this._praxis_sonstige_beschreibung = data._praxis_sonstige_beschreibung;
            this._praxis_telefon_homepage_uebernahme = data._praxis_telefon_homepage_uebernahme;
            this._praxis_uebergabedatum = data._praxis_uebergabedatum;
            this._praxis_uebernahme_in = data._praxis_uebernahme_in;
            this._praxis_uebernahme = data._praxis_uebernahme;
            this._praxis_teilhaber = data._praxis_teilhaber;
            this._praxis_ueberschrift = data._praxis_ueberschrift;
            this._praxis_zulassungsstatus = data._praxis_zulassungsstatus;
            this._praxis_sprache_1 = data._praxis_sprache_1;
            this._praxis_sprache_2 = data._praxis_sprache_2;
            this._raeume_apotheke = data._raeume_apotheke;
            this._raeume_behandlung1 = data._raeume_behandlung1;
            this._raeume_behandlung2 = data._raeume_behandlung2;
            this._raeume_empfang = data._raeume_empfang;
            this._raeume_labor = data._raeume_labor;
            this._raeume_op = data._raeume_op;
            this._raeume_op_vorb = data._raeume_op_vorb;
            this._raeume_roentgen = data._raeume_roentgen;
            this._raeume_sonstige = data._raeume_sonstige;
            this._raeume_sozial = data._raeume_sozial;
            this._raeume_station = data._raeume_station;
            this._raeume_warten = data._raeume_warten;
            this._raeume_wc = data._raeume_wc;
            this._raeume_mitarbeiter = data._raeume_mitarbeiter;
            this._raeume_vorhanden_apotheke = data._raeume_vorhanden_apotheke;
            this._raeume_vorhanden_behandlung1 = data._raeume_vorhanden_behandlung1;
            this._raeume_vorhanden_behandlung2 = data._raeume_vorhanden_behandlung2;
            this._raeume_vorhanden_empfang = data._raeume_vorhanden_empfang;
            this._raeume_vorhanden_labor = data._raeume_vorhanden_labor;
            this._raeume_vorhanden_op = data._raeume_vorhanden_op;
            this._raeume_vorhanden_op_vorb = data._raeume_vorhanden_op_vorb;
            this._raeume_vorhanden_roentgen = data._raeume_vorhanden_roentgen;
            this._raeume_vorhanden_sozial = data._raeume_vorhanden_sozial;
            this._raeume_vorhanden_station = data._raeume_vorhanden_station;
            this._raeume_vorhanden_warten = data._raeume_vorhanden_warten;
            this._raeume_vorhanden_wc = data._raeume_vorhanden_wc;
            this._raeume_vorhanden_sonstige = data._raeume_vorhanden_sonstige;
            this._raeume_vorhanden_mitarbeiter = data._raeume_vorhanden_mitarbeiter;
            this._ratio_exoten = data._ratio_exoten;
            this._ratio_kleintiere = data._ratio_kleintiere;
            this._ratio_nutztiere = data._ratio_nutztiere;
            this._ratio_pferde = data._ratio_pferde;
            this._ratio_rinder = data._ratio_rinder;
            this._ratio_schweine = data._ratio_schweine;
            this._ratio_gefluegel = data._ratio_gefluegel;
            this._ratio_heimtiere = data._ratio_heimtiere;
            this._ratio_reptilien = data._ratio_reptilien;
            this._ratio_oeffentlicher_dienst = data._ratio_oeffentlicher_dienst;
            this._ratio_industrie = data._ratio_industrie;
            this._ratio_sonstiges = data._ratio_sonstiges;
            this._alle_regionen = data._alle_regionen;
            this._region_bw = data._region_bw;
            this._region_by = data._region_by;
            this._region_be = data._region_be;
            this._region_bb = data._region_bb;
            this._region_hb = data._region_hb;
            this._region_hh = data._region_hh;
            this._region_he = data._region_he;
            this._region_mv = data._region_mv;
            this._region_ni = data._region_ni;
            this._region_nw = data._region_nw;
            this._region_rp = data._region_rp;
            this._region_sl = data._region_sl;
            this._region_sn = data._region_sn;
            this._region_sa = data._region_sa;
            this._region_sh = data._region_sh;
            this._region_th = data._region_th;
            this._region_0x = data._region_0x;
            this._region_1x = data._region_1x;
            this._region_2x = data._region_2x;
            this._region_3x = data._region_3x;
            this._region_4x = data._region_4x;
            this._region_5x = data._region_5x;
            this._region_6x = data._region_6x;
            this._region_7x = data._region_7x;
            this._region_8x = data._region_8x;
            this._region_9x = data._region_9x;
            this._schwerpunkt_apothekenmanagement = data._schwerpunkt_apothekenmanagement;
            this._schwerpunkt_allgemeine_tiermedizin = data._schwerpunkt_allgemeine_tiermedizin;
            this._schwerpunkt_alternativmedizin = data._schwerpunkt_alternativmedizin;
            this._schwerpunkt_fahrpraxis = data._schwerpunkt_fahrpraxis;
            this._schwerpunkt_bestandsbetreuung = data._schwerpunkt_bestandsbetreuung;
            this._schwerpunkt_chirurgie = data._schwerpunkt_chirurgie;
            this._schwerpunkt_gynaekologie = data._schwerpunkt_gynaekologie;
            this._schwerpunkt_haut_augenerkrankung = data._schwerpunkt_haut_augenerkrankung;
            this._schwerpunkt_innere_medizin = data._schwerpunkt_innere_medizin;
            this._schwerpunkt_intensivmedizin = data._schwerpunkt_intensivmedizin;
            this._schwerpunkt_kardiologie = data._schwerpunkt_kardiologie;
            this._schwerpunkt_klassische_tiermedizin = data._schwerpunkt_klassische_tiermedizin;
            this._schwerpunkt_neurologie = data._schwerpunkt_neurologie;
            this._schwerpunkt_notdienste = data._schwerpunkt_notdienste;
            this._schwerpunkt_onkologie = data._schwerpunkt_onkologie;
            this._schwerpunkt_operationen_knochen = data._schwerpunkt_operationen_knochen;
            this._schwerpunkt_operationen_weichteile = data._schwerpunkt_operationen_weichteile;
            this._schwerpunkt_operationen = data._schwerpunkt_operationen;
            this._schwerpunkt_op_assistenz = data._schwerpunkt_op_assistenz;
            this._schwerpunkt_roentgen = data._schwerpunkt_roentgen;
            this._schwerpunkt_sonstiges = data._schwerpunkt_sonstiges;
            this._schwerpunkt_stationaere_aufnahme = data._schwerpunkt_stationaere_aufnahme;
            this._schwerpunkt_stationaere_betreuung = data._schwerpunkt_stationaere_betreuung;
            this._schwerpunkt_sprechstunde = data._schwerpunkt_sprechstunde;
            this._schwerpunkt_ultraschall = data._schwerpunkt_ultraschall;
            this._schwerpunkt_untersuchung_laborproben = data._schwerpunkt_untersuchung_laborproben;
            this._schwerpunkt_ueberwachung_von_narkose = data._schwerpunkt_ueberwachung_von_narkose;
            this._schwerpunkt_verhaltenstherapie = data._schwerpunkt_verhaltenstherapie;
            this._schwerpunkt_veranstaltungsbetreuung = data._schwerpunkt_veranstaltungsbetreuung;
            this._schwerpunkt_zahn_kieferchirugie = data._schwerpunkt_zahn_kieferchirugie;
            this._schwerpunkt_zahn_behandlungen = data._schwerpunkt_zahn_behandlungen;
            this._schwerpunkt_zahnheilkunde = data._schwerpunkt_zahnheilkunde;
            this._schwerpunkt_nachtdienste = data._schwerpunkt_nachtdienste;
            this._schwerpunkt_nachtdienste_anzahl = data._schwerpunkt_nachtdienste_anzahl;
            this._schwerpunkt_wochenenddienste = data._schwerpunkt_wochenenddienste;
            this._schwerpunkt_wochenenddienste_anzahl = data._schwerpunkt_wochenenddienste_anzahl;
            this._schwerpunkt_ophthalmologie = data._schwerpunkt_ophthalmologie;
            this._schwerpunkt_dermatologie = data._schwerpunkt_dermatologie;
            this._schwerpunkt_orthopaedie = data._schwerpunkt_orthopaedie;
            this._schwerpunkt_alternative_heilmethoden = data._schwerpunkt_alternative_heilmethoden;
            this._schwerpunkt_weichteilchirurgie = data._schwerpunkt_weichteilchirurgie;
            this._schwerpunkt_osteosynthese = data._schwerpunkt_osteosynthese;
            this._schwerpunkt_arthroskopie = data._schwerpunkt_arthroskopie;
            this._schwerpunkt_augenheilkunde = data._schwerpunkt_augenheilkunde;
            this._schwerpunkt_reproduktionsmedizin = data._schwerpunkt_reproduktionsmedizin;
            this._schwerpunkt_ernaehrung = data._schwerpunkt_ernaehrung;
            this._schwerpunkt_notfallmedizin = data._schwerpunkt_notfallmedizin;
            this._schwerpunkt_endokrinologie = data._schwerpunkt_endokrinologie;
            this._schwerpunkt = data._schwerpunkt;
            this._seo_description = data._seo_description;
            this._seo_hiringorganization_name = data._seo_hiringorganization_name;
            this._seo_hiringorganization_sameas = data._seo_hiringorganization_sameas;
            this._seo_joblocation_addresslocality = data._seo_joblocation_addresslocality;
            this._seo_joblocation_streetaddress = data._seo_joblocation_streetaddress;
            this._seo_joblocation_addressregion = data._seo_joblocation_addressregion;
            this._seo_joblocation_postalcode = data._seo_joblocation_postalcode;
            this._seo_joblocation_addresscountry = data._seo_joblocation_addresscountry;
            this._slug = data._slug;
            this._slug_v2 = data._slug_v2;
            this._stammdaten_adresse = data._stammdaten_adresse;
            this._stammdaten_anrede = data._stammdaten_anrede;
            this._stammdaten_email = data._stammdaten_email;
            this._stammdaten_fax = data._stammdaten_fax;
            this._stammdaten_grussform = data._stammdaten_grussform;
            this._stammdaten_mobil = data._stammdaten_mobil;
            this._stammdaten_nachname = data._stammdaten_nachname;
            this._stammdaten_nr = data._stammdaten_nr;
            this._stammdaten_ort = data._stammdaten_ort;
            this._stammdaten_plz = data._stammdaten_plz;
            this._stammdaten_strasse = data._stammdaten_strasse;
            this._stammdaten_telefon = data._stammdaten_telefon;
            this._stammdaten_titel = data._stammdaten_titel;
            this._stammdaten_vorname = data._stammdaten_vorname;
            this._stammdaten_praxisname = data._stammdaten_praxisname;
            this._stammdaten_ansprechpartner = data._stammdaten_ansprechpartner;
            this._stammdaten_iso3166_1 = data._stammdaten_iso3166_1;
            this._stammdaten_iso3166_2 = data._stammdaten_iso3166_2;
            this._standort = data._standort;
            this._standort_beschreibung = data._standort_beschreibung;
            this._standort_distance = data._standort_distance;
            this._standort_einwohnerzahl = data._standort_einwohnerzahl;
            this._standort_anzahl_parkplatz = data._standort_anzahl_parkplatz;
            this._standort_entfernung_bahnhof = data._standort_entfernung_bahnhof;
            this._standort_entfernung_bushaltestelle = data._standort_entfernung_bushaltestelle;
            this._standort_entfernung_einkauf = data._standort_entfernung_einkauf;
            this._standort_entfernung_schule = data._standort_entfernung_schule;
            this._standort_entfernung_grundschule = data._standort_entfernung_grundschule;
            this._standort_entfernung_kindergarten = data._standort_entfernung_kindergarten;
            this._standort_hat_bahnhof = data._standort_hat_bahnhof;
            this._standort_hat_bushaltestelle = data._standort_hat_bushaltestelle;
            this._standort_hat_einkauf = data._standort_hat_einkauf;
            this._standort_hat_parkplatz = data._standort_hat_parkplatz;
            this._standort_hat_schule = data._standort_hat_schule;
            this._standort_hat_grundschule = data._standort_hat_grundschule;
            this._standort_hat_kindergarten = data._standort_hat_kindergarten;
            this._standort_hat_mitarbeiterparkplatz = data._standort_hat_mitarbeiterparkplatz;
            this._standort_hat_parkmoeglichkeiten = data._standort_hat_parkmoeglichkeiten;
            this._standort_hat_oeffentliche_anbindung = data._standort_hat_oeffentliche_anbindung;
            this._standort_immobilie = data._standort_immobilie;
            this._standort_immobilie_inklusive = data._standort_immobilie_inklusive;
            this._standort_geolocated = data._standort_geolocated;
            this._standort_mietzins = data._standort_mietzins;
            this._standort_lat = data._standort_lat;
            this._standort_lng = data._standort_lng;
            this._standort_land = data._standort_land;
            this._standort_plz = data._standort_plz;
            this._standort_region = data._standort_region;
            this._stellenart_assistenzstelle = data._stellenart_assistenzstelle;
            this._stellenart_praktikum = data._stellenart_praktikum;
            this._stellenart_praxisvertreter = data._stellenart_praxisvertreter;
            this._stellenart_tfa = data._stellenart_tfa;
            this._stellenart_praktikum_ausland = data._stellenart_praktikum_ausland;
            this._stellenart_selbststaendig = data._stellenart_selbststaendig;
            this._stellenart_teilhaber = data._stellenart_teilhaber;
            this._stellenart_tierarzt = data._stellenart_tierarzt;
            this._stellenart_tfa_ausbildung = data._stellenart_tfa_ausbildung;
            this._stellenart_dissertation = data._stellenart_dissertation;
            this._stellenart_sonstiges = data._stellenart_sonstiges;
            this._stellenart_is_empfehlung = data._stellenart_is_empfehlung;
            this._subtitle = data._subtitle;
            this._taetigkeit_ort = data._taetigkeit_ort;
            this._taetigkeit_nur_vor_ort = data._taetigkeit_nur_vor_ort;
            this._taetigkeit_nur_dezentral = data._taetigkeit_nur_dezentral;
            this._taetigkeit_ort_kombiniert = data._taetigkeit_ort_kombiniert;
            this._title = data._title;
            this._umfang_vollzeit = data._umfang_vollzeit;
            this._umfang_teilzeit = data._umfang_teilzeit;
            this._umfang_nebenbeschaeftigung = data._umfang_nebenbeschaeftigung;
            this._vormittag_dienstag = data._vormittag_dienstag;
            this._vormittag_donnerstag = data._vormittag_donnerstag;
            this._vormittag_freitag = data._vormittag_freitag;
            this._vormittag_mittwoch = data._vormittag_mittwoch;
            this._vormittag_montag = data._vormittag_montag;
            this._vormittag_samstag = data._vormittag_samstag;
            this._voraussetzungen_deutsche_approbation_tierarzt = data._voraussetzungen_deutsche_approbation_tierarzt;
            this._voraussetzungen_min_berufserfahrungen = data._voraussetzungen_min_berufserfahrungen;
            this._voraussetzungen_berufserfahrungen_in_jahren = data._voraussetzungen_berufserfahrungen_in_jahren;
            this._voraussetzungen_min_semester = data._voraussetzungen_min_semester;
            this._voraussetzungen_semester_anzahl = data._voraussetzungen_semester_anzahl;
            this._voraussetzungen_bereits_absolviertes_internship = data._voraussetzungen_bereits_absolviertes_internship;
            this._voraussetzungen_hat_titel = data._voraussetzungen_hat_titel;
            this._voraussetzungen_title_zusatzbezeichnung = data._voraussetzungen_title_zusatzbezeichnung;
            this._voraussetzungen_notdienst_bereitschaft = data._voraussetzungen_notdienst_bereitschaft;
            this._voraussetzungen_belastbarkeit = data._voraussetzungen_belastbarkeit;
            this._voraussetzungen_empathischer_umgang = data._voraussetzungen_empathischer_umgang;
            this._voraussetzungen_selbststaendiges_arbeiten = data._voraussetzungen_selbststaendiges_arbeiten;
            this._voraussetzungen_teamfaehigkeit = data._voraussetzungen_teamfaehigkeit;
            this._voraussetzungen_fuehrerschein = data._voraussetzungen_fuehrerschein;
            this._voraussetzungen_eigenes_auto = data._voraussetzungen_eigenes_auto;
            this._voraussetzungen_puenktlichkeit = data._voraussetzungen_puenktlichkeit;
            this._voraussetzungen_zuverlaessigkeit = data._voraussetzungen_zuverlaessigkeit;
            this._voraussetzungen_interesse_an_weiterbildung = data._voraussetzungen_interesse_an_weiterbildung;
            this._voraussetzungen_kollegialer_umgang = data._voraussetzungen_kollegialer_umgang;
            this._wunschort1_location_address = data._wunschort1_location_address;
            this._wunschort1_location = data._wunschort1_location;
            this._wunschort2_location_address = data._wunschort2_location_address;
            this._wunschort2_location = data._wunschort2_location;
            this._wunschort3_location_address = data._wunschort3_location_address;
            this._wunschort3_location = data._wunschort3_location;
            this._wunschort_flexibel = data._wunschort_flexibel;
            this._wunschorte = data._wunschorte;
            this._wochenstunden = data._wochenstunden;
            this._wochentag_montag = data._wochentag_montag;
            this._wochentag_dienstag = data._wochentag_dienstag;
            this._wochentag_mittwoch = data._wochentag_mittwoch;
            this._wochentag_donnerstag = data._wochentag_donnerstag;
            this._wochentag_freitag = data._wochentag_freitag;
            this._wochentag_samstag = data._wochentag_samstag;
            this._wochentag_sonntag = data._wochentag_sonntag;
            this._zusatzinfos = data._zusatzinfos;
            this._erreichbarkeit_uhrzeit = data._erreichbarkeit_uhrzeit;
        }
    }

    public static createInstance() {
        return new Offer({
            'uuid': '',
            'user_uuid': '',
            'offer_type': 0,
            'create_date': '',
            'modify_date': '',
            'delete_date': '',
            'expire_date': '',
            'last_mail_date': '',
            'opt_in_date': '',
            'opt_out_date': '',
            'import_id': 0,
            'is_public': false,
            'is_intern': false,
            'is_opted_in': false,
            'need_payment': false,
            '_chiffre': '',
            '_admin_status': '',
            '_admin_is_public': false,
            '_admin_is_sold': false,
            '_admin_is_deleted': false,
            '_admin_is_reserved': false,
            '_admin_is_upper_class': false,
            '_admin_tvd_ansprechpartner': '',
            '_admin_zusatzinfos': '',
            '_arbeitsantritt': '',
            '_arbeitsantritt_monate': '',
            '_arbeitsumfang': '',
            '_arbeitsumfang_teilzeit': '',
            '_arbeitsumfang_vollzeit': '',
            '_arbeitsumfang_dauer': '',
            '_arbeitsumfang_dauer_min_wochen': 0,
            '_arbeitsumfang_dauer_max_wochen': 0,
            '_arbeitsumfang_dauer_sonstiges': '',
            '_arbeitsumfang_nebenbeschaeftigung': '',
            '_arbeitszeit_montag': '',
            '_arbeitszeit_dienstag': '',
            '_arbeitszeit_mittwoch': '',
            '_arbeitszeit_donnerstag': '',
            '_arbeitszeit_freitag': '',
            '_arbeitszeit_samstag': '',
            '_arbeitszeit_sonntag': '',
            '_ausstattung_autoclav': false,
            '_ausstattung_behandlungstisch': false,
            '_ausstattung_blutanalyse': false,
            '_ausstattung_boxen': false,
            '_ausstattung_computer_drucker': false,
            '_ausstattung_endoskop': false,
            '_ausstattung_gefriertruhe': false,
            '_ausstattung_inhouse_labor': false,
            '_ausstattung_klimaanlage': false,
            '_ausstattung_kuehlschrank': false,
            '_ausstattung_mikroskop': false,
            '_ausstattung_narkosegeraet': false,
            '_ausstattung_op_besteck': false,
            '_ausstattung_op_lampen': false,
            '_ausstattung_osteosynthese_set': false,
            '_ausstattung_otoskop': false,
            '_ausstattung_roentgen_analog': false,
            '_ausstattung_roentgen_digital': false,
            '_ausstattung_sonstiges': false,
            '_ausstattung_telefonanlagen': false,
            '_ausstattung_tierwaage': false,
            '_ausstattung_trockner': false,
            '_ausstattung_ultraschall': false,
            '_ausstattung_herzultraschall': false,
            '_ausstattung_waschmaschine': false,
            '_ausstattung_zahnsteineinheit': false,
            '_ausstattung_szintigraph': false,
            '_ausstattung_station': false,
            '_ausstattung_getrennter_wartebereich': false,
            '_ausstattung_software_zur_praxisverwaltung': false,
            '_ausstattung_crm': false,
            '_ausstattung_ct': false,
            '_ausstattung_mrt': false,
            '_ausstattung': '',
            '_ausgleich_notdienst': '',
            '_behandlung_op_mit_narkose': false,
            '_behandlung_ernaehrungsberatung': false,
            '_behandlung_osteosynthese': false,
            '_behandlung_stationaer': false,
            '_behandlung_osteopathie': false,
            '_behandlung_physiotherapie': false,
            '_behandlung_chiropraktik': false,
            '_behandlung_akupunktur': false,
            '_behandlung_herzultraschall': false,
            '_behandlung_ultraschall': false,
            '_behandlung_digitales_roentgen': false,
            '_behandlung_mobiles_roentgen': false,
            '_behandlung_zahnbehandlungen_mobil': false,
            '_behandlung_dentalroentgen': false,
            '_behandlung_inhouse_labor': false,
            '_behandlung_sonstiges': false,
            '_behandlung': '',
            '_behandlung_ct': false,
            '_behandlung_mrt': false,
            '_behandlung_szintigraphie': false,
            '_behandlung_endoskopie': false,
            '_benoetigte_unterlagen': '',
            '_benoetigte_bewerber_voraussetzungen': '',
            '_beschreibung_text': '',
            '_beschreibung_titel': '',
            '_besonderheit': '',
            '_besonderheit_sonstiges': false,
            '_besonderheit_cat_friendly': false,
            '_besonderheit_mitnahme_hund': false,
            '_besonderheit_moegl_doktorarbeit': false,
            '_besonderheit_moegl_teilhaberschaft': false,
            '_besonderheit_praktikumsverguetung': false,
            '_besonderheit_bez_fortbildung': false,
            '_besonderheit_wohnen_vor_ort': false,
            '_besonderheit_kein_nachtdienst': false,
            '_besonderheit_bkv': false,
            '_besonderheit_bav': false,
            '_besonderheit_eigenverantwortung': false,
            '_besonderheit_gehaltsmodell': false,
            '_besonderheit_gute_einarbeitung': false,
            '_besonderheit_gutes_team': false,
            '_besonderheit_familiaere': false,
            '_besonderheit_hohes_niveau': false,
            '_besonderheit_moderne_ausstattung': false,
            '_besonderheit_feedbackgespraeche': false,
            '_besonderheit_arbeitszeiterfassung': false,
            '_besonderheit_bezahlte_fortbildung': false,
            '_besonderheit_interne_fortbildungen': false,
            '_besonderheit_weihnachtsgeld': false,
            '_besonderheit_urlaubsgeld': false,
            '_besonderheit_dienstwagen': false,
            '_besonderheit_dienstwagen_privat': false,
            '_besonderheit_mittagessen_kostenlos': false,
            '_besonderheit_rotation_in_bereiche': false,
            '_besonderheit_sportangebot': false,
            '_besonderheit_teamevents': false,
            '_besonderheit_mitglied_vuk': false,
            '_besonderheit_praktikumsleitfaden': false,
            '_besonderheit_notdienstring': false,
            '_besonderheit_kein_notdienst': false,
            '_besonderheit_gvp_zertifiziert': false,
            '_besonderheit_praktikum_basics': false,
            '_besonderheit_vwl': false,
            '_besonderheit_fahrtkostenzuschuss': false,
            '_besonderheit_keine_nacht_und_wochenenddienste': false,
            '_besonderheit_nacht_und_wochenenddienste_auf_wunsch': false,
            '_besonderheit_nacht_und_wochenenddienste_geregelt': false,
            '_besonderheit_berufseinsteiger_willkommen': false,
            '_bezahlte_stelle': false,
            '_an_stelle_gekoppelt': false,
            '_datenschutz_akzeptiert': false,
            '_einrichtung_sonstiges': false,
            '_einrichtung_tierklinik': false,
            '_einrichtung_tierarztpraxis': false,
            '_einrichtung_fahrpraxis': false,
            '_einrichtung_fachtierarztpraxis': false,
            '_einrichtung_industrie': false,
            '_einrichtung_oeffentlicher_dienst': false,
            '_einrichtung_hochschule': false,
            '_einrichtung': '',
            '_externes_medium1': '',
            '_externes_medium2': '',
            '_externes_medium3': '',
            '_facharzt_abschluesse': 0,
            '_facharzt_ausbildung': false,
            '_facharzt_abschluss1': '',
            '_facharzt_abschluss2': '',
            '_facharzt_abschluss3': '',
            '_feedback_gefunden_ueber': '',
            '_feedback_gefunden_ueber_tvd': false,
            '_feedback_gefunden_ueber_empfehlung': false,
            '_feedback_gefunden_ueber_google': false,
            '_feedback_gefunden_ueber_newsletter': false,
            '_feedback_gefunden_ueber_facebook': false,
            '_feedback_gefunden_ueber_instagram': false,
            '_feedback_gefunden_ueber_sonstiges': false,
            '_fortbildungsbudget': 0,
            '_fortbildungsbudget_vorhanden': false,
            '_fortbildungsbudget_nach_vereinbarung': false,
            '_urlaubstage': '',
            '_fortbildungstage': '',
            '_fortbildungstage_nach_vereinbarung': false,
            '_fortbildung_regelm_intern': false,
            '_fortbildung_freie_tage': false,
            '_geplante_dauer': '',
            '_homepage': '',
            '_icon_name': '',
            '_infotext': '',
            '_lebensphase': '',
            '_mitarbeiter_assistenten': 0,
            '_mitarbeiter_auszubildende': 0,
            '_mitarbeiter_in_mutterschutz': 0,
            '_mitarbeiter_minijobs': 0,
            '_mitarbeiter_tfa': 0,
            '_mitarbeiter_ta': 0,
            '_mitarbeiter_sonstige': 0,
            '_nachmittag_dienstag': false,
            '_nachmittag_donnerstag': false,
            '_nachmittag_freitag': false,
            '_nachmittag_mittwoch': false,
            '_nachmittag_montag': false,
            '_nachmittag_samstag': false,
            '_nachricht': '',
            '_parallele_anstellung': false,
            '_praxis_abgabe_in': '',
            '_praxis_apothekenbestand': 0,
            '_praxis_art': '',
            '_praxis_art_sonstiges': false,
            '_praxis_art_kleintier': false,
            '_praxis_art_pferde': false,
            '_praxis_art_gemischt': false,
            '_praxis_art_rinder': false,
            '_praxis_art_gefluegel': false,
            '_praxis_art_schwein': false,
            '_praxis_art_reptilien': false,
            '_praxis_art_industrie': false,
            '_praxis_art_verwaltung': false,
            '_praxis_art_universitaet': false,
            '_praxis_art_heimtiere': false,
            '_praxis_art_hochschule': false,
            '_praxis_art_oeffentlicher_dienst': false,
            '_praxis_art_wildtiere': false,
            '_praxis_art_ziervoegel': false,
            '_praxis_hat_preisvorstellung': false,
            '_praxis_fachliche_spezialisierung': '',
            '_praxis_form': '',
            '_praxis_flaeche': 0,
            '_praxis_fortbildung': false,
            '_praxis_fortbildung_angebot': '',
            '_praxis_gruendungsjahr': '',
            '_praxis_hat_notdienst': false,
            '_praxis_notdienst': '',
            '_praxis_notdienst_auszahlung': false,
            '_praxis_notdienst_freizeitausgleich': false,
            '_praxis_sprechstunde': '',
            '_praxis_hat_terminsprechstunde': false,
            '_praxis_hat_vertretungsregelung': false,
            '_praxis_jahresnettoumsatz_2015': 0,
            '_praxis_jahresnettoumsatz_2016': 0,
            '_praxis_jahresnettoumsatz_2017': 0,
            '_praxis_jahresnettoumsatz_2018': 0,
            '_praxis_jahresnettoumsatz_2019': 0,
            '_praxis_jahresnettoumsatz_2020': 0,
            '_praxis_jahresnettoumsatz_2021': 0,
            '_praxis_jahresnettoumsatz_2022': 0,
            '_praxis_kurzbeschreibung': '',
            '_praxis_materieller_wert': 0,
            '_praxis_patientenbesitzer': 0,
            '_praxis_preisvorstellung': 0,
            '_praxis_sonstige_beschreibung': '',
            '_praxis_telefon_homepage_uebernahme': false,
            '_praxis_uebergabedatum': '',
            '_praxis_uebernahme_in': '',
            '_praxis_uebernahme': false,
            '_praxis_teilhaber': false,
            '_praxis_ueberschrift': '',
            '_praxis_zulassungsstatus': '',
            '_praxis_sprache_1': '',
            '_praxis_sprache_2': '',
            '_raeume_apotheke': 0,
            '_raeume_behandlung1': 0,
            '_raeume_behandlung2': 0,
            '_raeume_empfang': 0,
            '_raeume_labor': 0,
            '_raeume_op': 0,
            '_raeume_op_vorb': 0,
            '_raeume_roentgen': 0,
            '_raeume_sonstige': '',
            '_raeume_sozial': 0,
            '_raeume_station': 0,
            '_raeume_warten': 0,
            '_raeume_wc': 0,
            '_raeume_mitarbeiter': 0,
            '_raeume_vorhanden_apotheke': false,
            '_raeume_vorhanden_behandlung1': false,
            '_raeume_vorhanden_behandlung2': false,
            '_raeume_vorhanden_empfang': false,
            '_raeume_vorhanden_labor': false,
            '_raeume_vorhanden_op': false,
            '_raeume_vorhanden_op_vorb': false,
            '_raeume_vorhanden_roentgen': false,
            '_raeume_vorhanden_sozial': false,
            '_raeume_vorhanden_station': false,
            '_raeume_vorhanden_warten': false,
            '_raeume_vorhanden_wc': false,
            '_raeume_vorhanden_sonstige': false,
            '_raeume_vorhanden_mitarbeiter': false,
            '_ratio_exoten': 0,
            '_ratio_kleintiere': 0,
            '_ratio_nutztiere': 0,
            '_ratio_pferde': 0,
            '_ratio_rinder': 0,
            '_ratio_schweine': 0,
            '_ratio_gefluegel': 0,
            '_ratio_heimtiere': 0,
            '_ratio_reptilien': 0,
            '_ratio_oeffentlicher_dienst': 0,
            '_ratio_industrie': 0,
            '_ratio_sonstiges': 0,
            '_alle_regionen': false,
            '_region_bw': false,
            '_region_by': false,
            '_region_be': false,
            '_region_bb': false,
            '_region_hb': false,
            '_region_hh': false,
            '_region_he': false,
            '_region_mv': false,
            '_region_ni': false,
            '_region_nw': false,
            '_region_rp': false,
            '_region_sl': false,
            '_region_sn': false,
            '_region_sa': false,
            '_region_sh': false,
            '_region_th': false,
            '_region_0x': false,
            '_region_1x': false,
            '_region_2x': false,
            '_region_3x': false,
            '_region_4x': false,
            '_region_5x': false,
            '_region_6x': false,
            '_region_7x': false,
            '_region_8x': false,
            '_region_9x': false,
            '_schwerpunkt_apothekenmanagement': false,
            '_schwerpunkt_allgemeine_tiermedizin': false,
            '_schwerpunkt_alternativmedizin': false,
            '_schwerpunkt_fahrpraxis': false,
            '_schwerpunkt_bestandsbetreuung': false,
            '_schwerpunkt_chirurgie': false,
            '_schwerpunkt_gynaekologie': false,
            '_schwerpunkt_haut_augenerkrankung': false,
            '_schwerpunkt_innere_medizin': false,
            '_schwerpunkt_intensivmedizin': false,
            '_schwerpunkt_kardiologie': false,
            '_schwerpunkt_klassische_tiermedizin': false,
            '_schwerpunkt_neurologie': false,
            '_schwerpunkt_notdienste': false,
            '_schwerpunkt_onkologie': false,
            '_schwerpunkt_operationen_knochen': false,
            '_schwerpunkt_operationen_weichteile': false,
            '_schwerpunkt_operationen': false,
            '_schwerpunkt_op_assistenz': false,
            '_schwerpunkt_roentgen': false,
            '_schwerpunkt_sonstiges': false,
            '_schwerpunkt_stationaere_aufnahme': false,
            '_schwerpunkt_stationaere_betreuung': false,
            '_schwerpunkt_sprechstunde': false,
            '_schwerpunkt_ultraschall': false,
            '_schwerpunkt_untersuchung_laborproben': false,
            '_schwerpunkt_ueberwachung_von_narkose': false,
            '_schwerpunkt_verhaltenstherapie': false,
            '_schwerpunkt_veranstaltungsbetreuung': false,
            '_schwerpunkt_zahn_kieferchirugie': false,
            '_schwerpunkt_zahn_behandlungen': false,
            '_schwerpunkt_zahnheilkunde': false,
            '_schwerpunkt_nachtdienste': false,
            '_schwerpunkt_nachtdienste_anzahl': 0,
            '_schwerpunkt_wochenenddienste': false,
            '_schwerpunkt_wochenenddienste_anzahl': 0,
            '_schwerpunkt_ophthalmologie': false,
            '_schwerpunkt_dermatologie': false,
            '_schwerpunkt_orthopaedie': false,
            '_schwerpunkt_alternative_heilmethoden': false,
            '_schwerpunkt_weichteilchirurgie': false,
            '_schwerpunkt_osteosynthese': false,
            '_schwerpunkt_arthroskopie': false,
            '_schwerpunkt_augenheilkunde': false,
            '_schwerpunkt_reproduktionsmedizin': false,
            '_schwerpunkt_ernaehrung': false,
            '_schwerpunkt_notfallmedizin': false,
            '_schwerpunkt_endokrinologie': false,
            '_schwerpunkt': '',
            '_seo_description': '',
            '_seo_hiringorganization_name': '',
            '_seo_hiringorganization_sameas': '',
            '_seo_joblocation_addresslocality': '',
            '_seo_joblocation_streetaddress': '',
            '_seo_joblocation_addressregion': '',
            '_seo_joblocation_postalcode': '',
            '_seo_joblocation_addresscountry': '',
            '_slug': '',
            '_slug_v2': '',
            '_stammdaten_adresse': '',
            '_stammdaten_anrede': '',
            '_stammdaten_email': '',
            '_stammdaten_fax': '',
            '_stammdaten_grussform': '',
            '_stammdaten_mobil': '',
            '_stammdaten_nachname': '',
            '_stammdaten_nr': '',
            '_stammdaten_ort': '',
            '_stammdaten_plz': '',
            '_stammdaten_strasse': '',
            '_stammdaten_telefon': '',
            '_stammdaten_titel': '',
            '_stammdaten_vorname': '',
            '_stammdaten_praxisname': '',
            '_stammdaten_ansprechpartner': '',
            '_stammdaten_iso3166_1': '',
            '_stammdaten_iso3166_2': '',
            '_standort': '',
            '_standort_beschreibung': '',
            '_standort_distance': 0,
            '_standort_einwohnerzahl': 0,
            '_standort_anzahl_parkplatz': 0,
            '_standort_entfernung_bahnhof': 0,
            '_standort_entfernung_bushaltestelle': 0,
            '_standort_entfernung_einkauf': 0,
            '_standort_entfernung_schule': 0,
            '_standort_entfernung_grundschule': 0,
            '_standort_entfernung_kindergarten': 0,
            '_standort_hat_bahnhof': false,
            '_standort_hat_bushaltestelle': false,
            '_standort_hat_einkauf': false,
            '_standort_hat_parkplatz': false,
            '_standort_hat_schule': false,
            '_standort_hat_grundschule': false,
            '_standort_hat_kindergarten': false,
            '_standort_hat_mitarbeiterparkplatz': false,
            '_standort_hat_parkmoeglichkeiten': false,
            '_standort_hat_oeffentliche_anbindung': false,
            '_standort_immobilie': '',
            '_standort_immobilie_inklusive': false,
            '_standort_geolocated': false,
            '_standort_mietzins': 0,
            '_standort_lat': 0,
            '_standort_lng': 0,
            '_standort_land': '',
            '_standort_plz': '',
            '_standort_region': '',
            '_stellenart_assistenzstelle': false,
            '_stellenart_praktikum': false,
            '_stellenart_praxisvertreter': false,
            '_stellenart_tfa': false,
            '_stellenart_praktikum_ausland': false,
            '_stellenart_selbststaendig': false,
            '_stellenart_teilhaber': false,
            '_stellenart_tierarzt': false,
            '_stellenart_tfa_ausbildung': false,
            '_stellenart_dissertation': false,
            '_stellenart_sonstiges': false,
            '_stellenart_is_empfehlung': false,
            '_subtitle': '',
            '_taetigkeit_ort': '',
            '_taetigkeit_nur_vor_ort': false,
            '_taetigkeit_nur_dezentral': false,
            '_taetigkeit_ort_kombiniert': false,
            '_title': '',
            '_umfang_vollzeit': false,
            '_umfang_teilzeit': false,
            '_umfang_nebenbeschaeftigung': false,
            '_vormittag_dienstag': false,
            '_vormittag_donnerstag': false,
            '_vormittag_freitag': false,
            '_vormittag_mittwoch': false,
            '_vormittag_montag': false,
            '_vormittag_samstag': false,
            '_voraussetzungen_deutsche_approbation_tierarzt': false,
            '_voraussetzungen_min_berufserfahrungen': false,
            '_voraussetzungen_berufserfahrungen_in_jahren': 0,
            '_voraussetzungen_min_semester': false,
            '_voraussetzungen_semester_anzahl': 0,
            '_voraussetzungen_bereits_absolviertes_internship': false,
            '_voraussetzungen_hat_titel': false,
            '_voraussetzungen_title_zusatzbezeichnung': false,
            '_voraussetzungen_notdienst_bereitschaft': false,
            '_voraussetzungen_belastbarkeit': false,
            '_voraussetzungen_empathischer_umgang': false,
            '_voraussetzungen_selbststaendiges_arbeiten': false,
            '_voraussetzungen_teamfaehigkeit': false,
            '_voraussetzungen_fuehrerschein': false,
            '_voraussetzungen_eigenes_auto': false,
            '_voraussetzungen_puenktlichkeit': false,
            '_voraussetzungen_zuverlaessigkeit': false,
            '_voraussetzungen_interesse_an_weiterbildung': false,
            '_voraussetzungen_kollegialer_umgang': false,
            '_wunschort1_location_address': '',
            '_wunschort1_location': '',
            '_wunschort2_location_address': '',
            '_wunschort2_location': '',
            '_wunschort3_location_address': '',
            '_wunschort3_location': '',
            '_wunschort_flexibel': false,
            '_wunschorte': 0,
            '_wochenstunden': '',
            '_wochentag_montag': false,
            '_wochentag_dienstag': false,
            '_wochentag_mittwoch': false,
            '_wochentag_donnerstag': false,
            '_wochentag_freitag': false,
            '_wochentag_samstag': false,
            '_wochentag_sonntag': false,
            '_zusatzinfos': '',
            '_erreichbarkeit_uhrzeit': '',
        });
    }
}

export class Request extends ApiResponseData<Request>
{
    public static request_type = 'request_type';
    public static is_opted_in = 'is_opted_in';
    public static uuid = 'uuid';
    public static user_uuid = 'user_uuid';
    public static offer_type = 'offer_type';
    public static create_date = 'create_date';
    public static modify_date = 'modify_date';
    public static delete_date = 'delete_date';
    public static expire_date = 'expire_date';
    public static last_mail_date = 'last_mail_date';
    public static opt_in_date = 'opt_in_date';
    public static opt_out_date = 'opt_out_date';
    public static import_id = 'import_id';
    public static is_public = 'is_public';
    public static is_intern = 'is_intern';
    public static need_payment = 'need_payment';
    public static _chiffre = '_chiffre';
    public static _admin_status = '_admin_status';
    public static _admin_is_public = '_admin_is_public';
    public static _admin_is_sold = '_admin_is_sold';
    public static _admin_is_deleted = '_admin_is_deleted';
    public static _admin_is_reserved = '_admin_is_reserved';
    public static _admin_is_upper_class = '_admin_is_upper_class';
    public static _admin_tvd_ansprechpartner = '_admin_tvd_ansprechpartner';
    public static _admin_zusatzinfos = '_admin_zusatzinfos';
    public static _arbeitsantritt = '_arbeitsantritt';
    public static _arbeitsantritt_monate = '_arbeitsantritt_monate';
    public static _arbeitsumfang = '_arbeitsumfang';
    public static _arbeitsumfang_teilzeit = '_arbeitsumfang_teilzeit';
    public static _arbeitsumfang_vollzeit = '_arbeitsumfang_vollzeit';
    public static _arbeitsumfang_dauer = '_arbeitsumfang_dauer';
    public static _arbeitsumfang_dauer_min_wochen = '_arbeitsumfang_dauer_min_wochen';
    public static _arbeitsumfang_dauer_max_wochen = '_arbeitsumfang_dauer_max_wochen';
    public static _arbeitsumfang_dauer_sonstiges = '_arbeitsumfang_dauer_sonstiges';
    public static _arbeitsumfang_nebenbeschaeftigung = '_arbeitsumfang_nebenbeschaeftigung';
    public static _arbeitszeit_montag = '_arbeitszeit_montag';
    public static _arbeitszeit_dienstag = '_arbeitszeit_dienstag';
    public static _arbeitszeit_mittwoch = '_arbeitszeit_mittwoch';
    public static _arbeitszeit_donnerstag = '_arbeitszeit_donnerstag';
    public static _arbeitszeit_freitag = '_arbeitszeit_freitag';
    public static _arbeitszeit_samstag = '_arbeitszeit_samstag';
    public static _arbeitszeit_sonntag = '_arbeitszeit_sonntag';
    public static _ausstattung_autoclav = '_ausstattung_autoclav';
    public static _ausstattung_behandlungstisch = '_ausstattung_behandlungstisch';
    public static _ausstattung_blutanalyse = '_ausstattung_blutanalyse';
    public static _ausstattung_boxen = '_ausstattung_boxen';
    public static _ausstattung_computer_drucker = '_ausstattung_computer_drucker';
    public static _ausstattung_endoskop = '_ausstattung_endoskop';
    public static _ausstattung_gefriertruhe = '_ausstattung_gefriertruhe';
    public static _ausstattung_inhouse_labor = '_ausstattung_inhouse_labor';
    public static _ausstattung_klimaanlage = '_ausstattung_klimaanlage';
    public static _ausstattung_kuehlschrank = '_ausstattung_kuehlschrank';
    public static _ausstattung_mikroskop = '_ausstattung_mikroskop';
    public static _ausstattung_narkosegeraet = '_ausstattung_narkosegeraet';
    public static _ausstattung_op_besteck = '_ausstattung_op_besteck';
    public static _ausstattung_op_lampen = '_ausstattung_op_lampen';
    public static _ausstattung_osteosynthese_set = '_ausstattung_osteosynthese_set';
    public static _ausstattung_otoskop = '_ausstattung_otoskop';
    public static _ausstattung_roentgen_analog = '_ausstattung_roentgen_analog';
    public static _ausstattung_roentgen_digital = '_ausstattung_roentgen_digital';
    public static _ausstattung_sonstiges = '_ausstattung_sonstiges';
    public static _ausstattung_telefonanlagen = '_ausstattung_telefonanlagen';
    public static _ausstattung_tierwaage = '_ausstattung_tierwaage';
    public static _ausstattung_trockner = '_ausstattung_trockner';
    public static _ausstattung_ultraschall = '_ausstattung_ultraschall';
    public static _ausstattung_herzultraschall = '_ausstattung_herzultraschall';
    public static _ausstattung_waschmaschine = '_ausstattung_waschmaschine';
    public static _ausstattung_zahnsteineinheit = '_ausstattung_zahnsteineinheit';
    public static _ausstattung_szintigraph = '_ausstattung_szintigraph';
    public static _ausstattung_station = '_ausstattung_station';
    public static _ausstattung_getrennter_wartebereich = '_ausstattung_getrennter_wartebereich';
    public static _ausstattung_software_zur_praxisverwaltung = '_ausstattung_software_zur_praxisverwaltung';
    public static _ausstattung_crm = '_ausstattung_crm';
    public static _ausstattung_ct = '_ausstattung_ct';
    public static _ausstattung_mrt = '_ausstattung_mrt';
    public static _ausstattung = '_ausstattung';
    public static _ausgleich_notdienst = '_ausgleich_notdienst';
    public static _behandlung_op_mit_narkose = '_behandlung_op_mit_narkose';
    public static _behandlung_ernaehrungsberatung = '_behandlung_ernaehrungsberatung';
    public static _behandlung_osteosynthese = '_behandlung_osteosynthese';
    public static _behandlung_stationaer = '_behandlung_stationaer';
    public static _behandlung_osteopathie = '_behandlung_osteopathie';
    public static _behandlung_physiotherapie = '_behandlung_physiotherapie';
    public static _behandlung_chiropraktik = '_behandlung_chiropraktik';
    public static _behandlung_akupunktur = '_behandlung_akupunktur';
    public static _behandlung_herzultraschall = '_behandlung_herzultraschall';
    public static _behandlung_ultraschall = '_behandlung_ultraschall';
    public static _behandlung_digitales_roentgen = '_behandlung_digitales_roentgen';
    public static _behandlung_mobiles_roentgen = '_behandlung_mobiles_roentgen';
    public static _behandlung_zahnbehandlungen_mobil = '_behandlung_zahnbehandlungen_mobil';
    public static _behandlung_dentalroentgen = '_behandlung_dentalroentgen';
    public static _behandlung_inhouse_labor = '_behandlung_inhouse_labor';
    public static _behandlung_sonstiges = '_behandlung_sonstiges';
    public static _behandlung = '_behandlung';
    public static _behandlung_ct = '_behandlung_ct';
    public static _behandlung_mrt = '_behandlung_mrt';
    public static _behandlung_szintigraphie = '_behandlung_szintigraphie';
    public static _behandlung_endoskopie = '_behandlung_endoskopie';
    public static _benoetigte_unterlagen = '_benoetigte_unterlagen';
    public static _benoetigte_bewerber_voraussetzungen = '_benoetigte_bewerber_voraussetzungen';
    public static _beschreibung_text = '_beschreibung_text';
    public static _beschreibung_titel = '_beschreibung_titel';
    public static _besonderheit = '_besonderheit';
    public static _besonderheit_sonstiges = '_besonderheit_sonstiges';
    public static _besonderheit_cat_friendly = '_besonderheit_cat_friendly';
    public static _besonderheit_mitnahme_hund = '_besonderheit_mitnahme_hund';
    public static _besonderheit_moegl_doktorarbeit = '_besonderheit_moegl_doktorarbeit';
    public static _besonderheit_moegl_teilhaberschaft = '_besonderheit_moegl_teilhaberschaft';
    public static _besonderheit_praktikumsverguetung = '_besonderheit_praktikumsverguetung';
    public static _besonderheit_bez_fortbildung = '_besonderheit_bez_fortbildung';
    public static _besonderheit_wohnen_vor_ort = '_besonderheit_wohnen_vor_ort';
    public static _besonderheit_kein_nachtdienst = '_besonderheit_kein_nachtdienst';
    public static _besonderheit_bkv = '_besonderheit_bkv';
    public static _besonderheit_bav = '_besonderheit_bav';
    public static _besonderheit_eigenverantwortung = '_besonderheit_eigenverantwortung';
    public static _besonderheit_gehaltsmodell = '_besonderheit_gehaltsmodell';
    public static _besonderheit_gute_einarbeitung = '_besonderheit_gute_einarbeitung';
    public static _besonderheit_gutes_team = '_besonderheit_gutes_team';
    public static _besonderheit_familiaere = '_besonderheit_familiaere';
    public static _besonderheit_hohes_niveau = '_besonderheit_hohes_niveau';
    public static _besonderheit_moderne_ausstattung = '_besonderheit_moderne_ausstattung';
    public static _besonderheit_feedbackgespraeche = '_besonderheit_feedbackgespraeche';
    public static _besonderheit_arbeitszeiterfassung = '_besonderheit_arbeitszeiterfassung';
    public static _besonderheit_bezahlte_fortbildung = '_besonderheit_bezahlte_fortbildung';
    public static _besonderheit_interne_fortbildungen = '_besonderheit_interne_fortbildungen';
    public static _besonderheit_weihnachtsgeld = '_besonderheit_weihnachtsgeld';
    public static _besonderheit_urlaubsgeld = '_besonderheit_urlaubsgeld';
    public static _besonderheit_dienstwagen = '_besonderheit_dienstwagen';
    public static _besonderheit_dienstwagen_privat = '_besonderheit_dienstwagen_privat';
    public static _besonderheit_mittagessen_kostenlos = '_besonderheit_mittagessen_kostenlos';
    public static _besonderheit_rotation_in_bereiche = '_besonderheit_rotation_in_bereiche';
    public static _besonderheit_sportangebot = '_besonderheit_sportangebot';
    public static _besonderheit_teamevents = '_besonderheit_teamevents';
    public static _besonderheit_mitglied_vuk = '_besonderheit_mitglied_vuk';
    public static _besonderheit_praktikumsleitfaden = '_besonderheit_praktikumsleitfaden';
    public static _besonderheit_notdienstring = '_besonderheit_notdienstring';
    public static _besonderheit_kein_notdienst = '_besonderheit_kein_notdienst';
    public static _besonderheit_gvp_zertifiziert = '_besonderheit_gvp_zertifiziert';
    public static _besonderheit_praktikum_basics = '_besonderheit_praktikum_basics';
    public static _besonderheit_vwl = '_besonderheit_vwl';
    public static _besonderheit_fahrtkostenzuschuss = '_besonderheit_fahrtkostenzuschuss';
    public static _besonderheit_keine_nacht_und_wochenenddienste = '_besonderheit_keine_nacht_und_wochenenddienste';
    public static _besonderheit_nacht_und_wochenenddienste_auf_wunsch = '_besonderheit_nacht_und_wochenenddienste_auf_wunsch';
    public static _besonderheit_nacht_und_wochenenddienste_geregelt = '_besonderheit_nacht_und_wochenenddienste_geregelt';
    public static _besonderheit_berufseinsteiger_willkommen = '_besonderheit_berufseinsteiger_willkommen';
    public static _bezahlte_stelle = '_bezahlte_stelle';
    public static _an_stelle_gekoppelt = '_an_stelle_gekoppelt';
    public static _datenschutz_akzeptiert = '_datenschutz_akzeptiert';
    public static _einrichtung_sonstiges = '_einrichtung_sonstiges';
    public static _einrichtung_tierklinik = '_einrichtung_tierklinik';
    public static _einrichtung_tierarztpraxis = '_einrichtung_tierarztpraxis';
    public static _einrichtung_fahrpraxis = '_einrichtung_fahrpraxis';
    public static _einrichtung_fachtierarztpraxis = '_einrichtung_fachtierarztpraxis';
    public static _einrichtung_industrie = '_einrichtung_industrie';
    public static _einrichtung_oeffentlicher_dienst = '_einrichtung_oeffentlicher_dienst';
    public static _einrichtung_hochschule = '_einrichtung_hochschule';
    public static _einrichtung = '_einrichtung';
    public static _externes_medium1 = '_externes_medium1';
    public static _externes_medium2 = '_externes_medium2';
    public static _externes_medium3 = '_externes_medium3';
    public static _facharzt_abschluesse = '_facharzt_abschluesse';
    public static _facharzt_ausbildung = '_facharzt_ausbildung';
    public static _facharzt_abschluss1 = '_facharzt_abschluss1';
    public static _facharzt_abschluss2 = '_facharzt_abschluss2';
    public static _facharzt_abschluss3 = '_facharzt_abschluss3';
    public static _feedback_gefunden_ueber = '_feedback_gefunden_ueber';
    public static _feedback_gefunden_ueber_tvd = '_feedback_gefunden_ueber_tvd';
    public static _feedback_gefunden_ueber_empfehlung = '_feedback_gefunden_ueber_empfehlung';
    public static _feedback_gefunden_ueber_google = '_feedback_gefunden_ueber_google';
    public static _feedback_gefunden_ueber_newsletter = '_feedback_gefunden_ueber_newsletter';
    public static _feedback_gefunden_ueber_facebook = '_feedback_gefunden_ueber_facebook';
    public static _feedback_gefunden_ueber_instagram = '_feedback_gefunden_ueber_instagram';
    public static _feedback_gefunden_ueber_sonstiges = '_feedback_gefunden_ueber_sonstiges';
    public static _fortbildungsbudget = '_fortbildungsbudget';
    public static _fortbildungsbudget_vorhanden = '_fortbildungsbudget_vorhanden';
    public static _fortbildungsbudget_nach_vereinbarung = '_fortbildungsbudget_nach_vereinbarung';
    public static _urlaubstage = '_urlaubstage';
    public static _fortbildungstage = '_fortbildungstage';
    public static _fortbildungstage_nach_vereinbarung = '_fortbildungstage_nach_vereinbarung';
    public static _fortbildung_regelm_intern = '_fortbildung_regelm_intern';
    public static _fortbildung_freie_tage = '_fortbildung_freie_tage';
    public static _geplante_dauer = '_geplante_dauer';
    public static _homepage = '_homepage';
    public static _icon_name = '_icon_name';
    public static _infotext = '_infotext';
    public static _lebensphase = '_lebensphase';
    public static _mitarbeiter_assistenten = '_mitarbeiter_assistenten';
    public static _mitarbeiter_auszubildende = '_mitarbeiter_auszubildende';
    public static _mitarbeiter_in_mutterschutz = '_mitarbeiter_in_mutterschutz';
    public static _mitarbeiter_minijobs = '_mitarbeiter_minijobs';
    public static _mitarbeiter_tfa = '_mitarbeiter_tfa';
    public static _mitarbeiter_ta = '_mitarbeiter_ta';
    public static _mitarbeiter_sonstige = '_mitarbeiter_sonstige';
    public static _nachmittag_dienstag = '_nachmittag_dienstag';
    public static _nachmittag_donnerstag = '_nachmittag_donnerstag';
    public static _nachmittag_freitag = '_nachmittag_freitag';
    public static _nachmittag_mittwoch = '_nachmittag_mittwoch';
    public static _nachmittag_montag = '_nachmittag_montag';
    public static _nachmittag_samstag = '_nachmittag_samstag';
    public static _nachricht = '_nachricht';
    public static _parallele_anstellung = '_parallele_anstellung';
    public static _praxis_abgabe_in = '_praxis_abgabe_in';
    public static _praxis_apothekenbestand = '_praxis_apothekenbestand';
    public static _praxis_art = '_praxis_art';
    public static _praxis_art_sonstiges = '_praxis_art_sonstiges';
    public static _praxis_art_kleintier = '_praxis_art_kleintier';
    public static _praxis_art_pferde = '_praxis_art_pferde';
    public static _praxis_art_gemischt = '_praxis_art_gemischt';
    public static _praxis_art_rinder = '_praxis_art_rinder';
    public static _praxis_art_gefluegel = '_praxis_art_gefluegel';
    public static _praxis_art_schwein = '_praxis_art_schwein';
    public static _praxis_art_reptilien = '_praxis_art_reptilien';
    public static _praxis_art_industrie = '_praxis_art_industrie';
    public static _praxis_art_verwaltung = '_praxis_art_verwaltung';
    public static _praxis_art_universitaet = '_praxis_art_universitaet';
    public static _praxis_art_heimtiere = '_praxis_art_heimtiere';
    public static _praxis_art_hochschule = '_praxis_art_hochschule';
    public static _praxis_art_oeffentlicher_dienst = '_praxis_art_oeffentlicher_dienst';
    public static _praxis_art_wildtiere = '_praxis_art_wildtiere';
    public static _praxis_art_ziervoegel = '_praxis_art_ziervoegel';
    public static _praxis_hat_preisvorstellung = '_praxis_hat_preisvorstellung';
    public static _praxis_fachliche_spezialisierung = '_praxis_fachliche_spezialisierung';
    public static _praxis_form = '_praxis_form';
    public static _praxis_flaeche = '_praxis_flaeche';
    public static _praxis_fortbildung = '_praxis_fortbildung';
    public static _praxis_fortbildung_angebot = '_praxis_fortbildung_angebot';
    public static _praxis_gruendungsjahr = '_praxis_gruendungsjahr';
    public static _praxis_hat_notdienst = '_praxis_hat_notdienst';
    public static _praxis_notdienst = '_praxis_notdienst';
    public static _praxis_notdienst_auszahlung = '_praxis_notdienst_auszahlung';
    public static _praxis_notdienst_freizeitausgleich = '_praxis_notdienst_freizeitausgleich';
    public static _praxis_sprechstunde = '_praxis_sprechstunde';
    public static _praxis_hat_terminsprechstunde = '_praxis_hat_terminsprechstunde';
    public static _praxis_hat_vertretungsregelung = '_praxis_hat_vertretungsregelung';
    public static _praxis_jahresnettoumsatz_2015 = '_praxis_jahresnettoumsatz_2015';
    public static _praxis_jahresnettoumsatz_2016 = '_praxis_jahresnettoumsatz_2016';
    public static _praxis_jahresnettoumsatz_2017 = '_praxis_jahresnettoumsatz_2017';
    public static _praxis_jahresnettoumsatz_2018 = '_praxis_jahresnettoumsatz_2018';
    public static _praxis_jahresnettoumsatz_2019 = '_praxis_jahresnettoumsatz_2019';
    public static _praxis_jahresnettoumsatz_2020 = '_praxis_jahresnettoumsatz_2020';
    public static _praxis_jahresnettoumsatz_2021 = '_praxis_jahresnettoumsatz_2021';
    public static _praxis_jahresnettoumsatz_2022 = '_praxis_jahresnettoumsatz_2022';
    public static _praxis_kurzbeschreibung = '_praxis_kurzbeschreibung';
    public static _praxis_materieller_wert = '_praxis_materieller_wert';
    public static _praxis_patientenbesitzer = '_praxis_patientenbesitzer';
    public static _praxis_preisvorstellung = '_praxis_preisvorstellung';
    public static _praxis_sonstige_beschreibung = '_praxis_sonstige_beschreibung';
    public static _praxis_telefon_homepage_uebernahme = '_praxis_telefon_homepage_uebernahme';
    public static _praxis_uebergabedatum = '_praxis_uebergabedatum';
    public static _praxis_uebernahme_in = '_praxis_uebernahme_in';
    public static _praxis_uebernahme = '_praxis_uebernahme';
    public static _praxis_teilhaber = '_praxis_teilhaber';
    public static _praxis_ueberschrift = '_praxis_ueberschrift';
    public static _praxis_zulassungsstatus = '_praxis_zulassungsstatus';
    public static _praxis_sprache_1 = '_praxis_sprache_1';
    public static _praxis_sprache_2 = '_praxis_sprache_2';
    public static _raeume_apotheke = '_raeume_apotheke';
    public static _raeume_behandlung1 = '_raeume_behandlung1';
    public static _raeume_behandlung2 = '_raeume_behandlung2';
    public static _raeume_empfang = '_raeume_empfang';
    public static _raeume_labor = '_raeume_labor';
    public static _raeume_op = '_raeume_op';
    public static _raeume_op_vorb = '_raeume_op_vorb';
    public static _raeume_roentgen = '_raeume_roentgen';
    public static _raeume_sonstige = '_raeume_sonstige';
    public static _raeume_sozial = '_raeume_sozial';
    public static _raeume_station = '_raeume_station';
    public static _raeume_warten = '_raeume_warten';
    public static _raeume_wc = '_raeume_wc';
    public static _raeume_mitarbeiter = '_raeume_mitarbeiter';
    public static _raeume_vorhanden_apotheke = '_raeume_vorhanden_apotheke';
    public static _raeume_vorhanden_behandlung1 = '_raeume_vorhanden_behandlung1';
    public static _raeume_vorhanden_behandlung2 = '_raeume_vorhanden_behandlung2';
    public static _raeume_vorhanden_empfang = '_raeume_vorhanden_empfang';
    public static _raeume_vorhanden_labor = '_raeume_vorhanden_labor';
    public static _raeume_vorhanden_op = '_raeume_vorhanden_op';
    public static _raeume_vorhanden_op_vorb = '_raeume_vorhanden_op_vorb';
    public static _raeume_vorhanden_roentgen = '_raeume_vorhanden_roentgen';
    public static _raeume_vorhanden_sozial = '_raeume_vorhanden_sozial';
    public static _raeume_vorhanden_station = '_raeume_vorhanden_station';
    public static _raeume_vorhanden_warten = '_raeume_vorhanden_warten';
    public static _raeume_vorhanden_wc = '_raeume_vorhanden_wc';
    public static _raeume_vorhanden_sonstige = '_raeume_vorhanden_sonstige';
    public static _raeume_vorhanden_mitarbeiter = '_raeume_vorhanden_mitarbeiter';
    public static _ratio_exoten = '_ratio_exoten';
    public static _ratio_kleintiere = '_ratio_kleintiere';
    public static _ratio_nutztiere = '_ratio_nutztiere';
    public static _ratio_pferde = '_ratio_pferde';
    public static _ratio_rinder = '_ratio_rinder';
    public static _ratio_schweine = '_ratio_schweine';
    public static _ratio_gefluegel = '_ratio_gefluegel';
    public static _ratio_heimtiere = '_ratio_heimtiere';
    public static _ratio_reptilien = '_ratio_reptilien';
    public static _ratio_oeffentlicher_dienst = '_ratio_oeffentlicher_dienst';
    public static _ratio_industrie = '_ratio_industrie';
    public static _ratio_sonstiges = '_ratio_sonstiges';
    public static _alle_regionen = '_alle_regionen';
    public static _region_bw = '_region_bw';
    public static _region_by = '_region_by';
    public static _region_be = '_region_be';
    public static _region_bb = '_region_bb';
    public static _region_hb = '_region_hb';
    public static _region_hh = '_region_hh';
    public static _region_he = '_region_he';
    public static _region_mv = '_region_mv';
    public static _region_ni = '_region_ni';
    public static _region_nw = '_region_nw';
    public static _region_rp = '_region_rp';
    public static _region_sl = '_region_sl';
    public static _region_sn = '_region_sn';
    public static _region_sa = '_region_sa';
    public static _region_sh = '_region_sh';
    public static _region_th = '_region_th';
    public static _region_0x = '_region_0x';
    public static _region_1x = '_region_1x';
    public static _region_2x = '_region_2x';
    public static _region_3x = '_region_3x';
    public static _region_4x = '_region_4x';
    public static _region_5x = '_region_5x';
    public static _region_6x = '_region_6x';
    public static _region_7x = '_region_7x';
    public static _region_8x = '_region_8x';
    public static _region_9x = '_region_9x';
    public static _schwerpunkt_apothekenmanagement = '_schwerpunkt_apothekenmanagement';
    public static _schwerpunkt_allgemeine_tiermedizin = '_schwerpunkt_allgemeine_tiermedizin';
    public static _schwerpunkt_alternativmedizin = '_schwerpunkt_alternativmedizin';
    public static _schwerpunkt_fahrpraxis = '_schwerpunkt_fahrpraxis';
    public static _schwerpunkt_bestandsbetreuung = '_schwerpunkt_bestandsbetreuung';
    public static _schwerpunkt_chirurgie = '_schwerpunkt_chirurgie';
    public static _schwerpunkt_gynaekologie = '_schwerpunkt_gynaekologie';
    public static _schwerpunkt_haut_augenerkrankung = '_schwerpunkt_haut_augenerkrankung';
    public static _schwerpunkt_innere_medizin = '_schwerpunkt_innere_medizin';
    public static _schwerpunkt_intensivmedizin = '_schwerpunkt_intensivmedizin';
    public static _schwerpunkt_kardiologie = '_schwerpunkt_kardiologie';
    public static _schwerpunkt_klassische_tiermedizin = '_schwerpunkt_klassische_tiermedizin';
    public static _schwerpunkt_neurologie = '_schwerpunkt_neurologie';
    public static _schwerpunkt_notdienste = '_schwerpunkt_notdienste';
    public static _schwerpunkt_onkologie = '_schwerpunkt_onkologie';
    public static _schwerpunkt_operationen_knochen = '_schwerpunkt_operationen_knochen';
    public static _schwerpunkt_operationen_weichteile = '_schwerpunkt_operationen_weichteile';
    public static _schwerpunkt_operationen = '_schwerpunkt_operationen';
    public static _schwerpunkt_op_assistenz = '_schwerpunkt_op_assistenz';
    public static _schwerpunkt_roentgen = '_schwerpunkt_roentgen';
    public static _schwerpunkt_sonstiges = '_schwerpunkt_sonstiges';
    public static _schwerpunkt_stationaere_aufnahme = '_schwerpunkt_stationaere_aufnahme';
    public static _schwerpunkt_stationaere_betreuung = '_schwerpunkt_stationaere_betreuung';
    public static _schwerpunkt_sprechstunde = '_schwerpunkt_sprechstunde';
    public static _schwerpunkt_ultraschall = '_schwerpunkt_ultraschall';
    public static _schwerpunkt_untersuchung_laborproben = '_schwerpunkt_untersuchung_laborproben';
    public static _schwerpunkt_ueberwachung_von_narkose = '_schwerpunkt_ueberwachung_von_narkose';
    public static _schwerpunkt_verhaltenstherapie = '_schwerpunkt_verhaltenstherapie';
    public static _schwerpunkt_veranstaltungsbetreuung = '_schwerpunkt_veranstaltungsbetreuung';
    public static _schwerpunkt_zahn_kieferchirugie = '_schwerpunkt_zahn_kieferchirugie';
    public static _schwerpunkt_zahn_behandlungen = '_schwerpunkt_zahn_behandlungen';
    public static _schwerpunkt_zahnheilkunde = '_schwerpunkt_zahnheilkunde';
    public static _schwerpunkt_nachtdienste = '_schwerpunkt_nachtdienste';
    public static _schwerpunkt_nachtdienste_anzahl = '_schwerpunkt_nachtdienste_anzahl';
    public static _schwerpunkt_wochenenddienste = '_schwerpunkt_wochenenddienste';
    public static _schwerpunkt_wochenenddienste_anzahl = '_schwerpunkt_wochenenddienste_anzahl';
    public static _schwerpunkt_ophthalmologie = '_schwerpunkt_ophthalmologie';
    public static _schwerpunkt_dermatologie = '_schwerpunkt_dermatologie';
    public static _schwerpunkt_orthopaedie = '_schwerpunkt_orthopaedie';
    public static _schwerpunkt_alternative_heilmethoden = '_schwerpunkt_alternative_heilmethoden';
    public static _schwerpunkt_weichteilchirurgie = '_schwerpunkt_weichteilchirurgie';
    public static _schwerpunkt_osteosynthese = '_schwerpunkt_osteosynthese';
    public static _schwerpunkt_arthroskopie = '_schwerpunkt_arthroskopie';
    public static _schwerpunkt_augenheilkunde = '_schwerpunkt_augenheilkunde';
    public static _schwerpunkt_reproduktionsmedizin = '_schwerpunkt_reproduktionsmedizin';
    public static _schwerpunkt_ernaehrung = '_schwerpunkt_ernaehrung';
    public static _schwerpunkt_notfallmedizin = '_schwerpunkt_notfallmedizin';
    public static _schwerpunkt_endokrinologie = '_schwerpunkt_endokrinologie';
    public static _schwerpunkt = '_schwerpunkt';
    public static _seo_description = '_seo_description';
    public static _seo_hiringorganization_name = '_seo_hiringorganization_name';
    public static _seo_hiringorganization_sameas = '_seo_hiringorganization_sameas';
    public static _seo_joblocation_addresslocality = '_seo_joblocation_addresslocality';
    public static _seo_joblocation_streetaddress = '_seo_joblocation_streetaddress';
    public static _seo_joblocation_addressregion = '_seo_joblocation_addressregion';
    public static _seo_joblocation_postalcode = '_seo_joblocation_postalcode';
    public static _seo_joblocation_addresscountry = '_seo_joblocation_addresscountry';
    public static _slug = '_slug';
    public static _slug_v2 = '_slug_v2';
    public static _stammdaten_adresse = '_stammdaten_adresse';
    public static _stammdaten_anrede = '_stammdaten_anrede';
    public static _stammdaten_email = '_stammdaten_email';
    public static _stammdaten_fax = '_stammdaten_fax';
    public static _stammdaten_grussform = '_stammdaten_grussform';
    public static _stammdaten_mobil = '_stammdaten_mobil';
    public static _stammdaten_nachname = '_stammdaten_nachname';
    public static _stammdaten_nr = '_stammdaten_nr';
    public static _stammdaten_ort = '_stammdaten_ort';
    public static _stammdaten_plz = '_stammdaten_plz';
    public static _stammdaten_strasse = '_stammdaten_strasse';
    public static _stammdaten_telefon = '_stammdaten_telefon';
    public static _stammdaten_titel = '_stammdaten_titel';
    public static _stammdaten_vorname = '_stammdaten_vorname';
    public static _stammdaten_praxisname = '_stammdaten_praxisname';
    public static _stammdaten_ansprechpartner = '_stammdaten_ansprechpartner';
    public static _stammdaten_iso3166_1 = '_stammdaten_iso3166_1';
    public static _stammdaten_iso3166_2 = '_stammdaten_iso3166_2';
    public static _standort = '_standort';
    public static _standort_beschreibung = '_standort_beschreibung';
    public static _standort_distance = '_standort_distance';
    public static _standort_einwohnerzahl = '_standort_einwohnerzahl';
    public static _standort_anzahl_parkplatz = '_standort_anzahl_parkplatz';
    public static _standort_entfernung_bahnhof = '_standort_entfernung_bahnhof';
    public static _standort_entfernung_bushaltestelle = '_standort_entfernung_bushaltestelle';
    public static _standort_entfernung_einkauf = '_standort_entfernung_einkauf';
    public static _standort_entfernung_schule = '_standort_entfernung_schule';
    public static _standort_entfernung_grundschule = '_standort_entfernung_grundschule';
    public static _standort_entfernung_kindergarten = '_standort_entfernung_kindergarten';
    public static _standort_hat_bahnhof = '_standort_hat_bahnhof';
    public static _standort_hat_bushaltestelle = '_standort_hat_bushaltestelle';
    public static _standort_hat_einkauf = '_standort_hat_einkauf';
    public static _standort_hat_parkplatz = '_standort_hat_parkplatz';
    public static _standort_hat_schule = '_standort_hat_schule';
    public static _standort_hat_grundschule = '_standort_hat_grundschule';
    public static _standort_hat_kindergarten = '_standort_hat_kindergarten';
    public static _standort_hat_mitarbeiterparkplatz = '_standort_hat_mitarbeiterparkplatz';
    public static _standort_hat_parkmoeglichkeiten = '_standort_hat_parkmoeglichkeiten';
    public static _standort_hat_oeffentliche_anbindung = '_standort_hat_oeffentliche_anbindung';
    public static _standort_immobilie = '_standort_immobilie';
    public static _standort_immobilie_inklusive = '_standort_immobilie_inklusive';
    public static _standort_geolocated = '_standort_geolocated';
    public static _standort_mietzins = '_standort_mietzins';
    public static _standort_lat = '_standort_lat';
    public static _standort_lng = '_standort_lng';
    public static _standort_land = '_standort_land';
    public static _standort_plz = '_standort_plz';
    public static _standort_region = '_standort_region';
    public static _stellenart_assistenzstelle = '_stellenart_assistenzstelle';
    public static _stellenart_praktikum = '_stellenart_praktikum';
    public static _stellenart_praxisvertreter = '_stellenart_praxisvertreter';
    public static _stellenart_tfa = '_stellenart_tfa';
    public static _stellenart_praktikum_ausland = '_stellenart_praktikum_ausland';
    public static _stellenart_selbststaendig = '_stellenart_selbststaendig';
    public static _stellenart_teilhaber = '_stellenart_teilhaber';
    public static _stellenart_tierarzt = '_stellenart_tierarzt';
    public static _stellenart_tfa_ausbildung = '_stellenart_tfa_ausbildung';
    public static _stellenart_dissertation = '_stellenart_dissertation';
    public static _stellenart_sonstiges = '_stellenart_sonstiges';
    public static _stellenart_is_empfehlung = '_stellenart_is_empfehlung';
    public static _subtitle = '_subtitle';
    public static _taetigkeit_ort = '_taetigkeit_ort';
    public static _taetigkeit_nur_vor_ort = '_taetigkeit_nur_vor_ort';
    public static _taetigkeit_nur_dezentral = '_taetigkeit_nur_dezentral';
    public static _taetigkeit_ort_kombiniert = '_taetigkeit_ort_kombiniert';
    public static _title = '_title';
    public static _umfang_vollzeit = '_umfang_vollzeit';
    public static _umfang_teilzeit = '_umfang_teilzeit';
    public static _umfang_nebenbeschaeftigung = '_umfang_nebenbeschaeftigung';
    public static _vormittag_dienstag = '_vormittag_dienstag';
    public static _vormittag_donnerstag = '_vormittag_donnerstag';
    public static _vormittag_freitag = '_vormittag_freitag';
    public static _vormittag_mittwoch = '_vormittag_mittwoch';
    public static _vormittag_montag = '_vormittag_montag';
    public static _vormittag_samstag = '_vormittag_samstag';
    public static _voraussetzungen_deutsche_approbation_tierarzt = '_voraussetzungen_deutsche_approbation_tierarzt';
    public static _voraussetzungen_min_berufserfahrungen = '_voraussetzungen_min_berufserfahrungen';
    public static _voraussetzungen_berufserfahrungen_in_jahren = '_voraussetzungen_berufserfahrungen_in_jahren';
    public static _voraussetzungen_min_semester = '_voraussetzungen_min_semester';
    public static _voraussetzungen_semester_anzahl = '_voraussetzungen_semester_anzahl';
    public static _voraussetzungen_bereits_absolviertes_internship = '_voraussetzungen_bereits_absolviertes_internship';
    public static _voraussetzungen_hat_titel = '_voraussetzungen_hat_titel';
    public static _voraussetzungen_title_zusatzbezeichnung = '_voraussetzungen_title_zusatzbezeichnung';
    public static _voraussetzungen_notdienst_bereitschaft = '_voraussetzungen_notdienst_bereitschaft';
    public static _voraussetzungen_belastbarkeit = '_voraussetzungen_belastbarkeit';
    public static _voraussetzungen_empathischer_umgang = '_voraussetzungen_empathischer_umgang';
    public static _voraussetzungen_selbststaendiges_arbeiten = '_voraussetzungen_selbststaendiges_arbeiten';
    public static _voraussetzungen_teamfaehigkeit = '_voraussetzungen_teamfaehigkeit';
    public static _voraussetzungen_fuehrerschein = '_voraussetzungen_fuehrerschein';
    public static _voraussetzungen_eigenes_auto = '_voraussetzungen_eigenes_auto';
    public static _voraussetzungen_puenktlichkeit = '_voraussetzungen_puenktlichkeit';
    public static _voraussetzungen_zuverlaessigkeit = '_voraussetzungen_zuverlaessigkeit';
    public static _voraussetzungen_interesse_an_weiterbildung = '_voraussetzungen_interesse_an_weiterbildung';
    public static _voraussetzungen_kollegialer_umgang = '_voraussetzungen_kollegialer_umgang';
    public static _wunschort1_location_address = '_wunschort1_location_address';
    public static _wunschort1_location = '_wunschort1_location';
    public static _wunschort2_location_address = '_wunschort2_location_address';
    public static _wunschort2_location = '_wunschort2_location';
    public static _wunschort3_location_address = '_wunschort3_location_address';
    public static _wunschort3_location = '_wunschort3_location';
    public static _wunschort_flexibel = '_wunschort_flexibel';
    public static _wunschorte = '_wunschorte';
    public static _wochenstunden = '_wochenstunden';
    public static _wochentag_montag = '_wochentag_montag';
    public static _wochentag_dienstag = '_wochentag_dienstag';
    public static _wochentag_mittwoch = '_wochentag_mittwoch';
    public static _wochentag_donnerstag = '_wochentag_donnerstag';
    public static _wochentag_freitag = '_wochentag_freitag';
    public static _wochentag_samstag = '_wochentag_samstag';
    public static _wochentag_sonntag = '_wochentag_sonntag';
    public static _zusatzinfos = '_zusatzinfos';
    public static _erreichbarkeit_uhrzeit = '_erreichbarkeit_uhrzeit';


        public static labels = {
        'de': {
            request_type: 'Request.request_type',
            is_opted_in: 'Request.is_opted_in',
            uuid: 'Request.uuid',
            user_uuid: 'Request.user_uuid',
            offer_type: 'Request.offer_type',
            create_date: 'Request.create_date',
            modify_date: 'Request.modify_date',
            delete_date: 'Request.delete_date',
            expire_date: 'Request.expire_date',
            last_mail_date: 'Request.last_mail_date',
            opt_in_date: 'Request.opt_in_date',
            opt_out_date: 'Request.opt_out_date',
            import_id: 'Request.import_id',
            is_public: 'Request.is_public',
            is_intern: 'Request.is_intern',
            need_payment: 'Request.need_payment',
            _chiffre: 'Chiffre',
            _admin_status: 'Request._admin_status',
            _admin_is_public: 'Request._admin_is_public',
            _admin_is_sold: 'Request._admin_is_sold',
            _admin_is_deleted: 'Request._admin_is_deleted',
            _admin_is_reserved: 'Request._admin_is_reserved',
            _admin_is_upper_class: 'Request._admin_is_upper_class',
            _admin_tvd_ansprechpartner: 'Request._admin_tvd_ansprechpartner',
            _admin_zusatzinfos: 'Request._admin_zusatzinfos',
            _arbeitsantritt: 'Arbeitsantritt',
            _arbeitsantritt_monate: 'Arbeitsantritt in Monaten',
            _arbeitsumfang: 'Arbeitsumfang',
            _arbeitsumfang_teilzeit: 'Teilzeit',
            _arbeitsumfang_vollzeit: 'Vollzeit',
            _arbeitsumfang_dauer: 'Dauer',
            _arbeitsumfang_dauer_min_wochen: 'Mindestens',
            _arbeitsumfang_dauer_max_wochen: 'Maximal',
            _arbeitsumfang_dauer_sonstiges: 'Sonstiges',
            _arbeitsumfang_nebenbeschaeftigung: 'Nebenbeschäftigung',
            _arbeitszeit_montag: 'Arbeitszeit Montag',
            _arbeitszeit_dienstag: 'Arbeitszeit Dienstag',
            _arbeitszeit_mittwoch: 'Arbeitszeit Mittwoch',
            _arbeitszeit_donnerstag: 'Arbeitszeit Donnerstag',
            _arbeitszeit_freitag: 'Arbeitszeit Freitag',
            _arbeitszeit_samstag: 'Arbeitszeit Samstag',
            _arbeitszeit_sonntag: 'Arbeitszeit Sonntag',
            _ausstattung_autoclav: 'Autoclav',
            _ausstattung_behandlungstisch: 'Behandlungstisch',
            _ausstattung_blutanalyse: 'Blutanalyse',
            _ausstattung_boxen: 'Boxen',
            _ausstattung_computer_drucker: 'Computer & Drucker',
            _ausstattung_endoskop: 'Endoskop',
            _ausstattung_gefriertruhe: 'Gefriertruhe',
            _ausstattung_inhouse_labor: 'Inhouse Labor',
            _ausstattung_klimaanlage: 'Klimaanlage',
            _ausstattung_kuehlschrank: 'Kühlschrank',
            _ausstattung_mikroskop: 'Mikroskop',
            _ausstattung_narkosegeraet: 'Narkosegeraet',
            _ausstattung_op_besteck: 'OP Besteck',
            _ausstattung_op_lampen: 'OP Lampen',
            _ausstattung_osteosynthese_set: 'Osteosynthese Set',
            _ausstattung_otoskop: 'Otoskop',
            _ausstattung_roentgen_analog: 'Analoges Röntgen',
            _ausstattung_roentgen_digital: 'Digitales Röntgen',
            _ausstattung_sonstiges: 'Sonstiges',
            _ausstattung_telefonanlagen: 'Telefonanlagen',
            _ausstattung_tierwaage: 'Tierwaage',
            _ausstattung_trockner: 'Trockner',
            _ausstattung_ultraschall: 'Ultraschall',
            _ausstattung_herzultraschall: 'Herzultraschall',
            _ausstattung_waschmaschine: 'Waschmaschine',
            _ausstattung_zahnsteineinheit: 'Zahnsteineinheit',
            _ausstattung_szintigraph: 'Szintigraph',
            _ausstattung_station: 'Station / Stationsboxen',
            _ausstattung_getrennter_wartebereich: 'Getrennte Wartebereiche für Katzen und Hunde',
            _ausstattung_software_zur_praxisverwaltung: 'Software zur Praxisverwaltung',
            _ausstattung_crm: 'CRM',
            _ausstattung_ct: 'CT',
            _ausstattung_mrt: 'MRT',
            _ausstattung: 'Ausstattung',
            _ausgleich_notdienst: 'Ausgleich bei Notdienst',
            _behandlung_op_mit_narkose: 'OP inkl. Inhalationsnarkose',
            _behandlung_ernaehrungsberatung: 'Ernährungsberatung',
            _behandlung_osteosynthese: 'Osteosynthese',
            _behandlung_stationaer: 'Stationäre Aufnahme von Patienten',
            _behandlung_osteopathie: 'Osteopathie',
            _behandlung_physiotherapie: 'Physiotherapie',
            _behandlung_chiropraktik: 'Chiropraktik',
            _behandlung_akupunktur: 'Akupunktur',
            _behandlung_herzultraschall: 'Herzultraschall',
            _behandlung_ultraschall: 'Ultraschall',
            _behandlung_digitales_roentgen: 'Digitales Röntgen',
            _behandlung_mobiles_roentgen: 'Mobiles Röntgen',
            _behandlung_zahnbehandlungen_mobil: 'Zahnbehandlungen mobil',
            _behandlung_dentalroentgen: 'Dentalröntgen',
            _behandlung_inhouse_labor: 'Inhouse Labor',
            _behandlung_sonstiges: 'Sonstiges',
            _behandlung: 'Behandlung',
            _behandlung_ct: 'CT',
            _behandlung_mrt: 'MRT',
            _behandlung_szintigraphie: 'Szintigraphie',
            _behandlung_endoskopie: 'Endoskopie',
            _benoetigte_unterlagen: 'Benötigte Unterlagen',
            _benoetigte_bewerber_voraussetzungen: 'Voraussetzungen für Bewerbende',
            _beschreibung_text: 'Beschreibung',
            _beschreibung_titel: 'Überschrift',
            _besonderheit: 'Besonderheit',
            _besonderheit_sonstiges: 'Sonstiges',
            _besonderheit_cat_friendly: 'Cat friendly practice/clinic',
            _besonderheit_mitnahme_hund: 'Möglichkeit der Mitnahme meines Hundes',
            _besonderheit_moegl_doktorarbeit: 'Doktorarbeit berufsbegleitend möglich',
            _besonderheit_moegl_teilhaberschaft: 'Möglichkeit zur Teilhaberschaft',
            _besonderheit_praktikumsverguetung: 'Praktikumsvergütung',
            _besonderheit_bez_fortbildung: 'Bezahlte Fortbildungen',
            _besonderheit_wohnen_vor_ort: 'Wohnmöglichkeit vor Ort',
            _besonderheit_kein_nachtdienst: 'Keine Nachtdienste',
            _besonderheit_bkv: 'Betriebliche Krankenversicherung',
            _besonderheit_bav: 'Betriebliche Altersvorsorge',
            _besonderheit_eigenverantwortung: 'Eigenverantwortliches Arbeiten',
            _besonderheit_gehaltsmodell: 'Umsatzbeteiligung möglich',
            _besonderheit_gute_einarbeitung: 'Gründliche Einarbeitungsphase',
            _besonderheit_gutes_team: 'Starker Teamzusammenhalt',
            _besonderheit_familiaere: 'Familiäre Arbeitsatmosphäre',
            _besonderheit_hohes_niveau: 'Arbeiten auf höchstem Niveau',
            _besonderheit_moderne_ausstattung: 'Moderne technische Ausstattung',
            _besonderheit_feedbackgespraeche: 'Regelmäßige Feedbackgespräche',
            _besonderheit_arbeitszeiterfassung: 'Digitale Arbeitszeiterfassung',
            _besonderheit_bezahlte_fortbildung: 'Bezahlte Fortbildungen',
            _besonderheit_interne_fortbildungen: 'Regelmäßige interne Fortbildungen',
            _besonderheit_weihnachtsgeld: 'Weihnachtsgeld',
            _besonderheit_urlaubsgeld: 'Urlaubsgeld',
            _besonderheit_dienstwagen: 'Dienstwagen',
            _besonderheit_dienstwagen_privat: 'Dienstwagen auch zur privaten Nutzung',
            _besonderheit_mittagessen_kostenlos: 'Kostenloses Mittagessen',
            _besonderheit_rotation_in_bereiche: 'Rotation in verschiedenen Bereichen',
            _besonderheit_sportangebot: 'Sportangebot für Mitarbeiter',
            _besonderheit_teamevents: 'Regelmäßige Teamevents',
            _besonderheit_mitglied_vuk: 'Mitglied im VuK',
            _besonderheit_praktikumsleitfaden: 'Orientierung an einem Praktikumsleitfaden',
            _besonderheit_notdienstring: 'Teilnahme am Notdienstring',
            _besonderheit_kein_notdienst: 'Keine Notdienste',
            _besonderheit_gvp_zertifiziert: 'GVP zertifiziert',
            _besonderheit_praktikum_basics: 'Basics können selbständig durchgeführt werden (Injektionen, Katheter legen, Allgemeinuntersuchung, etc.)',
            _besonderheit_vwl: 'Vermögenswirksame Leistungen',
            _besonderheit_fahrtkostenzuschuss: 'Fahrtkostenzuschuss/Tankgutschein',
            _besonderheit_keine_nacht_und_wochenenddienste: 'Keine Not- und Wochenenddienste',
            _besonderheit_nacht_und_wochenenddienste_auf_wunsch: 'Not- und Wochenenddienste auf Wunsch',
            _besonderheit_nacht_und_wochenenddienste_geregelt: 'Geregelte Not- und Wochenenddienste',
            _besonderheit_berufseinsteiger_willkommen: 'Berufseinsteiger willkommen',
            _bezahlte_stelle: 'Bezahlte Stelle?',
            _an_stelle_gekoppelt: 'An Stelle gekoppelt?',
            _datenschutz_akzeptiert: 'Datenschutz akzeptiert',
            _einrichtung_sonstiges: 'Sonstiges',
            _einrichtung_tierklinik: 'Tierklinik / Tiergesundheitszentrum',
            _einrichtung_tierarztpraxis: 'Tierarztpraxis',
            _einrichtung_fahrpraxis: 'Fahrpraxis',
            _einrichtung_fachtierarztpraxis: 'Fachtierarztpraxis',
            _einrichtung_industrie: 'Industrie',
            _einrichtung_oeffentlicher_dienst: 'Öffentlicher Dienst',
            _einrichtung_hochschule: 'Hochschule',
            _einrichtung: 'Sonstiges',
            _externes_medium1: 'Externes Medium',
            _externes_medium2: 'Externes Medium',
            _externes_medium3: 'Externes Medium',
            _facharzt_abschluesse: 'Abschlüsse',
            _facharzt_ausbildung: 'Facharzt',
            _facharzt_abschluss1: 'Erster Abschluss',
            _facharzt_abschluss2: 'Zweiter Abschluss',
            _facharzt_abschluss3: 'Dritter Abschluss',
            _feedback_gefunden_ueber: 'Woher kennen Sie job.vet?',
            _feedback_gefunden_ueber_tvd: 'Über TVD',
            _feedback_gefunden_ueber_empfehlung: 'Empfehlung',
            _feedback_gefunden_ueber_google: 'Google',
            _feedback_gefunden_ueber_newsletter: 'Newsletter',
            _feedback_gefunden_ueber_facebook: 'Facebook',
            _feedback_gefunden_ueber_instagram: 'Instagram',
            _feedback_gefunden_ueber_sonstiges: 'Sonstiges',
            _fortbildungsbudget: 'Fortbildungsbudget',
            _fortbildungsbudget_vorhanden: 'Fortbildungsbudget',
            _fortbildungsbudget_nach_vereinbarung: 'Nach Vereinbarung',
            _urlaubstage: 'Urlaubstage',
            _fortbildungstage: 'Fortbildungstage',
            _fortbildungstage_nach_vereinbarung: 'Nach Vereinbarung',
            _fortbildung_regelm_intern: 'regelmäßige, interne Fortbildungen',
            _fortbildung_freie_tage: 'freie Tage für Fortbildungen',
            _geplante_dauer: 'Geplante Dauer',
            _homepage: 'Homepage',
            _icon_name: 'Icon',
            _infotext: 'Infotext',
            _lebensphase: 'Lebensphase',
            _mitarbeiter_assistenten: 'Assistenten',
            _mitarbeiter_auszubildende: 'Auszubildende',
            _mitarbeiter_in_mutterschutz: 'In Mutterschutz',
            _mitarbeiter_minijobs: 'Minjobs',
            _mitarbeiter_tfa: 'TFAs',
            _mitarbeiter_ta: 'Tierärzte',
            _mitarbeiter_sonstige: 'Sonstige',
            _nachmittag_dienstag: 'Dienstag nachmittag',
            _nachmittag_donnerstag: 'Donnerstag nachmittag',
            _nachmittag_freitag: 'Freitag nachmittag',
            _nachmittag_mittwoch: 'Mittwoch nachmittag',
            _nachmittag_montag: 'Montag nachmittag',
            _nachmittag_samstag: 'Samstag nachmittag',
            _nachricht: 'Ihre Nachricht',
            _parallele_anstellung: 'Parallele Anstellung in Praxis/anderem Unternehmen möglich?',
            _praxis_abgabe_in: 'Abgabe geplant in',
            _praxis_apothekenbestand: 'Apothekenbestand',
            _praxis_art: 'Praxisart',
            _praxis_art_sonstiges: 'Sonstiges',
            _praxis_art_kleintier: 'Kleintiere',
            _praxis_art_pferde: 'Pferde',
            _praxis_art_gemischt: 'Gemischt',
            _praxis_art_rinder: 'Rinder',
            _praxis_art_gefluegel: 'Geflügel',
            _praxis_art_schwein: 'Schweine',
            _praxis_art_reptilien: 'Reptilien',
            _praxis_art_industrie: 'Industrie',
            _praxis_art_verwaltung: 'Verwaltung',
            _praxis_art_universitaet: 'Universität',
            _praxis_art_heimtiere: 'Heimtiere',
            _praxis_art_hochschule: 'Hochschule',
            _praxis_art_oeffentlicher_dienst: 'Öffentlicher Dienst',
            _praxis_art_wildtiere: 'Wildtiere',
            _praxis_art_ziervoegel: 'Ziervögel',
            _praxis_hat_preisvorstellung: 'Preisvorstellung vorhanden',
            _praxis_fachliche_spezialisierung: 'Fachliche Spezialisierung',
            _praxis_form: 'Praxisform',
            _praxis_flaeche: 'Fläche',
            _praxis_fortbildung: 'Weiterbildungsermächtigung',
            _praxis_fortbildung_angebot: 'Fortbildungsangebot',
            _praxis_gruendungsjahr: 'Gründungsjahr',
            _praxis_hat_notdienst: 'Notdienste',
            _praxis_notdienst: 'Notdienst',
            _praxis_notdienst_auszahlung: 'Notdienstvergütung',
            _praxis_notdienst_freizeitausgleich: 'Notdienst Freizeitausgleich',
            _praxis_sprechstunde: 'Sprechstundenform',
            _praxis_hat_terminsprechstunde: 'Terminvereinbarung',
            _praxis_hat_vertretungsregelung: 'Vertretungsregelung',
            _praxis_jahresnettoumsatz_2015: 'Jahresnettoumsatz 2015',
            _praxis_jahresnettoumsatz_2016: 'Jahresnettoumsatz 2016',
            _praxis_jahresnettoumsatz_2017: 'Jahresnettoumsatz 2017',
            _praxis_jahresnettoumsatz_2018: 'Jahresnettoumsatz 2018',
            _praxis_jahresnettoumsatz_2019: 'Jahresnettoumsatz 2019',
            _praxis_jahresnettoumsatz_2020: 'Jahresnettoumsatz 2020',
            _praxis_jahresnettoumsatz_2021: 'Jahresnettoumsatz 2021',
            _praxis_jahresnettoumsatz_2022: 'Jahresnettoumsatz 2022',
            _praxis_kurzbeschreibung: 'Kurzbeschreibung',
            _praxis_materieller_wert: 'Materieller Wert',
            _praxis_patientenbesitzer: 'Patientenbesitzer',
            _praxis_preisvorstellung: 'Preisvorstellung',
            _praxis_sonstige_beschreibung: 'Sonstige Beschreibung',
            _praxis_telefon_homepage_uebernahme: 'Telefonnummer und Homepage übernahme',
            _praxis_uebergabedatum: 'Übergabedatum',
            _praxis_uebernahme_in: 'Übernahme in',
            _praxis_uebernahme: 'Übernahme',
            _praxis_teilhaber: 'Teilhaber',
            _praxis_ueberschrift: 'Überschrift',
            _praxis_zulassungsstatus: 'Zulassungsstatus',
            _praxis_sprache_1: '1. Sprache',
            _praxis_sprache_2: '2. Sprache',
            _raeume_apotheke: 'Anzahl Apotheke',
            _raeume_behandlung1: 'Anzahl Behandlungszimmer',
            _raeume_behandlung2: 'Anzahl Behandlungszimmer',
            _raeume_empfang: 'Anzahl Empfangsbereich',
            _raeume_labor: 'Anzahl Labor',
            _raeume_op: 'Anzahl OP-Raum',
            _raeume_op_vorb: 'Anzahl OP-Vorbereitungsraum',
            _raeume_roentgen: 'Anzahl Röntgenstation',
            _raeume_sonstige: 'Anzahl Sonstige',
            _raeume_sozial: 'Anzahl Sozialraum',
            _raeume_station: 'Anzahl Station',
            _raeume_warten: 'Anzahl Wartezimmer',
            _raeume_wc: 'Anzahl Toiletten',
            _raeume_mitarbeiter: 'Anzahl Mitarbeiterraum',
            _raeume_vorhanden_apotheke: 'Apotheke',
            _raeume_vorhanden_behandlung1: 'Behandlungszimmer',
            _raeume_vorhanden_behandlung2: 'Behandlungszimmer',
            _raeume_vorhanden_empfang: 'Empfangsbereich',
            _raeume_vorhanden_labor: 'Labor',
            _raeume_vorhanden_op: 'OP-Raum',
            _raeume_vorhanden_op_vorb: 'OP-Vorbereitungsraum',
            _raeume_vorhanden_roentgen: 'Röntgenstation',
            _raeume_vorhanden_sozial: 'Sozialraum',
            _raeume_vorhanden_station: 'Station',
            _raeume_vorhanden_warten: 'Wartezimmer',
            _raeume_vorhanden_wc: 'Toiletten',
            _raeume_vorhanden_sonstige: 'Sonstige',
            _raeume_vorhanden_mitarbeiter: 'Mitarbeiterraum',
            _ratio_exoten: 'Anteil in % Exoten',
            _ratio_kleintiere: 'Anteil in % Kleintiere',
            _ratio_nutztiere: 'Anteil in % Nutztiere',
            _ratio_pferde: 'Anteil in % Pferde',
            _ratio_rinder: 'Anteil in % Rinder',
            _ratio_schweine: 'Anteil in % Schweine',
            _ratio_gefluegel: 'Anteil in % Geflügel',
            _ratio_heimtiere: 'Anteil in % Heimtiere',
            _ratio_reptilien: 'Anteil in % Reptilien',
            _ratio_oeffentlicher_dienst: 'Anteil in % Öffentlicher Dienst',
            _ratio_industrie: 'Anteil in % Industrie',
            _ratio_sonstiges: 'Anteil in % Sonstiges',
            _alle_regionen: 'Alle Regionen',
            _region_bw: 'Baden-Württemberg',
            _region_by: 'Bayern',
            _region_be: 'Berlin',
            _region_bb: 'Brandenburg',
            _region_hb: 'Bremen',
            _region_hh: 'Hamburg',
            _region_he: 'Hessen',
            _region_mv: 'Mecklenburg-Vorpommern',
            _region_ni: 'Niedersachsen',
            _region_nw: 'Nordrhein-Westfalen',
            _region_rp: 'Rheinland-Pfalz',
            _region_sl: 'Saarland',
            _region_sn: 'Sachsen',
            _region_sa: 'Sachsen-Anhalt',
            _region_sh: 'Schleswig-Holstein',
            _region_th: 'Thüringen',
            _region_0x: 'PLZ-Bereich 0xxxx',
            _region_1x: 'PLZ-Bereich 1xxxx',
            _region_2x: 'PLZ-Bereich 2xxxx',
            _region_3x: 'PLZ-Bereich 3xxxx',
            _region_4x: 'PLZ-Bereich 4xxxx',
            _region_5x: 'PLZ-Bereich 5xxxx',
            _region_6x: 'PLZ-Bereich 6xxxx',
            _region_7x: 'PLZ-Bereich 7xxxx',
            _region_8x: 'PLZ-Bereich 8xxxx',
            _region_9x: 'PLZ-Bereich 9xxxx',
            _schwerpunkt_apothekenmanagement: 'Apothekenmanagement',
            _schwerpunkt_allgemeine_tiermedizin: 'Allgemeine Tiermedizin',
            _schwerpunkt_alternativmedizin: 'Alternative Tiermedizin',
            _schwerpunkt_fahrpraxis: 'Fahrpraxis',
            _schwerpunkt_bestandsbetreuung: 'Bestandsbetreuung',
            _schwerpunkt_chirurgie: 'Chirurgie',
            _schwerpunkt_gynaekologie: 'Gynäkologie',
            _schwerpunkt_haut_augenerkrankung: 'Behandlung von Haut- und Augenerkrankungen',
            _schwerpunkt_innere_medizin: 'Innere Medizin',
            _schwerpunkt_intensivmedizin: 'Intensivmedizin',
            _schwerpunkt_kardiologie: 'Kardiologie',
            _schwerpunkt_klassische_tiermedizin: 'Klassische Tiermedizin',
            _schwerpunkt_neurologie: 'Neurologie',
            _schwerpunkt_notdienste: 'Geregelte Notdienste',
            _schwerpunkt_onkologie: 'Onkologie',
            _schwerpunkt_operationen_knochen: 'Operationen an Knochen',
            _schwerpunkt_operationen_weichteile: 'Operationen an Weichteilen',
            _schwerpunkt_operationen: 'Operationen',
            _schwerpunkt_op_assistenz: 'OP-Assistenz',
            _schwerpunkt_roentgen: 'Bildgebung',
            _schwerpunkt_sonstiges: 'Sonstiges',
            _schwerpunkt_stationaere_aufnahme: 'Stationäre Aufnahme von Patienten',
            _schwerpunkt_stationaere_betreuung: 'Betreuung stationärer Patienten',
            _schwerpunkt_sprechstunde: 'Führen der Sprechstunde',
            _schwerpunkt_ultraschall: 'Ultraschall',
            _schwerpunkt_untersuchung_laborproben: 'Untersuchung von Laborproben',
            _schwerpunkt_ueberwachung_von_narkose: 'Überwachung von Narkose',
            _schwerpunkt_verhaltenstherapie: 'Verhaltenstherapie',
            _schwerpunkt_veranstaltungsbetreuung: 'Betreuung von Veranstaltungen (z. B. Turniere, Hundeshows)',
            _schwerpunkt_zahn_kieferchirugie: 'Zahn- und Kieferchirurgie',
            _schwerpunkt_zahn_behandlungen: 'Durchführung von Zahnbehandlungen',
            _schwerpunkt_zahnheilkunde: 'Zahnheilkunde',
            _schwerpunkt_nachtdienste: 'Geregelte Nachtdienste',
            _schwerpunkt_nachtdienste_anzahl: 'Nachtdienste pro Monat',
            _schwerpunkt_wochenenddienste: 'Geregelte Wochenenddienste',
            _schwerpunkt_wochenenddienste_anzahl: 'Wochenenddienste pro Monat',
            _schwerpunkt_ophthalmologie: 'Ophthalmologie',
            _schwerpunkt_dermatologie: 'Dermatologie',
            _schwerpunkt_orthopaedie: 'Orthopädie',
            _schwerpunkt_alternative_heilmethoden: 'Alternative Heilmethoden',
            _schwerpunkt_weichteilchirurgie: 'Weichteilchirurgie',
            _schwerpunkt_osteosynthese: 'Osteosynthese',
            _schwerpunkt_arthroskopie: 'Arthroskopie',
            _schwerpunkt_augenheilkunde: 'Augenheilkunde',
            _schwerpunkt_reproduktionsmedizin: 'Reproduktionsmedizin',
            _schwerpunkt_ernaehrung: 'Ernährung',
            _schwerpunkt_notfallmedizin: 'Notfallmedizin',
            _schwerpunkt_endokrinologie: 'Endokrinologie',
            _schwerpunkt: 'Schwerpunkt',
            _seo_description: 'Kurzbeschreibung',
            _seo_hiringorganization_name: 'Anbieter',
            _seo_hiringorganization_sameas: 'URL',
            _seo_joblocation_addresslocality: 'Ort',
            _seo_joblocation_streetaddress: 'Straße',
            _seo_joblocation_addressregion: 'Region',
            _seo_joblocation_postalcode: 'PLZ',
            _seo_joblocation_addresscountry: 'Land',
            _slug: 'Slug',
            _slug_v2: 'Slug',
            _stammdaten_adresse: 'Adresse',
            _stammdaten_anrede: 'Anrede',
            _stammdaten_email: 'E-Mail-Adresse',
            _stammdaten_fax: 'Faxnummer',
            _stammdaten_grussform: 'Grußform',
            _stammdaten_mobil: 'Mobilnummer',
            _stammdaten_nachname: 'Nachname',
            _stammdaten_nr: 'Hausnummer',
            _stammdaten_ort: 'Ort',
            _stammdaten_plz: 'PLZ',
            _stammdaten_strasse: 'Straße',
            _stammdaten_telefon: 'Telefonnummer',
            _stammdaten_titel: 'Titel',
            _stammdaten_vorname: 'Vorname',
            _stammdaten_praxisname: 'Praxisname oder Name des Arbeitgebers',
            _stammdaten_ansprechpartner: 'Ansprechpartner',
            _stammdaten_iso3166_1: 'Landescode',
            _stammdaten_iso3166_2: 'Landescode erweitert',
            _standort: 'Standort',
            _standort_beschreibung: 'Standortbeschreibung',
            _standort_distance: 'Request._standort_distance',
            _standort_einwohnerzahl: 'Einwohnerzahl',
            _standort_anzahl_parkplatz: 'Anzahl Parkplätze',
            _standort_entfernung_bahnhof: 'Entfernung in km zum Bahnhof',
            _standort_entfernung_bushaltestelle: 'Entfernung in km zur Bushaltestelle',
            _standort_entfernung_einkauf: 'Entfernung in km zu Einkaufsmöglichkeit',
            _standort_entfernung_schule: 'Entfernung in km zu weiterbildende Schule',
            _standort_entfernung_grundschule: 'Entfernung in km zu Grundschule',
            _standort_entfernung_kindergarten: 'Entfernung in km zu Kindergarten',
            _standort_hat_bahnhof: 'Bahnhof in der Nähe',
            _standort_hat_bushaltestelle: 'Bushaltestelle in der Nähe',
            _standort_hat_einkauf: 'Einkaufsmöglichkeit in der Nähe',
            _standort_hat_parkplatz: 'Parkplatz in der Nähe',
            _standort_hat_schule: 'Weiterbildende Schule in der Nähe',
            _standort_hat_grundschule: 'Grundschule in der Nähe',
            _standort_hat_kindergarten: 'Kindergarten in der Nähe',
            _standort_hat_mitarbeiterparkplatz: 'Es stehen Mitarbeiterparkplätze zur Verfügung',
            _standort_hat_parkmoeglichkeiten: 'Es gibt ausreichende Parkmöglichkeiten in unmittelbarer Umgebung',
            _standort_hat_oeffentliche_anbindung: 'Mit öffentlichem Nahverkehr problemlos erreichbar',
            _standort_immobilie: 'Eigene Immobilie',
            _standort_immobilie_inklusive: 'Immobilie inklusiv',
            _standort_geolocated: 'Geolokalisiert',
            _standort_mietzins: 'Mietzins',
            _standort_lat: 'Breitengrad',
            _standort_lng: 'Längengrad',
            _standort_land: 'Land',
            _standort_plz: 'PLZ',
            _standort_region: 'Region',
            _stellenart_assistenzstelle: 'Assistenzstelle',
            _stellenart_praktikum: 'Praktikum in Deutschland',
            _stellenart_praxisvertreter: 'Praxisvertretung',
            _stellenart_tfa: 'Stelle als TFA (m/w/d)',
            _stellenart_praktikum_ausland: 'Praktikum im Ausland',
            _stellenart_selbststaendig: 'Selbständigkeit',
            _stellenart_teilhaber: 'Teilhaberschaft',
            _stellenart_tierarzt: 'Stelle als angestellter Tierarzt (m/w/d)',
            _stellenart_tfa_ausbildung: 'TFA-Ausbildung',
            _stellenart_dissertation: 'Dissertation',
            _stellenart_sonstiges: 'Sonstiges',
            _stellenart_is_empfehlung: 'Request._stellenart_is_empfehlung',
            _subtitle: 'Zweite Überschrift',
            _taetigkeit_ort: 'Ausübungsort der Tätigkeit',
            _taetigkeit_nur_vor_ort: 'Tätigkeit ausschließlich vor Ort',
            _taetigkeit_nur_dezentral: 'Tätigkeit ausschließlich dezentral',
            _taetigkeit_ort_kombiniert: 'Tätigkeitsort kombiniert',
            _title: 'Überschrift',
            _umfang_vollzeit: 'Vollzeit',
            _umfang_teilzeit: 'Teilzeit',
            _umfang_nebenbeschaeftigung: 'Nebenbeschäftigung',
            _vormittag_dienstag: 'Dienstag vormittag',
            _vormittag_donnerstag: 'Donnerstag vormittag',
            _vormittag_freitag: 'Freitag vormittag',
            _vormittag_mittwoch: 'Mittwoch vormittag',
            _vormittag_montag: 'Montag vormittag',
            _vormittag_samstag: 'Samstag vormittag',
            _voraussetzungen_deutsche_approbation_tierarzt: 'Deutsche Approbation als Tierarzt (m/w/d)',
            _voraussetzungen_min_berufserfahrungen: 'Berufserfahrung',
            _voraussetzungen_berufserfahrungen_in_jahren: 'Berufserfahrung in Jahren',
            _voraussetzungen_min_semester: 'Mindestens Anzahl Semester',
            _voraussetzungen_semester_anzahl: 'Semesteranzahl',
            _voraussetzungen_bereits_absolviertes_internship: 'Bereits absolviertes Internship',
            _voraussetzungen_hat_titel: 'FTA-Titel, Diplomate bzw. Zusatzbezeichnung',
            _voraussetzungen_title_zusatzbezeichnung: 'Titel und/oder Zusatzbezeichnung',
            _voraussetzungen_notdienst_bereitschaft: 'Bereitschaft an geregelten Notdiensten teilzunehmen',
            _voraussetzungen_belastbarkeit: 'Belastbarkeit',
            _voraussetzungen_empathischer_umgang: 'Empathischer Umgang mit Patienten und Patientenbesitzern',
            _voraussetzungen_selbststaendiges_arbeiten: 'Selbstständiges Arbeiten',
            _voraussetzungen_teamfaehigkeit: 'Teamfähigkeit',
            _voraussetzungen_fuehrerschein: 'Führerschein Klasse B',
            _voraussetzungen_eigenes_auto: 'Eigenes Auto',
            _voraussetzungen_puenktlichkeit: 'Pünktlichkeit',
            _voraussetzungen_zuverlaessigkeit: 'Zuverlässigkeit',
            _voraussetzungen_interesse_an_weiterbildung: 'Ausgeprägtes Interesse an Fort- und Weiterbildung',
            _voraussetzungen_kollegialer_umgang: 'Kollegialer Umgang',
            _wunschort1_location_address: 'Adresse',
            _wunschort1_location: '1. Wunschort',
            _wunschort2_location_address: 'Adresse',
            _wunschort2_location: '2. Wunschort',
            _wunschort3_location_address: 'Adresse',
            _wunschort3_location: '3. Wunschort',
            _wunschort_flexibel: 'Flexibel',
            _wunschorte: 'Wunschorte',
            _wochenstunden: 'Wochenstunden',
            _wochentag_montag: 'Montag',
            _wochentag_dienstag: 'Dienstag',
            _wochentag_mittwoch: 'Mittwoch',
            _wochentag_donnerstag: 'Donnerstag',
            _wochentag_freitag: 'Freitag',
            _wochentag_samstag: 'Samstag',
            _wochentag_sonntag: 'Sonntag',
            _zusatzinfos: 'Zusatzinfos',
            _erreichbarkeit_uhrzeit: 'Erreichbarkeit',

        }
    }

    public request_type: number;
    public is_opted_in: boolean;
    public uuid: string;
    public user_uuid: string;
    public offer_type: number;
    public create_date: string;
    public modify_date: string;
    public delete_date: string;
    public expire_date: string;
    public last_mail_date: string;
    public opt_in_date: string;
    public opt_out_date: string;
    public import_id: number;
    public is_public: boolean;
    public is_intern: boolean;
    public need_payment: boolean;
    public _chiffre: string;
    public _admin_status: string;
    public _admin_is_public: boolean;
    public _admin_is_sold: boolean;
    public _admin_is_deleted: boolean;
    public _admin_is_reserved: boolean;
    public _admin_is_upper_class: boolean;
    public _admin_tvd_ansprechpartner: string;
    public _admin_zusatzinfos: string;
    public _arbeitsantritt: string;
    public _arbeitsantritt_monate: string;
    public _arbeitsumfang: string;
    public _arbeitsumfang_teilzeit: string;
    public _arbeitsumfang_vollzeit: string;
    public _arbeitsumfang_dauer: string;
    public _arbeitsumfang_dauer_min_wochen: number;
    public _arbeitsumfang_dauer_max_wochen: number;
    public _arbeitsumfang_dauer_sonstiges: string;
    public _arbeitsumfang_nebenbeschaeftigung: string;
    public _arbeitszeit_montag: string;
    public _arbeitszeit_dienstag: string;
    public _arbeitszeit_mittwoch: string;
    public _arbeitszeit_donnerstag: string;
    public _arbeitszeit_freitag: string;
    public _arbeitszeit_samstag: string;
    public _arbeitszeit_sonntag: string;
    public _ausstattung_autoclav: boolean;
    public _ausstattung_behandlungstisch: boolean;
    public _ausstattung_blutanalyse: boolean;
    public _ausstattung_boxen: boolean;
    public _ausstattung_computer_drucker: boolean;
    public _ausstattung_endoskop: boolean;
    public _ausstattung_gefriertruhe: boolean;
    public _ausstattung_inhouse_labor: boolean;
    public _ausstattung_klimaanlage: boolean;
    public _ausstattung_kuehlschrank: boolean;
    public _ausstattung_mikroskop: boolean;
    public _ausstattung_narkosegeraet: boolean;
    public _ausstattung_op_besteck: boolean;
    public _ausstattung_op_lampen: boolean;
    public _ausstattung_osteosynthese_set: boolean;
    public _ausstattung_otoskop: boolean;
    public _ausstattung_roentgen_analog: boolean;
    public _ausstattung_roentgen_digital: boolean;
    public _ausstattung_sonstiges: boolean;
    public _ausstattung_telefonanlagen: boolean;
    public _ausstattung_tierwaage: boolean;
    public _ausstattung_trockner: boolean;
    public _ausstattung_ultraschall: boolean;
    public _ausstattung_herzultraschall: boolean;
    public _ausstattung_waschmaschine: boolean;
    public _ausstattung_zahnsteineinheit: boolean;
    public _ausstattung_szintigraph: boolean;
    public _ausstattung_station: boolean;
    public _ausstattung_getrennter_wartebereich: boolean;
    public _ausstattung_software_zur_praxisverwaltung: boolean;
    public _ausstattung_crm: boolean;
    public _ausstattung_ct: boolean;
    public _ausstattung_mrt: boolean;
    public _ausstattung: string;
    public _ausgleich_notdienst: string;
    public _behandlung_op_mit_narkose: boolean;
    public _behandlung_ernaehrungsberatung: boolean;
    public _behandlung_osteosynthese: boolean;
    public _behandlung_stationaer: boolean;
    public _behandlung_osteopathie: boolean;
    public _behandlung_physiotherapie: boolean;
    public _behandlung_chiropraktik: boolean;
    public _behandlung_akupunktur: boolean;
    public _behandlung_herzultraschall: boolean;
    public _behandlung_ultraschall: boolean;
    public _behandlung_digitales_roentgen: boolean;
    public _behandlung_mobiles_roentgen: boolean;
    public _behandlung_zahnbehandlungen_mobil: boolean;
    public _behandlung_dentalroentgen: boolean;
    public _behandlung_inhouse_labor: boolean;
    public _behandlung_sonstiges: boolean;
    public _behandlung: string;
    public _behandlung_ct: boolean;
    public _behandlung_mrt: boolean;
    public _behandlung_szintigraphie: boolean;
    public _behandlung_endoskopie: boolean;
    public _benoetigte_unterlagen: string;
    public _benoetigte_bewerber_voraussetzungen: string;
    public _beschreibung_text: string;
    public _beschreibung_titel: string;
    public _besonderheit: string;
    public _besonderheit_sonstiges: boolean;
    public _besonderheit_cat_friendly: boolean;
    public _besonderheit_mitnahme_hund: boolean;
    public _besonderheit_moegl_doktorarbeit: boolean;
    public _besonderheit_moegl_teilhaberschaft: boolean;
    public _besonderheit_praktikumsverguetung: boolean;
    public _besonderheit_bez_fortbildung: boolean;
    public _besonderheit_wohnen_vor_ort: boolean;
    public _besonderheit_kein_nachtdienst: boolean;
    public _besonderheit_bkv: boolean;
    public _besonderheit_bav: boolean;
    public _besonderheit_eigenverantwortung: boolean;
    public _besonderheit_gehaltsmodell: boolean;
    public _besonderheit_gute_einarbeitung: boolean;
    public _besonderheit_gutes_team: boolean;
    public _besonderheit_familiaere: boolean;
    public _besonderheit_hohes_niveau: boolean;
    public _besonderheit_moderne_ausstattung: boolean;
    public _besonderheit_feedbackgespraeche: boolean;
    public _besonderheit_arbeitszeiterfassung: boolean;
    public _besonderheit_bezahlte_fortbildung: boolean;
    public _besonderheit_interne_fortbildungen: boolean;
    public _besonderheit_weihnachtsgeld: boolean;
    public _besonderheit_urlaubsgeld: boolean;
    public _besonderheit_dienstwagen: boolean;
    public _besonderheit_dienstwagen_privat: boolean;
    public _besonderheit_mittagessen_kostenlos: boolean;
    public _besonderheit_rotation_in_bereiche: boolean;
    public _besonderheit_sportangebot: boolean;
    public _besonderheit_teamevents: boolean;
    public _besonderheit_mitglied_vuk: boolean;
    public _besonderheit_praktikumsleitfaden: boolean;
    public _besonderheit_notdienstring: boolean;
    public _besonderheit_kein_notdienst: boolean;
    public _besonderheit_gvp_zertifiziert: boolean;
    public _besonderheit_praktikum_basics: boolean;
    public _besonderheit_vwl: boolean;
    public _besonderheit_fahrtkostenzuschuss: boolean;
    public _besonderheit_keine_nacht_und_wochenenddienste: boolean;
    public _besonderheit_nacht_und_wochenenddienste_auf_wunsch: boolean;
    public _besonderheit_nacht_und_wochenenddienste_geregelt: boolean;
    public _besonderheit_berufseinsteiger_willkommen: boolean;
    public _bezahlte_stelle: boolean;
    public _an_stelle_gekoppelt: boolean;
    public _datenschutz_akzeptiert: boolean;
    public _einrichtung_sonstiges: boolean;
    public _einrichtung_tierklinik: boolean;
    public _einrichtung_tierarztpraxis: boolean;
    public _einrichtung_fahrpraxis: boolean;
    public _einrichtung_fachtierarztpraxis: boolean;
    public _einrichtung_industrie: boolean;
    public _einrichtung_oeffentlicher_dienst: boolean;
    public _einrichtung_hochschule: boolean;
    public _einrichtung: string;
    public _externes_medium1: string;
    public _externes_medium2: string;
    public _externes_medium3: string;
    public _facharzt_abschluesse: number;
    public _facharzt_ausbildung: boolean;
    public _facharzt_abschluss1: string;
    public _facharzt_abschluss2: string;
    public _facharzt_abschluss3: string;
    public _feedback_gefunden_ueber: string;
    public _feedback_gefunden_ueber_tvd: boolean;
    public _feedback_gefunden_ueber_empfehlung: boolean;
    public _feedback_gefunden_ueber_google: boolean;
    public _feedback_gefunden_ueber_newsletter: boolean;
    public _feedback_gefunden_ueber_facebook: boolean;
    public _feedback_gefunden_ueber_instagram: boolean;
    public _feedback_gefunden_ueber_sonstiges: boolean;
    public _fortbildungsbudget: number;
    public _fortbildungsbudget_vorhanden: boolean;
    public _fortbildungsbudget_nach_vereinbarung: boolean;
    public _urlaubstage: string;
    public _fortbildungstage: string;
    public _fortbildungstage_nach_vereinbarung: boolean;
    public _fortbildung_regelm_intern: boolean;
    public _fortbildung_freie_tage: boolean;
    public _geplante_dauer: string;
    public _homepage: string;
    public _icon_name: string;
    public _infotext: string;
    public _lebensphase: string;
    public _mitarbeiter_assistenten: number;
    public _mitarbeiter_auszubildende: number;
    public _mitarbeiter_in_mutterschutz: number;
    public _mitarbeiter_minijobs: number;
    public _mitarbeiter_tfa: number;
    public _mitarbeiter_ta: number;
    public _mitarbeiter_sonstige: number;
    public _nachmittag_dienstag: boolean;
    public _nachmittag_donnerstag: boolean;
    public _nachmittag_freitag: boolean;
    public _nachmittag_mittwoch: boolean;
    public _nachmittag_montag: boolean;
    public _nachmittag_samstag: boolean;
    public _nachricht: string;
    public _parallele_anstellung: boolean;
    public _praxis_abgabe_in: string;
    public _praxis_apothekenbestand: number;
    public _praxis_art: string;
    public _praxis_art_sonstiges: boolean;
    public _praxis_art_kleintier: boolean;
    public _praxis_art_pferde: boolean;
    public _praxis_art_gemischt: boolean;
    public _praxis_art_rinder: boolean;
    public _praxis_art_gefluegel: boolean;
    public _praxis_art_schwein: boolean;
    public _praxis_art_reptilien: boolean;
    public _praxis_art_industrie: boolean;
    public _praxis_art_verwaltung: boolean;
    public _praxis_art_universitaet: boolean;
    public _praxis_art_heimtiere: boolean;
    public _praxis_art_hochschule: boolean;
    public _praxis_art_oeffentlicher_dienst: boolean;
    public _praxis_art_wildtiere: boolean;
    public _praxis_art_ziervoegel: boolean;
    public _praxis_hat_preisvorstellung: boolean;
    public _praxis_fachliche_spezialisierung: string;
    public _praxis_form: string;
    public _praxis_flaeche: number;
    public _praxis_fortbildung: boolean;
    public _praxis_fortbildung_angebot: string;
    public _praxis_gruendungsjahr: string;
    public _praxis_hat_notdienst: boolean;
    public _praxis_notdienst: string;
    public _praxis_notdienst_auszahlung: boolean;
    public _praxis_notdienst_freizeitausgleich: boolean;
    public _praxis_sprechstunde: string;
    public _praxis_hat_terminsprechstunde: boolean;
    public _praxis_hat_vertretungsregelung: boolean;
    public _praxis_jahresnettoumsatz_2015: number;
    public _praxis_jahresnettoumsatz_2016: number;
    public _praxis_jahresnettoumsatz_2017: number;
    public _praxis_jahresnettoumsatz_2018: number;
    public _praxis_jahresnettoumsatz_2019: number;
    public _praxis_jahresnettoumsatz_2020: number;
    public _praxis_jahresnettoumsatz_2021: number;
    public _praxis_jahresnettoumsatz_2022: number;
    public _praxis_kurzbeschreibung: string;
    public _praxis_materieller_wert: number;
    public _praxis_patientenbesitzer: number;
    public _praxis_preisvorstellung: number;
    public _praxis_sonstige_beschreibung: string;
    public _praxis_telefon_homepage_uebernahme: boolean;
    public _praxis_uebergabedatum: string;
    public _praxis_uebernahme_in: string;
    public _praxis_uebernahme: boolean;
    public _praxis_teilhaber: boolean;
    public _praxis_ueberschrift: string;
    public _praxis_zulassungsstatus: string;
    public _praxis_sprache_1: string;
    public _praxis_sprache_2: string;
    public _raeume_apotheke: number;
    public _raeume_behandlung1: number;
    public _raeume_behandlung2: number;
    public _raeume_empfang: number;
    public _raeume_labor: number;
    public _raeume_op: number;
    public _raeume_op_vorb: number;
    public _raeume_roentgen: number;
    public _raeume_sonstige: string;
    public _raeume_sozial: number;
    public _raeume_station: number;
    public _raeume_warten: number;
    public _raeume_wc: number;
    public _raeume_mitarbeiter: number;
    public _raeume_vorhanden_apotheke: boolean;
    public _raeume_vorhanden_behandlung1: boolean;
    public _raeume_vorhanden_behandlung2: boolean;
    public _raeume_vorhanden_empfang: boolean;
    public _raeume_vorhanden_labor: boolean;
    public _raeume_vorhanden_op: boolean;
    public _raeume_vorhanden_op_vorb: boolean;
    public _raeume_vorhanden_roentgen: boolean;
    public _raeume_vorhanden_sozial: boolean;
    public _raeume_vorhanden_station: boolean;
    public _raeume_vorhanden_warten: boolean;
    public _raeume_vorhanden_wc: boolean;
    public _raeume_vorhanden_sonstige: boolean;
    public _raeume_vorhanden_mitarbeiter: boolean;
    public _ratio_exoten: number;
    public _ratio_kleintiere: number;
    public _ratio_nutztiere: number;
    public _ratio_pferde: number;
    public _ratio_rinder: number;
    public _ratio_schweine: number;
    public _ratio_gefluegel: number;
    public _ratio_heimtiere: number;
    public _ratio_reptilien: number;
    public _ratio_oeffentlicher_dienst: number;
    public _ratio_industrie: number;
    public _ratio_sonstiges: number;
    public _alle_regionen: boolean;
    public _region_bw: boolean;
    public _region_by: boolean;
    public _region_be: boolean;
    public _region_bb: boolean;
    public _region_hb: boolean;
    public _region_hh: boolean;
    public _region_he: boolean;
    public _region_mv: boolean;
    public _region_ni: boolean;
    public _region_nw: boolean;
    public _region_rp: boolean;
    public _region_sl: boolean;
    public _region_sn: boolean;
    public _region_sa: boolean;
    public _region_sh: boolean;
    public _region_th: boolean;
    public _region_0x: boolean;
    public _region_1x: boolean;
    public _region_2x: boolean;
    public _region_3x: boolean;
    public _region_4x: boolean;
    public _region_5x: boolean;
    public _region_6x: boolean;
    public _region_7x: boolean;
    public _region_8x: boolean;
    public _region_9x: boolean;
    public _schwerpunkt_apothekenmanagement: boolean;
    public _schwerpunkt_allgemeine_tiermedizin: boolean;
    public _schwerpunkt_alternativmedizin: boolean;
    public _schwerpunkt_fahrpraxis: boolean;
    public _schwerpunkt_bestandsbetreuung: boolean;
    public _schwerpunkt_chirurgie: boolean;
    public _schwerpunkt_gynaekologie: boolean;
    public _schwerpunkt_haut_augenerkrankung: boolean;
    public _schwerpunkt_innere_medizin: boolean;
    public _schwerpunkt_intensivmedizin: boolean;
    public _schwerpunkt_kardiologie: boolean;
    public _schwerpunkt_klassische_tiermedizin: boolean;
    public _schwerpunkt_neurologie: boolean;
    public _schwerpunkt_notdienste: boolean;
    public _schwerpunkt_onkologie: boolean;
    public _schwerpunkt_operationen_knochen: boolean;
    public _schwerpunkt_operationen_weichteile: boolean;
    public _schwerpunkt_operationen: boolean;
    public _schwerpunkt_op_assistenz: boolean;
    public _schwerpunkt_roentgen: boolean;
    public _schwerpunkt_sonstiges: boolean;
    public _schwerpunkt_stationaere_aufnahme: boolean;
    public _schwerpunkt_stationaere_betreuung: boolean;
    public _schwerpunkt_sprechstunde: boolean;
    public _schwerpunkt_ultraschall: boolean;
    public _schwerpunkt_untersuchung_laborproben: boolean;
    public _schwerpunkt_ueberwachung_von_narkose: boolean;
    public _schwerpunkt_verhaltenstherapie: boolean;
    public _schwerpunkt_veranstaltungsbetreuung: boolean;
    public _schwerpunkt_zahn_kieferchirugie: boolean;
    public _schwerpunkt_zahn_behandlungen: boolean;
    public _schwerpunkt_zahnheilkunde: boolean;
    public _schwerpunkt_nachtdienste: boolean;
    public _schwerpunkt_nachtdienste_anzahl: number;
    public _schwerpunkt_wochenenddienste: boolean;
    public _schwerpunkt_wochenenddienste_anzahl: number;
    public _schwerpunkt_ophthalmologie: boolean;
    public _schwerpunkt_dermatologie: boolean;
    public _schwerpunkt_orthopaedie: boolean;
    public _schwerpunkt_alternative_heilmethoden: boolean;
    public _schwerpunkt_weichteilchirurgie: boolean;
    public _schwerpunkt_osteosynthese: boolean;
    public _schwerpunkt_arthroskopie: boolean;
    public _schwerpunkt_augenheilkunde: boolean;
    public _schwerpunkt_reproduktionsmedizin: boolean;
    public _schwerpunkt_ernaehrung: boolean;
    public _schwerpunkt_notfallmedizin: boolean;
    public _schwerpunkt_endokrinologie: boolean;
    public _schwerpunkt: string;
    public _seo_description: string;
    public _seo_hiringorganization_name: string;
    public _seo_hiringorganization_sameas: string;
    public _seo_joblocation_addresslocality: string;
    public _seo_joblocation_streetaddress: string;
    public _seo_joblocation_addressregion: string;
    public _seo_joblocation_postalcode: string;
    public _seo_joblocation_addresscountry: string;
    public _slug: string;
    public _slug_v2: string;
    public _stammdaten_adresse: string;
    public _stammdaten_anrede: string;
    public _stammdaten_email: string;
    public _stammdaten_fax: string;
    public _stammdaten_grussform: string;
    public _stammdaten_mobil: string;
    public _stammdaten_nachname: string;
    public _stammdaten_nr: string;
    public _stammdaten_ort: string;
    public _stammdaten_plz: string;
    public _stammdaten_strasse: string;
    public _stammdaten_telefon: string;
    public _stammdaten_titel: string;
    public _stammdaten_vorname: string;
    public _stammdaten_praxisname: string;
    public _stammdaten_ansprechpartner: string;
    public _stammdaten_iso3166_1: string;
    public _stammdaten_iso3166_2: string;
    public _standort: string;
    public _standort_beschreibung: string;
    public _standort_distance: number;
    public _standort_einwohnerzahl: number;
    public _standort_anzahl_parkplatz: number;
    public _standort_entfernung_bahnhof: number;
    public _standort_entfernung_bushaltestelle: number;
    public _standort_entfernung_einkauf: number;
    public _standort_entfernung_schule: number;
    public _standort_entfernung_grundschule: number;
    public _standort_entfernung_kindergarten: number;
    public _standort_hat_bahnhof: boolean;
    public _standort_hat_bushaltestelle: boolean;
    public _standort_hat_einkauf: boolean;
    public _standort_hat_parkplatz: boolean;
    public _standort_hat_schule: boolean;
    public _standort_hat_grundschule: boolean;
    public _standort_hat_kindergarten: boolean;
    public _standort_hat_mitarbeiterparkplatz: boolean;
    public _standort_hat_parkmoeglichkeiten: boolean;
    public _standort_hat_oeffentliche_anbindung: boolean;
    public _standort_immobilie: string;
    public _standort_immobilie_inklusive: boolean;
    public _standort_geolocated: boolean;
    public _standort_mietzins: number;
    public _standort_lat: number;
    public _standort_lng: number;
    public _standort_land: string;
    public _standort_plz: string;
    public _standort_region: string;
    public _stellenart_assistenzstelle: boolean;
    public _stellenart_praktikum: boolean;
    public _stellenart_praxisvertreter: boolean;
    public _stellenart_tfa: boolean;
    public _stellenart_praktikum_ausland: boolean;
    public _stellenart_selbststaendig: boolean;
    public _stellenart_teilhaber: boolean;
    public _stellenart_tierarzt: boolean;
    public _stellenart_tfa_ausbildung: boolean;
    public _stellenart_dissertation: boolean;
    public _stellenart_sonstiges: boolean;
    public _stellenart_is_empfehlung: boolean;
    public _subtitle: string;
    public _taetigkeit_ort: string;
    public _taetigkeit_nur_vor_ort: boolean;
    public _taetigkeit_nur_dezentral: boolean;
    public _taetigkeit_ort_kombiniert: boolean;
    public _title: string;
    public _umfang_vollzeit: boolean;
    public _umfang_teilzeit: boolean;
    public _umfang_nebenbeschaeftigung: boolean;
    public _vormittag_dienstag: boolean;
    public _vormittag_donnerstag: boolean;
    public _vormittag_freitag: boolean;
    public _vormittag_mittwoch: boolean;
    public _vormittag_montag: boolean;
    public _vormittag_samstag: boolean;
    public _voraussetzungen_deutsche_approbation_tierarzt: boolean;
    public _voraussetzungen_min_berufserfahrungen: boolean;
    public _voraussetzungen_berufserfahrungen_in_jahren: number;
    public _voraussetzungen_min_semester: boolean;
    public _voraussetzungen_semester_anzahl: number;
    public _voraussetzungen_bereits_absolviertes_internship: boolean;
    public _voraussetzungen_hat_titel: boolean;
    public _voraussetzungen_title_zusatzbezeichnung: boolean;
    public _voraussetzungen_notdienst_bereitschaft: boolean;
    public _voraussetzungen_belastbarkeit: boolean;
    public _voraussetzungen_empathischer_umgang: boolean;
    public _voraussetzungen_selbststaendiges_arbeiten: boolean;
    public _voraussetzungen_teamfaehigkeit: boolean;
    public _voraussetzungen_fuehrerschein: boolean;
    public _voraussetzungen_eigenes_auto: boolean;
    public _voraussetzungen_puenktlichkeit: boolean;
    public _voraussetzungen_zuverlaessigkeit: boolean;
    public _voraussetzungen_interesse_an_weiterbildung: boolean;
    public _voraussetzungen_kollegialer_umgang: boolean;
    public _wunschort1_location_address: string;
    public _wunschort1_location: string;
    public _wunschort2_location_address: string;
    public _wunschort2_location: string;
    public _wunschort3_location_address: string;
    public _wunschort3_location: string;
    public _wunschort_flexibel: boolean;
    public _wunschorte: number;
    public _wochenstunden: string;
    public _wochentag_montag: boolean;
    public _wochentag_dienstag: boolean;
    public _wochentag_mittwoch: boolean;
    public _wochentag_donnerstag: boolean;
    public _wochentag_freitag: boolean;
    public _wochentag_samstag: boolean;
    public _wochentag_sonntag: boolean;
    public _zusatzinfos: string;
    public _erreichbarkeit_uhrzeit: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.request_type = data.request_type;
            this.is_opted_in = data.is_opted_in;
            this.uuid = data.uuid;
            this.user_uuid = data.user_uuid;
            this.offer_type = data.offer_type;
            this.create_date = data.create_date;
            this.modify_date = data.modify_date;
            this.delete_date = data.delete_date;
            this.expire_date = data.expire_date;
            this.last_mail_date = data.last_mail_date;
            this.opt_in_date = data.opt_in_date;
            this.opt_out_date = data.opt_out_date;
            this.import_id = data.import_id;
            this.is_public = data.is_public;
            this.is_intern = data.is_intern;
            this.need_payment = data.need_payment;
            this._chiffre = data._chiffre;
            this._admin_status = data._admin_status;
            this._admin_is_public = data._admin_is_public;
            this._admin_is_sold = data._admin_is_sold;
            this._admin_is_deleted = data._admin_is_deleted;
            this._admin_is_reserved = data._admin_is_reserved;
            this._admin_is_upper_class = data._admin_is_upper_class;
            this._admin_tvd_ansprechpartner = data._admin_tvd_ansprechpartner;
            this._admin_zusatzinfos = data._admin_zusatzinfos;
            this._arbeitsantritt = data._arbeitsantritt;
            this._arbeitsantritt_monate = data._arbeitsantritt_monate;
            this._arbeitsumfang = data._arbeitsumfang;
            this._arbeitsumfang_teilzeit = data._arbeitsumfang_teilzeit;
            this._arbeitsumfang_vollzeit = data._arbeitsumfang_vollzeit;
            this._arbeitsumfang_dauer = data._arbeitsumfang_dauer;
            this._arbeitsumfang_dauer_min_wochen = data._arbeitsumfang_dauer_min_wochen;
            this._arbeitsumfang_dauer_max_wochen = data._arbeitsumfang_dauer_max_wochen;
            this._arbeitsumfang_dauer_sonstiges = data._arbeitsumfang_dauer_sonstiges;
            this._arbeitsumfang_nebenbeschaeftigung = data._arbeitsumfang_nebenbeschaeftigung;
            this._arbeitszeit_montag = data._arbeitszeit_montag;
            this._arbeitszeit_dienstag = data._arbeitszeit_dienstag;
            this._arbeitszeit_mittwoch = data._arbeitszeit_mittwoch;
            this._arbeitszeit_donnerstag = data._arbeitszeit_donnerstag;
            this._arbeitszeit_freitag = data._arbeitszeit_freitag;
            this._arbeitszeit_samstag = data._arbeitszeit_samstag;
            this._arbeitszeit_sonntag = data._arbeitszeit_sonntag;
            this._ausstattung_autoclav = data._ausstattung_autoclav;
            this._ausstattung_behandlungstisch = data._ausstattung_behandlungstisch;
            this._ausstattung_blutanalyse = data._ausstattung_blutanalyse;
            this._ausstattung_boxen = data._ausstattung_boxen;
            this._ausstattung_computer_drucker = data._ausstattung_computer_drucker;
            this._ausstattung_endoskop = data._ausstattung_endoskop;
            this._ausstattung_gefriertruhe = data._ausstattung_gefriertruhe;
            this._ausstattung_inhouse_labor = data._ausstattung_inhouse_labor;
            this._ausstattung_klimaanlage = data._ausstattung_klimaanlage;
            this._ausstattung_kuehlschrank = data._ausstattung_kuehlschrank;
            this._ausstattung_mikroskop = data._ausstattung_mikroskop;
            this._ausstattung_narkosegeraet = data._ausstattung_narkosegeraet;
            this._ausstattung_op_besteck = data._ausstattung_op_besteck;
            this._ausstattung_op_lampen = data._ausstattung_op_lampen;
            this._ausstattung_osteosynthese_set = data._ausstattung_osteosynthese_set;
            this._ausstattung_otoskop = data._ausstattung_otoskop;
            this._ausstattung_roentgen_analog = data._ausstattung_roentgen_analog;
            this._ausstattung_roentgen_digital = data._ausstattung_roentgen_digital;
            this._ausstattung_sonstiges = data._ausstattung_sonstiges;
            this._ausstattung_telefonanlagen = data._ausstattung_telefonanlagen;
            this._ausstattung_tierwaage = data._ausstattung_tierwaage;
            this._ausstattung_trockner = data._ausstattung_trockner;
            this._ausstattung_ultraschall = data._ausstattung_ultraschall;
            this._ausstattung_herzultraschall = data._ausstattung_herzultraschall;
            this._ausstattung_waschmaschine = data._ausstattung_waschmaschine;
            this._ausstattung_zahnsteineinheit = data._ausstattung_zahnsteineinheit;
            this._ausstattung_szintigraph = data._ausstattung_szintigraph;
            this._ausstattung_station = data._ausstattung_station;
            this._ausstattung_getrennter_wartebereich = data._ausstattung_getrennter_wartebereich;
            this._ausstattung_software_zur_praxisverwaltung = data._ausstattung_software_zur_praxisverwaltung;
            this._ausstattung_crm = data._ausstattung_crm;
            this._ausstattung_ct = data._ausstattung_ct;
            this._ausstattung_mrt = data._ausstattung_mrt;
            this._ausstattung = data._ausstattung;
            this._ausgleich_notdienst = data._ausgleich_notdienst;
            this._behandlung_op_mit_narkose = data._behandlung_op_mit_narkose;
            this._behandlung_ernaehrungsberatung = data._behandlung_ernaehrungsberatung;
            this._behandlung_osteosynthese = data._behandlung_osteosynthese;
            this._behandlung_stationaer = data._behandlung_stationaer;
            this._behandlung_osteopathie = data._behandlung_osteopathie;
            this._behandlung_physiotherapie = data._behandlung_physiotherapie;
            this._behandlung_chiropraktik = data._behandlung_chiropraktik;
            this._behandlung_akupunktur = data._behandlung_akupunktur;
            this._behandlung_herzultraschall = data._behandlung_herzultraschall;
            this._behandlung_ultraschall = data._behandlung_ultraschall;
            this._behandlung_digitales_roentgen = data._behandlung_digitales_roentgen;
            this._behandlung_mobiles_roentgen = data._behandlung_mobiles_roentgen;
            this._behandlung_zahnbehandlungen_mobil = data._behandlung_zahnbehandlungen_mobil;
            this._behandlung_dentalroentgen = data._behandlung_dentalroentgen;
            this._behandlung_inhouse_labor = data._behandlung_inhouse_labor;
            this._behandlung_sonstiges = data._behandlung_sonstiges;
            this._behandlung = data._behandlung;
            this._behandlung_ct = data._behandlung_ct;
            this._behandlung_mrt = data._behandlung_mrt;
            this._behandlung_szintigraphie = data._behandlung_szintigraphie;
            this._behandlung_endoskopie = data._behandlung_endoskopie;
            this._benoetigte_unterlagen = data._benoetigte_unterlagen;
            this._benoetigte_bewerber_voraussetzungen = data._benoetigte_bewerber_voraussetzungen;
            this._beschreibung_text = data._beschreibung_text;
            this._beschreibung_titel = data._beschreibung_titel;
            this._besonderheit = data._besonderheit;
            this._besonderheit_sonstiges = data._besonderheit_sonstiges;
            this._besonderheit_cat_friendly = data._besonderheit_cat_friendly;
            this._besonderheit_mitnahme_hund = data._besonderheit_mitnahme_hund;
            this._besonderheit_moegl_doktorarbeit = data._besonderheit_moegl_doktorarbeit;
            this._besonderheit_moegl_teilhaberschaft = data._besonderheit_moegl_teilhaberschaft;
            this._besonderheit_praktikumsverguetung = data._besonderheit_praktikumsverguetung;
            this._besonderheit_bez_fortbildung = data._besonderheit_bez_fortbildung;
            this._besonderheit_wohnen_vor_ort = data._besonderheit_wohnen_vor_ort;
            this._besonderheit_kein_nachtdienst = data._besonderheit_kein_nachtdienst;
            this._besonderheit_bkv = data._besonderheit_bkv;
            this._besonderheit_bav = data._besonderheit_bav;
            this._besonderheit_eigenverantwortung = data._besonderheit_eigenverantwortung;
            this._besonderheit_gehaltsmodell = data._besonderheit_gehaltsmodell;
            this._besonderheit_gute_einarbeitung = data._besonderheit_gute_einarbeitung;
            this._besonderheit_gutes_team = data._besonderheit_gutes_team;
            this._besonderheit_familiaere = data._besonderheit_familiaere;
            this._besonderheit_hohes_niveau = data._besonderheit_hohes_niveau;
            this._besonderheit_moderne_ausstattung = data._besonderheit_moderne_ausstattung;
            this._besonderheit_feedbackgespraeche = data._besonderheit_feedbackgespraeche;
            this._besonderheit_arbeitszeiterfassung = data._besonderheit_arbeitszeiterfassung;
            this._besonderheit_bezahlte_fortbildung = data._besonderheit_bezahlte_fortbildung;
            this._besonderheit_interne_fortbildungen = data._besonderheit_interne_fortbildungen;
            this._besonderheit_weihnachtsgeld = data._besonderheit_weihnachtsgeld;
            this._besonderheit_urlaubsgeld = data._besonderheit_urlaubsgeld;
            this._besonderheit_dienstwagen = data._besonderheit_dienstwagen;
            this._besonderheit_dienstwagen_privat = data._besonderheit_dienstwagen_privat;
            this._besonderheit_mittagessen_kostenlos = data._besonderheit_mittagessen_kostenlos;
            this._besonderheit_rotation_in_bereiche = data._besonderheit_rotation_in_bereiche;
            this._besonderheit_sportangebot = data._besonderheit_sportangebot;
            this._besonderheit_teamevents = data._besonderheit_teamevents;
            this._besonderheit_mitglied_vuk = data._besonderheit_mitglied_vuk;
            this._besonderheit_praktikumsleitfaden = data._besonderheit_praktikumsleitfaden;
            this._besonderheit_notdienstring = data._besonderheit_notdienstring;
            this._besonderheit_kein_notdienst = data._besonderheit_kein_notdienst;
            this._besonderheit_gvp_zertifiziert = data._besonderheit_gvp_zertifiziert;
            this._besonderheit_praktikum_basics = data._besonderheit_praktikum_basics;
            this._besonderheit_vwl = data._besonderheit_vwl;
            this._besonderheit_fahrtkostenzuschuss = data._besonderheit_fahrtkostenzuschuss;
            this._besonderheit_keine_nacht_und_wochenenddienste = data._besonderheit_keine_nacht_und_wochenenddienste;
            this._besonderheit_nacht_und_wochenenddienste_auf_wunsch = data._besonderheit_nacht_und_wochenenddienste_auf_wunsch;
            this._besonderheit_nacht_und_wochenenddienste_geregelt = data._besonderheit_nacht_und_wochenenddienste_geregelt;
            this._besonderheit_berufseinsteiger_willkommen = data._besonderheit_berufseinsteiger_willkommen;
            this._bezahlte_stelle = data._bezahlte_stelle;
            this._an_stelle_gekoppelt = data._an_stelle_gekoppelt;
            this._datenschutz_akzeptiert = data._datenschutz_akzeptiert;
            this._einrichtung_sonstiges = data._einrichtung_sonstiges;
            this._einrichtung_tierklinik = data._einrichtung_tierklinik;
            this._einrichtung_tierarztpraxis = data._einrichtung_tierarztpraxis;
            this._einrichtung_fahrpraxis = data._einrichtung_fahrpraxis;
            this._einrichtung_fachtierarztpraxis = data._einrichtung_fachtierarztpraxis;
            this._einrichtung_industrie = data._einrichtung_industrie;
            this._einrichtung_oeffentlicher_dienst = data._einrichtung_oeffentlicher_dienst;
            this._einrichtung_hochschule = data._einrichtung_hochschule;
            this._einrichtung = data._einrichtung;
            this._externes_medium1 = data._externes_medium1;
            this._externes_medium2 = data._externes_medium2;
            this._externes_medium3 = data._externes_medium3;
            this._facharzt_abschluesse = data._facharzt_abschluesse;
            this._facharzt_ausbildung = data._facharzt_ausbildung;
            this._facharzt_abschluss1 = data._facharzt_abschluss1;
            this._facharzt_abschluss2 = data._facharzt_abschluss2;
            this._facharzt_abschluss3 = data._facharzt_abschluss3;
            this._feedback_gefunden_ueber = data._feedback_gefunden_ueber;
            this._feedback_gefunden_ueber_tvd = data._feedback_gefunden_ueber_tvd;
            this._feedback_gefunden_ueber_empfehlung = data._feedback_gefunden_ueber_empfehlung;
            this._feedback_gefunden_ueber_google = data._feedback_gefunden_ueber_google;
            this._feedback_gefunden_ueber_newsletter = data._feedback_gefunden_ueber_newsletter;
            this._feedback_gefunden_ueber_facebook = data._feedback_gefunden_ueber_facebook;
            this._feedback_gefunden_ueber_instagram = data._feedback_gefunden_ueber_instagram;
            this._feedback_gefunden_ueber_sonstiges = data._feedback_gefunden_ueber_sonstiges;
            this._fortbildungsbudget = data._fortbildungsbudget;
            this._fortbildungsbudget_vorhanden = data._fortbildungsbudget_vorhanden;
            this._fortbildungsbudget_nach_vereinbarung = data._fortbildungsbudget_nach_vereinbarung;
            this._urlaubstage = data._urlaubstage;
            this._fortbildungstage = data._fortbildungstage;
            this._fortbildungstage_nach_vereinbarung = data._fortbildungstage_nach_vereinbarung;
            this._fortbildung_regelm_intern = data._fortbildung_regelm_intern;
            this._fortbildung_freie_tage = data._fortbildung_freie_tage;
            this._geplante_dauer = data._geplante_dauer;
            this._homepage = data._homepage;
            this._icon_name = data._icon_name;
            this._infotext = data._infotext;
            this._lebensphase = data._lebensphase;
            this._mitarbeiter_assistenten = data._mitarbeiter_assistenten;
            this._mitarbeiter_auszubildende = data._mitarbeiter_auszubildende;
            this._mitarbeiter_in_mutterschutz = data._mitarbeiter_in_mutterschutz;
            this._mitarbeiter_minijobs = data._mitarbeiter_minijobs;
            this._mitarbeiter_tfa = data._mitarbeiter_tfa;
            this._mitarbeiter_ta = data._mitarbeiter_ta;
            this._mitarbeiter_sonstige = data._mitarbeiter_sonstige;
            this._nachmittag_dienstag = data._nachmittag_dienstag;
            this._nachmittag_donnerstag = data._nachmittag_donnerstag;
            this._nachmittag_freitag = data._nachmittag_freitag;
            this._nachmittag_mittwoch = data._nachmittag_mittwoch;
            this._nachmittag_montag = data._nachmittag_montag;
            this._nachmittag_samstag = data._nachmittag_samstag;
            this._nachricht = data._nachricht;
            this._parallele_anstellung = data._parallele_anstellung;
            this._praxis_abgabe_in = data._praxis_abgabe_in;
            this._praxis_apothekenbestand = data._praxis_apothekenbestand;
            this._praxis_art = data._praxis_art;
            this._praxis_art_sonstiges = data._praxis_art_sonstiges;
            this._praxis_art_kleintier = data._praxis_art_kleintier;
            this._praxis_art_pferde = data._praxis_art_pferde;
            this._praxis_art_gemischt = data._praxis_art_gemischt;
            this._praxis_art_rinder = data._praxis_art_rinder;
            this._praxis_art_gefluegel = data._praxis_art_gefluegel;
            this._praxis_art_schwein = data._praxis_art_schwein;
            this._praxis_art_reptilien = data._praxis_art_reptilien;
            this._praxis_art_industrie = data._praxis_art_industrie;
            this._praxis_art_verwaltung = data._praxis_art_verwaltung;
            this._praxis_art_universitaet = data._praxis_art_universitaet;
            this._praxis_art_heimtiere = data._praxis_art_heimtiere;
            this._praxis_art_hochschule = data._praxis_art_hochschule;
            this._praxis_art_oeffentlicher_dienst = data._praxis_art_oeffentlicher_dienst;
            this._praxis_art_wildtiere = data._praxis_art_wildtiere;
            this._praxis_art_ziervoegel = data._praxis_art_ziervoegel;
            this._praxis_hat_preisvorstellung = data._praxis_hat_preisvorstellung;
            this._praxis_fachliche_spezialisierung = data._praxis_fachliche_spezialisierung;
            this._praxis_form = data._praxis_form;
            this._praxis_flaeche = data._praxis_flaeche;
            this._praxis_fortbildung = data._praxis_fortbildung;
            this._praxis_fortbildung_angebot = data._praxis_fortbildung_angebot;
            this._praxis_gruendungsjahr = data._praxis_gruendungsjahr;
            this._praxis_hat_notdienst = data._praxis_hat_notdienst;
            this._praxis_notdienst = data._praxis_notdienst;
            this._praxis_notdienst_auszahlung = data._praxis_notdienst_auszahlung;
            this._praxis_notdienst_freizeitausgleich = data._praxis_notdienst_freizeitausgleich;
            this._praxis_sprechstunde = data._praxis_sprechstunde;
            this._praxis_hat_terminsprechstunde = data._praxis_hat_terminsprechstunde;
            this._praxis_hat_vertretungsregelung = data._praxis_hat_vertretungsregelung;
            this._praxis_jahresnettoumsatz_2015 = data._praxis_jahresnettoumsatz_2015;
            this._praxis_jahresnettoumsatz_2016 = data._praxis_jahresnettoumsatz_2016;
            this._praxis_jahresnettoumsatz_2017 = data._praxis_jahresnettoumsatz_2017;
            this._praxis_jahresnettoumsatz_2018 = data._praxis_jahresnettoumsatz_2018;
            this._praxis_jahresnettoumsatz_2019 = data._praxis_jahresnettoumsatz_2019;
            this._praxis_jahresnettoumsatz_2020 = data._praxis_jahresnettoumsatz_2020;
            this._praxis_jahresnettoumsatz_2021 = data._praxis_jahresnettoumsatz_2021;
            this._praxis_jahresnettoumsatz_2022 = data._praxis_jahresnettoumsatz_2022;
            this._praxis_kurzbeschreibung = data._praxis_kurzbeschreibung;
            this._praxis_materieller_wert = data._praxis_materieller_wert;
            this._praxis_patientenbesitzer = data._praxis_patientenbesitzer;
            this._praxis_preisvorstellung = data._praxis_preisvorstellung;
            this._praxis_sonstige_beschreibung = data._praxis_sonstige_beschreibung;
            this._praxis_telefon_homepage_uebernahme = data._praxis_telefon_homepage_uebernahme;
            this._praxis_uebergabedatum = data._praxis_uebergabedatum;
            this._praxis_uebernahme_in = data._praxis_uebernahme_in;
            this._praxis_uebernahme = data._praxis_uebernahme;
            this._praxis_teilhaber = data._praxis_teilhaber;
            this._praxis_ueberschrift = data._praxis_ueberschrift;
            this._praxis_zulassungsstatus = data._praxis_zulassungsstatus;
            this._praxis_sprache_1 = data._praxis_sprache_1;
            this._praxis_sprache_2 = data._praxis_sprache_2;
            this._raeume_apotheke = data._raeume_apotheke;
            this._raeume_behandlung1 = data._raeume_behandlung1;
            this._raeume_behandlung2 = data._raeume_behandlung2;
            this._raeume_empfang = data._raeume_empfang;
            this._raeume_labor = data._raeume_labor;
            this._raeume_op = data._raeume_op;
            this._raeume_op_vorb = data._raeume_op_vorb;
            this._raeume_roentgen = data._raeume_roentgen;
            this._raeume_sonstige = data._raeume_sonstige;
            this._raeume_sozial = data._raeume_sozial;
            this._raeume_station = data._raeume_station;
            this._raeume_warten = data._raeume_warten;
            this._raeume_wc = data._raeume_wc;
            this._raeume_mitarbeiter = data._raeume_mitarbeiter;
            this._raeume_vorhanden_apotheke = data._raeume_vorhanden_apotheke;
            this._raeume_vorhanden_behandlung1 = data._raeume_vorhanden_behandlung1;
            this._raeume_vorhanden_behandlung2 = data._raeume_vorhanden_behandlung2;
            this._raeume_vorhanden_empfang = data._raeume_vorhanden_empfang;
            this._raeume_vorhanden_labor = data._raeume_vorhanden_labor;
            this._raeume_vorhanden_op = data._raeume_vorhanden_op;
            this._raeume_vorhanden_op_vorb = data._raeume_vorhanden_op_vorb;
            this._raeume_vorhanden_roentgen = data._raeume_vorhanden_roentgen;
            this._raeume_vorhanden_sozial = data._raeume_vorhanden_sozial;
            this._raeume_vorhanden_station = data._raeume_vorhanden_station;
            this._raeume_vorhanden_warten = data._raeume_vorhanden_warten;
            this._raeume_vorhanden_wc = data._raeume_vorhanden_wc;
            this._raeume_vorhanden_sonstige = data._raeume_vorhanden_sonstige;
            this._raeume_vorhanden_mitarbeiter = data._raeume_vorhanden_mitarbeiter;
            this._ratio_exoten = data._ratio_exoten;
            this._ratio_kleintiere = data._ratio_kleintiere;
            this._ratio_nutztiere = data._ratio_nutztiere;
            this._ratio_pferde = data._ratio_pferde;
            this._ratio_rinder = data._ratio_rinder;
            this._ratio_schweine = data._ratio_schweine;
            this._ratio_gefluegel = data._ratio_gefluegel;
            this._ratio_heimtiere = data._ratio_heimtiere;
            this._ratio_reptilien = data._ratio_reptilien;
            this._ratio_oeffentlicher_dienst = data._ratio_oeffentlicher_dienst;
            this._ratio_industrie = data._ratio_industrie;
            this._ratio_sonstiges = data._ratio_sonstiges;
            this._alle_regionen = data._alle_regionen;
            this._region_bw = data._region_bw;
            this._region_by = data._region_by;
            this._region_be = data._region_be;
            this._region_bb = data._region_bb;
            this._region_hb = data._region_hb;
            this._region_hh = data._region_hh;
            this._region_he = data._region_he;
            this._region_mv = data._region_mv;
            this._region_ni = data._region_ni;
            this._region_nw = data._region_nw;
            this._region_rp = data._region_rp;
            this._region_sl = data._region_sl;
            this._region_sn = data._region_sn;
            this._region_sa = data._region_sa;
            this._region_sh = data._region_sh;
            this._region_th = data._region_th;
            this._region_0x = data._region_0x;
            this._region_1x = data._region_1x;
            this._region_2x = data._region_2x;
            this._region_3x = data._region_3x;
            this._region_4x = data._region_4x;
            this._region_5x = data._region_5x;
            this._region_6x = data._region_6x;
            this._region_7x = data._region_7x;
            this._region_8x = data._region_8x;
            this._region_9x = data._region_9x;
            this._schwerpunkt_apothekenmanagement = data._schwerpunkt_apothekenmanagement;
            this._schwerpunkt_allgemeine_tiermedizin = data._schwerpunkt_allgemeine_tiermedizin;
            this._schwerpunkt_alternativmedizin = data._schwerpunkt_alternativmedizin;
            this._schwerpunkt_fahrpraxis = data._schwerpunkt_fahrpraxis;
            this._schwerpunkt_bestandsbetreuung = data._schwerpunkt_bestandsbetreuung;
            this._schwerpunkt_chirurgie = data._schwerpunkt_chirurgie;
            this._schwerpunkt_gynaekologie = data._schwerpunkt_gynaekologie;
            this._schwerpunkt_haut_augenerkrankung = data._schwerpunkt_haut_augenerkrankung;
            this._schwerpunkt_innere_medizin = data._schwerpunkt_innere_medizin;
            this._schwerpunkt_intensivmedizin = data._schwerpunkt_intensivmedizin;
            this._schwerpunkt_kardiologie = data._schwerpunkt_kardiologie;
            this._schwerpunkt_klassische_tiermedizin = data._schwerpunkt_klassische_tiermedizin;
            this._schwerpunkt_neurologie = data._schwerpunkt_neurologie;
            this._schwerpunkt_notdienste = data._schwerpunkt_notdienste;
            this._schwerpunkt_onkologie = data._schwerpunkt_onkologie;
            this._schwerpunkt_operationen_knochen = data._schwerpunkt_operationen_knochen;
            this._schwerpunkt_operationen_weichteile = data._schwerpunkt_operationen_weichteile;
            this._schwerpunkt_operationen = data._schwerpunkt_operationen;
            this._schwerpunkt_op_assistenz = data._schwerpunkt_op_assistenz;
            this._schwerpunkt_roentgen = data._schwerpunkt_roentgen;
            this._schwerpunkt_sonstiges = data._schwerpunkt_sonstiges;
            this._schwerpunkt_stationaere_aufnahme = data._schwerpunkt_stationaere_aufnahme;
            this._schwerpunkt_stationaere_betreuung = data._schwerpunkt_stationaere_betreuung;
            this._schwerpunkt_sprechstunde = data._schwerpunkt_sprechstunde;
            this._schwerpunkt_ultraschall = data._schwerpunkt_ultraschall;
            this._schwerpunkt_untersuchung_laborproben = data._schwerpunkt_untersuchung_laborproben;
            this._schwerpunkt_ueberwachung_von_narkose = data._schwerpunkt_ueberwachung_von_narkose;
            this._schwerpunkt_verhaltenstherapie = data._schwerpunkt_verhaltenstherapie;
            this._schwerpunkt_veranstaltungsbetreuung = data._schwerpunkt_veranstaltungsbetreuung;
            this._schwerpunkt_zahn_kieferchirugie = data._schwerpunkt_zahn_kieferchirugie;
            this._schwerpunkt_zahn_behandlungen = data._schwerpunkt_zahn_behandlungen;
            this._schwerpunkt_zahnheilkunde = data._schwerpunkt_zahnheilkunde;
            this._schwerpunkt_nachtdienste = data._schwerpunkt_nachtdienste;
            this._schwerpunkt_nachtdienste_anzahl = data._schwerpunkt_nachtdienste_anzahl;
            this._schwerpunkt_wochenenddienste = data._schwerpunkt_wochenenddienste;
            this._schwerpunkt_wochenenddienste_anzahl = data._schwerpunkt_wochenenddienste_anzahl;
            this._schwerpunkt_ophthalmologie = data._schwerpunkt_ophthalmologie;
            this._schwerpunkt_dermatologie = data._schwerpunkt_dermatologie;
            this._schwerpunkt_orthopaedie = data._schwerpunkt_orthopaedie;
            this._schwerpunkt_alternative_heilmethoden = data._schwerpunkt_alternative_heilmethoden;
            this._schwerpunkt_weichteilchirurgie = data._schwerpunkt_weichteilchirurgie;
            this._schwerpunkt_osteosynthese = data._schwerpunkt_osteosynthese;
            this._schwerpunkt_arthroskopie = data._schwerpunkt_arthroskopie;
            this._schwerpunkt_augenheilkunde = data._schwerpunkt_augenheilkunde;
            this._schwerpunkt_reproduktionsmedizin = data._schwerpunkt_reproduktionsmedizin;
            this._schwerpunkt_ernaehrung = data._schwerpunkt_ernaehrung;
            this._schwerpunkt_notfallmedizin = data._schwerpunkt_notfallmedizin;
            this._schwerpunkt_endokrinologie = data._schwerpunkt_endokrinologie;
            this._schwerpunkt = data._schwerpunkt;
            this._seo_description = data._seo_description;
            this._seo_hiringorganization_name = data._seo_hiringorganization_name;
            this._seo_hiringorganization_sameas = data._seo_hiringorganization_sameas;
            this._seo_joblocation_addresslocality = data._seo_joblocation_addresslocality;
            this._seo_joblocation_streetaddress = data._seo_joblocation_streetaddress;
            this._seo_joblocation_addressregion = data._seo_joblocation_addressregion;
            this._seo_joblocation_postalcode = data._seo_joblocation_postalcode;
            this._seo_joblocation_addresscountry = data._seo_joblocation_addresscountry;
            this._slug = data._slug;
            this._slug_v2 = data._slug_v2;
            this._stammdaten_adresse = data._stammdaten_adresse;
            this._stammdaten_anrede = data._stammdaten_anrede;
            this._stammdaten_email = data._stammdaten_email;
            this._stammdaten_fax = data._stammdaten_fax;
            this._stammdaten_grussform = data._stammdaten_grussform;
            this._stammdaten_mobil = data._stammdaten_mobil;
            this._stammdaten_nachname = data._stammdaten_nachname;
            this._stammdaten_nr = data._stammdaten_nr;
            this._stammdaten_ort = data._stammdaten_ort;
            this._stammdaten_plz = data._stammdaten_plz;
            this._stammdaten_strasse = data._stammdaten_strasse;
            this._stammdaten_telefon = data._stammdaten_telefon;
            this._stammdaten_titel = data._stammdaten_titel;
            this._stammdaten_vorname = data._stammdaten_vorname;
            this._stammdaten_praxisname = data._stammdaten_praxisname;
            this._stammdaten_ansprechpartner = data._stammdaten_ansprechpartner;
            this._stammdaten_iso3166_1 = data._stammdaten_iso3166_1;
            this._stammdaten_iso3166_2 = data._stammdaten_iso3166_2;
            this._standort = data._standort;
            this._standort_beschreibung = data._standort_beschreibung;
            this._standort_distance = data._standort_distance;
            this._standort_einwohnerzahl = data._standort_einwohnerzahl;
            this._standort_anzahl_parkplatz = data._standort_anzahl_parkplatz;
            this._standort_entfernung_bahnhof = data._standort_entfernung_bahnhof;
            this._standort_entfernung_bushaltestelle = data._standort_entfernung_bushaltestelle;
            this._standort_entfernung_einkauf = data._standort_entfernung_einkauf;
            this._standort_entfernung_schule = data._standort_entfernung_schule;
            this._standort_entfernung_grundschule = data._standort_entfernung_grundschule;
            this._standort_entfernung_kindergarten = data._standort_entfernung_kindergarten;
            this._standort_hat_bahnhof = data._standort_hat_bahnhof;
            this._standort_hat_bushaltestelle = data._standort_hat_bushaltestelle;
            this._standort_hat_einkauf = data._standort_hat_einkauf;
            this._standort_hat_parkplatz = data._standort_hat_parkplatz;
            this._standort_hat_schule = data._standort_hat_schule;
            this._standort_hat_grundschule = data._standort_hat_grundschule;
            this._standort_hat_kindergarten = data._standort_hat_kindergarten;
            this._standort_hat_mitarbeiterparkplatz = data._standort_hat_mitarbeiterparkplatz;
            this._standort_hat_parkmoeglichkeiten = data._standort_hat_parkmoeglichkeiten;
            this._standort_hat_oeffentliche_anbindung = data._standort_hat_oeffentliche_anbindung;
            this._standort_immobilie = data._standort_immobilie;
            this._standort_immobilie_inklusive = data._standort_immobilie_inklusive;
            this._standort_geolocated = data._standort_geolocated;
            this._standort_mietzins = data._standort_mietzins;
            this._standort_lat = data._standort_lat;
            this._standort_lng = data._standort_lng;
            this._standort_land = data._standort_land;
            this._standort_plz = data._standort_plz;
            this._standort_region = data._standort_region;
            this._stellenart_assistenzstelle = data._stellenart_assistenzstelle;
            this._stellenart_praktikum = data._stellenart_praktikum;
            this._stellenart_praxisvertreter = data._stellenart_praxisvertreter;
            this._stellenart_tfa = data._stellenart_tfa;
            this._stellenart_praktikum_ausland = data._stellenart_praktikum_ausland;
            this._stellenart_selbststaendig = data._stellenart_selbststaendig;
            this._stellenart_teilhaber = data._stellenart_teilhaber;
            this._stellenart_tierarzt = data._stellenart_tierarzt;
            this._stellenart_tfa_ausbildung = data._stellenart_tfa_ausbildung;
            this._stellenart_dissertation = data._stellenart_dissertation;
            this._stellenart_sonstiges = data._stellenart_sonstiges;
            this._stellenart_is_empfehlung = data._stellenart_is_empfehlung;
            this._subtitle = data._subtitle;
            this._taetigkeit_ort = data._taetigkeit_ort;
            this._taetigkeit_nur_vor_ort = data._taetigkeit_nur_vor_ort;
            this._taetigkeit_nur_dezentral = data._taetigkeit_nur_dezentral;
            this._taetigkeit_ort_kombiniert = data._taetigkeit_ort_kombiniert;
            this._title = data._title;
            this._umfang_vollzeit = data._umfang_vollzeit;
            this._umfang_teilzeit = data._umfang_teilzeit;
            this._umfang_nebenbeschaeftigung = data._umfang_nebenbeschaeftigung;
            this._vormittag_dienstag = data._vormittag_dienstag;
            this._vormittag_donnerstag = data._vormittag_donnerstag;
            this._vormittag_freitag = data._vormittag_freitag;
            this._vormittag_mittwoch = data._vormittag_mittwoch;
            this._vormittag_montag = data._vormittag_montag;
            this._vormittag_samstag = data._vormittag_samstag;
            this._voraussetzungen_deutsche_approbation_tierarzt = data._voraussetzungen_deutsche_approbation_tierarzt;
            this._voraussetzungen_min_berufserfahrungen = data._voraussetzungen_min_berufserfahrungen;
            this._voraussetzungen_berufserfahrungen_in_jahren = data._voraussetzungen_berufserfahrungen_in_jahren;
            this._voraussetzungen_min_semester = data._voraussetzungen_min_semester;
            this._voraussetzungen_semester_anzahl = data._voraussetzungen_semester_anzahl;
            this._voraussetzungen_bereits_absolviertes_internship = data._voraussetzungen_bereits_absolviertes_internship;
            this._voraussetzungen_hat_titel = data._voraussetzungen_hat_titel;
            this._voraussetzungen_title_zusatzbezeichnung = data._voraussetzungen_title_zusatzbezeichnung;
            this._voraussetzungen_notdienst_bereitschaft = data._voraussetzungen_notdienst_bereitschaft;
            this._voraussetzungen_belastbarkeit = data._voraussetzungen_belastbarkeit;
            this._voraussetzungen_empathischer_umgang = data._voraussetzungen_empathischer_umgang;
            this._voraussetzungen_selbststaendiges_arbeiten = data._voraussetzungen_selbststaendiges_arbeiten;
            this._voraussetzungen_teamfaehigkeit = data._voraussetzungen_teamfaehigkeit;
            this._voraussetzungen_fuehrerschein = data._voraussetzungen_fuehrerschein;
            this._voraussetzungen_eigenes_auto = data._voraussetzungen_eigenes_auto;
            this._voraussetzungen_puenktlichkeit = data._voraussetzungen_puenktlichkeit;
            this._voraussetzungen_zuverlaessigkeit = data._voraussetzungen_zuverlaessigkeit;
            this._voraussetzungen_interesse_an_weiterbildung = data._voraussetzungen_interesse_an_weiterbildung;
            this._voraussetzungen_kollegialer_umgang = data._voraussetzungen_kollegialer_umgang;
            this._wunschort1_location_address = data._wunschort1_location_address;
            this._wunschort1_location = data._wunschort1_location;
            this._wunschort2_location_address = data._wunschort2_location_address;
            this._wunschort2_location = data._wunschort2_location;
            this._wunschort3_location_address = data._wunschort3_location_address;
            this._wunschort3_location = data._wunschort3_location;
            this._wunschort_flexibel = data._wunschort_flexibel;
            this._wunschorte = data._wunschorte;
            this._wochenstunden = data._wochenstunden;
            this._wochentag_montag = data._wochentag_montag;
            this._wochentag_dienstag = data._wochentag_dienstag;
            this._wochentag_mittwoch = data._wochentag_mittwoch;
            this._wochentag_donnerstag = data._wochentag_donnerstag;
            this._wochentag_freitag = data._wochentag_freitag;
            this._wochentag_samstag = data._wochentag_samstag;
            this._wochentag_sonntag = data._wochentag_sonntag;
            this._zusatzinfos = data._zusatzinfos;
            this._erreichbarkeit_uhrzeit = data._erreichbarkeit_uhrzeit;
        }
    }

    public static createInstance() {
        return new Request({
            'request_type': 0,
            'is_opted_in': false,
            'uuid': '',
            'user_uuid': '',
            'offer_type': 0,
            'create_date': '',
            'modify_date': '',
            'delete_date': '',
            'expire_date': '',
            'last_mail_date': '',
            'opt_in_date': '',
            'opt_out_date': '',
            'import_id': 0,
            'is_public': false,
            'is_intern': false,
            'need_payment': false,
            '_chiffre': '',
            '_admin_status': '',
            '_admin_is_public': false,
            '_admin_is_sold': false,
            '_admin_is_deleted': false,
            '_admin_is_reserved': false,
            '_admin_is_upper_class': false,
            '_admin_tvd_ansprechpartner': '',
            '_admin_zusatzinfos': '',
            '_arbeitsantritt': '',
            '_arbeitsantritt_monate': '',
            '_arbeitsumfang': '',
            '_arbeitsumfang_teilzeit': '',
            '_arbeitsumfang_vollzeit': '',
            '_arbeitsumfang_dauer': '',
            '_arbeitsumfang_dauer_min_wochen': 0,
            '_arbeitsumfang_dauer_max_wochen': 0,
            '_arbeitsumfang_dauer_sonstiges': '',
            '_arbeitsumfang_nebenbeschaeftigung': '',
            '_arbeitszeit_montag': '',
            '_arbeitszeit_dienstag': '',
            '_arbeitszeit_mittwoch': '',
            '_arbeitszeit_donnerstag': '',
            '_arbeitszeit_freitag': '',
            '_arbeitszeit_samstag': '',
            '_arbeitszeit_sonntag': '',
            '_ausstattung_autoclav': false,
            '_ausstattung_behandlungstisch': false,
            '_ausstattung_blutanalyse': false,
            '_ausstattung_boxen': false,
            '_ausstattung_computer_drucker': false,
            '_ausstattung_endoskop': false,
            '_ausstattung_gefriertruhe': false,
            '_ausstattung_inhouse_labor': false,
            '_ausstattung_klimaanlage': false,
            '_ausstattung_kuehlschrank': false,
            '_ausstattung_mikroskop': false,
            '_ausstattung_narkosegeraet': false,
            '_ausstattung_op_besteck': false,
            '_ausstattung_op_lampen': false,
            '_ausstattung_osteosynthese_set': false,
            '_ausstattung_otoskop': false,
            '_ausstattung_roentgen_analog': false,
            '_ausstattung_roentgen_digital': false,
            '_ausstattung_sonstiges': false,
            '_ausstattung_telefonanlagen': false,
            '_ausstattung_tierwaage': false,
            '_ausstattung_trockner': false,
            '_ausstattung_ultraschall': false,
            '_ausstattung_herzultraschall': false,
            '_ausstattung_waschmaschine': false,
            '_ausstattung_zahnsteineinheit': false,
            '_ausstattung_szintigraph': false,
            '_ausstattung_station': false,
            '_ausstattung_getrennter_wartebereich': false,
            '_ausstattung_software_zur_praxisverwaltung': false,
            '_ausstattung_crm': false,
            '_ausstattung_ct': false,
            '_ausstattung_mrt': false,
            '_ausstattung': '',
            '_ausgleich_notdienst': '',
            '_behandlung_op_mit_narkose': false,
            '_behandlung_ernaehrungsberatung': false,
            '_behandlung_osteosynthese': false,
            '_behandlung_stationaer': false,
            '_behandlung_osteopathie': false,
            '_behandlung_physiotherapie': false,
            '_behandlung_chiropraktik': false,
            '_behandlung_akupunktur': false,
            '_behandlung_herzultraschall': false,
            '_behandlung_ultraschall': false,
            '_behandlung_digitales_roentgen': false,
            '_behandlung_mobiles_roentgen': false,
            '_behandlung_zahnbehandlungen_mobil': false,
            '_behandlung_dentalroentgen': false,
            '_behandlung_inhouse_labor': false,
            '_behandlung_sonstiges': false,
            '_behandlung': '',
            '_behandlung_ct': false,
            '_behandlung_mrt': false,
            '_behandlung_szintigraphie': false,
            '_behandlung_endoskopie': false,
            '_benoetigte_unterlagen': '',
            '_benoetigte_bewerber_voraussetzungen': '',
            '_beschreibung_text': '',
            '_beschreibung_titel': '',
            '_besonderheit': '',
            '_besonderheit_sonstiges': false,
            '_besonderheit_cat_friendly': false,
            '_besonderheit_mitnahme_hund': false,
            '_besonderheit_moegl_doktorarbeit': false,
            '_besonderheit_moegl_teilhaberschaft': false,
            '_besonderheit_praktikumsverguetung': false,
            '_besonderheit_bez_fortbildung': false,
            '_besonderheit_wohnen_vor_ort': false,
            '_besonderheit_kein_nachtdienst': false,
            '_besonderheit_bkv': false,
            '_besonderheit_bav': false,
            '_besonderheit_eigenverantwortung': false,
            '_besonderheit_gehaltsmodell': false,
            '_besonderheit_gute_einarbeitung': false,
            '_besonderheit_gutes_team': false,
            '_besonderheit_familiaere': false,
            '_besonderheit_hohes_niveau': false,
            '_besonderheit_moderne_ausstattung': false,
            '_besonderheit_feedbackgespraeche': false,
            '_besonderheit_arbeitszeiterfassung': false,
            '_besonderheit_bezahlte_fortbildung': false,
            '_besonderheit_interne_fortbildungen': false,
            '_besonderheit_weihnachtsgeld': false,
            '_besonderheit_urlaubsgeld': false,
            '_besonderheit_dienstwagen': false,
            '_besonderheit_dienstwagen_privat': false,
            '_besonderheit_mittagessen_kostenlos': false,
            '_besonderheit_rotation_in_bereiche': false,
            '_besonderheit_sportangebot': false,
            '_besonderheit_teamevents': false,
            '_besonderheit_mitglied_vuk': false,
            '_besonderheit_praktikumsleitfaden': false,
            '_besonderheit_notdienstring': false,
            '_besonderheit_kein_notdienst': false,
            '_besonderheit_gvp_zertifiziert': false,
            '_besonderheit_praktikum_basics': false,
            '_besonderheit_vwl': false,
            '_besonderheit_fahrtkostenzuschuss': false,
            '_besonderheit_keine_nacht_und_wochenenddienste': false,
            '_besonderheit_nacht_und_wochenenddienste_auf_wunsch': false,
            '_besonderheit_nacht_und_wochenenddienste_geregelt': false,
            '_besonderheit_berufseinsteiger_willkommen': false,
            '_bezahlte_stelle': false,
            '_an_stelle_gekoppelt': false,
            '_datenschutz_akzeptiert': false,
            '_einrichtung_sonstiges': false,
            '_einrichtung_tierklinik': false,
            '_einrichtung_tierarztpraxis': false,
            '_einrichtung_fahrpraxis': false,
            '_einrichtung_fachtierarztpraxis': false,
            '_einrichtung_industrie': false,
            '_einrichtung_oeffentlicher_dienst': false,
            '_einrichtung_hochschule': false,
            '_einrichtung': '',
            '_externes_medium1': '',
            '_externes_medium2': '',
            '_externes_medium3': '',
            '_facharzt_abschluesse': 0,
            '_facharzt_ausbildung': false,
            '_facharzt_abschluss1': '',
            '_facharzt_abschluss2': '',
            '_facharzt_abschluss3': '',
            '_feedback_gefunden_ueber': '',
            '_feedback_gefunden_ueber_tvd': false,
            '_feedback_gefunden_ueber_empfehlung': false,
            '_feedback_gefunden_ueber_google': false,
            '_feedback_gefunden_ueber_newsletter': false,
            '_feedback_gefunden_ueber_facebook': false,
            '_feedback_gefunden_ueber_instagram': false,
            '_feedback_gefunden_ueber_sonstiges': false,
            '_fortbildungsbudget': 0,
            '_fortbildungsbudget_vorhanden': false,
            '_fortbildungsbudget_nach_vereinbarung': false,
            '_urlaubstage': '',
            '_fortbildungstage': '',
            '_fortbildungstage_nach_vereinbarung': false,
            '_fortbildung_regelm_intern': false,
            '_fortbildung_freie_tage': false,
            '_geplante_dauer': '',
            '_homepage': '',
            '_icon_name': '',
            '_infotext': '',
            '_lebensphase': '',
            '_mitarbeiter_assistenten': 0,
            '_mitarbeiter_auszubildende': 0,
            '_mitarbeiter_in_mutterschutz': 0,
            '_mitarbeiter_minijobs': 0,
            '_mitarbeiter_tfa': 0,
            '_mitarbeiter_ta': 0,
            '_mitarbeiter_sonstige': 0,
            '_nachmittag_dienstag': false,
            '_nachmittag_donnerstag': false,
            '_nachmittag_freitag': false,
            '_nachmittag_mittwoch': false,
            '_nachmittag_montag': false,
            '_nachmittag_samstag': false,
            '_nachricht': '',
            '_parallele_anstellung': false,
            '_praxis_abgabe_in': '',
            '_praxis_apothekenbestand': 0,
            '_praxis_art': '',
            '_praxis_art_sonstiges': false,
            '_praxis_art_kleintier': false,
            '_praxis_art_pferde': false,
            '_praxis_art_gemischt': false,
            '_praxis_art_rinder': false,
            '_praxis_art_gefluegel': false,
            '_praxis_art_schwein': false,
            '_praxis_art_reptilien': false,
            '_praxis_art_industrie': false,
            '_praxis_art_verwaltung': false,
            '_praxis_art_universitaet': false,
            '_praxis_art_heimtiere': false,
            '_praxis_art_hochschule': false,
            '_praxis_art_oeffentlicher_dienst': false,
            '_praxis_art_wildtiere': false,
            '_praxis_art_ziervoegel': false,
            '_praxis_hat_preisvorstellung': false,
            '_praxis_fachliche_spezialisierung': '',
            '_praxis_form': '',
            '_praxis_flaeche': 0,
            '_praxis_fortbildung': false,
            '_praxis_fortbildung_angebot': '',
            '_praxis_gruendungsjahr': '',
            '_praxis_hat_notdienst': false,
            '_praxis_notdienst': '',
            '_praxis_notdienst_auszahlung': false,
            '_praxis_notdienst_freizeitausgleich': false,
            '_praxis_sprechstunde': '',
            '_praxis_hat_terminsprechstunde': false,
            '_praxis_hat_vertretungsregelung': false,
            '_praxis_jahresnettoumsatz_2015': 0,
            '_praxis_jahresnettoumsatz_2016': 0,
            '_praxis_jahresnettoumsatz_2017': 0,
            '_praxis_jahresnettoumsatz_2018': 0,
            '_praxis_jahresnettoumsatz_2019': 0,
            '_praxis_jahresnettoumsatz_2020': 0,
            '_praxis_jahresnettoumsatz_2021': 0,
            '_praxis_jahresnettoumsatz_2022': 0,
            '_praxis_kurzbeschreibung': '',
            '_praxis_materieller_wert': 0,
            '_praxis_patientenbesitzer': 0,
            '_praxis_preisvorstellung': 0,
            '_praxis_sonstige_beschreibung': '',
            '_praxis_telefon_homepage_uebernahme': false,
            '_praxis_uebergabedatum': '',
            '_praxis_uebernahme_in': '',
            '_praxis_uebernahme': false,
            '_praxis_teilhaber': false,
            '_praxis_ueberschrift': '',
            '_praxis_zulassungsstatus': '',
            '_praxis_sprache_1': '',
            '_praxis_sprache_2': '',
            '_raeume_apotheke': 0,
            '_raeume_behandlung1': 0,
            '_raeume_behandlung2': 0,
            '_raeume_empfang': 0,
            '_raeume_labor': 0,
            '_raeume_op': 0,
            '_raeume_op_vorb': 0,
            '_raeume_roentgen': 0,
            '_raeume_sonstige': '',
            '_raeume_sozial': 0,
            '_raeume_station': 0,
            '_raeume_warten': 0,
            '_raeume_wc': 0,
            '_raeume_mitarbeiter': 0,
            '_raeume_vorhanden_apotheke': false,
            '_raeume_vorhanden_behandlung1': false,
            '_raeume_vorhanden_behandlung2': false,
            '_raeume_vorhanden_empfang': false,
            '_raeume_vorhanden_labor': false,
            '_raeume_vorhanden_op': false,
            '_raeume_vorhanden_op_vorb': false,
            '_raeume_vorhanden_roentgen': false,
            '_raeume_vorhanden_sozial': false,
            '_raeume_vorhanden_station': false,
            '_raeume_vorhanden_warten': false,
            '_raeume_vorhanden_wc': false,
            '_raeume_vorhanden_sonstige': false,
            '_raeume_vorhanden_mitarbeiter': false,
            '_ratio_exoten': 0,
            '_ratio_kleintiere': 0,
            '_ratio_nutztiere': 0,
            '_ratio_pferde': 0,
            '_ratio_rinder': 0,
            '_ratio_schweine': 0,
            '_ratio_gefluegel': 0,
            '_ratio_heimtiere': 0,
            '_ratio_reptilien': 0,
            '_ratio_oeffentlicher_dienst': 0,
            '_ratio_industrie': 0,
            '_ratio_sonstiges': 0,
            '_alle_regionen': false,
            '_region_bw': false,
            '_region_by': false,
            '_region_be': false,
            '_region_bb': false,
            '_region_hb': false,
            '_region_hh': false,
            '_region_he': false,
            '_region_mv': false,
            '_region_ni': false,
            '_region_nw': false,
            '_region_rp': false,
            '_region_sl': false,
            '_region_sn': false,
            '_region_sa': false,
            '_region_sh': false,
            '_region_th': false,
            '_region_0x': false,
            '_region_1x': false,
            '_region_2x': false,
            '_region_3x': false,
            '_region_4x': false,
            '_region_5x': false,
            '_region_6x': false,
            '_region_7x': false,
            '_region_8x': false,
            '_region_9x': false,
            '_schwerpunkt_apothekenmanagement': false,
            '_schwerpunkt_allgemeine_tiermedizin': false,
            '_schwerpunkt_alternativmedizin': false,
            '_schwerpunkt_fahrpraxis': false,
            '_schwerpunkt_bestandsbetreuung': false,
            '_schwerpunkt_chirurgie': false,
            '_schwerpunkt_gynaekologie': false,
            '_schwerpunkt_haut_augenerkrankung': false,
            '_schwerpunkt_innere_medizin': false,
            '_schwerpunkt_intensivmedizin': false,
            '_schwerpunkt_kardiologie': false,
            '_schwerpunkt_klassische_tiermedizin': false,
            '_schwerpunkt_neurologie': false,
            '_schwerpunkt_notdienste': false,
            '_schwerpunkt_onkologie': false,
            '_schwerpunkt_operationen_knochen': false,
            '_schwerpunkt_operationen_weichteile': false,
            '_schwerpunkt_operationen': false,
            '_schwerpunkt_op_assistenz': false,
            '_schwerpunkt_roentgen': false,
            '_schwerpunkt_sonstiges': false,
            '_schwerpunkt_stationaere_aufnahme': false,
            '_schwerpunkt_stationaere_betreuung': false,
            '_schwerpunkt_sprechstunde': false,
            '_schwerpunkt_ultraschall': false,
            '_schwerpunkt_untersuchung_laborproben': false,
            '_schwerpunkt_ueberwachung_von_narkose': false,
            '_schwerpunkt_verhaltenstherapie': false,
            '_schwerpunkt_veranstaltungsbetreuung': false,
            '_schwerpunkt_zahn_kieferchirugie': false,
            '_schwerpunkt_zahn_behandlungen': false,
            '_schwerpunkt_zahnheilkunde': false,
            '_schwerpunkt_nachtdienste': false,
            '_schwerpunkt_nachtdienste_anzahl': 0,
            '_schwerpunkt_wochenenddienste': false,
            '_schwerpunkt_wochenenddienste_anzahl': 0,
            '_schwerpunkt_ophthalmologie': false,
            '_schwerpunkt_dermatologie': false,
            '_schwerpunkt_orthopaedie': false,
            '_schwerpunkt_alternative_heilmethoden': false,
            '_schwerpunkt_weichteilchirurgie': false,
            '_schwerpunkt_osteosynthese': false,
            '_schwerpunkt_arthroskopie': false,
            '_schwerpunkt_augenheilkunde': false,
            '_schwerpunkt_reproduktionsmedizin': false,
            '_schwerpunkt_ernaehrung': false,
            '_schwerpunkt_notfallmedizin': false,
            '_schwerpunkt_endokrinologie': false,
            '_schwerpunkt': '',
            '_seo_description': '',
            '_seo_hiringorganization_name': '',
            '_seo_hiringorganization_sameas': '',
            '_seo_joblocation_addresslocality': '',
            '_seo_joblocation_streetaddress': '',
            '_seo_joblocation_addressregion': '',
            '_seo_joblocation_postalcode': '',
            '_seo_joblocation_addresscountry': '',
            '_slug': '',
            '_slug_v2': '',
            '_stammdaten_adresse': '',
            '_stammdaten_anrede': '',
            '_stammdaten_email': '',
            '_stammdaten_fax': '',
            '_stammdaten_grussform': '',
            '_stammdaten_mobil': '',
            '_stammdaten_nachname': '',
            '_stammdaten_nr': '',
            '_stammdaten_ort': '',
            '_stammdaten_plz': '',
            '_stammdaten_strasse': '',
            '_stammdaten_telefon': '',
            '_stammdaten_titel': '',
            '_stammdaten_vorname': '',
            '_stammdaten_praxisname': '',
            '_stammdaten_ansprechpartner': '',
            '_stammdaten_iso3166_1': '',
            '_stammdaten_iso3166_2': '',
            '_standort': '',
            '_standort_beschreibung': '',
            '_standort_distance': 0,
            '_standort_einwohnerzahl': 0,
            '_standort_anzahl_parkplatz': 0,
            '_standort_entfernung_bahnhof': 0,
            '_standort_entfernung_bushaltestelle': 0,
            '_standort_entfernung_einkauf': 0,
            '_standort_entfernung_schule': 0,
            '_standort_entfernung_grundschule': 0,
            '_standort_entfernung_kindergarten': 0,
            '_standort_hat_bahnhof': false,
            '_standort_hat_bushaltestelle': false,
            '_standort_hat_einkauf': false,
            '_standort_hat_parkplatz': false,
            '_standort_hat_schule': false,
            '_standort_hat_grundschule': false,
            '_standort_hat_kindergarten': false,
            '_standort_hat_mitarbeiterparkplatz': false,
            '_standort_hat_parkmoeglichkeiten': false,
            '_standort_hat_oeffentliche_anbindung': false,
            '_standort_immobilie': '',
            '_standort_immobilie_inklusive': false,
            '_standort_geolocated': false,
            '_standort_mietzins': 0,
            '_standort_lat': 0,
            '_standort_lng': 0,
            '_standort_land': '',
            '_standort_plz': '',
            '_standort_region': '',
            '_stellenart_assistenzstelle': false,
            '_stellenart_praktikum': false,
            '_stellenart_praxisvertreter': false,
            '_stellenart_tfa': false,
            '_stellenart_praktikum_ausland': false,
            '_stellenart_selbststaendig': false,
            '_stellenart_teilhaber': false,
            '_stellenart_tierarzt': false,
            '_stellenart_tfa_ausbildung': false,
            '_stellenart_dissertation': false,
            '_stellenart_sonstiges': false,
            '_stellenart_is_empfehlung': false,
            '_subtitle': '',
            '_taetigkeit_ort': '',
            '_taetigkeit_nur_vor_ort': false,
            '_taetigkeit_nur_dezentral': false,
            '_taetigkeit_ort_kombiniert': false,
            '_title': '',
            '_umfang_vollzeit': false,
            '_umfang_teilzeit': false,
            '_umfang_nebenbeschaeftigung': false,
            '_vormittag_dienstag': false,
            '_vormittag_donnerstag': false,
            '_vormittag_freitag': false,
            '_vormittag_mittwoch': false,
            '_vormittag_montag': false,
            '_vormittag_samstag': false,
            '_voraussetzungen_deutsche_approbation_tierarzt': false,
            '_voraussetzungen_min_berufserfahrungen': false,
            '_voraussetzungen_berufserfahrungen_in_jahren': 0,
            '_voraussetzungen_min_semester': false,
            '_voraussetzungen_semester_anzahl': 0,
            '_voraussetzungen_bereits_absolviertes_internship': false,
            '_voraussetzungen_hat_titel': false,
            '_voraussetzungen_title_zusatzbezeichnung': false,
            '_voraussetzungen_notdienst_bereitschaft': false,
            '_voraussetzungen_belastbarkeit': false,
            '_voraussetzungen_empathischer_umgang': false,
            '_voraussetzungen_selbststaendiges_arbeiten': false,
            '_voraussetzungen_teamfaehigkeit': false,
            '_voraussetzungen_fuehrerschein': false,
            '_voraussetzungen_eigenes_auto': false,
            '_voraussetzungen_puenktlichkeit': false,
            '_voraussetzungen_zuverlaessigkeit': false,
            '_voraussetzungen_interesse_an_weiterbildung': false,
            '_voraussetzungen_kollegialer_umgang': false,
            '_wunschort1_location_address': '',
            '_wunschort1_location': '',
            '_wunschort2_location_address': '',
            '_wunschort2_location': '',
            '_wunschort3_location_address': '',
            '_wunschort3_location': '',
            '_wunschort_flexibel': false,
            '_wunschorte': 0,
            '_wochenstunden': '',
            '_wochentag_montag': false,
            '_wochentag_dienstag': false,
            '_wochentag_mittwoch': false,
            '_wochentag_donnerstag': false,
            '_wochentag_freitag': false,
            '_wochentag_samstag': false,
            '_wochentag_sonntag': false,
            '_zusatzinfos': '',
            '_erreichbarkeit_uhrzeit': '',
        });
    }
}

export class RequestOffer extends ApiResponseData<RequestOffer>
{
    public static id = 'id';
    public static request_uuid = 'request_uuid';
    public static offer_uuid = 'offer_uuid';
    public static create_date = 'create_date';
    public static status_text = 'status_text';



    public id: number;
    public request_uuid: string;
    public offer_uuid: string;
    public create_date: string;
    public status_text: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.id = data.id;
            this.request_uuid = data.request_uuid;
            this.offer_uuid = data.offer_uuid;
            this.create_date = data.create_date;
            this.status_text = data.status_text;
        }
    }

    public static createInstance() {
        return new RequestOffer({
            'id': 0,
            'request_uuid': '',
            'offer_uuid': '',
            'create_date': '',
            'status_text': '',
        });
    }
}

export class User extends ApiResponseData<User>
{
    public static uuid = 'uuid';
    public static username = 'username';
    public static password = 'password';
    public static email = 'email';
    public static iv = 'iv';
    public static role = 'role';
    public static api_key = 'api_key';
    public static last_login = 'last_login';
    public static delete_date = 'delete_date';
    public static modify_date = 'modify_date';
    public static create_date = 'create_date';
    public static plain = 'plain';
    public static _stammdaten_adresse = '_stammdaten_adresse';
    public static _stammdaten_anrede = '_stammdaten_anrede';
    public static _stammdaten_email = '_stammdaten_email';
    public static _stammdaten_fax = '_stammdaten_fax';
    public static _stammdaten_grussform = '_stammdaten_grussform';
    public static _stammdaten_mobil = '_stammdaten_mobil';
    public static _stammdaten_nachname = '_stammdaten_nachname';
    public static _stammdaten_nr = '_stammdaten_nr';
    public static _stammdaten_ort = '_stammdaten_ort';
    public static _stammdaten_plz = '_stammdaten_plz';
    public static _stammdaten_strasse = '_stammdaten_strasse';
    public static _stammdaten_telefon = '_stammdaten_telefon';
    public static _stammdaten_titel = '_stammdaten_titel';
    public static _stammdaten_vorname = '_stammdaten_vorname';
    public static _stammdaten_praxisname = '_stammdaten_praxisname';
    public static _stammdaten_ansprechpartner = '_stammdaten_ansprechpartner';
    public static _stammdaten_iso3166_1 = '_stammdaten_iso3166_1';
    public static _stammdaten_iso3166_2 = '_stammdaten_iso3166_2';



    public uuid: string;
    public username: string;
    public password: string;
    public email: string;
    public iv: string;
    public role: string;
    public api_key: string;
    public last_login: string;
    public delete_date: string;
    public modify_date: string;
    public create_date: string;
    public plain: string;
    public _stammdaten_adresse: string;
    public _stammdaten_anrede: string;
    public _stammdaten_email: string;
    public _stammdaten_fax: string;
    public _stammdaten_grussform: string;
    public _stammdaten_mobil: string;
    public _stammdaten_nachname: string;
    public _stammdaten_nr: string;
    public _stammdaten_ort: string;
    public _stammdaten_plz: string;
    public _stammdaten_strasse: string;
    public _stammdaten_telefon: string;
    public _stammdaten_titel: string;
    public _stammdaten_vorname: string;
    public _stammdaten_praxisname: string;
    public _stammdaten_ansprechpartner: string;
    public _stammdaten_iso3166_1: string;
    public _stammdaten_iso3166_2: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.uuid = data.uuid;
            this.username = data.username;
            this.password = data.password;
            this.email = data.email;
            this.iv = data.iv;
            this.role = data.role;
            this.api_key = data.api_key;
            this.last_login = data.last_login;
            this.delete_date = data.delete_date;
            this.modify_date = data.modify_date;
            this.create_date = data.create_date;
            this.plain = data.plain;
            this._stammdaten_adresse = data._stammdaten_adresse;
            this._stammdaten_anrede = data._stammdaten_anrede;
            this._stammdaten_email = data._stammdaten_email;
            this._stammdaten_fax = data._stammdaten_fax;
            this._stammdaten_grussform = data._stammdaten_grussform;
            this._stammdaten_mobil = data._stammdaten_mobil;
            this._stammdaten_nachname = data._stammdaten_nachname;
            this._stammdaten_nr = data._stammdaten_nr;
            this._stammdaten_ort = data._stammdaten_ort;
            this._stammdaten_plz = data._stammdaten_plz;
            this._stammdaten_strasse = data._stammdaten_strasse;
            this._stammdaten_telefon = data._stammdaten_telefon;
            this._stammdaten_titel = data._stammdaten_titel;
            this._stammdaten_vorname = data._stammdaten_vorname;
            this._stammdaten_praxisname = data._stammdaten_praxisname;
            this._stammdaten_ansprechpartner = data._stammdaten_ansprechpartner;
            this._stammdaten_iso3166_1 = data._stammdaten_iso3166_1;
            this._stammdaten_iso3166_2 = data._stammdaten_iso3166_2;
        }
    }

    public static createInstance() {
        return new User({
            'uuid': '',
            'username': '',
            'password': '',
            'email': '',
            'iv': '',
            'role': '',
            'api_key': '',
            'last_login': '',
            'delete_date': '',
            'modify_date': '',
            'create_date': '',
            'plain': '',
            '_stammdaten_adresse': '',
            '_stammdaten_anrede': '',
            '_stammdaten_email': '',
            '_stammdaten_fax': '',
            '_stammdaten_grussform': '',
            '_stammdaten_mobil': '',
            '_stammdaten_nachname': '',
            '_stammdaten_nr': '',
            '_stammdaten_ort': '',
            '_stammdaten_plz': '',
            '_stammdaten_strasse': '',
            '_stammdaten_telefon': '',
            '_stammdaten_titel': '',
            '_stammdaten_vorname': '',
            '_stammdaten_praxisname': '',
            '_stammdaten_ansprechpartner': '',
            '_stammdaten_iso3166_1': '',
            '_stammdaten_iso3166_2': '',
        });
    }
}

export class Like extends ApiResponseData<Like>
{
    public static uuid = 'uuid';
    public static like_type = 'like_type';
    public static like_value = 'like_value';
    public static entity_uuid = 'entity_uuid';
    public static source_identifier = 'source_identifier';
    public static dislike_info = 'dislike_info';



    public uuid: string;
    public like_type: number;
    public like_value: number;
    public entity_uuid: string;
    public source_identifier: string;
    public dislike_info: string;

    public constructor(data = null) {
        super(data);

        if (data) {
            this.uuid = data.uuid;
            this.like_type = data.like_type;
            this.like_value = data.like_value;
            this.entity_uuid = data.entity_uuid;
            this.source_identifier = data.source_identifier;
            this.dislike_info = data.dislike_info;
        }
    }

    public static createInstance() {
        return new Like({
            'uuid': '',
            'like_type': 0,
            'like_value': 0,
            'entity_uuid': '',
            'source_identifier': '',
            'dislike_info': '',
        });
    }
}

