var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"id":_vm.id,"header-class":"h4 m-0","header-text-variant":"light","header-bg-variant":"primary","body-class":"p-0","no-close-on-backdrop":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.label)+" erstellen\n    ")]},proxy:true}],null,true)},[_vm._v(" "),_c('promised',{attrs:{"promise":_vm.promise,"overlay":""},scopedSlots:_vm._u([{key:"rejected",fn:function(){return [_c('div',{staticClass:"jumbotron m-3"},[_c('editable-content',{attrs:{"unique-identifier":_vm.namespaced('modal-rejected')}},[_vm._v("\n            Es ist ein Fehler aufgetreten."),_c('br'),_vm._v("\n            Versuchen Sie es später nochmal.\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.promise = null}}},[_vm._v("\n            OK\n          ")])],1)]},proxy:true},{key:"resolved",fn:function(){return [_c('div',{staticClass:"jumbotron m-3 text-center"},[_c('editable-content',{attrs:{"unique-identifier":_vm.namespaced('modal-resolved')}},[_vm._v("\n            Vielen Dank, Ihr "+_vm._s(_vm.label)+" wurde erstellt!"),_c('br'),_vm._v("\n            Zur abschließenden Aktivierung klicken Sie bitte auf den Bestätigungslink in der Mail, den wir Ihnen gerade zugesendet haben. So verpassen Sie in Zukunft keine Angebote mehr.\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.hide(_vm.id)}}},[_vm._v("\n            Schließen\n          ")])],1)]},proxy:true},{key:"pending",fn:function(){return [_c('div',{staticClass:"m-3"},[_c('editable-content',{attrs:{"unique-identifier":_vm.namespaced('modal-pending')}})],1),_vm._v(" "),_c('div',{staticClass:"m-3"},[_c('validate-input',{attrs:{"label":"Vorname","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var state = ref.state;
return [_c('b-form-input',{attrs:{"state":state},model:{value:(value._stammdaten_vorname),callback:function ($$v) {_vm.$set(value, "_stammdaten_vorname", $$v)},expression:"value._stammdaten_vorname"}})]}}],null,true),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_vm._v(" "),_c('validate-input',{attrs:{"label":"Nachname","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var state = ref.state;
return [_c('b-form-input',{attrs:{"state":state},model:{value:(value._stammdaten_nachname),callback:function ($$v) {_vm.$set(value, "_stammdaten_nachname", $$v)},expression:"value._stammdaten_nachname"}})]}}],null,true),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_vm._v(" "),_c('validate-input',{attrs:{"label":"E-Mail-Adresse","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var state = ref.state;
return [_c('b-form-input',{attrs:{"state":state},model:{value:(value._stammdaten_email),callback:function ($$v) {_vm.$set(value, "_stammdaten_email", $$v)},expression:"value._stammdaten_email"}})]}}],null,true),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_vm._v(" "),_c('div',{staticClass:"m-3"},[_c('filter-badges',{attrs:{"label":"Filterauswahl","rules":"min_value:1"},on:{"remove":_vm.removeFilter},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_vm._v(" "),_c('validate-input',{attrs:{"rules":{required: {allowFalse: false}}},scopedSlots:_vm._u([{key:"default",fn:function(slot){return [_c('b-form-checkbox',{staticClass:"mx-3",attrs:{"state":slot.state},model:{value:(slot.value._datenschutz_akzeptiert),callback:function ($$v) {_vm.$set(slot.value, "_datenschutz_akzeptiert", $$v)},expression:"slot.value._datenschutz_akzeptiert"}},[_vm._v("\n            Ich habe die "),_c('a',{attrs:{"href":"/datenschutz","target":"_blank"}},[_vm._v("\n              Datenschutzerklärung\n            ")]),_vm._v(" gelesen und akzeptiere sie hiermit.\n          ")])]}}],null,true),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"light-gray"},on:{"click":function($event){return _vm.$bvModal.hide(_vm.id)}}},[_vm._v("\n            Abbrechen\n          ")]),_vm._v(" "),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return handleSubmit(_vm.createAgent)}}},[_vm._v("\n            "+_vm._s(_vm.label)+" erstellen\n          ")])],1)]},proxy:true}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }